import classNames from 'classnames';
import React from 'react';
import {
  PinColors,
  pinColorToColorValue,
} from '../features/common/models/pin-colors';
import styles from './MaterialTimelineItem.module.scss';

interface Props {
  pinName: string;
  date: Date | any;
  color: PinColors | any;
  connector: boolean;
}

export const MaterialTimelineItem: React.FC<Props> = ({
  color,
  date,
  pinName,
  connector,
}) => {
  return (
    <div
      className={classNames(styles['gaas-material-timeline-item'], {
        [styles['gaas-material-timeline-item--end']]: !connector,
      })}
    >
      <div className={styles['gaas-material-timeline-item--content--left']}>
        {date}
      </div>
      <div
        className={classNames(
          styles['gaas-material-timeline-item--dot'],
          styles[
            `gaas-material-timeline-item--dot--${pinColorToColorValue(color)}`
          ]
        )}
      />
      <div className={styles['gaas-material-timeline-item--content--right']}>
        {pinName}
      </div>
      {connector && (
        <div className={styles['gaas-material-timeline-item--connector']} />
      )}
    </div>
  );
};
