import { createReducer } from 'typesafe-actions';
import {
  ApiAnyNotification,
  ApiCustomer,
  ApiPermissionInformation,
} from '../../models/openapi/openapiTypes';
import {
  asyncOptimisticResourceRequest,
  AsyncResource,
  asyncResourceFailure,
  asyncResourceRequest,
  asyncResourceSuccess,
} from '../../store/async-resource';
import { GatewayStateView } from '../inventory/models/gateway-state-view';
import {
  fireRayAsync,
  getCustomersAsync,
  getGatewayStatesAsync,
  getMaintenanceNoteAsync,
  getNotificationsAsync,
  notificationPush,
  onboardCustomersAsync,
  readAllNotificationsAsync,
  readNotificationAsync,
  selectCustomer,
  signedInAsync,
  signOutAsync,
} from './actions';
import { MaintenanceNote } from './models/maintenance-note';
import { UserRole } from './models/user-role';

interface State {
  user?: { user: string; role: UserRole; token: string };
  userRole?: UserRole;
  userError?: Error;
  customers?: AsyncResource<ApiCustomer[]>;
  notifications?: AsyncResource<ApiAnyNotification[]>;
  notificationMarkingPending?: AsyncResource<{}>;
  selectedCustomer?: ApiCustomer;
  firewallRayId?: AsyncResource<string>;
  readonly stage: string;
  gatewayStates: { [customerId: string]: AsyncResource<GatewayStateView[]> };
  maintenanceNote: AsyncResource<MaintenanceNote | null>;
  permissions?: AsyncResource<ApiPermissionInformation>;
}

const initialState = {
  stage: process.env.REACT_APP_STAGE,
  gatewayStates: {},
} as State;

export const commonReducer = createReducer(initialState)
  .handleAction(signedInAsync.success, (state, { payload: user }) => ({
    ...state,
    user,
    userError: undefined,
  }))
  .handleAction(signedInAsync.failure, (state, { payload: error }) => ({
    ...state,
    userError: error,
  }))
  .handleAction(signOutAsync.success, () => ({
    ...initialState,
  }))
  .handleAction(getCustomersAsync.request, state => ({
    ...state,
    customers: asyncResourceRequest(),
    selectedCustomer: undefined,
  }))
  .handleAction(getCustomersAsync.success, (state, { payload: customers }) => ({
    ...state,
    customers: asyncResourceSuccess(customers),
  }))
  .handleAction(getCustomersAsync.failure, (state, { payload: error }) => ({
    ...state,
    customers: asyncResourceFailure(error),
  }))
  .handleAction(getNotificationsAsync.request, state => ({
    ...state,
    notifications:
      state.notifications?.data === undefined
        ? asyncResourceRequest()
        : asyncOptimisticResourceRequest(state.notifications.data),
  }))
  .handleAction(
    getNotificationsAsync.success,
    (state, { payload: notifications }) => ({
      ...state,
      notifications: asyncResourceSuccess(notifications),
    })
  )
  .handleAction(getNotificationsAsync.failure, (state, { payload: error }) => ({
    ...state,
    notifications: asyncResourceFailure(error),
  }))
  .handleAction(notificationPush, (state, { payload: { notifications } }) => {
    const previousNotifications = state.notifications;
    if (
      (previousNotifications?.loading ?? true) ||
      previousNotifications?.error
    ) {
      // ignore updates while fetch is under way
      return state;
    }

    const previousNotificationData = previousNotifications?.data ?? [];
    const newNotificationData = [...notifications, ...previousNotificationData];
    newNotificationData.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );

    return {
      ...state,
      notifications: asyncResourceSuccess(newNotificationData),
    };
  })
  .handleAction(readAllNotificationsAsync.request, state => ({
    ...state,
    notificationMarkingPending: asyncResourceRequest(),
  }))
  .handleAction(
    readAllNotificationsAsync.failure,
    (state, { payload: error }) => ({
      ...state,
      notificationMarkingPending: asyncResourceFailure(error),
    })
  )
  .handleAction(readAllNotificationsAsync.success, state => {
    state.notifications?.data?.forEach(it => {
      it.readAt = new Date().toISOString();
    });
    return {
      ...state,
      notificationMarkingPending: asyncResourceSuccess({}),
    };
  })
  .handleAction(
    readNotificationAsync.request,
    (state, { payload: notificationId }) => {
      const notification = (state.notifications?.data ?? []).find(
        notification => notification.id === notificationId
      );
      if (notification == null) {
        return state;
      }
      notification.readAt = new Date().toISOString();
      return state;
    }
  )
  .handleAction(readNotificationAsync.failure, (state, { payload: { id } }) => {
    const notification = (state.notifications?.data ?? []).find(
      notification => notification.id === id
    );
    if (notification == null) {
      return state;
    }
    notification.readAt = undefined;
    return state;
  })
  .handleAction(fireRayAsync.request, state => ({
    ...state,
    firewallRayId: asyncResourceRequest(),
  }))
  .handleAction(fireRayAsync.failure, (state, { payload: error }) => ({
    ...state,
    firewallRayId: asyncResourceFailure(error),
  }))
  .handleAction(fireRayAsync.success, (state, { payload: ray }) => ({
    ...state,
    firewallRayId: asyncResourceSuccess(ray),
  }))
  .handleAction(getMaintenanceNoteAsync.request, state => ({
    ...state,
    maintenanceNote: asyncResourceRequest(),
  }))
  .handleAction(
    getMaintenanceNoteAsync.failure,
    (state, { payload: error }) => ({
      ...state,
      maintenanceNote: asyncResourceFailure(error),
    })
  )
  .handleAction(getMaintenanceNoteAsync.success, (state, { payload: ray }) => ({
    ...state,
    maintenanceNote: asyncResourceSuccess(ray),
  }))
  .handleAction(
    getGatewayStatesAsync.request,
    (state, { payload: customerId }) => ({
      ...state,
      gatewayStates: {
        ...state.gatewayStates,
        [customerId]: asyncResourceRequest(),
      },
    })
  )
  .handleAction(
    getGatewayStatesAsync.failure,
    (state, { payload: { customerId, error } }) => ({
      ...state,
      gatewayStates: {
        ...state.gatewayStates,
        [customerId]: asyncResourceFailure(error),
      },
    })
  )
  .handleAction(
    getGatewayStatesAsync.success,
    (state, { payload: { customerId, states } }) => ({
      ...state,
      gatewayStates: {
        ...state.gatewayStates,
        [customerId]: asyncResourceSuccess(states),
      },
    })
  )
  .handleAction(onboardCustomersAsync.request, state => ({
    ...state,
    permissions: asyncResourceRequest(),
  }))
  .handleAction(onboardCustomersAsync.success, (state, { payload }) => ({
    ...state,
    permissions: asyncResourceSuccess(payload.permissions),
  }))
  .handleAction(onboardCustomersAsync.failure, state => ({
    ...state,
    permissions: asyncResourceRequest(),
  }))
  .handleAction(selectCustomer, (state, { payload: customer }) => ({
    ...state,
    selectedCustomer: customer,
  }));

export default commonReducer;
export type CommonState = ReturnType<typeof commonReducer>;
