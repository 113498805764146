import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
} from 'rxjs/operators';
import { RootAction, RootState, Services, isActionOf } from 'typesafe-actions';
import { selectedCustomerChanged } from '../common/actions';
import {
  changeContainerGroupAsync,
  createInventoryThresholdAsync,
  deleteInventoryThresholdAsync,
  getContainerNoteAsync,
  getContainerStateByBarcodeAsync,
  getInventoryAsync,
  getStockContainerAsync,
  listActiveInventoryThresholdEventsAsync,
  listInventoryThresholdsAsync,
  returnContainerAsync,
  takeContainerAsync,
  updateContainerNoteAsync,
  updateInventoryThresholdAsync,
} from './actions';
import { discardGroupConsumptionHistory } from '../groups/actions';

export const getInventoryOnCustomerChange: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = action$ =>
  action$.pipe(
    filter(isActionOf(selectedCustomerChanged)),
    filter(({ payload: customer }) => !!customer),
    distinctUntilChanged(),
    map(({ payload: customer }) =>
      getInventoryAsync.request(customer!.customerId)
    )
  );

export const getInventory: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  _,
  { customer }
) =>
  action$.pipe(
    filter(isActionOf(getInventoryAsync.request)),
    switchMap(({ payload: customerId }) =>
      customer.getInventory(customerId).pipe(
        map(inventory => getInventoryAsync.success(inventory)),
        catchError(error => of(getInventoryAsync.failure(error)))
      )
    )
  );

export const getContainer: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  _,
  { customer }
) =>
  action$.pipe(
    filter(isActionOf(getContainerStateByBarcodeAsync.request)),
    switchMap(({ payload: barcode }) =>
      customer.getContainerByBarcode(barcode).pipe(
        map(container =>
          getContainerStateByBarcodeAsync.success({ barcode, container })
        ),
        catchError(error =>
          of(getContainerStateByBarcodeAsync.failure({ barcode, error }))
        )
      )
    )
  );

export const getStockContainer: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { customer }) =>
  action$.pipe(
    filter(isActionOf(getStockContainerAsync.request)),
    switchMap(({ payload: customerId }) =>
      customer.getStockContainer(customerId).pipe(
        map(container => getStockContainerAsync.success(container)),
        catchError(error => of(getStockContainerAsync.failure(error)))
      )
    )
  );

export const takeBottle: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  _,
  { customer }
) =>
  action$.pipe(
    filter(isActionOf(takeContainerAsync.request)),
    switchMap(({ payload: { customerId, bottleId, groupId } }) =>
      customer.takeContainer(customerId, bottleId, groupId).pipe(
        map(container => ({
          action: takeContainerAsync.success({ container, groupId }),
          customerId,
        })),
        catchError(error =>
          of({
            action: takeContainerAsync.failure({ error, bottleId }),
            customerId,
          })
        )
      )
    ),
    switchMap(({ action, customerId }) =>
      from([action, discardGroupConsumptionHistory(customerId)])
    )
  );

export const returnBottle: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  _,
  { customer }
) =>
  action$.pipe(
    filter(isActionOf(returnContainerAsync.request)),
    switchMap(({ payload: { customerId, bottleId, fillLevel } }) =>
      customer.returnContainer(customerId, bottleId, fillLevel).pipe(
        map(container => ({
          action: returnContainerAsync.success(container),
          customerId,
        })),
        catchError(error =>
          of({
            action: returnContainerAsync.failure({ error, bottleId }),
            customerId,
          })
        )
      )
    ),
    switchMap(({ action, customerId }) =>
      from([action, discardGroupConsumptionHistory(customerId)])
    )
  );

export const createInventoryThreshold: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { customer }) =>
  action$.pipe(
    filter(isActionOf(createInventoryThresholdAsync.request)),
    switchMap(({ payload: inventoryThresholdView }) =>
      customer.createInventoryThreshold(inventoryThresholdView).pipe(
        map(() => createInventoryThresholdAsync.success({})),
        catchError(error =>
          of(
            createInventoryThresholdAsync.failure({
              error,
              inventoryThreshold: inventoryThresholdView,
            })
          )
        )
      )
    )
  );

export const listInventoryThresholds: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { customer }) =>
  action$.pipe(
    filter(isActionOf(listInventoryThresholdsAsync.request)),
    switchMap(({ payload: { customerId } }) =>
      customer.listInventoryThresholds(customerId).pipe(
        map(inventoryThresholds =>
          listInventoryThresholdsAsync.success({
            inventoryThresholds,
            customerId,
          })
        ),
        catchError(error =>
          of(listInventoryThresholdsAsync.failure({ error, customerId }))
        )
      )
    )
  );

export const listInventoryThresholdPassedEvents: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { customer }) =>
  action$.pipe(
    filter(isActionOf(listActiveInventoryThresholdEventsAsync.request)),
    switchMap(({ payload: { customerId } }) =>
      customer.listInventoryThresholdPassedEvents(customerId).pipe(
        map(events =>
          listActiveInventoryThresholdEventsAsync.success({
            events,
            customerId,
          })
        ),
        catchError(error =>
          of(
            listActiveInventoryThresholdEventsAsync.failure({
              error,
              customerId,
            })
          )
        )
      )
    )
  );

export const deleteInventoryThreshold: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { customer }) =>
  action$.pipe(
    filter(isActionOf(deleteInventoryThresholdAsync.request)),
    switchMap(({ payload: thresholdView }) =>
      customer
        .deleteInventoryThreshold(
          thresholdView.customerId,
          thresholdView.materialId,
          thresholdView.id
        )
        .pipe(
          map(() => deleteInventoryThresholdAsync.success({})),
          catchError(error =>
            of(
              deleteInventoryThresholdAsync.failure({
                error,
                inventoryThreshold: thresholdView,
              })
            )
          )
        )
    )
  );

export const updateInventoryThreshold: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { customer }) =>
  action$.pipe(
    filter(isActionOf(updateInventoryThresholdAsync.request)),
    switchMap(({ payload: { threshold } }) =>
      customer.updateInventoryThreshold(threshold).pipe(
        map(() => updateInventoryThresholdAsync.success({})),
        catchError(error => of(updateInventoryThresholdAsync.failure(error)))
      )
    )
  );

export const getContainerNote: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { customer }) =>
  action$.pipe(
    filter(isActionOf(getContainerNoteAsync.request)),
    switchMap(({ payload: { containerId, customerId } }) =>
      customer.getContainerNote(customerId, containerId).pipe(
        map(note => {
          return getContainerNoteAsync.success({
            containerId,
            containerNote: note,
          });
        }),
        catchError(error => {
          return of(getContainerNoteAsync.failure({ containerId, error }));
        })
      )
    )
  );

export const setContainerNote: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { customer }) =>
  action$.pipe(
    filter(isActionOf(updateContainerNoteAsync.request)),
    switchMap(({ payload: { containerId, content, customerId } }) =>
      customer.updateContainerNote(customerId, containerId, content).pipe(
        map(() => ({
          customerId,
          action: updateContainerNoteAsync.success({
            containerId,
          }),
        })),
        catchError(error =>
          of({
            customerId,
            action: updateContainerNoteAsync.failure({ containerId, error }),
          })
        )
      )
    ),
    switchMap(({ action, customerId }) =>
      from([action, getInventoryAsync.request(customerId)])
    )
  );

export const changeContainerGroupAssignment: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { customer }) =>
  action$.pipe(
    filter(isActionOf(changeContainerGroupAsync.request)),
    switchMap(({ payload: { containerId, request, customerId } }) =>
      customer
        .changeContainerGroupAssignment(customerId, containerId, request)
        .pipe(
          map(newAssignment => ({
            action: changeContainerGroupAsync.success({
              containerId,
              customerId,
              newAssignment,
            }),
            customerId,
          })),
          catchError(error =>
            of({
              customerId,
              action: changeContainerGroupAsync.failure({
                containerId,
                customerId,
                error,
              }),
            })
          )
        )
    ),
    switchMap(({ action, customerId }) =>
      from([action, getInventoryAsync.request(customerId)])
    )
  );
