import IconClose from '@mui/icons-material/CloseRounded';
import IconError from '@mui/icons-material/ErrorRounded';
import { IconButton } from '@mui/material';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import styles from './Error.module.scss';

type Props = {
  error?: string;
  onClose?: () => void;
};

const ErrorPopup: React.FC<Props> = ({ error, onClose }) => {
  const [close, setClose] = useState(true);

  useEffect(() => {
    if (error != null && error !== '') {
      setClose(false);
    }
  }, [error, setClose]);

  return (
    <div
      className={classNames(styles.background, { [styles.displayNone]: close })}
    >
      <div className={styles.container}>
        <IconError />
        <p>
          <strong>Error:</strong> {error}
        </p>
        <div className={styles.close}>
          <IconButton
            onClick={() => {
              setClose(true);
              !!onClose && onClose();
            }}
            size="large"
          >
            <IconClose />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default ErrorPopup;
