import { createReducer } from 'typesafe-actions';
import {
  AsyncResource,
  asyncResourceFailure,
  asyncResourceRequest,
  asyncResourceSuccess,
} from '../../store/async-resource';
import { signOutAsync } from '../common/actions';
import {
  clearGatewayAssignmentCacheSync,
  clearValveAssignmentCacheSync,
  clearValveOfflineEvents,
  clearValveSusValueEvents,
  clearValveThresholdPassedEvents,
  fetchNextThresholdPassedEventsAsync,
  fetchNextValveOfflineEventsAsync,
  fetchNextValveSuspiciousValueEventsAsync,
  getGatewayAssignmentsAsync,
  getGatewayAsync,
  getValveAssignmentsAsync,
  getValveAsync,
  listGatewaysAsync,
  listValvesAsync,
  pairGatewayToCustomerAsync,
  pairValveToCustomerAsync,
  unpairGatewayFromCustomerAsync,
  unpairValveFromCustomerAsync,
  updateGatewayNoteAsync,
  updateValveNoteAsync,
} from './actions';
import { PaginatedEntityList } from '../common/models/paginated-entity-list';
import {
  ValveCustomerAssignment,
  ValveWithCurrentAssignment,
} from './models/valves-with-assignment';
import { sortingParamsToStoreIdentifier } from './selectors';
import {
  AdminListValveEventsResponse,
  SuspiciousValueDiagnosticEvent,
  ThresholdPassedEventAdminView,
  ValveOfflineDiagnosticEvent,
} from './models/admin-list-valve-events-response';
import {
  GatewayAssignment,
  GatewayWithCurrentAssignment,
} from './models/gateway-with-assignment';

interface State {
  pairValveToCustomer?: AsyncResource<{}>;
  pairGatewayToCustomer?: AsyncResource<{}>;
  unpairValveFromCustomer?: AsyncResource<{}>;
  unpairGatewayFromCustomer?: AsyncResource<{}>;
  updateValveNote?: AsyncResource<{}>;
  updateGatewayNote?: AsyncResource<{}>;
  valveList: {
    [p: string]: AsyncResource<PaginatedEntityList<ValveWithCurrentAssignment>>;
  };
  valves: {
    [p: string]: AsyncResource<ValveWithCurrentAssignment>;
  };
  gateways: {
    [p: string]: AsyncResource<GatewayWithCurrentAssignment>;
  };
  valveAssignments: {
    [p: string]: AsyncResource<PaginatedEntityList<ValveCustomerAssignment>>;
  };
  gatewayAssignments: {
    [p: string]: AsyncResource<PaginatedEntityList<GatewayAssignment>>;
  };
  valveOfflineEvents: {
    [p: string]: AsyncResource<
      AdminListValveEventsResponse<ValveOfflineDiagnosticEvent>
    >;
  };
  valveSuspiciousValueEvents: {
    [p: string]: AsyncResource<
      AdminListValveEventsResponse<SuspiciousValueDiagnosticEvent>
    >;
  };
  thresholdPassedEvents: {
    [p: string]: AsyncResource<
      AdminListValveEventsResponse<ThresholdPassedEventAdminView>
    >;
  };
  gatewayList: {
    [p: string]: AsyncResource<
      PaginatedEntityList<GatewayWithCurrentAssignment>
    >;
  };
}

const initialState: State = {
  valveList: {},
  gatewayList: {},
  valves: {},
  gateways: {},
  valveAssignments: {},
  gatewayAssignments: {},
  valveOfflineEvents: {},
  valveSuspiciousValueEvents: {},
  thresholdPassedEvents: {},
};

export const administrationReducer = createReducer(initialState)
  .handleAction(signOutAsync.success, () => ({
    ...initialState,
  }))
  .handleAction(listGatewaysAsync.request, (state, { payload: params }) => ({
    ...state,
    gatewayList: {
      ...state.gatewayList,
      [sortingParamsToStoreIdentifier(params)]: asyncResourceRequest(),
    },
  }))
  .handleAction(
    listGatewaysAsync.success,
    (state, { payload: { result: data, params } }) => ({
      ...state,
      gatewayList: {
        ...state.gatewayList,
        [sortingParamsToStoreIdentifier(params)]: asyncResourceSuccess(data),
      },
    })
  )
  .handleAction(
    listGatewaysAsync.failure,
    (state, { payload: { error, params } }) => ({
      ...state,
      gatewayList: {
        ...state.gatewayList,
        [sortingParamsToStoreIdentifier(params)]: asyncResourceFailure(error),
      },
    })
  )
  .handleAction(listValvesAsync.request, (state, { payload: params }) => ({
    ...state,
    valveList: {
      ...state.valveList,
      [sortingParamsToStoreIdentifier(params)]: asyncResourceRequest(),
    },
  }))
  .handleAction(
    listValvesAsync.success,
    (state, { payload: { result: data, params } }) => ({
      ...state,
      valveList: {
        ...state.valveList,
        [sortingParamsToStoreIdentifier(params)]: asyncResourceSuccess(data),
      },
    })
  )
  .handleAction(
    listValvesAsync.failure,
    (state, { payload: { error, params } }) => ({
      ...state,
      valveList: {
        ...state.valveList,
        [sortingParamsToStoreIdentifier(params)]: asyncResourceFailure(error),
      },
    })
  )
  .handleAction(getValveAsync.request, (state, request) => ({
    ...state,
    valves: {
      ...state.valves,
      [request.payload]: asyncResourceRequest(),
    },
  }))
  .handleAction(getValveAsync.success, (state, response) => ({
    ...state,
    valves: {
      ...state.valves,
      [response.payload.id]: asyncResourceSuccess(response.payload),
    },
  }))
  .handleAction(getValveAsync.failure, (state, request) => ({
    ...state,
    valves: {
      ...state.valves,
      [request.payload.id]: asyncResourceFailure(request.payload.error),
    },
  }))
  .handleAction(getGatewayAsync.request, (state, request) => ({
    ...state,
    gateways: {
      ...state.gateways,
      [request.payload]: asyncResourceRequest(),
    },
  }))
  .handleAction(getGatewayAsync.success, (state, response) => ({
    ...state,
    gateways: {
      ...state.gateways,
      [response.payload.id]: asyncResourceSuccess(response.payload),
    },
  }))
  .handleAction(getGatewayAsync.failure, (state, request) => ({
    ...state,
    gateways: {
      ...state.gateways,
      [request.payload.id]: asyncResourceFailure(request.payload.error),
    },
  }))
  .handleAction(
    getValveAssignmentsAsync.request,
    (state, { payload: { id, params } }) => ({
      ...state,
      valveAssignments: {
        ...state.valveAssignments,
        [sortingParamsToStoreIdentifier({
          ...params,
          filter: { deviceId: id },
        })]: asyncResourceRequest(),
      },
    })
  )
  .handleAction(
    getValveAssignmentsAsync.success,
    (state, { payload: { id, params, assignments } }) => ({
      ...state,
      valveAssignments: {
        ...state.valveAssignments,
        [sortingParamsToStoreIdentifier({
          ...params,
          filter: { deviceId: id },
        })]: asyncResourceSuccess(assignments),
      },
    })
  )
  .handleAction(
    getValveAssignmentsAsync.failure,
    (state, { payload: { id, params, error } }) => ({
      ...state,
      valveAssignments: {
        ...state.valveAssignments,
        [sortingParamsToStoreIdentifier({
          ...params,
          filter: { deviceId: id },
        })]: asyncResourceFailure(error),
      },
    })
  )
  .handleAction(
    getGatewayAssignmentsAsync.request,
    (state, { payload: { id, params } }) => ({
      ...state,
      gatewayAssignments: {
        ...state.gatewayAssignments,
        [sortingParamsToStoreIdentifier({
          ...params,
          filter: { gatewayId: id },
        })]: asyncResourceRequest(),
      },
    })
  )
  .handleAction(
    getGatewayAssignmentsAsync.success,
    (state, { payload: { id, params, assignments } }) => ({
      ...state,
      gatewayAssignments: {
        ...state.gatewayAssignments,
        [sortingParamsToStoreIdentifier({
          ...params,
          filter: { gatewayId: id },
        })]: asyncResourceSuccess(assignments),
      },
    })
  )
  .handleAction(
    getGatewayAssignmentsAsync.failure,
    (state, { payload: { id, params, error } }) => ({
      ...state,
      gatewayAssignments: {
        ...state.gatewayAssignments,
        [sortingParamsToStoreIdentifier({
          ...params,
          filter: { gatewayId: id },
        })]: asyncResourceFailure(error),
      },
    })
  )
  .handleAction(clearValveAssignmentCacheSync, state => ({
    ...state,
    valveAssignments: initialState.valveAssignments,
    valves: initialState.valves,
  }))
  .handleAction(clearGatewayAssignmentCacheSync, state => ({
    ...state,
    gatewayAssignments: initialState.gatewayAssignments,
    gateways: initialState.gateways,
  }))
  .handleAction(pairValveToCustomerAsync.request, (state, response) => ({
    ...state,
    pairValveToCustomer: asyncResourceRequest(),
  }))
  .handleAction(pairValveToCustomerAsync.success, (state, response) => ({
    ...state,
    pairValveToCustomer: asyncResourceSuccess({}),
  }))
  .handleAction(
    pairValveToCustomerAsync.failure,
    (state, { payload: error }) => ({
      ...state,
      pairValveToCustomer: asyncResourceFailure(error),
    })
  )
  .handleAction(unpairValveFromCustomerAsync.request, (state, response) => ({
    ...state,
    unpairValveFromCustomer: asyncResourceRequest(),
  }))
  .handleAction(unpairValveFromCustomerAsync.success, (state, response) => ({
    ...state,
    unpairValveFromCustomer: asyncResourceSuccess({}),
  }))
  .handleAction(
    unpairValveFromCustomerAsync.failure,
    (state, { payload: error }) => ({
      ...state,
      unpairValveFromCustomer: asyncResourceFailure(error),
    })
  )
  .handleAction(pairGatewayToCustomerAsync.request, (state, response) => ({
    ...state,
    pairGatewayToCustomer: asyncResourceRequest(),
  }))
  .handleAction(pairGatewayToCustomerAsync.success, (state, response) => ({
    ...state,
    pairGatewayToCustomer: asyncResourceSuccess({}),
  }))
  .handleAction(
    pairGatewayToCustomerAsync.failure,
    (state, { payload: error }) => ({
      ...state,
      pairGatewayToCustomer: asyncResourceFailure(error),
    })
  )
  .handleAction(unpairGatewayFromCustomerAsync.request, (state, response) => ({
    ...state,
    unpairGatewayFromCustomer: asyncResourceRequest(),
  }))
  .handleAction(unpairGatewayFromCustomerAsync.success, (state, response) => ({
    ...state,
    unpairGatewayFromCustomer: asyncResourceSuccess({}),
  }))
  .handleAction(
    unpairGatewayFromCustomerAsync.failure,
    (state, { payload: error }) => ({
      ...state,
      unpairGatewayFromCustomer: asyncResourceFailure(error),
    })
  )
  .handleAction(updateValveNoteAsync.request, (state, response) => ({
    ...state,
    updateValveNote: asyncResourceRequest(),
  }))
  .handleAction(updateValveNoteAsync.success, (state, response) => ({
    ...state,
    updateValveNote: asyncResourceSuccess({}),
  }))
  .handleAction(
    updateValveNoteAsync.failure,
    (state, { payload: { error } }) => ({
      ...state,
      updateValveNote: asyncResourceFailure(error),
    })
  )
  .handleAction(updateGatewayNoteAsync.request, (state, response) => ({
    ...state,
    updateGatewayNote: asyncResourceRequest(),
  }))
  .handleAction(updateGatewayNoteAsync.success, (state, response) => ({
    ...state,
    updateGatewayNote: asyncResourceSuccess({}),
  }))
  .handleAction(
    updateGatewayNoteAsync.failure,
    (state, { payload: { error } }) => ({
      ...state,
      updateGatewayNote: asyncResourceFailure(error),
    })
  )
  .handleAction(
    fetchNextValveOfflineEventsAsync.request,
    (state, { payload: { valveId } }) => {
      const oldState = state.valveOfflineEvents[valveId];
      let newState =
        asyncResourceRequest<
          AdminListValveEventsResponse<ValveOfflineDiagnosticEvent>
        >();
      if (oldState != null) {
        newState = oldState;
        newState.loading = true;
      }
      return {
        ...state,
        valveOfflineEvents: {
          ...state.valveOfflineEvents,
          [valveId]: newState,
        },
      };
    }
  )
  .handleAction(
    fetchNextValveOfflineEventsAsync.success,
    (
      state,
      {
        payload: {
          response: { events, lastKey },
          valveId,
        },
      }
    ) => {
      const oldState = state?.valveOfflineEvents?.[valveId]?.data?.events ?? [];
      return {
        ...state,
        valveOfflineEvents: {
          ...state.valveOfflineEvents,
          [valveId]: asyncResourceSuccess({
            events: oldState.concat(events),
            lastKey,
          }),
        },
      };
    }
  )
  .handleAction(clearValveOfflineEvents, (state, { payload: { valveId } }) => {
    if (state?.valveOfflineEvents?.[valveId] != null) {
      delete state.valveOfflineEvents[valveId];
    }
    return {
      ...state,
      valveOfflineEvents: {
        ...state.valveOfflineEvents,
      },
    };
  })
  .handleAction(
    fetchNextValveSuspiciousValueEventsAsync.request,
    (state, { payload: { valveId } }) => {
      const oldState = state.valveSuspiciousValueEvents[valveId];
      let newState =
        asyncResourceRequest<
          AdminListValveEventsResponse<SuspiciousValueDiagnosticEvent>
        >();
      if (oldState != null) {
        newState = oldState;
        newState.loading = true;
      }
      return {
        ...state,
        valveSuspiciousValueEvents: {
          ...state.valveSuspiciousValueEvents,
          [valveId]: newState,
        },
      };
    }
  )
  .handleAction(clearValveSusValueEvents, (state, { payload: { valveId } }) => {
    if (state?.valveSuspiciousValueEvents?.[valveId] != null) {
      delete state.valveSuspiciousValueEvents[valveId];
    }
    return {
      ...state,
      valveSuspiciousValueEvents: {
        ...state.valveSuspiciousValueEvents,
      },
    };
  })
  .handleAction(
    fetchNextValveSuspiciousValueEventsAsync.success,
    (
      state,
      {
        payload: {
          response: { events, lastKey },
          valveId,
        },
      }
    ) => {
      const oldState =
        state?.valveSuspiciousValueEvents?.[valveId]?.data?.events ?? [];
      return {
        ...state,
        valveSuspiciousValueEvents: {
          ...state.valveSuspiciousValueEvents,
          [valveId]: asyncResourceSuccess({
            events: oldState.concat(events),
            lastKey,
          }),
        },
      };
    }
  )
  .handleAction(
    fetchNextThresholdPassedEventsAsync.request,
    (state, { payload: { valveId } }) => {
      const oldState = state.thresholdPassedEvents[valveId];
      let newState =
        asyncResourceRequest<
          AdminListValveEventsResponse<ThresholdPassedEventAdminView>
        >();
      if (oldState != null) {
        newState = oldState;
        newState.loading = true;
      }
      return {
        ...state,
        thresholdPassedEvents: {
          ...state.thresholdPassedEvents,
          [valveId]: newState,
        },
      };
    }
  )
  .handleAction(
    clearValveThresholdPassedEvents,
    (state, { payload: { valveId } }) => {
      if (state?.thresholdPassedEvents?.[valveId] != null) {
        delete state.thresholdPassedEvents[valveId];
      }
      return {
        ...state,
        thresholdPassedEvents: {
          ...state.thresholdPassedEvents,
        },
      };
    }
  )
  .handleAction(
    fetchNextThresholdPassedEventsAsync.success,
    (
      state,
      {
        payload: {
          response: { events, lastKey },
          valveId,
        },
      }
    ) => {
      const oldState =
        state?.thresholdPassedEvents?.[valveId]?.data?.events ?? [];
      return {
        ...state,
        thresholdPassedEvents: {
          ...state.thresholdPassedEvents,
          [valveId]: asyncResourceSuccess({
            events: oldState.concat(events),
            lastKey,
          }),
        },
      };
    }
  );

export default administrationReducer;
export type AdministrationState = ReturnType<typeof administrationReducer>;
