import * as commonSelectors from '../../common/selectors';
import * as shopSelectors from '../selectors';
import * as shopActions from '../actions';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { Button } from '../../../stories/Button';
import { useHistory } from 'react-router-dom';
import { useShopCustomerNumber } from '../util/ShopCustomerNumber';
import styles from './FreeSubscriptionWizard.module.scss';
import { Checkbox } from '../../../stories/Checkbox';
import { TextButton } from '../../../stories/TextButton';
import { useState } from 'react';

const mapStateToProps = (state: RootState) => ({
  user: commonSelectors.user(state.common),
  shopUser: shopSelectors.shopUser(state.shop),
  freeSubscriptionCreationRequest: shopSelectors.freeSubscriptionCreationState(
    state.shop
  ),
});

const dispatchProps = {
  createFreeSubscription: shopActions.createFreeSubscriptionAsync.request,
};

interface ComponentProps {}

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const FreeSubscriptionWizard: React.FC<Props> = ({
  freeSubscriptionCreationRequest,
  createFreeSubscription,
}) => {
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const history = useHistory();
  const sapCustomerNo = useShopCustomerNumber();
  const loading = freeSubscriptionCreationRequest?.loading ?? false;
  const error: { code: string } | undefined =
    freeSubscriptionCreationRequest?.error as unknown as
      | { code: string }
      | undefined;
  const creationSuccessfull: boolean =
    freeSubscriptionCreationRequest?.data?.sapCustomerNo === sapCustomerNo;
  const errorIsSubscriptionExistsError =
    error?.code === 'ShopSubscriptionExistsError';

  const createContent = (
    <>
      <h1>GaaS® FREE - kostenlose Version freischalten</h1>
      <p>
        Möchten Sie Ihr Kundenkonto:{' '}
        <span className={styles['gaas-shopFree--customerNo']}>
          {sapCustomerNo}
        </span>{' '}
        für die Nutzung von GaaS® FREE freischalten?
      </p>
      <p>
        Sollten Sie Fragen zu GaaS® FREE haben, melden Sie sich gerne über{' '}
        <a href="mailto:support@mail.gaas.westfalen.com">
          <TextButton label="support@mail.gaas.westfalen.com" inline />
        </a>
      </p>
      <Checkbox
        label={
          <div>
            Hiermit akzeptiere ich den Vetrag und die{' '}
            <TextButton label="Widerrufsbelehrung" inline />. Die{' '}
            <a
              href="https://westfalen.com/de/de/datenschutz/"
              target="_blank"
              rel="noreferrer"
            >
              <TextButton label="Datenschutzerklärung" inline />
            </a>{' '}
            und Informationen nach Art. 13 DSGVO habe ich zur Kenntnis genommen.
          </div>
        }
        checked={termsAccepted}
        onClick={() => setTermsAccepted(!termsAccepted)}
      />

      <div className={styles['gaas-shopFree--buttons']}>
        <Button
          label="Abbrechen"
          disabled={loading}
          variant="outlined"
          size="l"
          onClick={() => history.push('/')}
        />
        <Button
          label="Kostenlos freischalten"
          loading={loading}
          size="l"
          variant="success"
          onClick={() => createFreeSubscription({ sapCustomerNo })}
          disabled={!termsAccepted}
        />
      </div>
    </>
  );

  const alreadyExistsContent = (
    <>
      <h1>Sie haben bereits einen Zugang zu GaaS®</h1>
      <p>
        Für den Kunden {sapCustomerNo} konnte kein Testzugang erstellt werden,
        da bereits ein Zugang existiert. Bei Fragen zu diesem Zugang wenden Sie
        sich an Ihren GaaS®-Administrator oder über{' '}
        <a href="mailto:support@mail.gaas.westfalen.com">
          <TextButton label="support@mail.gaas.westfalen.com" inline />
        </a>{' '}
        unter Angabe der obigen Kunden-Informationen.
      </p>

      <div className={styles['gaas-shopFree--buttons']}>
        <Button
          label="Jetzt zu GaaS®"
          loading={loading}
          size="l"
          onClick={() => history.push('/')}
          disabled={!termsAccepted}
        />
      </div>
    </>
  );

  const creationSuccessfullContent = (
    <>
      <h1>GaaS® FREE - kostenlose Version</h1>
      <p>
        Wir haben Ihr Kundenkonto für GaaS® freigeschaltet. Ihre Organisation
        kann den Service jetzt verwenden. Bitte beachten Sie, dass Sie aktuell
        nur die kostenlose Version von GaaS® nutzen.
      </p>
      <p>
        Innerhalb der nächsten Minuten erhalten Sie eine E-Mail mit weiteren
        Informationen.
      </p>
      <p>
        Wenn Sie von den Vorteilen von GaaS® TRANSPARENZ profitieren möchten,
        können Sie sich jederzeit dazu anmelden.
      </p>

      <div className={styles['gaas-shopFree--buttons']}>
        <Button
          label="Jetzt zu GaaS®"
          loading={loading}
          size="l"
          onClick={() => history.push('/')}
          disabled={!termsAccepted}
        />
      </div>
    </>
  );

  let content = createContent;
  if (errorIsSubscriptionExistsError) {
    content = alreadyExistsContent;
  } else if (creationSuccessfull) {
    content = creationSuccessfullContent;
  }

  return (
    <div className={styles['gaas-shopFree--background']}>
      <div className={styles['gaas-shopFree']}>{content}</div>
    </div>
  );
};

export default connect(mapStateToProps, dispatchProps)(FreeSubscriptionWizard);
