import { StockContainer } from './stock-container';

export enum PairingType {
  manual = 'manual',
  barcode = 'barcode',
  bluetooth = 'bluetooth',
}

export interface ContainerValvePairing {
  deviceIdentifier: string;
  container: StockContainer;
  paringType: PairingType;
  timestamp: number;
}
