import { Route, Switch } from 'react-router-dom';
import React from 'react';
import SalesforceAuthenticator from '../components/SalesforceAuthenticator';
import PublicBottlePage from '../features/public/screens/PublicBottlePage';
import { FrontendEnvironment, getEnvironment } from '../util/amplifyConfig';
import LocalAuthenticator from '../components/LocalAuthenticator';
import PublicShopPage from '../features/shop/screens/PublicShopPage';
import PublicFreeSubscriptionPage from '../features/shop/screens/PublicFreeSubscriptionPage';

const UnauthenticatedRoutes: React.FC<{}> = () => {
  const authenticator =
    getEnvironment() === FrontendEnvironment.LOCAL
      ? LocalAuthenticator
      : SalesforceAuthenticator;

  return (
    <Switch>
      <Route
        exact
        path="/public/inspect-bottle/:barcode"
        component={PublicBottlePage}
      />
      <Route
        exact
        path="/public/shop/"
        component={() => <PublicShopPage standalone={true} />}
      />
      <Route
        exact
        path="/public/shop/free"
        component={() => <PublicFreeSubscriptionPage standalone={true} />}
      />
      <Route
        exact
        path="/public/shop/:plan"
        component={() => <PublicShopPage standalone={true} />}
      />
      <Route path="*" component={authenticator} />
    </Switch>
  );
};

export default UnauthenticatedRoutes;
