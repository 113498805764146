import { amplifyAuthConfig, amplifyOAuthConfig } from './amplifyConfig';
import { AuthOptions, AwsCognitoOAuthOpts } from './fixedAwsAuthTypes';

export type GaaSAuthConfig = Pick<
  AuthOptions,
  'region' | 'userPoolWebClientId'
> &
  Pick<
    AwsCognitoOAuthOpts,
    'scope' | 'responseType' | 'redirectSignIn' | 'domain'
  > & {
    identityProviderName: string;
  };

export const gaasAuthConfig: GaaSAuthConfig = {
  // The identityProviderName must match the one defined as ProviderName in the
  // salesforce cognito template, see
  // https://bitbucket.org/odpat7/gaas-backend/src/7f371c2e650dc92a320a282800c7d4af1bf7e5f7/gaas-cognito/salesforce/template.yaml#lines-122
  identityProviderName:
    process.env.REACT_APP_STAGE === 'production'
      ? 'WestfalenID'
      : 'WagStagingWestfalenId',
  redirectSignIn: amplifyOAuthConfig.redirectSignIn,
  domain: amplifyOAuthConfig.domain,
  region: amplifyAuthConfig.region,
  responseType: amplifyOAuthConfig.responseType,
  scope: amplifyOAuthConfig.scope,
  userPoolWebClientId: amplifyAuthConfig.userPoolWebClientId,
};
