import { asyncResourceSelector } from '../../store/async-resource';
import { CommonState } from './reducer';
import {
  ApiAnyNotification,
  ApiGaasAction,
  ApiPermissionInformation,
  GaasSubscription,
  GaasUserRole,
} from '../../models/openapi/openapiTypes';
import { PermissionState } from './models/permission-state';
import { PermissionQueryResponse } from './models/permission-query-response';

export const user = (state: CommonState) => state.user;
export const loggedIn = (state: CommonState) => !!state.user;
export const userError = (state: CommonState) => state.userError;

export const customers = (state: CommonState) =>
  asyncResourceSelector(state.customers);

export const notifications = (state: CommonState) =>
  asyncResourceSelector<ApiAnyNotification[]>(state.notifications);

export const maintenanceNote = (state: CommonState) =>
  asyncResourceSelector(state.maintenanceNote);

export const firewallRayId = (state: CommonState) =>
  asyncResourceSelector(state.firewallRayId);

export const notificationsMarkingPending = (state: CommonState) =>
  asyncResourceSelector(state.notificationMarkingPending);

export const selectedCustomer = (state: CommonState) => state.selectedCustomer;

export const gatewayStates = (customerId: string, state: CommonState) =>
  state.gatewayStates[customerId];

export const stage = (state: CommonState) => state.stage;

export const hasPermissionForCustomer = (
  action: ApiGaasAction,
  customerId: string,
  state: CommonState
): PermissionQueryResponse => {
  const { permissions } = state;

  const { loading, error, data } = asyncResourceSelector(permissions);
  if (!data || loading || error) {
    return [
      PermissionState.DENIED_LOADING,
      {},
      {
        currentRole: GaasUserRole.Empty,
        currentSubscription: GaasSubscription.Empty,
      },
    ];
  }

  const permissionsForUser = data.userPermissions[customerId];
  if (!permissionsForUser) {
    return [
      PermissionState.DENIED_ASSIGNMENT,
      {},
      {
        currentRole: GaasUserRole.Empty,
        currentSubscription: GaasSubscription.Empty,
      },
    ];
  }

  const actionPermission = permissionsForUser.actions[action];
  if (!actionPermission) {
    return [
      PermissionState.DENIED_ASSIGNMENT,
      {},
      {
        currentRole: GaasUserRole.Empty,
        currentSubscription: GaasSubscription.Empty,
      },
    ];
  }

  const minRole: GaasUserRole | undefined = Object.values(GaasUserRole)
    .map(
      (
        role
      ): [
        GaasUserRole,
        ApiPermissionInformation['templates']['roles']['role-free']
      ] => [role, data.templates.roles[role]]
    )
    .map(([role, roleData]): [GaasUserRole, number, boolean] => [
      role,
      roleData.weight,
      roleData.actions.includes(action),
    ])
    .filter(([, , hasAction]) => hasAction)
    .sort((a, b) => a[1] - b[1])[0]?.[0];
  const minSubscription: GaasSubscription | undefined = Object.values(
    GaasSubscription
  )
    .map(
      (
        subscription
      ): [
        GaasSubscription,
        ApiPermissionInformation['templates']['subscriptions']['subscription-free']
      ] => [subscription, data.templates.subscriptions[subscription]]
    )
    .map(([subscription, subData]): [GaasSubscription, number, boolean] => [
      subscription,
      subData.weight,
      subData.actions.includes(action),
    ])
    .filter(([, , hasAction]) => hasAction)
    .sort((a, b) => a[1] - b[1])[0]?.[0];

  switch (actionPermission) {
    case 'GRANTED':
      return [
        PermissionState.GRANTED,
        {},
        {
          currentRole: permissionsForUser.role,
          currentSubscription: permissionsForUser.subscription,
        },
      ];
    case 'DENIED_ROLE':
      return [
        PermissionState.DENIED_ROLE,
        { requiredRole: minRole },
        {
          currentRole: permissionsForUser.role,
          currentSubscription: permissionsForUser.subscription,
        },
      ];
    case 'DENIED_SUBSCRIPTION':
      return [
        PermissionState.DENIED_SUBSCRIPTION,
        { requiredSubscription: minSubscription },
        {
          currentRole: permissionsForUser.role,
          currentSubscription: permissionsForUser.subscription,
        },
      ];
  }
};

export const hasPermission = (
  action: ApiGaasAction,
  state: CommonState
): PermissionQueryResponse => {
  const { selectedCustomer } = state;
  if (!selectedCustomer) {
    return [
      PermissionState.GRANTED,
      {},
      {
        currentRole: GaasUserRole.Empty,
        currentSubscription: GaasSubscription.Empty,
      },
    ];
  }
  return hasPermissionForCustomer(action, selectedCustomer.customerId, state);
};
