import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { ValveHistoryBucket } from '../../../services/valve-service';
import { Button } from '../../../stories/Button';
import { ContentSwitcher } from '../../../stories/ContentSwitcher';
import { Loading } from '../../../stories/Loading';
import { Modal } from '../../../stories/Modal';
import { Download } from '../../../stories/icons';
import { selectedCustomer } from '../../common/selectors';
import * as digidrumiActions from '../actions';
import * as digidrumiSelectors from '../selectors';
import styles from './DigiDrumiHistoryModal.module.scss';
import HistoryLineChart from './HistoryLineChart';

const mapStateToProps = (state: RootState) => ({
  selectValveHistory: (deviceIdentifier: string, bucket: ValveHistoryBucket) =>
    digidrumiSelectors.valveHistory(state.digidrumi, deviceIdentifier, bucket),
  selectValveHistoryExport: (
    deviceIdentifier: string,
    bucket: ValveHistoryBucket
  ) =>
    digidrumiSelectors.valveHistoryExport(
      state.digidrumi,
      deviceIdentifier,
      bucket
    ),
  currentCustomer: selectedCustomer(state.common),
});

const dispatchProps = {
  getValveHistory: digidrumiActions.getValveHistoryAsync.request,
  getValveHistoryExport: digidrumiActions.getValveHistoryExportAsync.request,
};

type ComponentProps = {
  open: boolean;
  onClose: () => void;
  deviceId: string;
  serialNumber: string;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const DigiDrumiHistoryModal: React.FC<Props> = ({
  open,
  onClose,
  deviceId,
  serialNumber,
  selectValveHistory,
  selectValveHistoryExport,
  getValveHistory,
  getValveHistoryExport,
}) => {
  const [activeTabId, setActiveTabId] = useState<number>(1);
  const [historyBucket, setHistoryBucket] = useState<ValveHistoryBucket>(
    ValveHistoryBucket.D7
  );
  const [exportHref, setExportHref] = useState<string>('');
  const exportButtonRef = useRef<HTMLAnchorElement | null>(null);

  const historyData = selectValveHistory(deviceId, historyBucket);
  const historyDataExport = selectValveHistoryExport(deviceId, historyBucket);

  useEffect(() => {
    if (!open) {
      return;
    }

    if (!historyData.loading && !historyData.data) {
      getValveHistory({
        serialNumber,
        bucket: historyBucket,
        deviceId,
      });
    }
  }, [
    getValveHistory,
    historyData,
    historyBucket,
    deviceId,
    serialNumber,
    open,
  ]);

  useEffect(() => {
    if (historyDataExport.loading || !historyDataExport.data) {
      return;
    }

    if (exportHref !== '') {
      window.URL.revokeObjectURL(exportHref);
    }
    const blob = new Blob([historyDataExport.data], { type: 'text/csv' });
    setExportHref(window.URL.createObjectURL(blob));
    exportButtonRef.current?.click();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    historyDataExport.data,
    historyDataExport.loading,
    historyBucket,
    deviceId,
    setExportHref,
  ]);

  return (
    <Modal
      title="Verlauf Flaschendruck"
      open={open}
      onClose={onClose}
      fullWidthOnMobile
    >
      <div className={styles.historyButtons}>
        <ContentSwitcher
          tabs={[
            {
              label: '24 Stunden',
              onClick: () => setHistoryBucket(ValveHistoryBucket.H24),
            },
            {
              label: '1 Woche',
              onClick: () => setHistoryBucket(ValveHistoryBucket.D7),
            },
            {
              label: '30 Tage',
              onClick: () => setHistoryBucket(ValveHistoryBucket.D30),
            },
          ]}
          activeTabId={activeTabId}
          setActiveTabId={setActiveTabId}
        />
        {!!historyDataExport.data ? (
          <a
            href={exportHref}
            ref={exportButtonRef}
            download={`export-${deviceId}.csv`}
            target="_blank"
            rel="noreferer noreferrer"
          >
            <Button
              label="Download"
              LeadingIcon={Download}
              variant="outlined"
              size="s"
            />
          </a>
        ) : historyDataExport.loading ? (
          <Button loading variant="outlined" size="s" />
        ) : (
          <Button
            label="Download"
            LeadingIcon={Download}
            variant="outlined"
            size="s"
            onClick={() =>
              getValveHistoryExport({
                serialNumber,
                bucket: historyBucket,
                deviceId,
              })
            }
          />
        )}
      </div>

      <div className={styles.chartWrap}>
        {historyData.data != null ? (
          <HistoryLineChart
            data={historyData.data}
            pressureUnit={'bar'}
            tempUnit={'°C'}
            filterMod={historyBucket === ValveHistoryBucket.H24 ? 4 : 1}
          />
        ) : (
          <Loading />
        )}
      </div>
    </Modal>
  );
};

export default connect(mapStateToProps, dispatchProps)(DigiDrumiHistoryModal);
