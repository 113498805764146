import React, { useEffect, useState } from 'react';
import { RootState } from 'typesafe-actions';

import { connect } from 'react-redux';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Button } from '@mui/material';

import * as administrationSelectors from '../selectors';
import * as administrationActions from '../actions';

const mapStateToProps = (state: RootState) => ({
  valveOfflineEventSelector: (valveId: string) =>
    administrationSelectors.adminValveOfflineEvents(
      state.administration,
      valveId
    ),

  suspiciousValueEventSelector: (valveId: string) =>
    administrationSelectors.adminValveSuspiciousValueEvents(
      state.administration,
      valveId
    ),

  thresholdPassedEventSelector: (valveId: string) =>
    administrationSelectors.adminThresholdPassedEvents(
      state.administration,
      valveId
    ),
});

const dispatchProps = {
  fetchNextValveOfflineEvents:
    administrationActions.fetchNextValveOfflineEventsAsync.request,
  fetchNextSuspiciousValueEvents:
    administrationActions.fetchNextValveSuspiciousValueEventsAsync.request,
  fetchNextThresholdPassedEvents:
    administrationActions.fetchNextThresholdPassedEventsAsync.request,

  clearValveOfflineEvents: administrationActions.clearValveOfflineEvents,
  clearValveSusValueEvents: administrationActions.clearValveSusValueEvents,
  clearValveThresholdPassedEvents:
    administrationActions.clearValveThresholdPassedEvents,
};

interface ComponentProps {
  valveId: string;
  type: 'SuspiciousValue' | 'Offline' | 'Threshold';
}

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

interface PaginationProps {
  disabled: boolean;
  disableNext: boolean;
  disablePrev: boolean;
  onRefresh: () => void;
  onNext: () => void;
  onPrev: () => void;
  pageStart: number;
  pageEnd: number;
  total: number | null;
}

const ValveOfflineColumns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 350, sortable: false },
  {
    field: 'startTs',
    headerName: 'Eintritt',
    width: 250,
    type: 'dateTime',
    valueGetter: (params: GridValueGetterParams) =>
      new Date(Date.parse(params.row.startTs)),
    sortable: false,
  },
  {
    field: 'endTs',
    headerName: 'Ende',
    width: 250,
    type: 'dateTime',
    valueGetter: (params: GridValueGetterParams) =>
      params.row.endTs != null
        ? new Date(Date.parse(params.row.endTs))
        : 'Aktiv',
    sortable: false,
  },
  { field: 'packageCtr', headerName: 'Auslösende Pkt.', sortable: false },
  { field: 'endPackageCtr', headerName: 'Beendende Pkt.', sortable: false },
];

const ThresholdPassedColumns: GridColDef[] = [
  {
    field: 'pressureThresholdAssignmentId',
    headerName: 'ID',
    width: 250,
    sortable: false,
  },
  { field: 'thresholdName', headerName: 'Name', width: 200, sortable: false },
  { field: 'pressure', headerName: 'Druck(bar)', width: 100, sortable: false },
  {
    field: 'startTs',
    headerName: 'Start',
    type: 'dateTime',
    width: 175,
    valueGetter: (params: GridValueGetterParams) =>
      new Date(Date.parse(params.row.startTs)),
    sortable: false,
  },
  {
    field: 'endTs',
    headerName: 'Ende',
    width: 175,
    type: 'dateTime',
    valueGetter: (params: GridValueGetterParams) =>
      params.row.endTs != null
        ? new Date(Date.parse(params.row.endTs))
        : 'Aktiv',
    sortable: false,
  },
  { field: 'packageCtr', headerName: 'Auslösende Pkt.', sortable: false },
  { field: 'endPackageCtr', headerName: 'Beendende Pkt.', sortable: false },
];

const EventListPagination: React.FC<PaginationProps> = ({
  disabled,
  disableNext,
  disablePrev,
  onRefresh,
  onNext,
  onPrev,
  total,
  pageEnd,
  pageStart,
}) => {
  return (
    <>
      <Button disabled={disabled} onClick={onRefresh}>
        Neu Laden
      </Button>
      <span>
        Einträge {pageStart} - {pageEnd} von {total ?? '?'}{' '}
      </span>
      <Button disabled={disabled || disablePrev} onClick={onPrev}>
        Vorherige Einträge
      </Button>
      <Button disabled={disabled || disableNext} onClick={onNext}>
        Nächste Einträge
      </Button>
    </>
  );
};

const EventList: React.FC<Props> = ({
  valveId,
  fetchNextValveOfflineEvents,
  fetchNextSuspiciousValueEvents,
  fetchNextThresholdPassedEvents,
  suspiciousValueEventSelector,
  valveOfflineEventSelector,
  thresholdPassedEventSelector,
  clearValveOfflineEvents,
  clearValveSusValueEvents,
  clearValveThresholdPassedEvents,
  type,
}) => {
  let eventSelector:
      | typeof suspiciousValueEventSelector
      | typeof valveOfflineEventSelector
      | typeof thresholdPassedEventSelector,
    fetchNextEvents:
      | typeof fetchNextSuspiciousValueEvents
      | typeof fetchNextValveOfflineEvents
      | typeof fetchNextThresholdPassedEvents,
    clearEvents: (payload: { valveId: string }) => void,
    columnDefinition;
  switch (type) {
    case 'SuspiciousValue':
      eventSelector = suspiciousValueEventSelector;
      fetchNextEvents = fetchNextSuspiciousValueEvents;
      columnDefinition = ValveOfflineColumns;
      clearEvents = clearValveSusValueEvents;
      break;
    case 'Offline':
      eventSelector = valveOfflineEventSelector;
      fetchNextEvents = fetchNextValveOfflineEvents;
      columnDefinition = ValveOfflineColumns;
      clearEvents = clearValveOfflineEvents;
      break;
    case 'Threshold':
      eventSelector = thresholdPassedEventSelector;
      fetchNextEvents = fetchNextThresholdPassedEvents;
      columnDefinition = ThresholdPassedColumns;
      clearEvents = clearValveThresholdPassedEvents;
      break;
    default:
      throw new Error();
  }

  const [page, setPage] = useState<number>(0);
  const pageSize = 100;

  const events = eventSelector(valveId);
  const eventEntities = events?.data?.events ?? [];
  const isLoading = events?.loading ?? true;

  const fetchNextEventsFunc = () => {
    if (events?.loading === false) {
      fetchNextEvents({ valveId, lastKey: events?.data?.lastKey ?? null });
    }
  };

  useEffect(() => {
    if (events?.data === undefined) {
      fetchNextEventsFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, valveId, fetchNextEvents]);

  const numLoadedData = events?.data?.events?.length ?? 0;
  const hasMoreLocalDataToDisplay = (page + 1) * pageSize < numLoadedData;
  const canLoadMoreData = events?.data?.lastKey != null;

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={eventEntities.slice(page * pageSize, (page + 1) * pageSize)}
        pageSize={pageSize}
        columns={columnDefinition}
        disableColumnMenu
        rowCount={eventEntities.length}
        getRowId={
          type === 'Threshold'
            ? row => row.pressureThresholdAssignmentId + row.startTs
            : row => row.id + row.startTs
        }
        components={{
          Pagination: () => (
            <EventListPagination
              pageStart={page * pageSize}
              pageEnd={
                (page + 1) * pageSize > numLoadedData
                  ? numLoadedData
                  : (page + 1) * pageSize
              }
              total={canLoadMoreData ? null : numLoadedData}
              disabled={isLoading}
              disableNext={!hasMoreLocalDataToDisplay && !canLoadMoreData}
              disablePrev={page <= 0}
              onNext={() => {
                if (!hasMoreLocalDataToDisplay && canLoadMoreData) {
                  fetchNextEventsFunc();
                  // only switch to the next page if the current page is full
                  if (numLoadedData % pageSize === 0) {
                    setPage(page + 1);
                  }
                } else {
                  setPage(page + 1);
                }
              }}
              onPrev={() => {
                setPage(page - 1);
              }}
              onRefresh={() => {
                clearEvents({ valveId });
                setPage(0);
              }}
            />
          ),
        }}
        loading={isLoading}
        disableSelectionOnClick
      />
    </div>
  );
};

export default connect(mapStateToProps, dispatchProps)(EventList);
