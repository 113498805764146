import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { Bottles } from '../../../stories/Bottles';
import { Card } from '../../../stories/Card';
import { InventoryLoading } from '../../../stories/InventoryLoading';
import { Modal } from '../../../stories/Modal';
import { TextButton } from '../../../stories/TextButton';
import { Chart, Replay } from '../../../stories/icons';
import CustomerSelection from '../../common/components/CustomerSelection';
import * as commonSelectors from '../../common/selectors';
import * as inventoryActions from '../actions';
import Gas from '../components/Gas';
import StatisticsUnderConstruction from '../components/StatisticsUnderConstruction';
import {
  isEmptyContainer,
  isFullContainer,
  isHalfFullContainer,
} from '../helpers/fill-level';
import * as inventorySelectors from '../selectors';
import styles from './InventoryPage.module.scss';
import { ApiGaasAction } from '../../../models/openapi/openapiTypes';

const mapStateToProps = (state: RootState) => ({
  inventory: inventorySelectors.inventory(state.inventory),
  selectedCustomer: commonSelectors.selectedCustomer(state.common),
  inventoryThresholds: (customerId: string) =>
    inventorySelectors.inventoryThresholds(state.inventory, customerId),
  inventoryThresholdPassedEvents: (customerId: string) =>
    inventorySelectors.inventoryThresholdPassedEvents(
      state.inventory,
      customerId
    ),
  hasPermission: (action: ApiGaasAction) =>
    commonSelectors.hasPermission(action, state.common),
});

const dispatchProps = {
  getInventory: inventoryActions.getInventoryAsync.request,
  listInventoryThresholds:
    inventoryActions.listInventoryThresholdsAsync.request,
  listInventoryThresholdPassedEvents:
    inventoryActions.listActiveInventoryThresholdEventsAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const groupBy = <K, V>(array: V[], projection: (item: V) => K) => {
  const map = new Map<K, V[]>();
  array.forEach(item => {
    const key = projection(item);
    if (map.has(key)) {
      map.get(key)!.push(item);
    } else {
      map.set(key, [item]);
    }
  });

  return map;
};

const InventoryPage: React.FC<Props> = ({
  inventory,
  selectedCustomer,
  hasPermission,
  getInventory,
  listInventoryThresholds,
  inventoryThresholds,
  listInventoryThresholdPassedEvents,
  inventoryThresholdPassedEvents,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const containerDetails = inventory.data?.containers || [];

  const containerMap = groupBy(
    inventory.data?.containers || [],
    container => container.gasType
  );
  const containerDetailsMap = groupBy(
    containerDetails,
    container => container.gasType
  );
  const orderMap = groupBy(
    inventory.data?.orders || [],
    order => order.gasType
  );

  const gasTypes = new Set([...containerDetailsMap.keys(), ...orderMap.keys()]);

  const loadInventory = () => {
    if (selectedCustomer && !inventory.loading) {
      getInventory(selectedCustomer.customerId);
    }
  };

  useEffect(() => {
    if (selectedCustomer == null) {
      return;
    }

    const thresholds = inventoryThresholds(selectedCustomer.customerId);
    if (thresholds.data == null && !thresholds.loading) {
      listInventoryThresholds({ customerId: selectedCustomer.customerId });
    }

    const inventoryThresholdPassedEventsForCustomer =
      inventoryThresholdPassedEvents(selectedCustomer.customerId);
    if (
      inventoryThresholdPassedEventsForCustomer.data == null &&
      !inventoryThresholdPassedEventsForCustomer.loading
    ) {
      listInventoryThresholdPassedEvents({
        customerId: selectedCustomer.customerId,
      });
    }
  }, [
    selectedCustomer,
    listInventoryThresholds,
    inventoryThresholds,
    inventoryThresholdPassedEvents,
    listInventoryThresholdPassedEvents,
  ]);

  const containerData = () => {
    if (inventory.error) {
      return (
        <div className={styles['gaas-inventory-page--empty']}>
          <p style={{ marginBottom: '0.5rem' }}>
            Laden des Bestands fehlgeschlagen.
          </p>
          <TextButton
            label="Erneut versuchen"
            LeadingIcon={Replay}
            onClick={() => loadInventory()}
          />
        </div>
      );
    }

    if (inventory.loading || !inventory.data) {
      return (
        <div className={styles['gaas-inventory-page--loading']}>
          <InventoryLoading />
        </div>
      );
    }

    if (selectedCustomer == null) {
      return <></>;
    }

    return (
      <>
        <h2 className={styles['gaas-inventory-page--title']}>Alle Gasarten</h2>
        <Card maxWidth={450}>
          <Bottles
            full={
              inventory.data.containers.filter(c => isFullContainer(c)).length
            }
            open={
              inventory.data.containers.filter(c => isHalfFullContainer(c))
                .length
            }
            empty={
              inventory.data.containers.filter(c => isEmptyContainer(c)).length
            }
            ordered={inventory.data.orders.reduce(
              (sum, o) => sum + o.quantity,
              0
            )}
          />
          <div style={{ height: '24px' }} />
          <TextButton
            label="Verbrauchsstatistik"
            LeadingIcon={Chart}
            onClick={handleOpen}
            permission={hasPermission('inventory.history.read')}
          />
          <Modal
            title="Verlauf Flaschendruck"
            open={open}
            onClose={handleClose}
          >
            <StatisticsUnderConstruction />
            {/* <Statistic */}
            {/*   datasets={Array.from(gasTypes) */}
            {/*     .sort() */}
            {/*     .map(gas => ({ */}
            {/*       label: gas, */}
            {/*       data: chartValues.map(({ createdAt, value }) => ({ */}
            {/*         createdAt, */}
            {/*         value: (containerMap.get(gas) ?? []).length * value, */}
            {/*       })), */}
            {/*     }))} */}
            {/* /> */}
          </Modal>
        </Card>

        {Array.from(gasTypes)
          .filter(gasType => gasType !== '')
          .sort()
          .map(gasType => (
            <Gas
              gasType={gasType}
              containers={containerMap.get(gasType) ?? []}
              orders={orderMap.get(gasType) ?? []}
              key={gasType}
              customerId={selectedCustomer!.customerId}
            />
          ))}
        {gasTypes.has('') && (
          <Gas
            gasType="Sonstige"
            containers={containerMap.get('') ?? []}
            orders={orderMap.get('') ?? []}
            customerId={selectedCustomer!.customerId}
          />
        )}
      </>
    );
  };

  return (
    <CustomerSelection title="Aktueller Flaschenbestand" scanBottle>
      {containerData()}
    </CustomerSelection>
  );
};

export default connect(mapStateToProps, dispatchProps)(InventoryPage);
