import { useEffect, useState } from 'react';
import * as inventorySelectors from '../selectors';
import * as inventoryActions from '../actions';
import { RootState } from 'typesafe-actions';
import { connect } from 'react-redux';
import { TextField } from '@mui/material';
import { Button } from '../../../stories/Button';
import { LabelAndText } from '../../../stories/LabelAndText';
import { Skeleton } from '../../../stories/Skeleton';
import styles from './ContainerNote.module.scss';
import { ApiGaasAction } from '../../../models/openapi/openapiTypes';
import * as commonSelectors from '../../common/selectors';

const mapStateToProps = (state: RootState) => ({
  containerNoteRequest: (containerId: string) =>
    inventorySelectors.containerNote(state.inventory, containerId),
  hasPermission: (action: ApiGaasAction) =>
    commonSelectors.hasPermission(action, state.common),
});

const dispatchProps = {
  getContainerNoteById: inventoryActions.getContainerNoteAsync.request,
  setContainerNoteById: inventoryActions.updateContainerNoteAsync.request,
};

type Props = {
  customerId: string;
  containerId: string;
} & ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

const ContainerNote: React.FC<Props> = ({
  customerId,
  containerId,
  containerNoteRequest,
  setContainerNoteById,
  getContainerNoteById,
  hasPermission,
}) => {
  const [editorContent, setEditorContent] = useState<string>('');
  const [containerNote, setContainerNote] = useState<string | null>(null);
  const { data: asyncNote, loading, error } = containerNoteRequest(containerId);
  const [hasError, setHasError] = useState<boolean>(false);

  const [actionAllowed] = hasPermission('container.note.write');

  useEffect(
    () => {
      if (!loading && !error) {
        if (asyncNote === undefined) {
          getContainerNoteById({ customerId, containerId });
        } else {
          setContainerNote(asyncNote);
          setEditorContent(asyncNote);
        }
      }
      setHasError(error != null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [containerId, asyncNote]
  );

  if (hasError) {
    return (
      <div>
        <p>Notiz konnte nicht geladen werden</p>
      </div>
    );
  }

  return (
    <div className={styles['gaas-container-note']}>
      <LabelAndText label="Zusätzliche Notizen" />
      {loading ? (
        <Skeleton height={79} />
      ) : (
        <TextField
          id="container-note"
          placeholder="Notiz verfassen…"
          multiline
          rows={2}
          fullWidth
          variant="outlined"
          value={editorContent ?? ''}
          onChange={event => setEditorContent(event.currentTarget.value)}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '0',
              fontFamily: 'WestfalenNewsSans',
            },
          }}
          error={hasError}
          disabled={loading || !actionAllowed}
        />
      )}
      <Button
        variant="primary"
        label="Notiz speichern"
        fullWidth
        onClick={() => {
          setContainerNoteById({
            customerId,
            containerId,
            content: editorContent,
          });
          setContainerNote(editorContent);
        }}
        disabled={loading || containerNote === editorContent}
        permission={hasPermission('container.note.write')}
      />
    </div>
  );
};

export default connect(mapStateToProps, dispatchProps)(ContainerNote);
