import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  CameraDeviceWithFacingMode,
  CameraStreamData,
  enumerateCamerasWeighted,
  startCameraStream,
  stopCameraStream,
  toggleCameraTorch,
} from './CameraUtil';
import { BarcodeScannerV2Loading } from './BarcodeScannerV2Loading';

interface Props {
  hidden?: boolean;
  children: React.ReactNode;
}

export const CameraStreamProvider: React.FC<Props> = ({
  hidden = true,
  children,
}) => {
  const [cameras, setCameras] = useState<
    CameraDeviceWithFacingMode[] | undefined
  >(undefined);
  const [cameraIndex, setCameraIndex] = useState<number>(0);
  const [cameraState, setCameraState] = useState<CameraStreamData | undefined>(
    undefined
  );

  const [torchOn, setTorchOn] = useState<boolean>(false);

  const [videoRef, setVideoRef] = useState<HTMLVideoElement | undefined>(
    undefined
  );
  const [cameraVideoLoading, setCameraVideoLoading] = useState<boolean>(true);

  const videoRefCb = useCallback(node => {
    if (node !== null) {
      setVideoRef(node);
    }
  }, []);

  const numCameras = cameras?.length ?? 0;
  const forceChangeCamera = useCallback(() => {
    setCameraIndex(c => (c + 1) % numCameras);
    setTorchOn(false);
  }, [setCameraIndex, numCameras, setTorchOn]);

  const changeCamera = useMemo(() => {
    if (numCameras < 2) {
      return undefined;
    }

    return forceChangeCamera;
  }, [forceChangeCamera, numCameras]);

  const toggleTorch = useMemo(() => {
    if (!cameraState) {
      return undefined;
    }
    const { hasTorch, stream } = cameraState;
    if (!hasTorch) {
      return undefined;
    }
    return () => {
      toggleCameraTorch(stream, !torchOn);
      setTorchOn(!torchOn);
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps --
     * *cameraIndex* is not used in the Memo, but we want to re-run
     * this effect when the selected camera changes
     **/
  }, [cameraState, cameraState?.hasTorch, cameraIndex, setTorchOn, torchOn]);

  useEffect(() => {
    enumerateCamerasWeighted().then(cameras => {
      setCameras(cameras);
    });
  }, []);

  useEffect(() => {
    // this hook closes the old camera stream
    // if a new camera is selected or the component is unmounted
    return () => {
      if (cameraState) {
        const { stream } = cameraState;
        if (stream) {
          stopCameraStream(stream);
        }
      }
    };
  }, [cameraState, cameraState?.stream]);

  useEffect(() => {
    setCameraVideoLoading(true);
    if (cameras === undefined || !videoRef) {
      return;
    }

    const camera = cameras[cameraIndex];
    startCameraStream(camera)
      .then(cameraState => {
        videoRef.srcObject = cameraState.stream;
        videoRef.addEventListener('loadedmetadata', () => {
          videoRef.play().then(() => {
            setCameraVideoLoading(false);
          });
        });

        setCameraState(cameraState);
      })
      .catch(e => {
        console.error('Failed to play video', e);
        forceChangeCamera();
      });
    /* eslint-disable-next-line react-hooks/exhaustive-deps --
     * We don't want to re-run this effect when the cameraState changes, as we only use it in this
     * effect to clean up the previous camera stream, if there already was one.
     **/
  }, [
    cameras,
    cameraIndex,
    forceChangeCamera,
    videoRef,
    setCameraState,
    setCameraVideoLoading,
  ]);

  const isLoading = useMemo(() => {
    return !cameraState || cameraVideoLoading;
  }, [cameraState, cameraVideoLoading]);

  return (
    <>
      <video
        ref={videoRefCb}
        autoPlay={true}
        muted={true}
        playsInline={true}
        disablePictureInPicture={true}
        style={hidden ? { display: 'none' } : {}}
      />
      {!isLoading && (
        <CameraStreamContext.Provider
          value={{
            videoRef,
            changeCamera,
            cameraId: cameraState!.cameraId,
            toggleTorch,
            torchOn,
          }}
        >
          {children}
        </CameraStreamContext.Provider>
      )}
      {isLoading && <BarcodeScannerV2Loading />}
    </>
  );
};

export type CameraStreamContextData = {
  videoRef: HTMLVideoElement | undefined;
  changeCamera: (() => void) | undefined;
  cameraId: string;
  toggleTorch: (() => void) | undefined;
  torchOn: boolean;
};

export const CameraStreamContext = createContext<CameraStreamContextData>({
  videoRef: undefined,
  changeCamera: undefined,
  cameraId: '',
  toggleTorch: undefined,
  torchOn: false,
});

export const useCameraStream = () => useContext(CameraStreamContext);
