import React from 'react';
import { ManometerPin as ManometerPinModel } from '../features/common/models/manometer-pin';
import styles from './Manometer.module.scss';
import { ManometerPin } from './ManometerPin';
import { PermissionQueryResponse } from '../features/common/models/permission-query-response';

interface Props {
  skala?: 200 | 300;
  pressureBar?: number;
  pins?: ManometerPinModel[];
  setCurrentPin: React.Dispatch<
    React.SetStateAction<
      | {
          id: string;
          ref: React.RefObject<SVGGElement>;
        }
      | undefined
    >
  >;
  permission: PermissionQueryResponse;
}

export const Manometer: React.FC<Props> = ({
  skala = 300,
  pressureBar,
  pins,
  setCurrentPin,
  permission,
}) => {
  const manometerValue = (pressureBar: number) =>
    `rotate(${
      skala === 200
        ? pressureBar * 1.35 - 135
        : (pressureBar / 300) * 200 * 1.35 - 135
    } 62 62)`;

  return (
    <div className={styles['gaas-manometer']}>
      <svg
        className={styles['gaas-manometer-scale']}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 124 124"
      >
        <path d="M0 0h124v124H0z" style={{ fill: 'none' }} />
        <path
          d="m39.37,84.63l-12.72,12.72c-9.05-9.05-14.65-21.55-14.65-35.35,0-6.93,1.41-13.53,3.96-19.54l16.57,7.04c-1.63,3.84-2.53,8.06-2.53,12.5,0,8.84,3.58,16.84,9.37,22.63Z"
          style={{ fill: '#f0f0f0' }}
        />
        <path
          d="m112,62c0,6.93-1.41,13.53-3.96,19.54-2.53,5.95-6.19,11.3-10.69,15.81l-12.72-12.72c2.89-2.88,5.22-6.31,6.84-10.12,1.63-3.84,2.53-8.07,2.53-12.51s-.9-8.67-2.53-12.51l16.57-7.03c2.55,6.01,3.96,12.61,3.96,19.54Z"
          style={{ fill: '#b3b2b2' }}
        />
        <path
          d="m108.04,42.46l-16.57,7.03c-1.62-3.81-3.95-7.24-6.84-10.12-5.8-5.79-13.8-9.37-22.63-9.37V12c13.8,0,26.3,5.6,35.35,14.65,4.5,4.51,8.16,9.86,10.69,15.81Z"
          style={{ fill: '#c9c8c8' }}
        />
        <path
          d="m62,12v18c-8.84,0-16.84,3.58-22.63,9.37-2.89,2.88-5.22,6.32-6.84,10.13l-16.57-7.04c2.53-5.95,6.19-11.3,10.69-15.81,9.05-9.05,21.55-14.65,35.35-14.65Z"
          style={{ fill: '#e6e6e6' }}
        />
      </svg>

      <svg
        className={styles['gaas-manometer-hand']}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 124 124"
      >
        <defs>
          <linearGradient
            id="a"
            x1="62.19"
            x2="61.91"
            y1="27.38"
            y2="62.26"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#595959" />
            <stop offset="1" stopColor="#919191" />
          </linearGradient>
        </defs>
        <path d="M0 0h124v124H0z" style={{ fill: 'none' }} />
        <path
          d="M67.91 62c0 3.55-3.1 6.39-6.74 5.96-2.65-.32-4.84-2.46-5.2-5.11-.31-2.29.67-4.37 2.31-5.62l2.96-30.83c.09-.78 1.22-.78 1.31 0l2.98 30.83A5.947 5.947 0 0 1 67.91 62Z"
          style={{ fill: '#1e335d' }}
          transform={manometerValue(pressureBar || 0)}
        />
        <circle cx="62" cy="62" r="5" style={{ fill: '#fff' }} />

        {skala === 200 ? (
          <>
            <path
              d="m12.57,97.39c-.37,0-.65-.13-.84-.41-.19-.27-.29-.68-.29-1.25,0-1.14.39-1.71,1.18-1.71.37,0,.65.13.84.41.19.27.29.69.29,1.25,0,1.14-.4,1.71-1.19,1.71Zm-.53-.66c.12.21.31.32.57.32.25,0,.43-.1.54-.31.12-.21.17-.54.17-1.01s-.06-.84-.18-1.05c-.12-.21-.31-.32-.57-.32-.25,0-.43.1-.54.31-.11.21-.17.54-.17,1.01s.06.83.18,1.04Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m15.51,93.85h.38v1.2c.08-.05.18-.09.28-.12.1-.03.21-.04.31-.04.61,0,.92.39.92,1.17,0,.48-.1.82-.29,1.02-.19.2-.48.31-.87.31-.09,0-.23-.01-.42-.03h-.32v-3.5Zm.79,3.21c.23,0,.41-.07.53-.21.12-.14.18-.38.18-.72s-.05-.57-.16-.71c-.11-.14-.26-.21-.47-.21-.07,0-.15.01-.24.04s-.17.05-.25.09v1.67c.17.02.3.04.41.04Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m19.29,97.14c-.09.07-.19.13-.32.17s-.26.07-.41.07c-.22,0-.4-.06-.53-.18-.13-.12-.2-.28-.2-.48,0-.25.08-.44.24-.58.16-.14.4-.21.71-.21.16,0,.32.02.46.05v-.16c0-.23-.04-.39-.12-.48s-.22-.13-.42-.13c-.1,0-.2.01-.3.03-.1.02-.21.05-.34.09h-.12v-.32c.31-.08.59-.12.83-.12.29,0,.51.07.64.2.14.14.21.34.21.63v1.63h-.34v-.21Zm-.94-.18c.08.07.19.11.32.11.1,0,.2-.02.3-.05s.19-.08.28-.13v-.59c-.16-.04-.33-.06-.53-.06-.33,0-.49.15-.49.44,0,.12.04.22.12.29Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m20.37,94.92h.36v.25c.2-.12.43-.21.69-.27h.15v.38c-.12,0-.26.02-.41.05-.15.03-.28.07-.41.11v1.91h-.39v-2.43Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m104.45,95.47c-.1.22-.29.5-.58.84l-.57.7h1.34v.34h-1.79v-.33l.78-1.01c.2-.26.33-.47.42-.64.08-.17.12-.33.12-.49s-.05-.28-.14-.38c-.1-.09-.23-.14-.39-.14s-.36.05-.58.16h-.12v-.33c.26-.11.52-.17.79-.17s.48.07.64.22.24.34.24.57c0,.22-.05.44-.16.65Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m106.31,97.39c-.37,0-.65-.13-.84-.41-.19-.27-.29-.68-.29-1.25,0-1.14.39-1.71,1.18-1.71.37,0,.65.13.84.41.19.27.29.69.29,1.25,0,1.14-.4,1.71-1.19,1.71Zm-.53-.66c.12.21.31.32.57.32.25,0,.43-.1.54-.31.12-.21.17-.54.17-1.01s-.06-.84-.18-1.05c-.12-.21-.31-.32-.57-.32-.25,0-.43.1-.54.31-.11.21-.17.54-.17,1.01s.06.83.18,1.04Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m109.2,97.39c-.37,0-.65-.13-.84-.41-.19-.27-.29-.68-.29-1.25,0-1.14.4-1.71,1.19-1.71.37,0,.65.13.84.41.2.27.29.69.29,1.25,0,1.14-.4,1.71-1.19,1.71Zm-.53-.66c.12.21.31.32.57.32.25,0,.43-.1.54-.31s.17-.54.17-1.01-.06-.84-.18-1.05c-.12-.21-.31-.32-.57-.32-.25,0-.43.1-.54.31-.12.21-.17.54-.17,1.01s.06.83.18,1.04Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m112.13,93.85h.39v1.2c.08-.05.18-.09.28-.12.1-.03.21-.04.31-.04.61,0,.92.39.92,1.17,0,.48-.1.82-.29,1.02-.19.2-.48.31-.87.31-.09,0-.23-.01-.42-.03h-.32v-3.5Zm.79,3.21c.23,0,.41-.07.53-.21.12-.14.18-.38.18-.72s-.05-.57-.16-.71-.26-.21-.47-.21c-.07,0-.15.01-.23.04-.09.02-.17.05-.25.09v1.67c.17.02.3.04.4.04Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m115.92,97.14c-.09.07-.19.13-.32.17s-.26.07-.41.07c-.22,0-.39-.06-.53-.18-.13-.12-.19-.28-.19-.48,0-.25.08-.44.24-.58.16-.14.4-.21.71-.21.16,0,.31.02.46.05v-.16c0-.23-.04-.39-.12-.48s-.22-.13-.42-.13c-.1,0-.21.01-.3.03-.1.02-.21.05-.34.09h-.12v-.32c.31-.08.59-.12.83-.12.29,0,.51.07.65.2.14.14.21.34.21.63v1.63h-.35v-.21Zm-.94-.18c.08.07.19.11.32.11.1,0,.2-.02.3-.05s.19-.08.28-.13v-.59c-.16-.04-.34-.06-.53-.06-.33,0-.5.15-.5.44,0,.12.04.22.12.29Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m116.99,94.92h.36v.25c.2-.12.43-.21.69-.27h.15v.38c-.12,0-.26.02-.41.05-.15.03-.28.07-.41.11v1.91h-.38v-2.43Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m54.54,7.01l1.06-.3h.11v2.95h.62v.34h-1.8v-.34h.76v-2.5l-.66.18h-.1v-.34Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m57.81,10.04c-.37,0-.65-.13-.84-.41-.19-.27-.29-.68-.29-1.25,0-1.14.4-1.71,1.19-1.71.37,0,.65.13.84.41.2.27.29.69.29,1.25,0,1.14-.4,1.71-1.19,1.71Zm-.53-.66c.12.21.31.32.57.32.25,0,.43-.1.54-.31s.17-.54.17-1.01-.06-.84-.18-1.05c-.12-.21-.31-.32-.57-.32-.25,0-.43.1-.54.31-.12.21-.17.54-.17,1.01s.06.83.18,1.04Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m60.7,10.04c-.37,0-.65-.13-.84-.41-.19-.27-.29-.68-.29-1.25,0-1.14.4-1.71,1.19-1.71.37,0,.65.13.84.41.2.27.29.69.29,1.25,0,1.14-.4,1.71-1.19,1.71Zm-.53-.66c.12.21.31.32.57.32.25,0,.43-.1.54-.31s.17-.54.17-1.01-.06-.84-.18-1.05c-.12-.21-.31-.32-.57-.32-.25,0-.43.1-.54.31-.12.21-.17.54-.17,1.01s.06.83.18,1.04Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m63.63,6.5h.39v1.2c.08-.05.18-.09.28-.12.1-.03.21-.04.31-.04.61,0,.92.39.92,1.17,0,.48-.1.82-.29,1.02-.19.2-.48.31-.87.31-.09,0-.23-.01-.42-.03h-.32v-3.5Zm.79,3.21c.23,0,.41-.07.53-.21.12-.14.18-.38.18-.72s-.05-.57-.16-.71-.26-.21-.47-.21c-.07,0-.15.01-.23.04-.09.02-.17.05-.25.09v1.67c.17.02.3.04.4.04Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m67.42,9.79c-.09.07-.19.13-.32.17s-.26.07-.41.07c-.22,0-.39-.06-.53-.18-.13-.12-.19-.28-.19-.48,0-.25.08-.44.24-.58.16-.14.4-.21.71-.21.16,0,.31.02.46.05v-.16c0-.23-.04-.39-.12-.48s-.22-.13-.42-.13c-.1,0-.21.01-.3.03-.1.02-.21.05-.34.09h-.12v-.32c.31-.08.59-.12.83-.12.29,0,.51.07.65.2.14.14.21.34.21.63v1.63h-.35v-.21Zm-.94-.18c.08.07.19.11.32.11.1,0,.2-.02.3-.05s.19-.08.28-.13v-.59c-.16-.04-.34-.06-.53-.06-.33,0-.5.15-.5.44,0,.12.04.22.12.29Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m68.49,7.57h.36v.25c.2-.12.43-.21.69-.27h.15v.38c-.12,0-.26.02-.41.05-.15.03-.28.07-.41.11v1.91h-.39v-2.43Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m3.69,40.88c-.12-.11-.32-.17-.62-.17-.14,0-.32,0-.52.03v-1.58h1.59v.33h-1.18v.91c.18,0,.28-.02.31-.02.34,0,.6.08.76.24.16.16.25.4.25.73,0,.36-.1.64-.29.84s-.49.3-.88.3c-.23,0-.46-.02-.67-.07v-.36h.14c.12.03.22.05.31.06.09.01.18.02.28.02.22,0,.39-.06.51-.18s.19-.3.19-.54c0-.26-.06-.44-.17-.55Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m5.94,42.5c-.37,0-.65-.13-.84-.41-.19-.27-.29-.68-.29-1.25,0-1.14.39-1.71,1.19-1.71.37,0,.65.13.84.41.2.27.29.69.29,1.25,0,1.14-.4,1.71-1.19,1.71Zm-.53-.66c.12.21.31.32.57.32.25,0,.43-.1.54-.31.12-.21.17-.54.17-1.01s-.06-.84-.18-1.05c-.12-.21-.31-.32-.57-.32-.25,0-.43.1-.54.31-.12.21-.17.54-.17,1.01s.06.83.18,1.04Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m8.87,38.96h.38v1.2c.08-.05.18-.09.28-.12.1-.03.21-.04.31-.04.61,0,.92.39.92,1.17,0,.48-.1.82-.29,1.02-.19.2-.48.31-.87.31-.09,0-.23-.01-.42-.03h-.32v-3.5Zm.79,3.21c.23,0,.41-.07.53-.21.12-.14.18-.38.18-.72s-.05-.57-.16-.71c-.11-.14-.26-.21-.47-.21-.07,0-.15.01-.24.04-.09.02-.17.05-.25.09v1.67c.17.02.3.04.41.04Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m12.66,42.25c-.09.07-.19.13-.32.17s-.26.07-.41.07c-.22,0-.39-.06-.53-.18-.13-.12-.2-.28-.2-.48,0-.25.08-.44.24-.58s.4-.21.71-.21c.16,0,.31.02.46.05v-.16c0-.23-.04-.39-.12-.48s-.22-.13-.42-.13c-.1,0-.2.01-.3.03-.1.02-.21.05-.34.09h-.12v-.32c.31-.08.59-.12.83-.12.29,0,.51.07.65.2.14.14.21.34.21.63v1.63h-.35v-.21Zm-.94-.18c.08.07.19.11.32.11.1,0,.2-.02.3-.05s.19-.08.28-.13v-.59c-.16-.04-.34-.06-.53-.06-.33,0-.5.15-.5.44,0,.12.04.22.12.29Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m13.73,40.03h.36v.25c.2-.12.43-.21.69-.27h.15v.38c-.12,0-.26.02-.41.05-.15.03-.28.07-.41.11v1.91h-.39v-2.43Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m109.31,39.47l1.07-.3h.11v2.95h.62v.34h-1.8v-.34h.76v-2.5l-.65.18h-.11v-.34Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m112.75,40.88c-.12-.11-.32-.17-.62-.17-.14,0-.32,0-.53.03v-1.58h1.6v.33h-1.18v.91c.18,0,.28-.02.31-.02.34,0,.6.08.76.24s.25.4.25.73c0,.36-.1.64-.29.84-.19.2-.49.3-.88.3-.23,0-.46-.02-.67-.07v-.36h.14c.12.03.22.05.31.06.09.01.18.02.28.02.22,0,.39-.06.51-.18s.19-.3.19-.54c0-.26-.06-.44-.17-.55Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m115,42.5c-.37,0-.65-.13-.84-.41-.19-.27-.29-.68-.29-1.25,0-1.14.39-1.71,1.18-1.71.37,0,.65.13.84.41.19.27.29.69.29,1.25,0,1.14-.4,1.71-1.19,1.71Zm-.53-.66c.12.21.31.32.57.32.25,0,.43-.1.54-.31.12-.21.17-.54.17-1.01s-.06-.84-.18-1.05c-.12-.21-.31-.32-.57-.32-.25,0-.43.1-.54.31-.11.21-.17.54-.17,1.01s.06.83.18,1.04Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m117.93,38.96h.38v1.2c.08-.05.18-.09.28-.12.1-.03.21-.04.31-.04.61,0,.92.39.92,1.17,0,.48-.1.82-.29,1.02-.19.2-.48.31-.87.31-.09,0-.23-.01-.42-.03h-.32v-3.5Zm.79,3.21c.23,0,.41-.07.53-.21.12-.14.18-.38.18-.72s-.05-.57-.16-.71c-.11-.14-.26-.21-.47-.21-.07,0-.15.01-.24.04s-.17.05-.25.09v1.67c.17.02.3.04.41.04Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m121.71,42.25c-.09.07-.19.13-.32.17s-.26.07-.41.07c-.22,0-.39-.06-.53-.18-.13-.12-.19-.28-.19-.48,0-.25.08-.44.24-.58.16-.14.4-.21.71-.21.16,0,.31.02.46.05v-.16c0-.23-.04-.39-.12-.48s-.22-.13-.42-.13c-.1,0-.21.01-.3.03-.1.02-.21.05-.34.09h-.12v-.32c.31-.08.59-.12.83-.12.29,0,.51.07.65.2.14.14.21.34.21.63v1.63h-.35v-.21Zm-.94-.18c.08.07.19.11.32.11.1,0,.2-.02.3-.05s.19-.08.28-.13v-.59c-.16-.04-.34-.06-.53-.06-.33,0-.5.15-.5.44,0,.12.04.22.12.29Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m122.79,40.03h.36v.25c.2-.12.43-.21.69-.27h.15v.38c-.12,0-.26.02-.41.05-.15.03-.28.07-.41.11v1.91h-.39v-2.43Z"
              style={{ fill: '#969696' }}
            />
          </>
        ) : skala === 300 ? (
          <>
            <path
              d="m12.57,97.39c-.37,0-.65-.13-.84-.41-.19-.27-.29-.68-.29-1.25,0-1.14.4-1.71,1.19-1.71.37,0,.65.13.84.41.2.27.29.69.29,1.25,0,1.14-.4,1.71-1.19,1.71Zm-.53-.66c.12.21.31.32.57.32.25,0,.43-.1.54-.31s.17-.54.17-1.01-.06-.84-.18-1.05c-.12-.21-.31-.32-.57-.32-.25,0-.43.1-.54.31-.12.21-.17.54-.17,1.01s.06.83.18,1.04Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m15.51,93.85h.39v1.2c.08-.05.18-.09.28-.12.1-.03.21-.04.31-.04.61,0,.92.39.92,1.17,0,.48-.1.82-.29,1.02-.19.2-.48.31-.87.31-.09,0-.23-.01-.42-.03h-.32v-3.5Zm.79,3.21c.23,0,.41-.07.53-.21.12-.14.18-.38.18-.72s-.05-.57-.16-.71-.26-.21-.47-.21c-.07,0-.15.01-.23.04-.09.02-.17.05-.25.09v1.67c.17.02.3.04.4.04Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m19.29,97.14c-.09.07-.19.13-.32.17s-.26.07-.41.07c-.22,0-.39-.06-.53-.18-.13-.12-.19-.28-.19-.48,0-.25.08-.44.24-.58.16-.14.4-.21.71-.21.16,0,.31.02.46.05v-.16c0-.23-.04-.39-.12-.48s-.22-.13-.42-.13c-.1,0-.21.01-.3.03-.1.02-.21.05-.34.09h-.12v-.32c.31-.08.59-.12.83-.12.29,0,.51.07.65.2.14.14.21.34.21.63v1.63h-.35v-.21Zm-.94-.18c.08.07.19.11.32.11.1,0,.2-.02.3-.05s.19-.08.28-.13v-.59c-.16-.04-.34-.06-.53-.06-.33,0-.5.15-.5.44,0,.12.04.22.12.29Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m20.37,94.92h.36v.25c.2-.12.43-.21.69-.27h.15v.38c-.12,0-.26.02-.41.05-.15.03-.28.07-.41.11v1.91h-.38v-2.43Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m103.97,95.33c.17-.12.25-.29.25-.5,0-.15-.04-.27-.13-.35-.09-.08-.21-.12-.36-.12-.18,0-.4.05-.64.16h-.12v-.34c.12-.04.25-.08.4-.11.15-.03.3-.04.45-.04.26,0,.46.07.6.21s.21.32.21.54c0,.19-.06.36-.17.51-.11.15-.26.25-.44.31.5.04.75.3.75.78,0,.32-.1.57-.29.75s-.47.26-.83.26c-.26,0-.51-.03-.75-.09v-.37h.11c.24.07.46.11.67.11.22,0,.39-.05.5-.16.12-.1.17-.26.17-.47s-.07-.36-.2-.46c-.13-.1-.35-.16-.66-.16-.09,0-.2,0-.32.01v-.3c.35,0,.61-.06.78-.18Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m106.45,97.39c-.37,0-.65-.13-.84-.41-.19-.27-.29-.68-.29-1.25,0-1.14.39-1.71,1.18-1.71.37,0,.65.13.84.41.19.27.29.69.29,1.25,0,1.14-.4,1.71-1.19,1.71Zm-.53-.66c.12.21.31.32.57.32.25,0,.43-.1.54-.31.12-.21.17-.54.17-1.01s-.06-.84-.18-1.05c-.12-.21-.31-.32-.57-.32-.25,0-.43.1-.54.31-.11.21-.17.54-.17,1.01s.06.83.18,1.04Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m109.34,97.39c-.37,0-.65-.13-.84-.41-.19-.27-.29-.68-.29-1.25,0-1.14.39-1.71,1.18-1.71.37,0,.65.13.84.41.19.27.29.69.29,1.25,0,1.14-.4,1.71-1.19,1.71Zm-.53-.66c.12.21.31.32.57.32.25,0,.43-.1.54-.31.12-.21.17-.54.17-1.01s-.06-.84-.18-1.05c-.12-.21-.31-.32-.57-.32-.25,0-.43.1-.54.31-.11.21-.17.54-.17,1.01s.06.83.18,1.04Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m112.27,93.85h.39v1.2c.08-.05.18-.09.28-.12.1-.03.21-.04.31-.04.61,0,.92.39.92,1.17,0,.48-.1.82-.29,1.02-.19.2-.48.31-.87.31-.09,0-.23-.01-.42-.03h-.32v-3.5Zm.79,3.21c.23,0,.41-.07.53-.21.12-.14.18-.38.18-.72s-.05-.57-.16-.71-.26-.21-.47-.21c-.07,0-.15.01-.23.04-.09.02-.17.05-.25.09v1.67c.17.02.3.04.4.04Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m116.06,97.14c-.09.07-.19.13-.32.17s-.26.07-.41.07c-.22,0-.4-.06-.53-.18-.13-.12-.2-.28-.2-.48,0-.25.08-.44.24-.58.16-.14.4-.21.71-.21.16,0,.32.02.46.05v-.16c0-.23-.04-.39-.12-.48s-.22-.13-.42-.13c-.1,0-.2.01-.3.03-.1.02-.21.05-.34.09h-.12v-.32c.31-.08.59-.12.83-.12.29,0,.51.07.64.2.14.14.21.34.21.63v1.63h-.34v-.21Zm-.94-.18c.08.07.19.11.32.11.1,0,.2-.02.3-.05s.19-.08.28-.13v-.59c-.16-.04-.33-.06-.53-.06-.33,0-.49.15-.49.44,0,.12.04.22.12.29Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m117.13,94.92h.36v.25c.2-.12.43-.21.69-.27h.15v.38c-.12,0-.26.02-.41.05-.15.03-.28.07-.41.11v1.91h-.38v-2.43Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m54.78,7.01l1.07-.3h.11v2.95h.62v.34h-1.8v-.34h.76v-2.5l-.65.18h-.11v-.34Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m58.21,8.42c-.12-.11-.32-.17-.62-.17-.14,0-.32,0-.53.03v-1.58h1.6v.33h-1.18v.91c.18,0,.28-.02.31-.02.34,0,.6.08.76.24s.25.4.25.73c0,.36-.1.64-.29.84-.19.2-.49.3-.88.3-.23,0-.46-.02-.67-.07v-.36h.14c.12.03.22.05.31.06.09.01.18.02.28.02.22,0,.39-.06.51-.18s.19-.3.19-.54c0-.26-.06-.44-.17-.55Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m60.46,10.04c-.37,0-.65-.13-.84-.41-.19-.27-.29-.68-.29-1.25,0-1.14.39-1.71,1.18-1.71.37,0,.65.13.84.41.19.27.29.69.29,1.25,0,1.14-.4,1.71-1.19,1.71Zm-.53-.66c.12.21.31.32.57.32.25,0,.43-.1.54-.31.12-.21.17-.54.17-1.01s-.06-.84-.18-1.05c-.12-.21-.31-.32-.57-.32-.25,0-.43.1-.54.31-.11.21-.17.54-.17,1.01s.06.83.18,1.04Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m63.4,6.5h.38v1.2c.08-.05.18-.09.28-.12.1-.03.21-.04.31-.04.61,0,.92.39.92,1.17,0,.48-.1.82-.29,1.02-.19.2-.48.31-.87.31-.09,0-.23-.01-.42-.03h-.32v-3.5Zm.79,3.21c.23,0,.41-.07.53-.21.12-.14.18-.38.18-.72s-.05-.57-.16-.71c-.11-.14-.26-.21-.47-.21-.07,0-.15.01-.24.04s-.17.05-.25.09v1.67c.17.02.3.04.41.04Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m67.18,9.79c-.09.07-.19.13-.32.17s-.26.07-.41.07c-.22,0-.39-.06-.53-.18-.13-.12-.19-.28-.19-.48,0-.25.08-.44.24-.58.16-.14.4-.21.71-.21.16,0,.31.02.46.05v-.16c0-.23-.04-.39-.12-.48s-.22-.13-.42-.13c-.1,0-.21.01-.3.03-.1.02-.21.05-.34.09h-.12v-.32c.31-.08.59-.12.83-.12.29,0,.51.07.65.2.14.14.21.34.21.63v1.63h-.35v-.21Zm-.94-.18c.08.07.19.11.32.11.1,0,.2-.02.3-.05s.19-.08.28-.13v-.59c-.16-.04-.34-.06-.53-.06-.33,0-.5.15-.5.44,0,.12.04.22.12.29Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m68.26,7.57h.36v.25c.2-.12.43-.21.69-.27h.15v.38c-.12,0-.26.02-.41.05-.15.03-.28.07-.41.11v1.91h-.39v-2.43Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m3.29,42.46v-.16l1.18-2.79h-1.51v-.34h1.96v.21s-1.25,3.08-1.25,3.08h-.38Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m6.58,40.88c-.12-.11-.32-.17-.62-.17-.14,0-.32,0-.53.03v-1.58h1.6v.33h-1.18v.91c.18,0,.28-.02.31-.02.34,0,.6.08.76.24s.25.4.25.73c0,.36-.1.64-.29.84-.19.2-.49.3-.88.3-.23,0-.46-.02-.67-.07v-.36h.14c.12.03.22.05.31.06.09.01.18.02.28.02.22,0,.39-.06.51-.18s.19-.3.19-.54c0-.26-.06-.44-.17-.55Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m8.88,38.96h.38v1.2c.08-.05.18-.09.28-.12.1-.03.21-.04.31-.04.61,0,.92.39.92,1.17,0,.48-.1.82-.29,1.02-.19.2-.48.31-.87.31-.09,0-.23-.01-.42-.03h-.32v-3.5Zm.79,3.21c.23,0,.41-.07.53-.21.12-.14.18-.38.18-.72s-.05-.57-.16-.71c-.11-.14-.26-.21-.47-.21-.07,0-.15.01-.24.04s-.17.05-.25.09v1.67c.17.02.3.04.41.04Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m12.66,42.25c-.09.07-.19.13-.32.17s-.26.07-.41.07c-.22,0-.39-.06-.53-.18-.13-.12-.19-.28-.19-.48,0-.25.08-.44.24-.58.16-.14.4-.21.71-.21.16,0,.31.02.46.05v-.16c0-.23-.04-.39-.12-.48s-.22-.13-.42-.13c-.1,0-.21.01-.3.03-.1.02-.21.05-.34.09h-.12v-.32c.31-.08.59-.12.83-.12.29,0,.51.07.65.2.14.14.21.34.21.63v1.63h-.35v-.21Zm-.94-.18c.08.07.19.11.32.11.1,0,.2-.02.3-.05s.19-.08.28-.13v-.59c-.16-.04-.34-.06-.53-.06-.33,0-.5.15-.5.44,0,.12.04.22.12.29Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m13.73,40.03h.36v.25c.2-.12.43-.21.69-.27h.15v.38c-.12,0-.26.02-.41.05-.15.03-.28.07-.41.11v1.91h-.39v-2.43Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m110.84,40.58c-.1.22-.29.5-.58.84l-.57.7h1.34v.34h-1.78v-.33l.77-1.01c.2-.26.34-.47.42-.64.08-.17.12-.33.12-.49s-.05-.28-.14-.38c-.1-.09-.23-.14-.39-.14s-.36.05-.58.16h-.12v-.33c.26-.11.52-.17.79-.17s.48.07.64.22.24.34.24.57c0,.22-.05.44-.16.65Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m113.07,40.58c-.1.22-.29.5-.58.84l-.57.7h1.34v.34h-1.78v-.33l.77-1.01c.2-.26.33-.47.42-.64.08-.17.12-.33.12-.49s-.05-.28-.14-.38c-.1-.09-.23-.14-.39-.14s-.36.05-.58.16h-.12v-.33c.26-.11.52-.17.79-.17s.48.07.64.22.24.34.24.57c0,.22-.05.44-.15.65Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m115.04,40.88c-.12-.11-.32-.17-.62-.17-.14,0-.32,0-.53.03v-1.58h1.6v.33h-1.18v.91c.18,0,.28-.02.31-.02.34,0,.6.08.76.24s.25.4.25.73c0,.36-.1.64-.29.84s-.49.3-.88.3c-.23,0-.46-.02-.68-.07v-.36h.15c.12.03.22.05.31.06.09.01.18.02.28.02.22,0,.39-.06.51-.18s.19-.3.19-.54c0-.26-.06-.44-.17-.55Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m117.33,38.96h.38v1.2c.08-.05.18-.09.28-.12.1-.03.21-.04.31-.04.61,0,.92.39.92,1.17,0,.48-.1.82-.29,1.02-.19.2-.48.31-.87.31-.09,0-.23-.01-.41-.03h-.32v-3.5Zm.79,3.21c.23,0,.41-.07.53-.21.12-.14.18-.38.18-.72s-.05-.57-.16-.71-.26-.21-.47-.21c-.07,0-.15.01-.23.04-.09.02-.17.05-.25.09v1.67c.17.02.3.04.4.04Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m121.12,42.25c-.09.07-.19.13-.32.17s-.26.07-.41.07c-.22,0-.4-.06-.53-.18-.13-.12-.2-.28-.2-.48,0-.25.08-.44.24-.58.16-.14.4-.21.71-.21.16,0,.32.02.46.05v-.16c0-.23-.04-.39-.12-.48s-.22-.13-.42-.13c-.1,0-.21.01-.3.03s-.21.05-.34.09h-.12v-.32c.31-.08.59-.12.83-.12.29,0,.51.07.65.2.14.14.21.34.21.63v1.63h-.34v-.21Zm-.94-.18c.08.07.19.11.32.11.1,0,.2-.02.3-.05s.2-.08.28-.13v-.59c-.16-.04-.33-.06-.53-.06-.33,0-.5.15-.5.44,0,.12.04.22.12.29Z"
              style={{ fill: '#969696' }}
            />
            <path
              d="m122.19,40.03h.36v.25c.2-.12.43-.21.69-.27h.15v.38c-.12,0-.26.02-.41.05-.15.03-.29.07-.41.11v1.91h-.38v-2.43Z"
              style={{ fill: '#969696' }}
            />
          </>
        ) : (
          <></>
        )}
      </svg>

      {pins && (
        <svg
          className={styles['gaas-manometer-pin']}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 124 124"
          name={`pins`}
        >
          {pins.map((pin, i) => {
            return (
              <ManometerPin
                value={manometerValue(pin.value)}
                color={pin.color}
                id={pin.id}
                setCurrentPin={setCurrentPin}
                key={i}
                permission={permission}
              />
            );
          })}
        </svg>
      )}
    </div>
  );
};
