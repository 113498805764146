import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as commonActions from '../features/common/actions';
import { useAuth } from '@aws-amplify/ui-react/internal';

const mapStateToProps = () => ({});

const dispatchProps = {
  signedIn: commonActions.signedInAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const AuthGuard: React.FC<Props> = ({ signedIn }) => {
  // for possible values, see https://ui.docs.amplify.aws/components/authenticator#access-auth-state
  const { user } = useAuth();

  useEffect(() => {
    if (user != null) {
      signedIn();
    }
  }, [user, signedIn]);

  return <></>;
};

export default connect(mapStateToProps, dispatchProps)(AuthGuard);
