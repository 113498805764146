import { WsStreamingValveUpdatePayloadType } from '../../models/zod/WsSchema';
import { ValveHistoryBucket } from '../../services/valve-service';
import { makeAsyncAction } from '../../util/makeAsyncAction';
import {
  ContainerValvePairing,
  PairingType,
} from './models/container-valve-pairing';
import { PressureThresholdView } from './models/pressure-threshold-view';
import { ValveHistoryItem } from './models/valve';

export const getValvesAsync = makeAsyncAction('GET_VALVES')<
  string,
  { customerId: string; valves: WsStreamingValveUpdatePayloadType[] },
  Error
>();

export const getThresholdAsync = makeAsyncAction('GET_THRESHOLDS')<
  string,
  PressureThresholdView[],
  Error
>();

export const pairContainerToValveAsync = makeAsyncAction(
  'PAIR_CONTAINER_TO_VALVE'
)<
  {
    barcode: string;
    serialNumber: string;
    pairingType: PairingType;
  },
  { pairing: ContainerValvePairing; serialNumber: string },
  { error: Error; serialNumber: string }
>();

export const unpairContainerFromValveAsync = makeAsyncAction(
  'UNPAIR_CONTAINER_FROM_VALVE'
)<
  {
    serialNumber: string;
  },
  { serialNumber: string },
  { error: Error; serialNumber: string }
>();

export const updateValveLocationAsync = makeAsyncAction(
  'UPDATE_VALVE_LOCATION'
)<
  {
    serialNumber: string;
    location: string | null;
    oldDeviceName: string | undefined;
  },
  {},
  { error: Error; oldDeviceName: string | undefined; serialNumber: string }
>();

export const createThresholdAsync = makeAsyncAction('CREATE_THRESHOLD')<
  {
    threshold: PressureThresholdView;
    customerId: string;
  },
  {},
  Error
>();

export const updateThresholdAsync = makeAsyncAction('UPDATE_THRESHOLD')<
  {
    threshold: PressureThresholdView;
    customerId: string;
  },
  {},
  Error
>();

export const deleteThresholdAsync = makeAsyncAction('DELETE_THRESHOLD')<
  {
    serialNumber: string;
    thresholdId: string;
    customerId: string;
  },
  {},
  Error
>();

export const getValveHistoryAsync = makeAsyncAction('GET_VALVE_HISTORY')<
  {
    serialNumber: string;
    deviceId: string;
    bucket: ValveHistoryBucket;
  },
  {
    items: ValveHistoryItem[];
    bucket: ValveHistoryBucket;
    serialNumber: string;
    deviceId: string;
  },
  {
    bucket: ValveHistoryBucket;
    serialNumber: string;
    deviceId: string;
    error: Error;
  }
>();

export const getValveHistoryExportAsync = makeAsyncAction(
  'GET_VALVE_HISTORY_EXPORT'
)<
  {
    serialNumber: string;
    deviceId: string;
    bucket: ValveHistoryBucket;
  },
  {
    data: string;
    bucket: ValveHistoryBucket;
    serialNumber: string;
    deviceId: string;
  },
  {
    bucket: ValveHistoryBucket;
    serialNumber: string;
    deviceId: string;
    error: Error;
  }
>();

export const getCustomerAssignmentForValveAsync = makeAsyncAction(
  'GET_ASSIGNMENT_FOR_VALVE'
)<
  {
    serialNumber: string;
  },
  {
    customerId: string;
    serialNumber: string;
    deviceId: string;
  },
  {
    serialNumber: string;
    error: Error;
  }
>();
