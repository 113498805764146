import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useCameraStream } from './CameraStreamProvider';
import { useCameraVideoFeedCallbacks } from './CameraVideoFrameCallbackProvider';
import * as scannerSelectors from '../../features/scanning/selectors';
import { RootState } from 'typesafe-actions';
import { connect } from 'react-redux';
import styles from './BarcodeScannerV2.module.scss';

interface ComponentProps {
  debugDrawIn?: boolean;
}

const mapStateToProps = (rootState: RootState) => ({
  lastScanResult: () => scannerSelectors.lastScanResult(rootState.scanner),
});

const dispatchProps = {};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

function drawCandidate(
  path: number[][],
  dx: number,
  dy: number,
  ratio: number,
  ctx: CanvasRenderingContext2D
): void {
  if (path.length === 0) {
    return;
  }
  ctx.beginPath();
  ctx.moveTo(path[0][0] * ratio + dx, path[0][1] * ratio + dy);
  for (let j = 1; j < path.length; j++) {
    ctx.lineTo(path[j][0] * ratio + dx, path[j][1] * ratio + dy);
  }
  ctx.closePath();
  ctx.stroke();
}

const CameraCanvasRenderer: React.FC<Props> = ({
  lastScanResult,
  debugDrawIn,
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [canvasSizeX, setCanvasSizeX] = useState<number>(100);
  const [canvasSizeY, setCanvasSizeY] = useState<number>(100);

  const [wrapperRef, setWrapperRef] = useState<HTMLDivElement | null>(null);
  const wrapperRefCb = useCallback(node => {
    if (node !== null) {
      setWrapperRef(node);
    }
  }, []);

  const { videoRef } = useCameraStream();
  const { subscribeToFrames, unsubscribeFromFrames } =
    useCameraVideoFeedCallbacks();

  const onNewFrame = useMemo(() => {
    return () => {
      const currentCanvasRef = canvasRef.current;
      if (!currentCanvasRef || !videoRef) {
        return;
      }

      const ctx = currentCanvasRef.getContext('2d');
      const vw = videoRef.videoWidth;
      const vh = videoRef.videoHeight;
      const cw = currentCanvasRef.width;
      const ch = currentCanvasRef.height;

      let screenToFeedRatio = 1;
      if (vw > vh) {
        // x is main axis
        screenToFeedRatio = cw / vw;
      } else {
        // y is the main axis
        screenToFeedRatio = ch / vh;
      }
      const ratio = Math.min(screenToFeedRatio, 1);
      // const drawRatio = screenToFeedRatio * ratio

      const dWidth = vw * ratio;
      const dHeight = vh * ratio;
      const dx = (cw - dWidth) / 2;
      const dy = (ch - dHeight) / 2;

      const barcodes = lastScanResult();

      if (!ctx) {
        return;
      }

      ctx.clearRect(0, 0, cw, ch);
      ctx.drawImage(videoRef, dx, dy, dWidth, dHeight);

      const { detected, candidates } = barcodes;
      if (detected) {
        ctx.strokeStyle = 'green';
        ctx.fillStyle = 'green';
        ctx.lineWidth = 1;
        drawCandidate(detected.roi, dx, dy, ratio, ctx);

        // inpaint result
        if (debugDrawIn) {
          ctx.font = '52px Arial';
          ctx.fillStyle = 'purple';
          ctx.fillText(detected.code, 0, 100);
          ctx.fillText(`${barcodes.timestamp.getTime()}`, 0, 200);
        }
      }

      for (const candidate of candidates ?? []) {
        ctx.strokeStyle = 'purple';
        ctx.fillStyle = 'purple';
        ctx.lineWidth = 1;
        drawCandidate(candidate.roi, dx, dy, ratio, ctx);
      }
    };
  }, [canvasRef, videoRef, lastScanResult, debugDrawIn]);

  useEffect(() => {
    const id = subscribeToFrames(onNewFrame);
    return () => {
      unsubscribeFromFrames(id);
    };
  }, [onNewFrame, subscribeToFrames, unsubscribeFromFrames]);

  useEffect(() => {
    if (!wrapperRef) {
      return;
    }
    setCanvasSizeX(wrapperRef.clientWidth);
    setCanvasSizeY(wrapperRef.clientHeight);

    const resizeObserver = new ResizeObserver(entries => {
      const cameraWrapper = entries.find(
        it => it.target.id === 'camera-canvas-wrapper'
      );
      if (!cameraWrapper) {
        return;
      }

      setCanvasSizeX(cameraWrapper.contentRect.width);
      setCanvasSizeY(cameraWrapper.contentRect.height);
    });

    resizeObserver.observe(wrapperRef);
    return () => {
      resizeObserver.disconnect();
    };
  }, [wrapperRef, setCanvasSizeX, setCanvasSizeY]);

  return (
    <div
      className={styles['gaas-barcode-scanner--video']}
      ref={wrapperRefCb}
      id={'camera-canvas-wrapper'}
    >
      <canvas
        ref={canvasRef}
        width={canvasSizeX}
        height={canvasSizeY}
        id={'CameraCanvasRenderer'}
        className={styles['gaas-barcode-scanner--video--canvas']}
      />
    </div>
  );
};

export default connect(mapStateToProps, dispatchProps)(CameraCanvasRenderer);
