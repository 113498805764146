export enum ScanDetectionCodeSet {
  VARIANT_A = 'A',
  VARIANT_B = 'B',
  VARIANT_C = 'C',
}
export interface ScanDetection extends ScanCandidate {
  code: string;
  error: number;
  codeSet: ScanDetectionCodeSet | undefined;
}

export interface ScanCandidate {
  roi: number[][];
}

export interface ScanResult {
  timestamp: Date;
  detected?: ScanDetection;
  candidates?: ScanCandidate[];
}
