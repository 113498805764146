import React, { useEffect, useState } from 'react';
import { Devider } from '../stories/Devider';
import { Logo } from '../stories/icons';
import styles from './SalesforceAuthenticator.module.scss';
import { Autocomplete, MenuItem, Select, TextField } from '@mui/material';
import { Button } from '../stories/Button';
import * as commonActions from '../features/common/actions';
import { connect } from 'react-redux';
import { Checkbox } from '../stories/Checkbox';
import { FakeAuth, FakeAuthCredentials } from '../util/fakeAuth';
import { GaasSubscription, GaasUserRole } from '../models/openapi/openapiTypes';

const mapStateToProps = () => ({});

const dispatchProps = {
  signIn: commonActions.signedInLocalAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

type UserStory =
  | 'customer'
  | 'admin'
  | 'empty'
  | 'freeUser'
  | 'freeSubs'
  | 'emptyUser';
const UserStoryData: { [key in UserStory]: FakeAuthCredentials } = {
  admin: {
    isAdmin: true,
    customerIds: ['123456', '987654'],
    salesforceId: 'SF1234',
    cognitoId: 'c10a41e6-1584-467e-8674-ea084b756fe0',
    email: 'sebastian.staffa@tapdo.io',
  },
  customer: {
    isAdmin: false,
    customerIds: ['123456'],
    salesforceId: 'SF4321',
    cognitoId: '165bd0ff-6449-466a-a669-851909e4c1b7',
    email: 'sebastian.staffa@tapdo.io',
  },
  freeUser: {
    isAdmin: false,
    customerIds: ['123456'],
    salesforceId: 'SF5678',
    cognitoId: '41c1d530-9bc6-4844-844e-f8c657c9d0aa',
    email: 'sebastian.staffa@tapdo.io',
    role: GaasUserRole.Free,
  },
  freeSubs: {
    isAdmin: false,
    customerIds: ['123456'],
    salesforceId: 'SF5678',
    cognitoId: '41c1d530-9bc6-4844-844e-f8c657c9d0aa',
    email: 'sebastian.staffa@tapdo.io',
    role: GaasUserRole.Admin,
    subscription: GaasSubscription.Free,
  },
  emptyUser: {
    isAdmin: false,
    customerIds: ['123456'],
    salesforceId: 'SF5678',
    cognitoId: '41c1d530-9bc6-4844-844e-f8c657c9d0aa',
    email: 'sebastian.staffa@tapdo.io',
    role: GaasUserRole.Empty,
    subscription: GaasSubscription.Transparency,
  },
  empty: {
    isAdmin: false,
    customerIds: [],
    salesforceId: 'SF1337',
    cognitoId: '211f56f3-8701-49d9-a9b7-61e48bb10213',
    email: 'sebastian.staffa@tapdo.io',
  },
};
const AvailableCustomerIds = ['123456', '987654'];

const initialUserStory: UserStory = 'admin';
const LocalAuthenticator: React.FC<Props> = ({ signIn }) => {
  const [salesforceId, setSalesforceId] = useState(
    UserStoryData[initialUserStory].salesforceId
  );
  const [cognitoId, setCognitoId] = useState(
    UserStoryData[initialUserStory].cognitoId
  );
  const [email, setEmail] = useState(UserStoryData[initialUserStory].email);
  const [isAdmin, setIsAdmin] = useState(
    UserStoryData[initialUserStory].isAdmin
  );
  const [customerIds, setCustomerIds] = useState(
    UserStoryData[initialUserStory].customerIds
  );

  const [role, setRole] = useState<GaasUserRole | undefined>(
    UserStoryData[initialUserStory].role
  );
  const [subscription, setSubscription] = useState<
    GaasSubscription | undefined
  >(UserStoryData[initialUserStory].subscription);

  const [useSimpleMode, setSimpleMode] = useState(true);
  const [selectedStory, setSelectedStory] = useState<UserStory>('admin');

  useEffect(() => {
    const prevAuth = FakeAuth.getStoredCredentials();
    if (prevAuth == null) {
      return;
    }
    signIn(prevAuth);
  }, [signIn]);

  useEffect(() => {
    if (!useSimpleMode) {
      return;
    }

    const storyData = UserStoryData[selectedStory];

    setEmail(storyData.email);
    setCognitoId(storyData.cognitoId);
    setSalesforceId(storyData.salesforceId);
    setIsAdmin(storyData.isAdmin);
    setCustomerIds(storyData.customerIds);
    setRole(storyData.role);
    setSubscription(storyData.subscription);
  }, [
    selectedStory,
    useSimpleMode,
    setEmail,
    setCognitoId,
    setSalesforceId,
    setIsAdmin,
    setCustomerIds,
  ]);

  return (
    <div className={styles['gaas-salesforce-auth']}>
      <div
        className={styles['gaas-salesforce-auth--container']}
        style={{ rowGap: '12px' }}
      >
        <Logo />
        <h1 className={styles['gaas-salesforce-auth--title']}>
          Gas as a Service
        </h1>
        <p className={styles['gaas-salesforce-auth--text']}>
          Local Backend Fake Authentication
        </p>
        <Devider />
        <Checkbox
          label={'Custom User Data'}
          checked={!useSimpleMode}
          onClick={() => {
            const newSimpleModeValues = !useSimpleMode;
            setSimpleMode(newSimpleModeValues);
            if (newSimpleModeValues) {
              setSelectedStory(initialUserStory);
            }
          }}
        ></Checkbox>
        {/*<InputLabel id="user-story-select-label">User Story</InputLabel>*/}
        <Select
          disabled={!useSimpleMode}
          value={selectedStory}
          label={'Dataset'}
          id={'user-story-select'}
          labelId={'user-story-select-label'}
          onChange={event => {
            const story = event.target.value as UserStory;
            setSelectedStory(story);
          }}
        >
          <MenuItem value={'admin'}>Thomas Tester</MenuItem>
          <MenuItem value={'customer'}>Karla Kunde</MenuItem>
          <MenuItem value={'empty'}>Leonard Leer</MenuItem>
          <MenuItem value={'freeUser'}>Ferdinant Free</MenuItem>
          <MenuItem value={'freeSubs'}>Bernd Billig</MenuItem>
          <MenuItem value={'emptyUser'}>Nolan Nix</MenuItem>
        </Select>
        <TextField
          label={'Salesforce ID'}
          disabled={useSimpleMode}
          value={salesforceId}
        ></TextField>
        <TextField
          label={'Cognito ID'}
          disabled={useSimpleMode}
          value={cognitoId}
        ></TextField>
        <TextField
          label={'Email'}
          disabled={useSimpleMode}
          value={email}
        ></TextField>
        <Autocomplete
          multiple
          options={AvailableCustomerIds}
          getOptionLabel={option => option}
          value={customerIds}
          onChange={(event, value) => setCustomerIds(value)}
          readOnly={useSimpleMode}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              label="CustomerIds"
              placeholder="Favorites"
            />
          )}
        />
        <Checkbox
          label={'WAG Admin?'}
          disabled={useSimpleMode}
          checked={isAdmin}
          onClick={() => setIsAdmin(!isAdmin)}
        ></Checkbox>
        <Button
          label="Login"
          onClick={() => {
            signIn({
              salesforceId,
              cognitoId,
              email,
              isAdmin,
              customerIds,
              subscription,
              role,
            });
          }}
        />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, dispatchProps)(LocalAuthenticator);
