import Search from '@mui/icons-material/SearchRounded';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Pagination,
} from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'typesafe-actions';
import Sadhorse from '../../stories/assets/graphics/sadhorse.svg';
import {
  SortDirection,
  SortingParameters,
} from '../../features/common/models/listing-parameters';
import { PaginatedEntityList } from '../../features/common/models/paginated-entity-list';
import { AsyncResource } from '../../store/async-resource';

import { Devider } from '../../stories/Devider';
import styles from './List.module.scss';
import ListItem from './ListItem';
import ListLabels from './ListLabels';

export function debounce(
  fn: () => void,
  timerRef: React.MutableRefObject<NodeJS.Timer | null>,
  time: number
) {
  return () => {
    if (timerRef.current !== null) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      timerRef.current = null;
      fn();
    }, time);
  };
}

// export type SortDir = ListCustomersSortDirEnum | ListCustomersFullSortDirEnum;
export type AdditionalSearchProps = { [prop: string]: string };

export interface ColumnMapping {
  label: string;
  prop: string;
}

// interface ComponentProps<ListType,ItemType> {
interface ComponentProps<T> {
  /// Whether this list should display pagination controls
  hasPagination: boolean;
  /// Whether this list has a search input bar
  hasSearchBar: boolean;
  /// Whether this list has sorting controls
  hasSorting?: boolean;

  /// The mapping of entity props to the string labels of each list column
  columnLabels: ColumnMapping[];
  /// A string representing the css grid-template-column https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-columns
  /// of this list
  columnLabelGrid: string;

  /// The same as columnLabelGrid, but this prop can have more or less entries than
  /// columns
  columnValueGrid?: string;
  /// A function which transform the object fetched from the server to
  /// the values to display in each column. This function should do all necessary filtering,
  /// mapping and string formatting
  rowValueTransform: (obj: any) => any[];

  /// A slot which can be filled with an element to display which triggers an
  // "add entity" dialog
  addEntitySlot?: JSX.Element;
  /// The route to the entity detail page. The entity id is append to this
  /// route when the user clicks it.
  editEntityLink?: string;

  listSelector: (
    params: SortingParameters
  ) => AsyncResource<PaginatedEntityList<T>> | undefined;
  listRequest: (params: SortingParameters) => void;

  /// The base key which is used to reference lists in the global store.
  entityKey: string;

  /// The name of entity properties which should be searched (inclusive) for the user input
  /// from the search bar
  searchProps?: string[];
  /// The name of entity properties which should be filtered (exclusive) for the user input
  /// from the search bar
  filterProps?: string[];
  /// An object containing key-values pairs which should always be used when filtering
  additionalFilterProps?: AdditionalSearchProps;
}

const mapStateToProps = (state: RootState) => ({
  // listIsStale: (listName: string, subName: string) =>
  //   stalenessSelectors.isStale(state.staleness, listName, subName),
});

const dispatchProps = {
  // markListAsFresh: stalenessActions.markAsFresh,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof dispatchProps;
// type Props<ListType,ItemType> = ComponentProps<ListType,ItemType> & StateProps & DispatchProps
type Props<T> = ComponentProps<T> & StateProps & DispatchProps;

interface ListState {
  searchText: string;
  sortIndex: number;
  sortProp: string;
  sortDir: SortDirection;
  pageSize: number;
  page: number;
}

function List<T>({
  rowValueTransform,
  columnValueGrid,
  columnLabels,
  columnLabelGrid,
  hasSearchBar,
  hasPagination,
  hasSorting,
  addEntitySlot,
  editEntityLink,
  additionalFilterProps,
  searchProps,
  listSelector,
  listRequest,
  entityKey,
}: Props<T>) {
  hasSorting = hasSorting == null ? true : hasSorting;

  const debounceTimerRef = useRef<NodeJS.Timer | null>(null);

  // we store the list state in a single object,
  // to be able change multiple values at once
  // without triggering multiple requests
  const [listState, setListState] = useState<ListState>({
    page: 1,
    pageSize: 10,
    sortDir: SortDirection.asc,
    sortProp: String(columnLabels[0].prop ?? ''),
    sortIndex: 0,
    searchText: '',
  });

  const sortingParams = useMemo<SortingParameters>(
    () => ({
      sort: {
        dir: listState.sortDir,
        prop: listState.sortProp,
      },
      start: (listState.page - 1) * listState.pageSize,
      size: listState.pageSize,
      ...(searchProps != null && listState.searchText.length > 0
        ? {
            search: Object.fromEntries(
              searchProps.map(p => [p, listState.searchText])
            ),
          }
        : {}),
    }),
    [listState, searchProps]
  );

  const entityListSelector = listSelector(sortingParams);
  const entityData = entityListSelector?.data?.entities ?? [];
  const entityNum = entityListSelector?.data?.numTotal ?? 0;

  const numPages = Math.ceil(entityNum / listState.pageSize);

  useEffect(() => {
    if (
      !entityListSelector?.loading &&
      entityListSelector?.data == null &&
      entityListSelector?.error == null
    ) {
      listRequest(sortingParams);
    }
  }, [entityListSelector, listRequest, sortingParams]);

  let filterControl = <></>;
  if (hasSearchBar) {
    filterControl = (
      <div className={addEntitySlot && `${styles.withButton}`}>
        <FormControl className={styles.searchInput}>
          <InputLabel htmlFor="search-input">Suche</InputLabel>
          <OutlinedInput
            id="search-input"
            label="Suche"
            onChange={event =>
              debounce(
                () => {
                  setListState({
                    ...listState,
                    searchText: event.target.value,
                    page: 1,
                  });
                },
                debounceTimerRef,
                300
              )()
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end" color="primary">
                  <Search />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        {addEntitySlot}
      </div>
    );
  }

  let paginationControl = <></>;
  if (hasPagination) {
    paginationControl = (
      <div className={styles.pagination}>
        <Pagination
          color="secondary"
          count={numPages}
          page={listState.page}
          onChange={(event, page) => {
            if (page != null) {
              setListState({
                ...listState,
                page,
              });
            }
          }}
        />
      </div>
    );
  }

  return (
    <div>
      {filterControl}
      <Devider />

      <div>
        <ListLabels
          hasSorting={hasSorting}
          labels={columnLabels.map(it => it.label)}
          grid={columnLabelGrid}
          currentSortIndex={listState.sortIndex}
          currentSortDir={listState.sortDir}
          onChangeSort={index => {
            setListState({
              ...listState,
              sortDir:
                listState.sortDir === SortDirection.asc &&
                index === listState.sortIndex
                  ? SortDirection.desc
                  : SortDirection.asc,
              sortIndex: index,
              sortProp: String(columnLabels[index].prop ?? ''),
            });
          }}
        />
        {entityListSelector?.loading ? (
          <div className={styles.fakeItems}>
            {[...Array(10)].map((e, i) => (
              <div className={styles.fakeItem} key={i} />
            ))}
          </div>
        ) : entityData?.length > 0 ? (
          entityData.map((obj: any, i: number) =>
            editEntityLink != null ? (
              <Link
                to={`${editEntityLink}/${obj.id}`}
                key={`${entityKey}-${i}`}
              >
                <ListItem
                  values={rowValueTransform(obj)}
                  grid={columnValueGrid ?? columnLabelGrid}
                />
              </Link>
            ) : (
              <ListItem
                values={rowValueTransform(obj)}
                grid={columnValueGrid ?? columnLabelGrid}
              />
            )
          )
        ) : (
          <div className={styles.fakeItems}>
            <div className={styles.nothing}>
              <img
                className={styles.img}
                src={Sadhorse}
                alt="A very sad horse"
              />
              Zu diesen Kriterien wurde nichts gefunden…
            </div>
          </div>
        )}
      </div>

      {paginationControl}
    </div>
  );
}

export default connect(mapStateToProps, dispatchProps)(List);
