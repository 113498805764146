import React from 'react';

export const ManometerLoading: React.FC = () => {
  return (
    <svg style={{ position: 'relative' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 124 124"
        style={{ position: 'absolute' }}
      >
        <path
          d="m112,62c0,13.8-5.6,26.3-14.65,35.35l-12.72-12.72c5.79-5.79,9.37-13.79,9.37-22.63s-3.58-16.84-9.37-22.63c-5.8-5.79-13.8-9.37-22.63-9.37s-16.84,3.58-22.63,9.37c-5.79,5.79-9.37,13.79-9.37,22.63s3.58,16.84,9.37,22.63l-12.72,12.72c-9.05-9.05-14.65-21.55-14.65-35.35s5.6-26.3,14.65-35.35,21.55-14.65,35.35-14.65,26.3,5.6,35.35,14.65,14.65,21.55,14.65,35.35Z"
          fill="#fff"
        />
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 124 124"
        style={{ position: 'absolute' }}
      >
        <path
          d="m67.91,62c0,3.55-3.1,6.39-6.74,5.96-2.65-.32-4.84-2.46-5.2-5.11-.31-2.29.67-4.37,2.31-5.62l2.96-30.83c.09-.78,1.22-.78,1.31,0l2.98,30.83c1.45,1.09,2.38,2.82,2.38,4.77Z"
          fill="#1e335d"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            from="-135  62 62"
            to="135 62 62"
            values="-135  62 62; 135 62 62; -135  62 62;"
            dur="1.5s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </svg>
  );
};
