import { ValveHistoryBucket } from '../../services/valve-service';
import { asyncResourceSelector } from '../../store/async-resource';
import { DigidrumiState } from './reducer';

export const valves = (state: DigidrumiState) =>
  asyncResourceSelector(state.valves);

export const thresholds = (state: DigidrumiState) =>
  asyncResourceSelector(state.thresholds);

export const valveHistory = (
  state: DigidrumiState,
  deviceIdentifier: string,
  bucket: ValveHistoryBucket
) => asyncResourceSelector(state.valveHistory?.[deviceIdentifier]?.[bucket]);

export const valveHistoryExport = (
  state: DigidrumiState,
  deviceIdentifier: string,
  bucket: ValveHistoryBucket
) =>
  asyncResourceSelector(state.valveHistoryExport?.[deviceIdentifier]?.[bucket]);

export const containerUnpairingInProgress = (
  state: DigidrumiState,
  deviceIdentifier: string
) => state.containerUnpairingInProgress[deviceIdentifier] ?? false;
export const containerPairingInProgress = (
  state: DigidrumiState,
  serialNumber: string
) => state.containerPairingInProgress[serialNumber] ?? false;

export const customerAssignmentForValve = (state: DigidrumiState) =>
  state.assignmentForValve;
