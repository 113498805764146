import React from 'react';
import styles from './Chip.module.scss';
import classNames from 'classnames';

interface Props {
  children: React.ReactNode;
  size?: 's' | 'm' | 'l';
  clickable?: boolean;
  onClick?: () => void;
}

export const Chip: React.FC<Props> = ({
  children,
  size = 'm',
  clickable,
  onClick,
}) => {
  return (
    <div
      className={classNames(styles['gaas-chip'], styles[`gaas-chip--${size}`], {
        [styles['gaas-chip--clickable']]: onClick || clickable,
      })}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
