import CustomerSelection from '../../common/components/CustomerSelection';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import * as inventorySelectors from '../../inventory/selectors';
import * as inventoryActions from '../../inventory/actions';
import * as groupActions from '..//actions';
import * as commonSelectors from '../../common/selectors';
import * as groupSelectors from '../selectors';
import { useEffect, useState } from 'react';
import { Skeleton } from '../../../stories/Skeleton';
import { Button } from '../../../stories/Button';
import { Add } from '../../../stories/icons';
import GroupCard from '../components/GroupCard';
import CreateGroupModal from '../components/CreateGroupModal';
import styles from './GroupPage.module.scss';
import { GroupCardDummy } from '../../../stories/GroupCardDummy';
import { GroupsLoading } from '../../../stories/GroupsLoading';
import { ApiGaasAction } from '../../../models/openapi/openapiTypes';

// interface RouteParams {
//   customer: string;
//   group: string;
// }
const mapStateToProps = (state: RootState) => ({
  customerGroups: (customerId: string) =>
    groupSelectors.customerGroups(state.groups, customerId),
  customerGroupsConsumptionHistory: (customerId: string) =>
    groupSelectors.customerGroupConsumptionHistory(state.groups, customerId),
  selectedCustomer: commonSelectors.selectedCustomer(state.common),
  customerInventory: inventorySelectors.inventory(state.inventory),
  hasPermission: (action: ApiGaasAction) =>
    commonSelectors.hasPermission(action, state.common),
});
const dispatchProps = {
  getCustomerGroups: groupActions.getCustomerGroupsAsync.request,
  getCustomerGroupsConsumptionHistory:
    groupActions.getGroupConsumptionHistoryAsync.request,
  getInventory: inventoryActions.getInventoryAsync.request,
};

type ComponentProps = {};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const GroupPage: React.FC<Props> = ({
  customerGroups,
  getCustomerGroups,
  selectedCustomer,
  getInventory,
  customerInventory,
  customerGroupsConsumptionHistory,
  getCustomerGroupsConsumptionHistory,
  hasPermission,
}) => {
  // todo routing
  // let { customer: routeCustomerId, group: routeGroupId } =
  //   useParams<RouteParams>();
  const writeGroupPermission = hasPermission('group.write');
  const readGroupConsumptionPermission = hasPermission(
    'group.history.consumption.read'
  );
  const allowed =
    writeGroupPermission[0] === 'GRANTED' &&
    readGroupConsumptionPermission[0] === 'GRANTED';

  const [isCreateGroupModalOpen, setCreateGroupModalOpen] =
    useState<boolean>(false);

  const selectedCustomerId = selectedCustomer?.customerId;
  useEffect(() => {
    if (!selectedCustomerId) {
      return;
    }

    const groups = customerGroups(selectedCustomerId);
    if (groups.data == null && !groups.loading) {
      getCustomerGroups({ customerId: selectedCustomerId });
    }
  }, [selectedCustomerId, customerGroups, getCustomerGroups]);

  useEffect(() => {
    if (!selectedCustomerId) {
      return;
    }

    const groupHistory = customerGroupsConsumptionHistory(selectedCustomerId);
    if (
      groupHistory.data == null &&
      !groupHistory.loading &&
      !groupHistory.error &&
      readGroupConsumptionPermission[0] === 'GRANTED'
    ) {
      getCustomerGroupsConsumptionHistory({ customerId: selectedCustomerId });
    }
  }, [
    selectedCustomerId,
    customerGroupsConsumptionHistory,
    getCustomerGroupsConsumptionHistory,
    readGroupConsumptionPermission,
  ]);

  useEffect(() => {
    if (!selectedCustomerId) {
      return;
    }

    // todo: this is bad and should be fixed because it only allows one inventory
    const inventory = customerInventory;
    if (inventory.data == null && !inventory.loading) {
      getInventory(selectedCustomerId);
    }
  }, [selectedCustomerId, customerInventory, getInventory]);

  const groups = selectedCustomerId
    ? customerGroups(selectedCustomerId).data ?? []
    : [];
  const isLoading = selectedCustomerId
    ? customerGroups(selectedCustomerId).loading || customerInventory.loading
    : false;

  const additionalContent = isLoading ? (
    <Skeleton height={40} />
  ) : (
    <Button
      LeadingIcon={Add}
      fullWidth
      label="Gruppe"
      onClick={() => setCreateGroupModalOpen(true)}
      permission={hasPermission('group.write')}
    />
  );

  const content = () => {
    if (isLoading) {
      return (
        <div className={styles['gaas-group-page--loading']}>
          <GroupsLoading />
        </div>
      );
    }

    if (!selectedCustomerId) {
      return <></>;
    }

    if (!allowed) {
      return (
        <div className={styles['gaas-group-page']}>
          <GroupCardDummy
            onClick={() => null}
            permission={hasPermission('group.write')}
          />
        </div>
      );
    }

    if (!isLoading && !groups.length) {
      return (
        <>
          <div className={styles['gaas-group-page']}>
            <GroupCardDummy
              onClick={() => setCreateGroupModalOpen(true)}
              permission={writeGroupPermission}
            />
          </div>
          <CreateGroupModal
            isOpen={isCreateGroupModalOpen}
            onClose={() => setCreateGroupModalOpen(false)}
            customerId={selectedCustomerId}
          />
        </>
      );
    }

    return (
      <>
        <div className={styles['gaas-group-page']}>
          {groups.map(group => (
            <GroupCard
              group={group}
              allGroups={groups ?? []}
              containers={(customerInventory.data?.containers ?? []).filter(
                container => container.groupId === group.id
              )}
              key={group.id}
              customerId={selectedCustomerId}
              history={
                customerGroupsConsumptionHistory(selectedCustomerId).data
              }
              historyLoading={
                customerGroupsConsumptionHistory(selectedCustomerId).loading
              }
              readGroupConsumptionPermission={readGroupConsumptionPermission}
            />
          ))}
        </div>
        <CreateGroupModal
          isOpen={isCreateGroupModalOpen}
          onClose={() => setCreateGroupModalOpen(false)}
          customerId={selectedCustomerId}
        />
      </>
    );
  };

  return (
    <CustomerSelection
      title={'Verbrauchsgruppen'}
      additionalSidebarContent={additionalContent}
    >
      {content()}
    </CustomerSelection>
  );
};

export default connect(mapStateToProps, dispatchProps)(GroupPage);
