import { Link } from 'react-router-dom';
import styles from './BottleList.module.scss';
import { isAutomaticContainerState } from '../features/inventory/models/container-state';
import {
  isFullContainer,
  isHalfFullContainer,
} from '../features/inventory/helpers/fill-level';
import { TextButton } from './TextButton';
import {
  BottleEmptyDark,
  BottleFull,
  BottleOpenDark,
  Description,
  Speed,
} from './icons';
import React from 'react';
import { ContainerStateView } from '../features/inventory/models/inventory';
import { Tooltip } from './Tooltip';
import { Chip } from './Chip';

interface ComponentProps {
  customerId: string;
  containers: ContainerStateView[];
}

const BottleList: React.FC<ComponentProps> = ({ customerId, containers }) => {
  return (
    <div className={styles['gaas-bottle-list']}>
      <div className={styles['gaas-bottle-list--header']}>
        <span>Barcode</span>
        <span>Informationen</span>
      </div>

      <div className={styles['gaas-bottle-list--wrap']}>
        {containers.map(bottle => (
          <div className={styles['gaas-bottle-list--item']} key={bottle.id}>
            <Link
              to={`/inventory/inspect-bottle/${customerId}/${bottle.barcode}`}
              className={styles['gaas-bottle-list--item--barcode']}
            >
              <div className={styles.code}>{bottle.barcode}</div>
            </Link>

            <div className={styles['gaas-bottle-list--item--values']}>
              <Chip>
                {isFullContainer(bottle) ? (
                  <BottleFull />
                ) : isHalfFullContainer(bottle) ? (
                  <BottleOpenDark />
                ) : (
                  <BottleEmptyDark />
                )}
                {isAutomaticContainerState(bottle)
                  ? `${Math.round(
                      (100 * bottle.pressureBar) / bottle.fillPressure
                    )} %`
                  : undefined}
              </Chip>

              {isAutomaticContainerState(bottle) && (
                <Chip>{`${bottle.pressureBar} bar`}</Chip>
              )}

              {bottle.serialNumber !== undefined && (
                <Tooltip text="Zum Manometer">
                  <Link
                    to={`/digidrumi/${customerId}/${bottle.serialNumber}`}
                    className={styles.iconContainer}
                  >
                    <Chip clickable>
                      <TextButton LeadingIcon={Speed} />
                    </Chip>
                  </Link>
                </Tooltip>
              )}

              {bottle.note && bottle.note.content && (
                <Tooltip text={bottle.note.content}>
                  <Chip clickable>
                    <TextButton LeadingIcon={Description} />
                  </Chip>
                </Tooltip>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BottleList;
