export enum OverlayMessageType {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  Success = 'success',
}

export interface OverlayMessage {
  id: string;
  title: string;
  message: string;
  type: OverlayMessageType;
  createdAt: Date;
  sticky: boolean;
  details?: any;
}
