import React from 'react';
import { Tooltip } from './Tooltip';
import { HelpFilled } from './icons';

interface Props {
  text: string;
}

export const Hint: React.FC<Props> = ({ text }) => {
  return (
    <Tooltip text={text}>
      <HelpFilled />
    </Tooltip>
  );
};
