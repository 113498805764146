import { Modal } from '../../../stories/Modal';
import { ContainerStateView } from '../../inventory/models/inventory';
import BottleList from '../../../stories/BottleList';

interface ComponentProps {
  containers?: ContainerStateView[];
  onClose: () => void;
  groupName: string;
}

const GroupContainerListModal: React.FC<ComponentProps> = ({
  onClose,
  containers,
  groupName,
}) => {
  return (
    <Modal
      open={containers != null}
      onClose={onClose}
      title={`Flaschen der Gruppe: ${groupName}`}
      maxWidth={500}
    >
      {containers && (
        <BottleList
          customerId={containers?.[0]?.customerId ?? ''}
          containers={containers!}
        />
      )}
    </Modal>
  );
};

export default GroupContainerListModal;
