import React, { useState } from 'react';
import { Modal } from '../../../stories/Modal';
import { MenuItem, Select } from '@mui/material';
import { CustomerManagedGroup } from '../../groups/models/customer-managed-group';

const NO_GROUP_SELECTED_KEY = '__NO_GROUP_SELECTED__';

interface ComponentProps {
  isOpen: boolean;
  groups: CustomerManagedGroup[];
  onAbort: () => void;
  onConfirm: (groupId: string | null) => void;
  titleText: string;
  descriptionText: string;
  confirmWithGroupText: string;
  confirmWithoutGroupText: string;
  allowConfirmWithoutGroup: boolean;
}

const GroupSelectModal: React.FC<ComponentProps> = ({
  isOpen,
  onAbort,
  onConfirm,
  groups,
  titleText,
  descriptionText,
  confirmWithGroupText,
  confirmWithoutGroupText,
  allowConfirmWithoutGroup,
}) => {
  const [selectedGroupId, setSelectedGroupId] = useState<string>(
    NO_GROUP_SELECTED_KEY
  );

  return (
    <Modal
      title={titleText}
      open={isOpen}
      onClose={onAbort}
      onSave={{
        label:
          selectedGroupId === NO_GROUP_SELECTED_KEY
            ? confirmWithoutGroupText
            : confirmWithGroupText,
        action: () =>
          onConfirm(
            selectedGroupId === NO_GROUP_SELECTED_KEY ? null : selectedGroupId
          ),
        disabled:
          selectedGroupId === NO_GROUP_SELECTED_KEY &&
          !allowConfirmWithoutGroup,
      }}
    >
      <p style={{ fontFamily: 'WestfalenNewsSans' }}>{descriptionText}</p>

      <Select
        sx={{ borderRadius: 0, fontFamily: 'WestfalenNewsSans' }}
        value={selectedGroupId}
        onChange={event => setSelectedGroupId(event.target.value)}
      >
        <MenuItem
          value={NO_GROUP_SELECTED_KEY}
          key={NO_GROUP_SELECTED_KEY}
          sx={{ fontFamily: 'WestfalenNewsSans' }}
        >
          Keine Verbrauchsgruppe
        </MenuItem>
        {groups.map(group => {
          return (
            <MenuItem
              key={group.id}
              value={group.id}
              sx={{ fontFamily: 'WestfalenNewsSans' }}
            >
              {group.name}
            </MenuItem>
          );
        })}
      </Select>
    </Modal>
  );
};

export default GroupSelectModal;
