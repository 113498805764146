import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerManagedGroup } from '../features/groups/models/customer-managed-group';
import {
  ApiConsumptionHistory,
  ApiCustomerManagedGroup,
  ApiCustomerManagedGroupCreateRequest,
  ApiCustomerManagedGroupDeleteRequest,
} from '../models/openapi/openapiTypes';
import { apiDelete, apiGet, apiPost } from './api';
import { ConsumptionHistory } from '../features/groups/models/consumption-history';

export function getGroups(
  customerId: string
): Observable<CustomerManagedGroup[]> {
  return apiGet<ApiCustomerManagedGroup[]>(
    'GaasBackend',
    `/customers/${customerId}/groups`
  ).pipe(
    map(groups =>
      groups.map(group => ({
        ...group,
        createdAt: new Date(group.createdAt),
      }))
    )
  );
}

export function createGroup(
  customerId: string,
  group: ApiCustomerManagedGroupCreateRequest
): Observable<CustomerManagedGroup> {
  return apiPost<ApiCustomerManagedGroup>(
    'GaasBackend',
    `/customers/${customerId}/groups`,
    group
  ).pipe(
    map(createdGroup => ({
      ...createdGroup,
      createdAt: new Date(createdGroup.createdAt),
    }))
  );
}

export function deleteGroup(
  customerId: string,
  groupId: string,
  request: ApiCustomerManagedGroupDeleteRequest
): Observable<CustomerManagedGroup> {
  return apiDelete<ApiCustomerManagedGroup>(
    'GaasBackend',
    `/customers/${customerId}/groups/${groupId}`,
    undefined,
    request
  ).pipe(
    map(deletedGroup => ({
      ...deletedGroup,
      createdAt: new Date(deletedGroup.createdAt),
    }))
  );
}

export function getGroupConsumptionHistory(
  customerId: string
): Observable<ConsumptionHistory> {
  return apiGet<ApiConsumptionHistory>(
    'GaasBackend',
    `/history/consumption/${customerId}/groups`
  ).pipe(
    map(
      (history): ConsumptionHistory => ({
        items: history.items,
        start: new Date(history.start),
        end: new Date(history.end),
      })
    )
  );
}
