import classNames from 'classnames';
import React from 'react';
import styles from './ContentSwitcher.module.scss';

interface Tabs {
  label: string;
  onClick: () => void;
}

interface Props {
  tabs: Tabs[];
  activeTabId: number;
  setActiveTabId: React.Dispatch<React.SetStateAction<number>>;
}

export const ContentSwitcher = ({
  tabs,
  activeTabId,
  setActiveTabId,
}: Props) => {
  return (
    <div
      className={styles['gaas-content-switcher']}
      style={{ gridTemplateColumns: '1fr '.repeat(tabs.length) }}
    >
      {tabs.map((tab, i) => (
        <button
          className={classNames(styles['gaas-content-switcher--button'], {
            [styles['gaas-content-switcher--button--selected']]:
              i === activeTabId,
          })}
          onClick={() => {
            tab.onClick();
            setActiveTabId(i);
          }}
          key={i}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};
