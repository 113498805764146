import { useCallback, useEffect, useRef } from 'react';
import * as shopSelectors from '../selectors';
import * as shopActions from '../actions';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { TextButton } from '../../../stories/TextButton';
import { Download } from '../../../stories/icons';
import { Loading } from '../../../stories/Loading';
import styles from './ShopDownloadContractButton.module.scss';
import classNames from 'classnames';

function unpackB64(b64Data: string): Uint8Array {
  const byteCharacters = atob(b64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  return new Uint8Array(byteNumbers);
}

const mapStateToProps = (state: RootState) => ({
  contractPreview: shopSelectors.contractPreview(state.shop),
  cart: shopSelectors.currentCart(state.shop),
});

const dispatchProps = {
  renderContract: shopActions.renderContractAsync.request,
};

interface ComponentProps {}

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const ShopDownloadContractButton: React.FC<Props> = ({
  contractPreview,
  renderContract,
  cart,
}) => {
  const buttonRef = useRef<HTMLAnchorElement>(null);

  const currentB64Content = contractPreview?.data?.base64Content ?? '';

  const openPdfTab = useCallback(
    (b64Blob: string) => {
      if (b64Blob === '') {
        return;
      }
      const blob = new Blob([unpackB64(b64Blob)], {
        type: 'application/pdf',
      });
      const blobUrl = window.URL.createObjectURL(blob);
      buttonRef.current?.setAttribute('href', blobUrl);
      buttonRef.current?.click();
      window.URL.revokeObjectURL(blobUrl);
    },
    [buttonRef]
  );

  useEffect(() => {
    openPdfTab(currentB64Content);
  }, [currentB64Content, openPdfTab]);

  if (contractPreview?.loading ?? false) {
    return (
      <div
        className={classNames(
          styles['gaas-shopContract'],
          styles['gaas-shopContract--loading']
        )}
      >
        <Loading />
        Bitte warten Sie einen Augenblick, Ihr Vertrag wird für Sie generiert.
      </div>
    );
  }

  return (
    <div className={styles['gaas-shopContract']}>
      <div>
        Hier können Sie Ihren{' '}
        <TextButton
          onClick={() => {
            if (currentB64Content !== '') {
              openPdfTab(currentB64Content);
            } else {
              renderContract(cart);
            }
          }}
          label="GaaS® Vertrag"
          TrailingIcon={Download}
          inline
          // loading={contractPreview?.loading ?? false}
        />
        {
          // eslint-disable-next-line
          <a
            ref={buttonRef}
            style={{ display: 'hidden' }}
            download={`contract.pdf`}
            target="_blank"
            rel="noreferer noreferrer"
          />
        }
        herunterladen. Bitte lesen Sie sich den Vertrag aufmerksam und
        vollständig durch.
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  dispatchProps
)(ShopDownloadContractButton);
