import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import React from 'react';
import ShopWizard from '../components/ShopWizard';
import ShopCartProvider from '../components/ShopCartProvider';
import ShopCustomerNumberProvider from '../components/ShopCustomerNumberProvider';

const mapStateToProps = (state: RootState) => ({});

const dispatchProps = {};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const ShopPage: React.FC<Props> = () => {
  return (
    <ShopCustomerNumberProvider>
      <ShopCartProvider>
        <ShopWizard />
      </ShopCartProvider>
    </ShopCustomerNumberProvider>
  );
};

export default connect(mapStateToProps, dispatchProps)(ShopPage);
