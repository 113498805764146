import underConstruction from '../../../stories/assets/graphics/under-construction.svg';

const StatisticUnderConstruction: React.FC = () => {
  // TODO @nina maybe improve the look of this
  return (
    <div>
      <img src={underConstruction} alt="under construction" width={320} />
      <p>Verbrauchsstatistik befindet sich im Aufbau.</p>
    </div>
  );
};

export default StatisticUnderConstruction;
