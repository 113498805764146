import React, { useEffect, useState } from 'react';
import styles from './MaintenanceBanner.module.scss';
import IconEngineer from '@mui/icons-material/EngineeringRounded';
import IconClose from '@mui/icons-material/CloseRounded';
import { IconButton } from '@mui/material';
import classNames from 'classnames';
import { connect } from 'react-redux';
import * as commonSelectors from '../features/common/selectors';
import * as commonActions from '../features/common/actions';
import { RootState } from 'typesafe-actions';

const mapStateToProps = (state: RootState) => ({
  maintenanceNote: commonSelectors.maintenanceNote(state.common),
});

const dispatchProps = {
  getMaintenanceNote: commonActions.getMaintenanceNoteAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const MaintenanceBanner: React.FC<Props> = ({
  maintenanceNote,
  getMaintenanceNote,
}) => {
  const [close, setClose] = useState(true);

  useEffect(() => {
    if (
      maintenanceNote.data === undefined &&
      !maintenanceNote.loading &&
      maintenanceNote.error == null
    ) {
      getMaintenanceNote();
      return;
    }
    if (maintenanceNote.loading) {
      return;
    }
    const note = maintenanceNote.data;
    if (note == null) {
      return;
    }
    if (note.validUntil.getTime() > Date.now()) {
      setClose(false);
    }
  }, [maintenanceNote, getMaintenanceNote, setClose]);

  return (
    <div
      className={classNames(styles.container, { [styles.displayNone]: close })}
    >
      <IconEngineer />
      <p>{maintenanceNote?.data?.content}</p>
      <div className={styles.close}>
        <IconButton onClick={() => setClose(true)}>
          <IconClose />
        </IconButton>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, dispatchProps)(MaintenanceBanner);
