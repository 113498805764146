import { Auth } from 'aws-amplify';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserRole } from '../features/common/models/user-role';

export function getUser(): Observable<{
  user: string;
  role: UserRole;
  token: string;
}> {
  return from(Auth.currentSession()).pipe(
    map(session => session.getIdToken()),
    map(token => {
      let groupClaim = (token.payload['cognito:groups'] ?? []) as
        | string[]
        | string;
      if (typeof groupClaim === 'string') {
        groupClaim = [groupClaim];
      }

      let role = UserRole.Default;
      if (groupClaim.includes('admins')) {
        role = UserRole.Admin;
      }
      return {
        user: token.payload['custom:email'],
        role,
        token: token.getJwtToken(),
      };
    })
  );
}
