import { Observable } from 'rxjs';
import { apiPost } from './api';
import { ApiMaintenanceNoteRequest } from '../models/openapi/openapiTypes';

export function setPassword(
  email: string,
  password: string,
  permanent: boolean
): Observable<{}> {
  return apiPost<{}>('GaasBackend', `/admin/set-password`, {
    email,
    password,
    permanent,
  });
}

export function createAccount(
  email: string,
  sendInvitation: boolean
): Observable<string> {
  return apiPost<string>('GaasBackend', `/admin/create-account`, {
    email,
    sendInvitation,
  });
}

export function pairValveToCustomer(
  digimanoId: string,
  customerId: string,
  upsert: boolean
): Observable<{}> {
  return apiPost<string>('GaasBackend', `/admin/pairvalvetocustomer`, {
    deviceIdentifier: digimanoId,
    customerId,
    upsert,
  });
}

export function unpairValveFromCustomer(digimanoId: string): Observable<{}> {
  return apiPost<string>('GaasBackend', `/admin/unpairvalvefromcustomer`, {
    deviceIdentifier: digimanoId,
  });
}

export function pairGatewayToCustomer(
  gatewayId: string,
  customerId: string,
  upsert: boolean
): Observable<{}> {
  return apiPost<string>('GaasBackend', `/admin/pairgatewaytocustomer`, {
    gatewayId,
    customerId,
    upsert,
  });
}

export function unpairGatewayFromCustomer(gatewayId: string): Observable<{}> {
  return apiPost<string>('GaasBackend', `/admin/unpairgatewayfromcustomer`, {
    gatewayId,
  });
}

export function editMaintenanceNote(
  note: ApiMaintenanceNoteRequest
): Observable<{}> {
  return apiPost<{}>('GaasBackend', `/admin/maintenance-note`, note);
}
