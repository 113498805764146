import React from 'react';
import { ReactComponent as Dummy } from './assets/graphics/manometer-dummy.svg';
import styles from './ManometerCardDummy.module.scss';

export const ManometerCardDummy: React.FC = () => {
  const Detail = () => (
    <div className={styles['gaas-manometer-card-dummy--detail']}>
      <div className={styles['gaas-manometer-card-dummy--detail--label']} />
      <div className={styles['gaas-manometer-card-dummy--detail--value']} />
    </div>
  );

  return (
    <div className={styles['gaas-manometer-card-dummy']}>
      <h3 className={styles['gaas-manometer-card-dummy--title']}>Manometer</h3>
      <div className={styles['gaas-manometer-card-dummy--manometer']}>
        <Dummy />
      </div>
      <div className={styles['gaas-manometer-card-dummy--details']}>
        <Detail />
        <Detail />
        <Detail />
      </div>
      <div className={styles['gaas-manometer-card-dummy--cta']}>
        Nutzen Sie alle Vorteile des GaaS-Hubs mit unseren{' '}
        <b>digitalen Manometern</b>. Informieren Sie sich unter{' '}
        <a href="https://bit.ly/3OE8xIg" target="_blank" rel="noreferrer">
          westfalen.com/gaas
        </a>
        .
      </div>
    </div>
  );
};
