import { Observable } from 'rxjs';
import { apiGet, apiPost } from './api';
import {
  ApiCalculateCartRequest,
  ApiCalculateCartResponse,
  ApiGetShopAccessibleCustomers,
  ApiGetShopAddressesResponse,
  ApiGetShopItemsResponse,
  ApiRenderContractResponse,
  ApiSubmitCartRequest,
} from '../models/openapi/openapiTypes';

import { Cart, cartToApiCart } from '../features/shop/models/Cart';
import {
  SubmitCartRequest,
  submitCartRequestToApiSubmitCartRequest,
} from '../features/shop/models/SubmitCartRequest';

export function calculateCart(
  calculateCartRequest: Cart
): Observable<ApiCalculateCartResponse> {
  return apiPost<ApiCalculateCartResponse, ApiCalculateCartRequest>(
    'GaasShopBackend',
    `/shop/cart/calculate`,
    cartToApiCart(calculateCartRequest)
  );
}

export function renderContract(
  calculateCartRequest: Cart
): Observable<ApiRenderContractResponse> {
  return apiPost<ApiRenderContractResponse, ApiCalculateCartRequest>(
    'GaasShopBackend',
    `/shop/cart/render-contract`,
    cartToApiCart(calculateCartRequest)
  );
}

export function getShopItems(): Observable<ApiGetShopItemsResponse> {
  return apiGet<ApiGetShopItemsResponse>('PublicGaasBackend', `/shop/items`);
}

export function getAccessibleCustomers(): Observable<ApiGetShopAccessibleCustomers> {
  return apiGet<ApiGetShopAccessibleCustomers>(
    'GaasShopBackend',
    `/shop/customer-numbers`
  );
}

export function submitCart(
  submitCartRequest: SubmitCartRequest
): Observable<void> {
  return apiPost<void, ApiSubmitCartRequest>(
    'GaasShopBackend',
    `/shop/cart/submit`,
    submitCartRequestToApiSubmitCartRequest(submitCartRequest)
  );
}

export function getAddresses(
  sapCustomerNo: string
): Observable<ApiGetShopAddressesResponse> {
  return apiGet<ApiGetShopAddressesResponse>(
    'GaasShopBackend',
    `/shop/addresses`,
    {
      sapCustomerNo,
    }
  );
}

export function createFreeSubscription(
  sapCustomerNo: string
): Observable<void> {
  return apiPost<void>(
    'GaasShopBackend',
    `/shop/subscriptions/free/${sapCustomerNo}`,
    {}
  );
}
