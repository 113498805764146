import { Auth } from 'aws-amplify';
import { push } from 'connected-react-router';
import { Epic } from 'redux-observable';
import { EMPTY, from, of } from 'rxjs';
import {
  catchError,
  concatMap,
  delay,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { RootAction, RootState, Services, isActionOf } from 'typesafe-actions';
import { WsStreamingNotifications } from '../../models/zod/WsSchema';
import { FakeAuth } from '../../util/fakeAuth';
import { webSocketCheckToken, webSocketConnected } from '../websockets/actions';
import {
  awaitTokenRefresh,
  fireRayAsync,
  getCustomersAsync,
  getGatewayStatesAsync,
  getMaintenanceNoteAsync,
  getNotificationsAsync,
  notificationPush,
  onboardCustomersAsync,
  readAllNotificationsAsync,
  readNotificationAsync,
  refreshUserTokenAsync,
  selectCustomer,
  selectedCustomerChanged,
  signOutAsync,
  signOutLocalAsync,
  signedInAsync,
  signedInLocalAsync,
} from './actions';
import { UserRole } from './models/user-role';

export const handleAwaitTokenRefresh: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = action$ =>
  action$.pipe(
    filter(isActionOf(awaitTokenRefresh)),
    map(payload => payload.payload),
    switchMap(dt => of(dt).pipe(delay(dt))),
    switchMap(() => from([refreshUserTokenAsync()]))
  );

export const refreshToken: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  _a,
  _b
) =>
  action$.pipe(
    filter(isActionOf(refreshUserTokenAsync)),
    switchMap(() => from(Auth.currentSession()).pipe()),
    map(session => ({
      session,
      delay: session.getIdToken().getExpiration() * 1000 - Date.now(),
    })),
    switchMap(payload =>
      from([
        webSocketCheckToken({
          token: payload.session.getIdToken().getJwtToken(),
        }),
        awaitTokenRefresh(payload.delay),
      ])
    ),
    catchError(_ => from([refreshUserTokenAsync()]))
  );

export const signedIn: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  _,
  { user }
) =>
  action$.pipe(
    filter(isActionOf(signedInAsync.request)),
    switchMap(() => user.getUser()),
    distinctUntilChanged(
      (prev, current) =>
        prev != null && current != null && prev.user === current.user
    ),
    concatMap(payload =>
      from([onboardCustomersAsync.request(payload), refreshUserTokenAsync()])
    ),
    catchError(error => {
      console.error('Error getting user', error);
      return of(signedInAsync.failure(error));
    })
  );

export const signOut: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = action$ =>
  action$.pipe(
    filter(isActionOf(signOutAsync.request)),
    switchMap(() =>
      from(Auth.signOut()).pipe(
        switchMap(() => of(signOutAsync.success(), push('/'))),
        catchError(error => {
          console.error('Error signing out', error);
          return of(signOutAsync.failure(error));
        })
      )
    )
  );

export const signedInLocal: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { user }) =>
  action$.pipe(
    filter(isActionOf(signedInLocalAsync.request)),
    map(({ payload }) => {
      FakeAuth.signIn(payload);
      return onboardCustomersAsync.request({
        user: payload.salesforceId,
        role: payload.isAdmin ? UserRole.Admin : UserRole.Default,
        token: '',
      });
    })
  );

export const onboardCustomers: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { customer }) =>
  action$.pipe(
    filter(isActionOf(onboardCustomersAsync.request)),
    // delay(10000),
    // map((signInPayload) => signedInAsync.success(signInPayload.payload)),
    switchMap(signInPayload =>
      customer.onboardCustomers().pipe(
        concatMap(response =>
          from([
            signedInAsync.success(signInPayload.payload),
            onboardCustomersAsync.success({ permissions: response }),
          ])
        ),
        catchError(error => of(signedInAsync.failure(error)))
      )
    )
  );

export const signOutLocal: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = action$ =>
  action$.pipe(
    filter(isActionOf(signOutLocalAsync.request)),
    map(payload => {
      FakeAuth.signOut();
      push('/');
      return signOutAsync.success();
    })
  );

export const getCustomers: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  _,
  { customer }
) =>
  action$.pipe(
    filter(isActionOf(getCustomersAsync.request)),
    switchMap(() =>
      customer.getCustomers().pipe(
        map(customers => getCustomersAsync.success(customers)),
        catchError(error => of(getCustomersAsync.failure(error)))
      )
    )
  );

export const fireRay: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  _,
  { firewall }
) =>
  action$.pipe(
    filter(isActionOf(fireRayAsync.request)),
    switchMap(({ payload }) =>
      firewall.fireRay(payload).pipe(
        map(rayResponse => fireRayAsync.success(rayResponse.rayid)),
        catchError(error => of(fireRayAsync.failure(error)))
      )
    )
  );

export const getNotifications: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { notifications }) =>
  action$.pipe(
    filter(isActionOf(getNotificationsAsync.request)),
    switchMap(() =>
      notifications.getNotificationsForUser().pipe(
        map(notifications => getNotificationsAsync.success(notifications)),
        catchError(error => of(getNotificationsAsync.failure(error)))
      )
    )
  );

export const getMaintenanceNote: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { common }) =>
  action$.pipe(
    filter(isActionOf(getMaintenanceNoteAsync.request)),
    switchMap(() =>
      common.getMaintenanceNote().pipe(
        map(note => getMaintenanceNoteAsync.success(note)),
        catchError(error => of(getMaintenanceNoteAsync.failure(error)))
      )
    )
  );

export const markAllNotificationsRead: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { notifications }) =>
  action$.pipe(
    filter(isActionOf(readAllNotificationsAsync.request)),
    switchMap(() =>
      notifications.markAllNotificationsRead().pipe(
        map(() => readAllNotificationsAsync.success()),
        catchError(error => of(readAllNotificationsAsync.failure(error)))
      )
    )
  );

export const markNotificationRead: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { notifications }) =>
  action$.pipe(
    filter(isActionOf(readNotificationAsync.request)),
    switchMap(({ payload: notificationId }) =>
      notifications.markNotificationRead(notificationId).pipe(
        map(() => readNotificationAsync.success()),
        catchError(error =>
          of(readNotificationAsync.failure({ error, id: notificationId }))
        )
      )
    )
  );

export const getGatewayStates: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { customer }) =>
  action$.pipe(
    filter(isActionOf(getGatewayStatesAsync.request)),
    switchMap(({ payload: customerId }) =>
      customer.getGatewayStates(customerId).pipe(
        map(states => getGatewayStatesAsync.success({ states, customerId })),
        catchError(error =>
          of(getGatewayStatesAsync.failure({ error, customerId }))
        )
      )
    )
  );

export const selectSingleCustomer: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = action$ =>
  action$.pipe(
    filter(isActionOf(getCustomersAsync.success)),
    filter(({ payload: customers }) => customers.length === 1),
    map(({ payload: customers }) => selectCustomer(customers[0]))
  );

export const debounceSelectedCustomer: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = action$ =>
  action$.pipe(
    filter(isActionOf(selectCustomer)),
    map(({ payload: customer }) => customer),
    distinctUntilChanged(
      (prev, current) => prev?.customerId === current?.customerId
    ),
    map(customer => selectedCustomerChanged(customer))
  );

export const handleWebSocketConnection: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state, { websocket }) =>
  action$.pipe(
    filter(isActionOf(webSocketConnected)),
    withLatestFrom(state),
    switchMap(([customerId, state]) => {
      const { websocket: ws } = state.websocket;
      if (!ws) {
        return EMPTY;
      }
      return websocket.listenForWebSocketMessages<
        typeof WsStreamingNotifications
      >(ws, WsStreamingNotifications);
    }),
    map(payload => notificationPush(payload))
  );
