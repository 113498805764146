import React from 'react';
import { ContainerStateView } from '../models/inventory';
import { Order } from '../models/order';
import styles from './Gas.module.scss';
import Material from './Material';

const groupBy = <K, V>(array: V[], projection: (item: V) => K) => {
  const map = new Map<K, V[]>();
  array.forEach(item => {
    const key = projection(item);
    if (map.has(key)) {
      map.get(key)!.push(item);
    } else {
      map.set(key, [item]);
    }
  });

  return map;
};

interface Props {
  gasType: string;
  containers: ContainerStateView[];
  orders: Order[];
  customerId: string;
}

const Gas: React.FC<Props> = ({ gasType, containers, orders, customerId }) => {
  const containerMap = groupBy(containers, bottle => bottle.materialId);
  const orderMap = groupBy(orders, order => order.materialId);

  const materialIds = new Set([...containerMap.keys(), ...orderMap.keys()]);

  const getContainerOrOrder = (materialId: string) => {
    if (containerMap.has(materialId)) {
      return containerMap.get(materialId)![0];
    }

    if (orderMap.has(materialId)) {
      return orderMap.get(materialId)![0];
    }

    return undefined;
  };

  const sortByVolumeAndMaterialId = (a: string, b: string) => {
    const itemA = getContainerOrOrder(a);
    const volumeA = itemA ? itemA.volume : 0;
    const itemB = getContainerOrOrder(b);
    const volumeB = itemB ? itemB.volume : 0;
    return volumeA < volumeB
      ? -1
      : volumeA > volumeB
      ? 1
      : a < b
      ? -1
      : a > b
      ? 1
      : 0;
  };

  return (
    <>
      <h2 style={{ marginTop: '2rem' }}>{gasType}</h2>
      <div className={styles['gaas-gas']} role={'list'}>
        {Array.from(materialIds)
          .sort(sortByVolumeAndMaterialId)
          .map(materialId => {
            const containerOrOrder = getContainerOrOrder(materialId)!;

            return (
              <Material
                containerType={containerOrOrder.containerType}
                materialId={materialId}
                materialDescription={containerOrOrder.materialDescription}
                containers={containerMap.get(materialId) ?? []}
                orders={orderMap.get(materialId) ?? []}
                key={materialId}
                customerId={customerId}
                role={'listitem'}
              />
            );
          })}
      </div>
    </>
  );
};

export default Gas;
