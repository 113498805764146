import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StockContainer } from '../features/digidrumi/models/stock-container';
import { ContainerNoteView } from '../features/inventory/models/container-note';
import { ContainerState } from '../features/inventory/models/container-state';
import { GatewayStateView } from '../features/inventory/models/gateway-state-view';
import {
  ContainerStateView,
  Inventory,
} from '../features/inventory/models/inventory';
import { InventoryThresholdPassedEventView } from '../features/inventory/models/inventory-threshold-passed-event-view';
import { InventoryThresholdView } from '../features/inventory/models/inventory-threshold-view';
import {
  ApiChangeContainerGroupAssignmentRequest,
  ApiContainerGroupAssignment,
  ApiContainerNote,
  ApiContainerState,
  ApiCustomer,
  ApiGatewayStateView,
  ApiInventory,
  ApiInventoryThresholdPassedEventView,
  ApiInventoryThresholdView,
  ApiPermissionInformation,
  ApiStockContainer,
  GaasUserRole,
  GaasSubscription,
} from '../models/openapi/openapiTypes';
import { apiDelete, apiGet, apiPost, apiPut } from './api';
import { components } from '../models/openapi/openapiSchema';

export function onboardCustomers(): Observable<ApiPermissionInformation> {
  const rawPermissionInfoIsApiPermissionInformation = (
    permissionInformation: components['schemas']['PermissionInformation']
  ): permissionInformation is ApiPermissionInformation => {
    for (const key of Object.keys(permissionInformation.templates.roles)) {
      if (
        !Object.values(GaasUserRole).includes(key as unknown as GaasUserRole)
      ) {
        console.log('Role not found', key, Object.values(GaasUserRole));
        return false;
      }
    }
    for (const key of Object.keys(
      permissionInformation.templates.subscriptions
    )) {
      if (
        !Object.values(GaasSubscription).includes(
          key as unknown as GaasSubscription
        )
      ) {
        console.log('Subscription not found', key);
        return false;
      }
    }

    return true;
  };

  return apiPost<components['schemas']['PermissionInformation']>(
    'GaasBackend',
    '/onboarding',
    {}
  ).pipe(
    map((permissionInformation): ApiPermissionInformation => {
      if (!rawPermissionInfoIsApiPermissionInformation(permissionInformation)) {
        throw new Error('Invalid permission information');
      }
      return permissionInformation;
    })
  );
}

export function getCustomers(): Observable<ApiCustomer[]> {
  return apiGet<ApiCustomer[]>('GaasBackend', '/customers');
}

export function getInventory(customerId: string): Observable<Inventory> {
  return apiGet<ApiInventory>(
    'GaasBackend',
    `/customers/${customerId}/inventory`
  ).pipe(
    map(
      (inventory): Inventory => ({
        ...inventory,
        containers: inventory.containers.map(
          (c): ContainerStateView => ({
            ...c,
            note: c.note
              ? {
                  ...c.note,
                  createdAt: new Date(c.note.createdAt),
                  lastModified: new Date(c.note.lastModified),
                }
              : undefined,
          })
        ),
        groups: inventory.groups.map(g => ({
          ...g,
          createdAt: new Date(g.createdAt),
        })),
      })
    )
  );
}

export function changeContainerGroupAssignment(
  customerId: string,
  containerId: string,
  request: ApiChangeContainerGroupAssignmentRequest
): Observable<ApiContainerGroupAssignment> {
  return apiPut<ApiContainerGroupAssignment>(
    'GaasBackend',
    `/customers/${customerId}/bottle/${containerId}/group`,
    request
  );
}

export function getContainerByBarcode(
  barcode: string
): Observable<ContainerState> {
  return apiGet<ApiContainerState>(
    'PublicGaasBackend',
    `/container/barcode/${barcode}`
  );
}

export function getGatewayStates(
  customerId: string
): Observable<GatewayStateView[]> {
  return apiGet<ApiGatewayStateView[]>(
    'GaasBackend',
    `/customers/${customerId}/gateways/state`
  );
}

export function getStockContainer(
  customerId: string
): Observable<StockContainer[]> {
  return apiGet<ApiStockContainer[]>(
    'GaasBackend',
    `/customers/${customerId}/stock-container`
  );
}

export function takeContainer(
  customerId: string,
  bottleId: string,
  groupId: string | null
): Observable<ContainerState> {
  return apiPost<ApiContainerState>(
    'GaasBackend',
    `/customers/${customerId}/bottle/${bottleId}/take`,
    { groupId }
  );
}

export function returnContainer(
  customerId: string,
  bottleId: string,
  fillLevel: 'full' | 'empty'
): Observable<ContainerState> {
  return apiPost<ApiContainerState>(
    'GaasBackend',
    `/customers/${customerId}/bottle/${bottleId}/return`,
    { fillLevel }
  );
}

export function createInventoryThreshold(
  threshold: ApiInventoryThresholdView
): Observable<{}> {
  return apiPost<{}>('GaasBackend', `/inventory-threshold/create`, threshold);
}

export function updateInventoryThreshold(
  threshold: ApiInventoryThresholdView
): Observable<{}> {
  return apiPost<{}>('GaasBackend', `/inventory-threshold/update`, threshold);
}

export function deleteInventoryThreshold(
  customerId: string,
  materialId: string,
  thresholdId: string
): Observable<{}> {
  return apiDelete<{}>(
    'GaasBackend',
    `/inventory-threshold/by-customer/${customerId}/for-material/${materialId}/${thresholdId}`
  );
}

export function listInventoryThresholds(
  customerId: string
): Observable<InventoryThresholdView[]> {
  return apiGet<ApiInventoryThresholdView[]>(
    'GaasBackend',
    `/inventory-threshold/by-customer/${customerId}/list`
  );
}

export function listInventoryThresholdPassedEvents(
  customerId: string
): Observable<InventoryThresholdPassedEventView[]> {
  return apiGet<ApiInventoryThresholdPassedEventView[]>(
    'GaasBackend',
    `/inventory-threshold/by-customer/${customerId}/events/list`
  ).pipe(
    map(events =>
      events.map(event => ({
        ...event,
        start: new Date(event.start),
      }))
    )
  );
}

export function getContainerNote(
  customerId: string,
  containerId: string
): Observable<ContainerNoteView | null> {
  // internally, amplify returns the empty string for 204s
  // thus, we map it to null
  return apiGet<ApiContainerNote | ''>(
    'GaasBackend',
    `/customers/${customerId}/bottle/${containerId}/note`
  ).pipe(
    map(note => {
      return note === ''
        ? null
        : {
            ...note,
            createdAt: new Date(note.createdAt),
            lastModified: new Date(note.lastModified),
          };
    })
  );
}

export function updateContainerNote(
  customerId: string,
  containerId: string,
  note: string
): Observable<{}> {
  return apiPost<{}>(
    'GaasBackend',
    `/customers/${customerId}/bottle/${containerId}/note`,
    { content: note }
  );
}
