import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './Notifications.module.scss';
import { TextButton } from './TextButton';
import { Check, Close } from './icons';

interface Props {
  open: boolean;
  onClose: () => void;
  //TODO replace any
  onReadAllClick: any;
  readAllDisabled: boolean;
}

export const Notifications: React.FC<Props> = ({
  open,
  onClose,
  onReadAllClick,
  readAllDisabled,
  children,
}) => {
  const [scrollY, setScrollY] = useState(0);
  const [visible, setVisible] = useState(open);
  const [transitioning, setTransitioning] = useState(false);

  const backgroundRef = useRef<HTMLDivElement | null>(null);

  const closeModal = useCallback(() => {
    setTransitioning(true);

    setTimeout(() => {
      setScrollY(parseInt(document.body.style.top.slice(0, -2)) * -1);
      document.body.style.position = '';
      document.body.style.top = '';

      onClose();
      setTransitioning(false);
      setVisible(false);
    }, 200);
  }, [onClose]);

  const event = () => setScrollY(window.scrollY);

  useEffect(() => {
    window.addEventListener('scroll', event);
    setVisible(open);

    if (!open) {
      window.removeEventListener('scroll', event);
      setVisible(false);
    }
  }, [open]);

  useEffect(() => {
    if (visible) {
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY}px`;
    } else window.scrollTo(0, scrollY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (e.target === backgroundRef.current) {
        closeModal();
      }
    },
    [closeModal]
  );

  useEffect(() => {
    document.addEventListener('pointerdown', handleClick);

    return () => {
      document.removeEventListener('pointerdown', handleClick);
    };
  }, [handleClick]);

  return (
    <div
      ref={backgroundRef}
      className={classNames(styles['gaas-notifications'], {
        [styles['gaas-notifications--visible']]: open,
        [styles['gaas-notifications--transitioning']]: transitioning,
      })}
    >
      <div
        className={classNames(styles['gaas-notifications--container'], {
          [styles['gaas-notifications--container--visible']]: open,
          [styles['gaas-notifications--container--transitioning']]:
            transitioning,
        })}
      >
        <div className={styles['gaas-notifications--header']}>
          <h2 className={styles['gaas-notifications--header--title']}>
            Benachrichtigungen
          </h2>
          <button
            className={styles['gaas-notifications--header--close']}
            onClick={closeModal}
          >
            <Close />
          </button>
        </div>
        <div className={styles['gaas-notifications--content']}>{children}</div>
        <div className={styles['gaas-notifications--readAll']}>
          <TextButton
            label="Als gelesen markieren"
            LeadingIcon={Check}
            onClick={onReadAllClick}
            disabled={readAllDisabled}
          />
        </div>
      </div>
    </div>
  );
};
