import { createReducer } from 'typesafe-actions';
import { showMessageAction } from './actions';
import { OverlayMessage } from './models/OverlayMessage';

interface State {
  numLoadingResources: number;
  messages: { [key: string]: OverlayMessage };
}

const initialState: State = {
  numLoadingResources: 0,
  messages: {} as { [key: string]: OverlayMessage },
};

type Actions = ReturnType<typeof showMessageAction>;

export const errorReducer = createReducer<State, Actions>(
  initialState
).handleAction(showMessageAction, (state, action) => {
  return {
    ...state,
    messages: {
      ...state.messages,
      [action.payload.id]: action.payload,
    },
  };
});

export type ErrorState = State;
