import { InfoBox } from '../../../stories/InfoBox';

const ShopConfiguratorDeviceInfo = () => {
  return (
    <InfoBox>
      <span>
        Sie können die Anzahl Ihrer Mietgeräte nach Bedarf erhöhen. Nach
        Abschluss des Vertrages werden wir die zusätzlichen Mietgeräte innerhalb
        von x Tagen versenden. Die angepasste Abrechnung beginnt im darauf
        folgenden Monat. Für die Reduzierung Ihrer Mietgeräte wenden Sie sich
        bitte an&nbsp;
        <a href="mailTo:support@mail.gaas.westfalen.com">
          support@mail.gaas.westfalen.com
        </a>
        .
      </span>
    </InfoBox>
  );
};

export default ShopConfiguratorDeviceInfo;
