import { useCameraVideoFeedCallbacks } from './CameraVideoFrameCallbackProvider';
import { useCameraStream } from './CameraStreamProvider';
import * as scannerActions from '../../features/scanning/actions';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import React, { useCallback, useEffect, useState } from 'react';
import { ScanResult } from '../../features/scanning/models/scan-result';
import { BarcodeScannerResultCallback } from './CameraUtil';

interface ComponentProps {
  onBarcodeFound?: BarcodeScannerResultCallback;
}

const mapStateToProps = (_: RootState) => ({});

const dispatchProps = {
  newScannerResult: scannerActions.newScannerResult,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const NativeBarcodeScanner: React.FC<Props> = ({
  newScannerResult,
  onBarcodeFound,
}) => {
  const { subscribeToFrames, unsubscribeFromFrames } =
    useCameraVideoFeedCallbacks();

  const { videoRef, cameraId } = useCameraStream();

  const [processingState] = useState<{
    isProcessing: boolean;
  }>({ isProcessing: false });

  const [framebufferSize, setFramebufferSize] = React.useState<{
    width: number;
    height: number;
  }>({ width: 100, height: 100 });

  const [framebufferRef, setFramebufferRef] =
    useState<HTMLCanvasElement | null>(null);
  const framebufferRefCb = useCallback(node => {
    if (node !== null) {
      setFramebufferRef(node);
    }
  }, []);

  useEffect(() => {
    if (!videoRef) {
      return;
    }
    setFramebufferSize({
      width: videoRef.videoWidth,
      height: videoRef.videoHeight,
    });
  }, [videoRef, videoRef?.videoHeight, videoRef?.videoWidth]);

  const [barcodeWorker, setBarcodeWorker] = useState<Worker | undefined>(
    undefined
  );

  const onNewScannerResult = useCallback(
    (result: ScanResult) => {
      newScannerResult(result);
      const { detected } = result;
      if (onBarcodeFound && detected) {
        onBarcodeFound(detected, cameraId);
      }
    },
    [newScannerResult, onBarcodeFound, cameraId]
  );

  useEffect(() => {
    const worker = new Worker(
      new URL('./NativeBarcodeScannerWorker.worker', import.meta.url)
    );
    setBarcodeWorker(worker);

    worker.onmessage = e => {
      onNewScannerResult(e.data);
      processingState.isProcessing = false;
    };
    return () => {
      worker.terminate();
    };
  }, [setBarcodeWorker, processingState, onNewScannerResult]);

  const onNewFrame = useCallback(() => {
    if (
      !videoRef ||
      !barcodeWorker ||
      !framebufferRef ||
      processingState.isProcessing
    ) {
      return;
    }

    const ctx = framebufferRef.getContext('2d');
    if (!ctx) {
      return;
    }

    processingState.isProcessing = true;

    ctx.drawImage(
      videoRef,
      0,
      0,
      videoRef.videoWidth,
      videoRef.videoHeight,
      0,
      0,
      framebufferSize.width,
      framebufferSize.height
    );
    const imgData = ctx.getImageData(
      0,
      0,
      framebufferSize.width,
      framebufferSize.height
    );
    barcodeWorker.postMessage(imgData);
  }, [
    videoRef,
    processingState,
    barcodeWorker,
    framebufferRef,
    framebufferSize.width,
    framebufferSize.height,
  ]);

  useEffect(() => {
    const handle = subscribeToFrames(onNewFrame);
    return () => {
      unsubscribeFromFrames(handle);
    };
  }, [videoRef, onNewFrame, subscribeToFrames, unsubscribeFromFrames]);

  return (
    <canvas
      style={{ display: 'none' }}
      ref={framebufferRefCb}
      width={framebufferSize.width}
      height={framebufferSize.height}
      id={'NativeBarcodeReaderFramebuffer'}
    />
  );
};

export default connect(mapStateToProps, dispatchProps)(NativeBarcodeScanner);
