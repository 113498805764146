import {
  AsyncResource,
  asyncResourceSelector,
} from '../../store/async-resource';
import { AdministrationState } from './reducer';
import { PaginatedEntityList } from '../common/models/paginated-entity-list';
import {
  ValveCustomerAssignment,
  ValveWithCurrentAssignment,
} from './models/valves-with-assignment';
import {
  ListingParameters,
  SortingParameters,
} from '../common/models/listing-parameters';
import {
  AdminListValveEventsResponse,
  SuspiciousValueDiagnosticEvent,
  ThresholdPassedEventAdminView,
  ValveOfflineDiagnosticEvent,
} from './models/admin-list-valve-events-response';
import {
  GatewayAssignment,
  GatewayWithCurrentAssignment,
} from './models/gateway-with-assignment';

export const pairValveToCustomerResult = (state: AdministrationState) =>
  asyncResourceSelector(state.pairValveToCustomer);

export const unpairValveFromCustomerResult = (state: AdministrationState) =>
  asyncResourceSelector(state.unpairValveFromCustomer);

export const pairGatewayToCustomerResult = (state: AdministrationState) =>
  asyncResourceSelector(state.pairGatewayToCustomer);

export const unpairGatewayFromCustomerResult = (state: AdministrationState) =>
  asyncResourceSelector(state.unpairGatewayFromCustomer);

export const updateValveNoteResult = (state: AdministrationState) =>
  asyncResourceSelector(state.updateValveNote);

export const updateGatewayNoteResult = (state: AdministrationState) =>
  asyncResourceSelector(state.updateGatewayNote);

export const sortingParamsToStoreIdentifier = (params: SortingParameters) => {
  const parts = [
    `start${params.start}`,
    `size=${params.size}`,
    ...(params.search ? Object.entries(params.search) : []).map(
      item => `search=${encodeURIComponent(`${item[0]}=${item[1]}`)}`
    ),
    ...(params.filter ? Object.entries(params.filter) : []).map(
      item => `filter=${encodeURIComponent(`${item[0]}=${item[1]}`)}`
    ),
  ];
  return parts.join('-');
};

export const adminGatewayList = (
  state: AdministrationState,
  params: SortingParameters
):
  | AsyncResource<PaginatedEntityList<GatewayWithCurrentAssignment>>
  | undefined =>
  asyncResourceSelector(
    state.gatewayList[sortingParamsToStoreIdentifier(params)]
  );

export const adminValveList = (
  state: AdministrationState,
  params: SortingParameters
): AsyncResource<PaginatedEntityList<ValveWithCurrentAssignment>> | undefined =>
  asyncResourceSelector(
    state.valveList[sortingParamsToStoreIdentifier(params)]
  );

export const adminValve = (
  state: AdministrationState,
  id: string
): AsyncResource<ValveWithCurrentAssignment> | undefined =>
  asyncResourceSelector(state.valves[id]);

export const adminGateway = (
  state: AdministrationState,
  id: string
): AsyncResource<GatewayWithCurrentAssignment> | undefined =>
  asyncResourceSelector(state.gateways[id]);

export const adminValveAssignments = (
  state: AdministrationState,
  id: string,
  params: ListingParameters
): AsyncResource<PaginatedEntityList<ValveCustomerAssignment>> | undefined =>
  asyncResourceSelector(
    state.valveAssignments[
      sortingParamsToStoreIdentifier({ ...params, filter: { deviceId: id } })
    ]
  );

export const adminGatewayAssignments = (
  state: AdministrationState,
  id: string,
  params: ListingParameters
): AsyncResource<PaginatedEntityList<GatewayAssignment>> | undefined =>
  asyncResourceSelector(
    state.gatewayAssignments[
      sortingParamsToStoreIdentifier({ ...params, filter: { gatewayId: id } })
    ]
  );

export const adminValveOfflineEvents = (
  state: AdministrationState,
  id: string
):
  | AsyncResource<AdminListValveEventsResponse<ValveOfflineDiagnosticEvent>>
  | undefined => asyncResourceSelector(state.valveOfflineEvents[id]);

export const adminValveSuspiciousValueEvents = (
  state: AdministrationState,
  id: string
):
  | AsyncResource<AdminListValveEventsResponse<SuspiciousValueDiagnosticEvent>>
  | undefined => asyncResourceSelector(state.valveSuspiciousValueEvents[id]);

export const adminThresholdPassedEvents = (
  state: AdministrationState,
  id: string
):
  | AsyncResource<AdminListValveEventsResponse<ThresholdPassedEventAdminView>>
  | undefined => asyncResourceSelector(state.thresholdPassedEvents[id]);
