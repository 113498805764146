// this interface is shared with the backend
import { GaasSubscription, GaasUserRole } from '../models/openapi/openapiTypes';

export interface FakeAuthCredentials {
  cognitoId: string;
  email: string;
  salesforceId: string;
  customerIds: string[];
  isAdmin: boolean;
  role?: GaasUserRole;
  subscription?: GaasSubscription;
}

const LOCAL_STORE_KEY = 'TD_LOCAL_AUTH';

class FakeAuthClass {
  private _credentials: FakeAuthCredentials | null = null;

  signIn(credentials: FakeAuthCredentials) {
    localStorage.setItem(LOCAL_STORE_KEY, JSON.stringify(credentials));

    this._credentials = credentials;
  }

  signOut() {
    localStorage.removeItem(LOCAL_STORE_KEY);
    this._credentials = null;
  }

  getCredentials(): FakeAuthCredentials | null {
    return this._credentials;
  }

  getStoredCredentials(): FakeAuthCredentials | null {
    return JSON.parse(localStorage.getItem(LOCAL_STORE_KEY) ?? 'null');
  }
}

export const FakeAuth = new FakeAuthClass();
