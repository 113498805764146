import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  CameraConsentState,
  queryCameraPermissions,
  requestCameraPermissions,
} from './CameraUtil';
import styles from './BarcodeScannerV2.module.scss';
import { BarcodeScanner as BarcodeScannerIcon } from '../../stories/icons';
import { Button } from '../../stories/Button';
import { BarcodeScannerV2Loading } from './BarcodeScannerV2Loading';

interface Props {
  children: ReactNode;
}

export const CameraConsentGuard: React.FC<Props> = ({ children }: Props) => {
  // true = granted, false = denied, null = unknown
  const [hasCameraPermission, setHasCameraPermission] =
    useState<CameraConsentState>('unknown');

  const requestPermissions = useMemo(() => {
    return async () => {
      const result = await requestCameraPermissions();
      setHasCameraPermission(result);
    };
  }, [setHasCameraPermission]);

  // on mount of the component, check if camera permissions are granted
  useEffect(() => {
    queryCameraPermissions().then(result => {
      setHasCameraPermission(result);
    });
  }, []);

  // when permissions have not been granted, this second hook
  // will run and request permissions
  useEffect(() => {
    if (
      hasCameraPermission === 'unknown' ||
      hasCameraPermission === 'pending'
    ) {
      requestPermissions();
    }
  }, [hasCameraPermission, requestPermissions]);

  if (hasCameraPermission === 'granted') {
    return <>{children}</>;
  }

  if (hasCameraPermission === 'unknown' || hasCameraPermission === 'pending') {
    return <BarcodeScannerV2Loading />;
  }

  return (
    <div className={styles['gaas-barcode-scanner--prompts']}>
      <BarcodeScannerIcon width="40px" />
      <span>
        Ihr Gerät besitzt keine Kamera, alle Kameras sind bereits in Benutzung
        oder Sie haben uns den Zugriff auf Ihre Kamera verwehrt. Um den
        Barcodescanner nutzen zu können, benötigen wir Zugriff auf Ihre Kamera.
      </span>
      <Button
        label="Kamera öffnen"
        onClick={() => setHasCameraPermission('pending')}
        size="l"
      />
    </div>
  );
};
