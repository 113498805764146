import { connect } from 'react-redux';
import * as shopSelectors from '../selectors';
import * as shopActions from '../actions';
import { RootState } from 'typesafe-actions';
import React, { ReactElement, useEffect, useState } from 'react';
import ChangeAddressModal from '../components/ChangeAddressModal';
import ShopFullscreenLoading from '../components/ShopFullscreenLoading';
import { ShopCustomerNumberContext } from '../util/ShopCustomerNumber';

const mapStateToProps = (state: RootState) => ({
  accessibleCustomers: shopSelectors.accessibleCustomers(state.shop),
});

const dispatchProps = {
  getAccessibleCustomers: shopActions.getShopAccessibleCustomersAsync.request,
};

interface ComponentProps {
  children: ReactElement | null;
}

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const ShopCustomerNumberProvider: React.FC<Props> = ({
  getAccessibleCustomers,
  accessibleCustomers,
  children,
}) => {
  const [selectedCustomer, setSelectedCustomer] = useState<string>('none');
  const [customerSelected, setCustomerSelected] = useState<boolean>(false);

  useEffect(() => {
    if (
      !accessibleCustomers.loading &&
      !accessibleCustomers.data &&
      !accessibleCustomers.error
    ) {
      getAccessibleCustomers();
    }
  }, [accessibleCustomers, getAccessibleCustomers]);

  useEffect(() => {
    if (!accessibleCustomers.data) {
      return;
    }
    if (customerSelected) {
      return;
    }
    setSelectedCustomer(accessibleCustomers.data[0].customerId);
    if (accessibleCustomers.data.length === 1) {
      setCustomerSelected(true);
    }
  }, [accessibleCustomers, customerSelected]);

  if (accessibleCustomers.loading || !accessibleCustomers.data) {
    return <ShopFullscreenLoading />;
  }

  if (!customerSelected) {
    return (
      <ChangeAddressModal
        open={!customerSelected}
        shippingAddressId={selectedCustomer}
        setShippingAddressId={value => {
          setSelectedCustomer(
            accessibleCustomers.data!.find(it => it.addressId === value)
              ?.customerId ?? 'none'
          );
        }}
        addresses={accessibleCustomers.data!}
        onClose={() => {
          setCustomerSelected(true);
        }}
        shopWelcome
      />
    );
  }

  return (
    <ShopCustomerNumberContext.Provider value={selectedCustomer}>
      {children}
    </ShopCustomerNumberContext.Provider>
  );
};

export default connect(
  mapStateToProps,
  dispatchProps
)(ShopCustomerNumberProvider);
