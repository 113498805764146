import classnames from 'classnames';
import React from 'react';
import styles from './ListLabels.module.scss';
import { SortDirection } from '../../features/common/models/listing-parameters';

interface Props {
  labels: string[];
  grid: string;
  hasSorting: boolean;
  currentSortIndex: number;
  currentSortDir: SortDirection;
  onChangeSort: (i: number) => void;
}

const ListLabels: React.FC<Props> = ({
  labels,
  grid,
  hasSorting,
  currentSortIndex,
  onChangeSort,
  currentSortDir,
}) => {
  return (
    <div className={styles.container} style={{ gridTemplateColumns: grid }}>
      {labels.map((label, i) => (
        <button
          className={classnames(styles.button, {
            [styles.active]: hasSorting && currentSortIndex === i,
          })}
          onClick={() => hasSorting && onChangeSort(i)}
          key={label}
        >
          {label}
          {!hasSorting || currentSortIndex !== i
            ? ''
            : currentSortDir === SortDirection.asc
            ? ' ↑'
            : ' ↓'}
        </button>
      ))}
    </div>
  );
};

export default ListLabels;
