import React, { useEffect, useMemo } from 'react';
import { Devider } from '../stories/Devider';
import { Loading } from '../stories/Loading';
import { TextButton } from '../stories/TextButton';
import { Logo } from '../stories/icons';
import styles from './SalesforceAuthenticator.module.scss';
import { gaasAuthConfig } from '../util/gaasAuthConfig';
import { Auth } from 'aws-amplify';
import { useLocation } from 'react-router-dom';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const SalesforceAuthenticator: React.FC = () => {
  const redirectButtonRef = React.useRef<HTMLButtonElement>(null);
  const queryParams = useQuery();

  useEffect(() => {
    Auth.currentAuthenticatedUser().catch(() => {
      // If we fail to get the user, we are not logged in.
      // This can happen in two circumstances:
      // 1. The user is not logged in and needs to be redirected to
      //   the westfalen id.
      // 2. The user has already completed the login flow and
      //   is being redirected back to the app with a 'code'. In this case we don't
      //   want to redirect the user again, but we'll have to wait until
      //   amplify has finished processing the login data.
      if (!queryParams.has('code')) {
        redirectButtonRef?.current?.click();
      }
    });
  }, [queryParams, redirectButtonRef]);

  return (
    <div className={styles['gaas-salesforce-auth']}>
      <div className={styles['gaas-salesforce-auth--container']}>
        <Logo />
        <h1 className={styles['gaas-salesforce-auth--title']}>
          Gas as a Service®
        </h1>
        <Loading />
        <p className={styles['gaas-salesforce-auth--text']}>
          Redirecting to your login provider...
        </p>
        <Devider />
        <TextButton
          label="Click here if you are not being redirected"
          onClick={
            () =>
              // Sometimes ( I, Sebastian, cannot reproduce this reliably), cognito
              // redirects to a terrible hosted ui page where the user has to
              // choose the IDP (for which there is only one option in our system)
              // when using Auth.federatedSignIn({ customProvider: '...' }).
              // This is why I am using this custom redirect which I have
              // stolen from here
              // https://github.com/aws-amplify/amplify-js/issues/2456#issuecomment-458598438
              (window.location.href = `https://${
                gaasAuthConfig.domain
              }/oauth2/authorize?identity_provider=${
                gaasAuthConfig.identityProviderName
              }&redirect_uri=${gaasAuthConfig.redirectSignIn}&response_type=${
                gaasAuthConfig.responseType
              }&client_id=${
                gaasAuthConfig.userPoolWebClientId
              }&scope=${gaasAuthConfig.scope.join(' ')}`)
            // Auth.federatedSignIn({ customProvider: 'WagStagingWestfalenId' })
          }
          buttonRef={redirectButtonRef}
        />
      </div>
    </div>
  );
};

export default SalesforceAuthenticator;
