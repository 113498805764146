import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { RootState } from 'typesafe-actions';
import { InventoryLoading } from '../../../stories/InventoryLoading';
import { TextButton } from '../../../stories/TextButton';
import { ArrowBack } from '../../../stories/icons';
import * as inventoryActions from '../actions';
import BottleDetails from '../components/BottleDetails';
import * as commonActions from '../../common/actions';
import * as commonSelectors from '../../common/selectors';
import * as inventorySelectors from '../selectors';
import styles from './InventoryBottlePage.module.scss';
import { ContainerState } from '../models/container-state';

const mapStateToProps = (state: RootState) => ({
  inventory: inventorySelectors.inventory(state.inventory),
  containerStates: (barcode: string) =>
    inventorySelectors.containerStatesByBarcode(state.inventory, barcode),
  availableCustomers: commonSelectors.customers(state.common),
});

const dispatchProps = {
  getInventory: inventoryActions.getInventoryAsync.request,
  getContainerStateByBarcode:
    inventoryActions.getContainerStateByBarcodeAsync.request,
  getAvailableCustomers: commonActions.getCustomersAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const InventoryBottlePage: React.FC<Props> = ({
  inventory,
  getInventory,
  getContainerStateByBarcode,
  containerStates,
  availableCustomers,
  getAvailableCustomers,
}) => {
  let history = useHistory();
  const { customer, barcode } = useParams<{
    customer?: string;
    barcode: string;
  }>();
  // undefined container === container does not exist, null container === container not yet loaded
  const [container, setContainer] = useState<ContainerState | null | undefined>(
    null
  );

  const [containerError, setContainerError] = useState<null | string>(null);

  const [bottleInInventory, setBottleInInventory] = useState<boolean>(true);

  useEffect(() => {
    if (availableCustomers.data == null && !availableCustomers.loading) {
      getAvailableCustomers();
    }
  }, [availableCustomers, getAvailableCustomers]);

  useEffect(() => {
    if (
      inventory.data == null &&
      !inventory.loading &&
      !inventory.error &&
      customer != null
    ) {
      getInventory(customer);
    } else if (customer == null) {
      setBottleInInventory(false);
    }
  }, [customer, inventory, getInventory]);

  useEffect(() => {
    if (inventory.error) {
      setBottleInInventory(false);
    }

    if (inventory.data == null || customer == null) {
      return;
    }

    const foundContainer = inventory.data.containers.find(
      container => container.barcode === barcode
    );
    if (foundContainer) {
      setContainer(foundContainer as ContainerState);
    } else {
      setBottleInInventory(false);
    }
  }, [inventory, setContainer, barcode, setBottleInInventory, customer]);

  useEffect(() => {
    if (bottleInInventory) {
      return;
    }

    const foundContainer = containerStates(barcode);
    if (foundContainer.error) {
      setContainerError(foundContainer.error.message);
    } else if (foundContainer.data == null && !foundContainer.loading) {
      getContainerStateByBarcode(barcode);
    } else if (foundContainer.data) {
      setContainer(foundContainer.data);
    }
  }, [bottleInInventory, containerStates, getContainerStateByBarcode, barcode]);

  // bottle loading
  if (container == null && containerError == null) {
    return (
      <div className={styles['gaas-bottle-page--loading']}>
        <InventoryLoading />
      </div>
    );
  }

  let siteContent;
  if (container != null) {
    const actionsLoading = availableCustomers.loading;

    const bottleNotAssigned = container.customerId == null;

    const wrongInventorySelected =
      customer != null && container.customerId !== customer;
    const customerNotAvailable =
      !bottleNotAssigned &&
      availableCustomers.data?.find(
        c => c.customerId === container.customerId
      ) == null;

    siteContent = (
      <BottleDetails
        container={container}
        actionsDisabled={customerNotAvailable || bottleNotAssigned}
        actionsLoading={actionsLoading}
      >
        {wrongInventorySelected && !customerNotAvailable && (
          <p>
            Diese Flasche gehört nicht zu dem Inventar, aus dem Sie diese
            Aufgerufen haben!
          </p>
        )}
        {customerNotAvailable && (
          <p>
            Diese Flasche gehört dem Kunden {container.customerId}, auf den Sie
            keinen Zugriff haben.
          </p>
        )}
        {bottleNotAssigned && (
          <p>
            Diese Flasche ist dem GaaS System nicht bekannt und kann nicht
            genutzt werden.
          </p>
        )}
      </BottleDetails>
    );
  } else {
    siteContent = <p>Flasche existiert nicht!</p>;
  }

  return (
    <div className={styles['gaas-bottle-page']}>
      <div className={styles['gaas-bottle-page--background']}>
        <div className={styles['gaas-bottle-page--content']}>
          <TextButton
            label="Zurück zum Bestand"
            LeadingIcon={ArrowBack}
            onClick={() => {
              history.push(
                customer && container
                  ? `/inventory/${customer}/${container.materialId}`
                  : `/inventory`
              );
            }}
          />
          {siteContent}
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, dispatchProps)(InventoryBottlePage);
