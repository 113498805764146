import React, { RefObject, useCallback, useLayoutEffect, useRef } from 'react';
import styles from './Tooltip.module.scss';

interface Props {
  text: string | number | React.ReactNode;
  children: React.ReactNode;
}

export const Tooltip: React.FC<Props> = ({ text, children }) => {
  const textRef = useRef<HTMLParagraphElement>(null);

  const getWidth = useCallback((ref: RefObject<HTMLParagraphElement>) => {
    let width = 0;

    if (ref.current) {
      ref.current.style.whiteSpace = 'nowrap';
      width = ref.current.getBoundingClientRect().width;
      ref.current.style.whiteSpace = 'normal';
    }
    return width;
  }, []);

  useLayoutEffect(() => {
    if (textRef && textRef.current) {
      const width = getWidth(textRef);

      if (width >= 200) {
        textRef.current.style.width = '200px';
      } else textRef.current.style.width = width + 'px';
    }
  }, [getWidth, text]);

  return (
    <div className={styles['gaas-tooltip--trigger']}>
      {children}
      <div className={styles['gaas-tooltip']}>
        <p className={styles['gaas-tooltip--text']} ref={textRef}>
          {text}
        </p>
      </div>
    </div>
  );
};
