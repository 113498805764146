import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RootState } from 'typesafe-actions';

import { Button, Container, TextField } from '@mui/material';
import { format } from 'date-fns';
import { connect } from 'react-redux';
import List from '../../../components/list/List';
import { Loading } from '../../../stories/Loading';
import { ListingParameters } from '../../common/models/listing-parameters';
import {
  getGatewayAssignmentsAsync,
  getGatewayAsync,
  pairGatewayToCustomerAsync,
  unpairGatewayFromCustomerAsync,
  updateGatewayNoteAsync,
} from '../actions';
import { GatewayAssignment } from '../models/gateway-with-assignment';
import * as administrationSelectors from '../selectors';
import styles from './AdministrationPage.module.scss';

const mapStateToProps = (state: RootState) => ({
  gatewayNoteUpdateResult: administrationSelectors.updateGatewayNoteResult(
    state.administration
  ),
  adminGatewaySelector: (id: string) =>
    administrationSelectors.adminGateway(state.administration, id),
  adminGatewayAssignmentSelector: (id: string, params: ListingParameters) =>
    administrationSelectors.adminGatewayAssignments(
      state.administration,
      id,
      params
    ),
  pairGatewayToCustomerResult:
    administrationSelectors.pairGatewayToCustomerResult(state.administration),
  unpairGatewayFromCustomerResult:
    administrationSelectors.unpairGatewayFromCustomerResult(
      state.administration
    ),
});

const dispatchProps = {
  getGateway: getGatewayAsync.request,
  updateGatewayNote: updateGatewayNoteAsync.request,
  getGatewayAssignments: getGatewayAssignmentsAsync.request,
  pairGatewayToCustomer: pairGatewayToCustomerAsync.request,
  unpairGatewayFromCustomer: unpairGatewayFromCustomerAsync.request,
};

interface ComponentProps {}

type Props = ComponentProps &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;
const GatewayEdit: React.FC<Props> = ({
  gatewayNoteUpdateResult,
  updateGatewayNote,
  adminGatewaySelector,
  getGateway,
  adminGatewayAssignmentSelector,
  getGatewayAssignments,
  pairGatewayToCustomerResult,
  pairGatewayToCustomer,
  unpairGatewayFromCustomer,
  unpairGatewayFromCustomerResult,
}) => {
  const { gatewayId } = useParams<{ gatewayId: string }>();
  const humanGatewayId = useMemo(() => gatewayId.slice(19), [gatewayId]);

  const [note, setNote] = useState<string | null>('');
  const [nextCustomerId, setNextCustomerId] = useState<string>('');

  // const [alertTab, setAlertTab] = useState<number>(0);
  const gatewayEntity = adminGatewaySelector(gatewayId);

  useEffect(() => {
    if (gatewayEntity?.data == null && !gatewayEntity?.loading) {
      getGateway(gatewayId);
    }
  }, [gatewayEntity, gatewayId, getGateway]);

  useEffect(() => {
    if (gatewayEntity?.data) {
      setNote(gatewayEntity.data.note ?? '');
    }
  }, [gatewayEntity?.data]);

  if (gatewayEntity == null || gatewayEntity?.loading) {
    return <Loading />;
  }

  const onSaveNote = () => {
    updateGatewayNote({ gatewayId, note });
  };

  const onPairValve = () => {
    pairGatewayToCustomer({
      customerId: nextCustomerId,
      gatewayId: gatewayId,
      upsert: false,
    });
  };

  const onUnpairGateway = () => {
    unpairGatewayFromCustomer({
      gatewayId: gatewayId,
    });
  };

  return (
    <Container className={styles['gaas-administration-page']}>
      <h2>Manometer {humanGatewayId}</h2>

      <TextField
        id="notes"
        label="Notizen zum Gateway"
        multiline
        rows={4}
        fullWidth
        variant="filled"
        value={note}
        onChange={event => setNote(event.target.value)}
      />

      <Button variant="contained" onClick={onSaveNote}>
        {gatewayNoteUpdateResult.loading ? <Loading /> : 'Notizen speichern'}
      </Button>

      {/*<h3>Alarme</h3>*/}
      {/*<Tabs*/}
      {/*  value={alertTab}*/}
      {/*  onChange={(ev, tab) => {*/}
      {/*    setAlertTab(tab);*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Tab label="Offline Alarme" />*/}
      {/*  <Tab label="Wertbereich Alarme" />*/}
      {/*  <Tab label="Druckmarken" />*/}
      {/*</Tabs>*/}

      {/*{alertTab === 0 ? (*/}
      {/*  <EventList valveId={valveId} type={'Offline'} key={'list-offline'} />*/}
      {/*) : alertTab === 1 ? (*/}
      {/*  <EventList*/}
      {/*    valveId={valveId}*/}
      {/*    type={'SuspiciousValue'}*/}
      {/*    key={'list-sus'}*/}
      {/*  />*/}
      {/*) : alertTab === 2 ? (*/}
      {/*  <EventList*/}
      {/*    valveId={valveId}*/}
      {/*    type={'Threshold'}*/}
      {/*    key={'list-threshold'}*/}
      {/*  />*/}
      {/*) : (*/}
      {/*  <div>nothing</div>*/}
      {/*)}*/}

      <h3>Kundenzuweisungen</h3>

      <TextField
        label={'Neue Kundennummer'}
        style={{ marginLeft: '14px', marginRight: '7px' }}
        value={nextCustomerId}
        onChange={event => setNextCustomerId(event.target.value)}
      />

      <Button
        variant="contained"
        disabled={nextCustomerId === ''}
        style={{ marginRight: '7px' }}
        onClick={onPairValve}
      >
        {pairGatewayToCustomerResult.loading ? <Loading /> : 'Gateway zuweisen'}
      </Button>

      <Button
        variant="contained"
        color="secondary"
        disabled={gatewayEntity?.data?.assignment === null}
        onClick={onUnpairGateway}
      >
        {unpairGatewayFromCustomerResult.loading ? (
          <Loading />
        ) : (
          'Zuweisung entfernen'
        )}
      </Button>

      <List
        hasPagination={true}
        hasSearchBar={false}
        hasSorting={false}
        columnLabels={[
          { prop: 'id', label: 'ID' },
          { prop: 'customerId', label: 'WEF' },
          { prop: 'startTs', label: 'Start' },
          { prop: 'endTs', label: 'Ende' },
        ]}
        columnLabelGrid={'2.25fr 1.75fr 1.75fr 1.75fr '}
        rowValueTransform={(obj: GatewayAssignment) => [
          obj.id,
          obj.customerId,
          // '-'
          format(obj.startTs, 'dd.MM.yy, HH:mm'),
          obj.endTs != null ? format(obj.endTs, 'dd.MM.yy, HH:mm') : 'Aktiv',
        ]}
        searchProps={['id']}
        entityKey={'gatewayassignments'}
        listRequest={sortingParams =>
          getGatewayAssignments({
            id: gatewayId,
            params: { start: sortingParams.start, size: sortingParams.size },
          })
        }
        listSelector={params =>
          adminGatewayAssignmentSelector(gatewayId, params)
        }
      />
    </Container>
  );
};

export default connect(mapStateToProps, dispatchProps)(GatewayEdit);
