import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { ManometerLoading } from '../../../stories/ManometerLoading';
import { TextButton } from '../../../stories/TextButton';
import { Replay } from '../../../stories/icons';
import CustomerSelection from '../../common/components/CustomerSelection';
import * as commonSelectors from '../../common/selectors';
import * as digidrumiActions from '../actions';
import DigiDrumiCard from '../components/DigiDrumiCard';
import { Valve } from '../models/valve';
import * as digidrumiSelectors from '../selectors';
import styles from './DigiDrumiPage.module.scss';
import { ManometerCardDummy } from '../../../stories/ManometerCardDummy';

const lexicalSortValves: (deviceA: Valve, deviceB: Valve) => number = (
  { deviceName: a, deviceIdentifier: aId },
  { deviceName: b, deviceIdentifier: bId }
) => {
  a = a ?? aId;
  b = b ?? bId;
  return a < b ? -1 : a > b ? 1 : 0;
};

const mapStateToProps = (state: RootState) => ({
  valves: digidrumiSelectors.valves(state.digidrumi),
  selectedCustomer: commonSelectors.selectedCustomer(state.common),
  thresholds: digidrumiSelectors.thresholds(state.digidrumi),
});

const dispatchProps = {
  getValves: digidrumiActions.getValvesAsync.request,
  getThresholds: digidrumiActions.getThresholdAsync.request,
  updateValveLocation: digidrumiActions.updateValveLocationAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const DigiDrumi: React.FC<Props> = ({
  valves,
  thresholds,
  selectedCustomer,
  getValves,
  getThresholds,
  updateValveLocation,
}) => {
  const customerId = selectedCustomer?.customerId;
  useEffect(() => {
    if (valves.error || valves.loading) {
      return;
    }
    if (!valves.data && customerId) {
      getValves(customerId);
    }
  }, [valves.error, valves.loading, valves.data, customerId, getValves]);

  useEffect(() => {
    if (thresholds.error || thresholds.loading) {
      return;
    }
    if (!thresholds.data && customerId) {
      getThresholds(customerId);
    }
  }, [
    thresholds.error,
    thresholds.loading,
    thresholds.data,
    customerId,
    getThresholds,
  ]);

  const loadValves = () => {
    if (selectedCustomer && !valves.loading) {
      getValves(selectedCustomer.customerId);
    }
  };

  const valveContent = () => {
    if (valves.error) {
      return (
        <div className={styles['gaas-manometer-page--empty']}>
          <p>Laden der Ventilinformationen fehlgeschlagen.</p>
          <TextButton
            label="Erneut versuchen"
            LeadingIcon={Replay}
            onClick={() => loadValves()}
          />
        </div>
      );
    }

    if (valves.loading) {
      return (
        <div className={styles['gaas-manometer-page--empty']}>
          <ManometerLoading />
        </div>
      );
    }

    const valvesData = valves.data ?? [];
    const thresholdData = thresholds.data ?? [];
    if (!valvesData.length) {
      return <ManometerCardDummy />;
    }

    // if pressure is above 5 bar, show valve as open
    const openPressureThreshold = 5;
    const valveIsOpen = (valve: Valve): boolean =>
      valve.pressureBar != null && valve.pressureBar >= openPressureThreshold;
    const sortedValves = valvesData.sort(lexicalSortValves);

    const card = (valve: Valve, open: boolean) => (
      <DigiDrumiCard
        serialNumber={valve.serialNumber}
        deviceName={valve.deviceIdentifier}
        updateTimestamp={valve.timestamp}
        location={valve.deviceName ?? ''}
        batteryFillLevel={
          // for the value range, see
          // https://bitbucket.org/odpat7/gaas-meter-nrf52/src/6b8f46dc71288204750fd95f58fd0e792a0cf6ca/config/app_config.h?at=release%2Fv2.0.1#lines-162
          valve.voltage != null
            ? Math.min(1, Math.max(0, (valve.voltage - 3.2) / (4 - 3.2))) * 100
            : valve.voltage
        }
        batteryCharging={valve.isCharging}
        currentPairing={valve.containerPairing}
        pressureBar={valve.pressureBar}
        fillPressureBar={valve.containerPairing?.container?.fillPressure}
        remainingTimeMinutes={valve.remainingTimeMinutes}
        temperatureCelsius={valve.temperatureManometerCelsius}
        key={valve.deviceIdentifier}
        thresholds={
          thresholdData?.filter(it => it.deviceId === valve.deviceIdentifier) ??
          []
        }
        onLocationChange={location =>
          updateValveLocation({
            serialNumber: valve.serialNumber,
            location: location,
            oldDeviceName: valve.deviceName,
          })
        }
        open={open}
        role={'listitem'}
      />
    );

    return (
      <div className={styles['gaas-manometer-page']} role={'list'}>
        {sortedValves.map(valve => card(valve, valveIsOpen(valve)))}
      </div>
    );
  };

  return (
    <CustomerSelection title="Digitale Manometer" showGatewayIndicator>
      {valveContent()}
    </CustomerSelection>
  );
};

export default connect(mapStateToProps, dispatchProps)(DigiDrumi);
