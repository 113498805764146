import React, { ChangeEvent, useRef } from 'react';
import { LabelAndText } from './LabelAndText';
import styles from './Input.module.scss';
import classNames from 'classnames';
import { Error } from './icons';

interface Props {
  label: string;
  placeholder: string;
  defaultValue?: string | number;
  value?: string | number;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  type: 'text' | 'number' | 'date' | 'email' | 'password' | 'tel';
  name: string;
  id?: string;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  pattern?: RegExp;
  required?: boolean;
  readonly?: boolean;
  spellCheck?: boolean;
  autoCorrect?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  size?: 's' | 'm' | 'l';
  unit?: string;
}

export const Input: React.FC<Props> = ({
  label,
  name,
  id = name,
  pattern,
  disabled,
  autoCorrect,
  error,
  errorMessage,
  size = 'm',
  unit,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  // const iconRef = useRef<SVGSVGElement>(null);

  // const clearInput = (event: MouseEvent) => {
  //   if (inputRef.current && iconRef.current) {
  //     if (iconRef.current.contains(event.target as Node)) {
  //       inputRef.current.focus();
  //     }
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('mousedown', event => clearInput(event));

  //   return window.removeEventListener('mousedown', event => clearInput(event));
  // });
  // useEffect(() => {
  //   console.log('input', value);
  // }, [value]);

  return (
    <div
      className={classNames(
        styles['gaas-input'],
        styles[`gaas-input--${size}`],
        {
          [styles['gaas-input--disabled']]: disabled,
          [styles['gaas-input--error']]: error,
          [styles['gaas-input--withUnit']]: unit,
        }
      )}
    >
      <LabelAndText label={label} labelFor={id} />
      <input
        id={id}
        pattern={pattern && pattern.toString()}
        autoCorrect={autoCorrect ? 'on' : 'off'}
        ref={inputRef}
        {...props}
      />
      {/* <CancelFilled
        className={classNames(
          styles['gaas-input--icon'],
          styles['gaas-input--icon--cancel']
        )}
        ref={iconRef}
      /> */}
      {error && (
        <Error
          className={classNames(
            styles['gaas-input--icon'],
            styles['gaas-input--icon--error']
          )}
        />
      )}
      {error && <p className={styles['gaas-input--hint']}>{errorMessage}</p>}
      {unit && <span className={styles['gaas-input--unit']}>{unit}</span>}
    </div>
  );
};
