import { useRef } from 'react';
import { DebugQuaggaBarcodeScanner } from './DebugQuaggaBarcodeScanner';

interface Props {
  onBarcodeFound: (barcode: string) => void;
  requiredBarcodePrefix?: string;
}

export const DebugQuaggaBarcodeScannerWrapper: React.FC<Props> = ({
  onBarcodeFound,
}) => {
  const scannerRef = useRef<HTMLDivElement | null>(null);

  return (
    <div
      ref={scannerRef}
      style={{
        background: 'aquamarine',
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <canvas
        className="drawingBuffer"
        style={{
          position: 'absolute',
          top: '0px',
          // left: '0px',
          // height: '100%',
          // width: '100%',
          border: '3px solid green',
        }}
        width="640"
        height="480"
      />
      {
        <DebugQuaggaBarcodeScanner
          onBarcodeFound={onBarcodeFound}
          scannerRef={scannerRef}
        />
      }
      <div id="debug" />
    </div>
  );
};
