import { useCameraStream } from './CameraStreamProvider';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import './requestVideoFrameCallbackPolyfill';

// definitions stolen from https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/927#issue-714138779
declare global {
  export interface VideoFrameMetadata {
    presentationTime: DOMHighResTimeStamp;
    expectedDisplayTime: DOMHighResTimeStamp;
    width: number;
    height: number;
    mediaTime: number;
    presentedFrames: number;
    processingDuration?: number;
    captureTime?: DOMHighResTimeStamp;
    receiveTime?: DOMHighResTimeStamp;
    rtpTimestamp?: number;
  }
  type VideoFrameRequestCallbackId = number;

  interface HTMLVideoElement extends HTMLMediaElement {
    requestVideoFrameCallback(
      callback: (now: DOMHighResTimeStamp, metadata: VideoFrameMetadata) => any
    ): VideoFrameRequestCallbackId;
    cancelVideoFrameCallback(handle: VideoFrameRequestCallbackId): void;
  }
}

interface Props {
  children: React.ReactNode;
}

export const CameraVideoFrameCallbackProvider: React.FC<Props> = ({
  children,
}) => {
  const { videoRef } = useCameraStream();
  const [subscriptions] = useState<Record<string, () => void>>({});
  const [frameCallbackHandle, setFrameCallbackHandle] = useState<number>(-1);

  const onNewFrame = useCallback(() => {
    Object.values(subscriptions).forEach(subscription => {
      subscription();
    });

    if (!videoRef) {
      // this cannot happen here imho
      return;
    }

    const videoCallbackHandle = videoRef.requestVideoFrameCallback(now => {
      onNewFrame();
    });
    setFrameCallbackHandle(videoCallbackHandle);
  }, [subscriptions, videoRef]);

  useEffect(() => {
    if (!videoRef) {
      return;
    }

    const videoCallbackHandle = videoRef.requestVideoFrameCallback(now => {
      onNewFrame();
    });
    setFrameCallbackHandle(videoCallbackHandle);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onNewFrame, videoRef, setFrameCallbackHandle]);

  useEffect(() => {
    return () => {
      if (!videoRef) {
        return;
      }

      videoRef.cancelVideoFrameCallback(frameCallbackHandle);
    };
  }, [videoRef, frameCallbackHandle]);

  const subscribeToFrames = useCallback(
    (callback: () => void) => {
      const id = uuidv4();
      subscriptions[id] = callback;
      return id;
    },
    [subscriptions]
  );

  const unsubscribeFromFrames = useCallback(
    (id: string) => {
      if (id in subscriptions) {
        delete subscriptions[id];
      }
    },
    [subscriptions]
  );

  return (
    <CameraVideoCallbackContext.Provider
      value={{
        subscribeToFrames,
        unsubscribeFromFrames,
      }}
    >
      {children}
    </CameraVideoCallbackContext.Provider>
  );
};

export interface CameraVideoCallbackContextData {
  subscribeToFrames: (callback: () => void) => string;
  unsubscribeFromFrames: (id: string) => void;
}

export const CameraVideoCallbackContext =
  createContext<CameraVideoCallbackContextData>({
    unsubscribeFromFrames: () => {},
    subscribeToFrames: () => '',
  });

export const useCameraVideoFeedCallbacks = () =>
  useContext(CameraVideoCallbackContext);
