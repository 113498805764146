import { RootState } from 'typesafe-actions';
import { connect } from 'react-redux';
import { Modal } from '../../../stories/Modal';
import { MenuItem, OutlinedInput, Select } from '@mui/material';
import { Button } from '../../../stories/Button';
import { Devider } from '../../../stories/Devider';
import React, { useEffect, useState } from 'react';
import * as groupSelectors from '../selectors';
import * as groupActions from '../actions';
import { CustomerManagedGroup } from '../models/customer-managed-group';

const NO_GROUP_SELECTED_KEY = '__NO_GROUP_SELECTED__';

interface ComponentProps {
  isOpen: boolean;
  onClose: () => void;
  allGroups: CustomerManagedGroup[];
  currentGroup: CustomerManagedGroup;
  customerId: string;
}

const mapStateToProps = (state: RootState) => ({
  groupDeleting: (groupId: string) =>
    groupSelectors.groupIsDeleting(state.groups, groupId),
});
const dispatchProps = {
  deleteGroup: groupActions.deleteGroupAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const EditGroupModal: React.FC<Props> = ({
  isOpen,
  onClose,
  allGroups,
  currentGroup,
  groupDeleting,
  deleteGroup,
  customerId,
}) => {
  const [selectedGroupId, setSelectedGroupId] = useState<string>(
    NO_GROUP_SELECTED_KEY
  );
  const [groupDeleteRequested, setGroupDeleteRequested] =
    useState<boolean>(false);
  const { data: groupDeleted } = groupDeleting(currentGroup.id);

  useEffect(() => {
    setGroupDeleteRequested(false);
  }, [currentGroup.id]);

  useEffect(() => {
    if (groupDeleteRequested && groupDeleted) {
      onClose();
    }
  });

  const deleteGroupLabel = `Gruppe löschen${
    selectedGroupId === NO_GROUP_SELECTED_KEY
      ? ''
      : ' und alle Zuweisungen übertragen'
  }`;

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title="Verbrauchsgruppe bearbeiten"
      maxWidth={500}
    >
      <OutlinedInput
        placeholder={'Name der Gruppe'}
        disabled={true}
        sx={{ borderRadius: 0, fontFamily: 'WestfalenNewsSans' }}
      />
      <Button label={'Gruppennamen ändern'} fullWidth disabled={true} />
      <Devider noMargin />
      <div>
        <h3 style={{ margin: 0 }}>Verbrauchsgruppe löschen</h3>
        <p style={{ marginBottom: 0 }}>
          Wenn Sie diese Gruppen löschen, werden die Zuweisungen aller ihr
          zugewiesenen Flaschen und GaaS-Meter ebenso entfernt. Sie können diese
          Zuweisungen auch auf eine andere Gruppe übertragen, indem Sie das
          untenstehende Auswahlfeld nutzen.
        </p>
      </div>
      <Select
        sx={{ borderRadius: 0, fontFamily: 'WestfalenNewsSans' }}
        value={selectedGroupId}
        onChange={event => setSelectedGroupId(event.target.value)}
      >
        <MenuItem
          value={NO_GROUP_SELECTED_KEY}
          key={NO_GROUP_SELECTED_KEY}
          sx={{ fontFamily: 'WestfalenNewsSans' }}
        >
          Keine Verbrauchsgruppe
        </MenuItem>
        {allGroups
          .filter(group => group.id !== currentGroup.id)
          .map(group => {
            return (
              <MenuItem
                key={group.id}
                value={group.id}
                sx={{ fontFamily: 'WestfalenNewsSans' }}
              >
                {group.name}
              </MenuItem>
            );
          })}
      </Select>

      <Button
        label={deleteGroupLabel}
        fullWidth
        loading={groupDeleteRequested}
        onClick={() => {
          setGroupDeleteRequested(true);
          deleteGroup({
            groupId: currentGroup.id,
            customerId,
            request: {
              newGroupId:
                selectedGroupId === NO_GROUP_SELECTED_KEY
                  ? undefined
                  : selectedGroupId,
            },
          });
        }}
      />
    </Modal>
  );
};

export default connect(mapStateToProps, dispatchProps)(EditGroupModal);
