import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { Hint } from './Hint';
import styles from './LabelAndText.module.scss';

interface Props {
  label: string;
  labelFor?: string;
  text?: string | number | ReactNode;
  state?: 'default' | 'success' | 'warning' | 'error';
  hint?: string;
  children?: React.ReactNode;
}

export const LabelAndText: React.FC<Props> = ({
  label,
  labelFor,
  text,
  state = 'default',
  hint,
  children,
}) => {
  return (
    <div className={styles['gaas-label-and-text']} aria-label={'key-value:key'}>
      <span className={styles['gaas-label-and-text--label']}>
        <label htmlFor={labelFor}>
          {label} {hint && <Hint text={hint} />}
        </label>
      </span>
      <div
        className={styles['gaas-label-and-text--content']}
        aria-label={'key-value:content'}
      >
        {text && (
          <span
            className={classNames(
              styles['gaas-label-and-text--text'],
              styles[`gaas-label-and-text--text--${state}`]
            )}
          >
            {text}
          </span>
        )}
        {children && (
          <div className={styles['gaas-label-and-text--content--additional']}>
            {children}
          </div>
        )}
      </div>
    </div>
  );
};
