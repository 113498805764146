import {
  ContainerState,
  ManualContainerFillLevel,
} from '../models/container-state';
import { ContainerStateView } from '../models/inventory';

const emptyThreshold = 0.1;

export const isFull = (fillLevel: number | ManualContainerFillLevel) =>
  fillLevel === 1.0 || fillLevel === 'FULL';
export const isFullContainer = (
  container: ContainerState | ContainerStateView
) => isFull(container.fillLevel);

export const isHalfFull = (fillLevel: number | ManualContainerFillLevel) =>
  typeof fillLevel === 'number'
    ? fillLevel < 1.0 && fillLevel >= emptyThreshold
    : fillLevel === 'HALF';
export const isHalfFullContainer = (
  container: ContainerState | ContainerStateView
) => isHalfFull(container.fillLevel);

export const isEmpty = (fillLevel: number | ManualContainerFillLevel) =>
  typeof fillLevel === 'number'
    ? fillLevel < emptyThreshold
    : fillLevel === 'EMPTY';
export const isEmptyContainer = (
  container: ContainerState | ContainerStateView
) => isEmpty(container.fillLevel);
