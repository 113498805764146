import { ScanDetection } from '../../features/scanning/models/scan-result';

export type CameraDeviceWithFacingMode = {
  id: string;
  label: string;
  facingMode?: 'user' | 'environment';
};

const LAST_SUCCESSFUL_CAMERA_LOCALSTORE_KEY = 'gaas-last-sucessfull-camera-id';
export const getLastSuccessfulCameraIdFromLocalStore = (): string | null => {
  return localStorage.getItem(LAST_SUCCESSFUL_CAMERA_LOCALSTORE_KEY);
};

export const writeLastSuccessfulCameraIdToLocalStore = (cameraId: string) => {
  localStorage.setItem(LAST_SUCCESSFUL_CAMERA_LOCALSTORE_KEY, cameraId);
};

export const enumerateCamerasWeighted = async (): Promise<
  CameraDeviceWithFacingMode[]
> => {
  const devices = await navigator.mediaDevices.enumerateDevices();

  const results = [];
  for (const device of devices) {
    if (device.kind === 'videoinput') {
      // @ts-expect-error -- get capabilities is not supported in all browsers yet https://developer.mozilla.org/en-US/docs/Web/API/MediaStreamTrack/getCapabilities
      const capabilities = device.getCapabilities
        ? // @ts-expect-error
          (device.getCapabilities()?.facingMode as ('environment' | 'user')[])
        : undefined;
      let facingMode: CameraDeviceWithFacingMode['facingMode'] = undefined;
      if (capabilities) {
        if (capabilities.includes('environment')) {
          facingMode = 'environment';
        } else if (capabilities.includes('user')) {
          facingMode = 'user';
        }
      }
      results.push({
        id: device.deviceId,
        label: device.label,
        facingMode,
      });
    }
  }

  const facingModeToWeight = (camera: CameraDeviceWithFacingMode): number => {
    const { facingMode } = camera;
    switch (facingMode) {
      case 'environment':
        return 0;
      case 'user':
        return 1;
      default:
        return 2;
    }
  };

  results
    .sort((a, b) => a.id.localeCompare(b.id))
    .sort((a, b) => facingModeToWeight(a) - facingModeToWeight(b));

  const lastSuccessfulCameraId = getLastSuccessfulCameraIdFromLocalStore();
  // there is no last successful camera store yet, so we don't need to reorder
  if (!lastSuccessfulCameraId) {
    return results;
  }

  const lastSuccessfulCameraIdx = results.findIndex(
    camera => camera.id === lastSuccessfulCameraId
  );
  // the camera we stored was unplugged or is otherwise unavailable
  if (lastSuccessfulCameraIdx === -1) {
    return results;
  }

  const lastSuccessfulCamera = results[lastSuccessfulCameraIdx];
  results.splice(lastSuccessfulCameraIdx, 1);
  results.unshift(lastSuccessfulCamera);

  return results;
};

export interface CameraStreamData {
  stream: MediaStream;
  cameraId: string;
  hasTorch: boolean;
}

interface NonStandardMediaTrackCapabilities extends MediaTrackCapabilities {
  torch?: boolean;
}

export const startCameraStream = async (
  config: CameraDeviceWithFacingMode
): Promise<CameraStreamData> => {
  const cameraStream = await navigator.mediaDevices.getUserMedia({
    audio: false,
    video: {
      deviceId: { exact: config.id },
    },
  });

  // the following code can be used to change the track resolution
  // after a stream was opened, which works more reliably
  // than setting the constraints in the getUserMedia call
  // cameraStream.getVideoTracks().forEach(track => {
  //   track.applyConstraints({
  //     width: { ideal: 4096 },
  //     height: { ideal: 2160 },
  //   });
  // });

  // todo: we could check the track capabilities for the min and max focus distance to see if the camera has a wide angle lens
  //       Sadly, we can only check for this attribute after opening a camera, which clashes with the current implementation
  //       of choosing a camera
  const hasTorch = cameraStream
    .getVideoTracks()
    .map(
      track =>
        'getCapabilities' in track &&
        (track.getCapabilities() as NonStandardMediaTrackCapabilities).torch
    )
    .some(trackHasTorch => trackHasTorch);
  return { stream: cameraStream, hasTorch, cameraId: config.id };
};

export const stopCameraStream = (stream: MediaStream) => {
  const tracks = stream.getVideoTracks();
  for (const track of tracks) {
    track.enabled = false;
    track.stop();
    stream.removeTrack(track);
  }
};

export const toggleCameraTorch = (
  stream: MediaStream,
  activateTorch: boolean
) => {
  const tracks = stream.getVideoTracks();
  for (const track of tracks) {
    track.applyConstraints({
      // @ts-expect-error the torch attrib is missing in the MediaTrackConstraints, but should be supported on chrome
      advanced: [{ torch: activateTorch }],
    });
  }
};

export type CameraConsentState = 'unknown' | 'pending' | 'granted' | 'denied';

export const queryCameraPermissions: () => Promise<CameraConsentState> =
  async (): Promise<CameraConsentState> => {
    if (navigator?.permissions?.query == null) {
      // if the api for querying permissions is not supported,
      // we'll simply have to try it
      return 'pending';
    }

    try {
      const result = await navigator.permissions.query({ name: 'camera' });

      return result.state === 'granted' ? 'granted' : 'pending';
    } catch (e) {
      // According to MDN https://developer.mozilla.org/en-US/docs/Web/API/Permissions/query#exceptions
      // a TypeError is raised when querying for the "camera" permission is
      // not supported
      return 'pending';
    }
  };

export const requestCameraPermissions: () => Promise<CameraConsentState> =
  async (): Promise<CameraConsentState> => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: false,
      });
      // immediately close the stream
      stopCameraStream(stream);
      return 'granted';
    } catch (e) {
      console.log('failed to get camera permissions', e);
      return 'denied';
    }
  };

export type BarcodeScannerResultCallback = (
  barcode: ScanDetection,
  cameraId: string
) => void;
