import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';
import * as commonActions from '../features/common/actions';
import * as commonSelectors from '../features/common/selectors';
import { Modal } from '../stories/Modal';
import styles from './FirewallCheckPopup.module.scss';

const mapStateToProps = (state: RootState) => ({
  firewallRayId: commonSelectors.firewallRayId(state.common),
});

const dispatchProps = {
  fireRay: commonActions.fireRayAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const FirewallCheck: React.FC<Props> = ({ firewallRayId, fireRay }) => {
  const [expectedRay] = useState<string>(uuidv4());
  const [close, setClose] = useState(true);
  const [errorReport, setErrorReport] = useState<string>('');

  useEffect(() => {
    if (
      firewallRayId.error == null &&
      firewallRayId.data == null &&
      !firewallRayId.loading
    ) {
      fireRay(expectedRay);
      return;
    }

    if (firewallRayId?.loading) {
      return;
    }

    if (
      firewallRayId.error != null &&
      /Request aborted$/gi.test(firewallRayId.error.message)
    ) {
      // this error happens whenever the user leaves the page too fast
      return;
    }

    if (firewallRayId.error != null || firewallRayId.data !== expectedRay) {
      console.log(
        'firewall check failed. Error:',
        JSON.stringify(firewallRayId.error),
        'expected ray',
        expectedRay,
        'actual ray',
        firewallRayId.data
      );
      setErrorReport(
        JSON.stringify(
          {
            expectedRay,
            receivedRay: firewallRayId.data,
            requestError: firewallRayId.error,
            timestamp: new Date().toISOString(),
          },
          undefined,
          2
        )
      );
      setClose(false);
    }
  }, [expectedRay, fireRay, firewallRayId, setClose]);

  return (
    <Modal
      title="Verbindungsproblem"
      open={!close}
      onClose={() => setClose(true)}
      maxWidth={600}
    >
      <div className={styles['gaas-firewall-check--text']}>
        <p>Wir können unseren Server nicht erreichen.</p>
        <p>
          Sollten Sie diesen Fehler auch in einigen Minuten noch erhalten,
          deutet dies auf ein Problem mit Ihrem Netzwerk hin. Bitte kontaktieren
          Sie Ihren Systemadministrator und stellen Sie sicher, dass folgende
          Domains nicht durch Ihre Firewall blockiert werden:
        </p>
        <ul>
          <li>westfalen.com</li>
          <li>cloud-api.net</li>
          <li>amazonaws.com</li>
        </ul>
        <p>Technische Informationen für Entwickler:</p>
        <div className={styles['gaas-firewall-check--code']}>
          <span>{errorReport}</span>
        </div>
      </div>
    </Modal>
  );
};

export default connect(mapStateToProps, dispatchProps)(FirewallCheck);
