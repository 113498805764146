import { createReducer } from 'typesafe-actions';
import {
  AsyncResource,
  asyncResourceFailure,
  asyncResourceRequest,
  asyncResourceSuccess,
} from '../../store/async-resource';
import {
  createGroupAsync,
  deleteGroupAsync,
  discardGroupConsumptionHistory,
  getCustomerGroupsAsync,
  getGroupConsumptionHistoryAsync,
} from './actions';
import { CustomerManagedGroup } from './models/customer-managed-group';
import { ConsumptionHistory } from './models/consumption-history';

interface State {
  groupLoading: { [groupId: string]: AsyncResource<boolean> };
  groupDeleting: { [groupId: string]: AsyncResource<boolean> };
  groups: { [customerId: string]: AsyncResource<CustomerManagedGroup[]> };
  groupConsumptionHistory: {
    [customerId: string]: AsyncResource<ConsumptionHistory>;
  };
}

const initialState: State = {
  groupLoading: {},
  groupDeleting: {},
  groups: {},
  groupConsumptionHistory: {},
} as State;

export const groupsReducer = createReducer(initialState)
  .handleAction(
    createGroupAsync.request,
    (
      state,
      {
        payload: {
          request: { id },
        },
      }
    ) => ({
      ...state,
      groupLoading: {
        ...state.groupLoading,
        [id]: asyncResourceRequest(),
      },
    })
  )
  .handleAction(
    createGroupAsync.success,
    (state, { payload: { group, customerId } }) => {
      const currentGroupsForCustomer =
        state.groups[customerId] ?? asyncResourceSuccess([]);
      // data could be undefined when a group is created while the list of groups is loading
      // I can think of no scenario where this could happen, but in this case the user
      // will have to reload the page for now
      currentGroupsForCustomer.data?.push(group);
      return {
        ...state,
        groupLoading: {
          ...state.groupLoading,
          [group.id]: asyncResourceSuccess(true),
        },
        groups: {
          ...state.groups,
          [customerId]: currentGroupsForCustomer,
        },
      };
    }
  )
  .handleAction(
    createGroupAsync.failure,
    (state, { payload: { request, error } }) => ({
      ...state,
      groupLoading: {
        ...state.groupLoading,
        [request.id]: asyncResourceFailure(error),
      },
    })
  )
  .handleAction(
    deleteGroupAsync.request,
    (state, { payload: { groupId } }) => ({
      ...state,
      groupDeleting: {
        ...state.groupDeleting,
        [groupId]: asyncResourceRequest(),
      },
    })
  )
  .handleAction(
    deleteGroupAsync.success,
    (state, { payload: { groupId } }) => ({
      ...state,
      groupDeleting: {
        ...state.groupDeleting,
        [groupId]: asyncResourceSuccess(true),
      },
    })
  )
  .handleAction(
    deleteGroupAsync.failure,
    (state, { payload: { groupId, error } }) => ({
      ...state,
      groupDeleting: {
        ...state.groupDeleting,
        [groupId]: asyncResourceFailure(error),
      },
    })
  )
  .handleAction(
    getCustomerGroupsAsync.request,
    (state, { payload: { customerId } }) => ({
      ...state,
      groups: {
        ...state.groups,
        [customerId]: asyncResourceRequest(),
      },
    })
  )
  .handleAction(
    getCustomerGroupsAsync.success,
    (state, { payload: { customerId, groups } }) => ({
      ...state,
      groups: {
        ...state.groups,
        [customerId]: asyncResourceSuccess(groups),
      },
    })
  )
  .handleAction(
    getCustomerGroupsAsync.failure,
    (state, { payload: { customerId, error } }) => ({
      ...state,
      groups: {
        ...state.groups,
        [customerId]: asyncResourceFailure(error),
      },
    })
  )
  .handleAction(
    getGroupConsumptionHistoryAsync.request,
    (state, { payload: { customerId } }) => ({
      ...state,
      groupConsumptionHistory: {
        ...state.groupConsumptionHistory,
        [customerId]: asyncResourceRequest(),
      },
    })
  )
  .handleAction(
    getGroupConsumptionHistoryAsync.success,
    (state, { payload: { customerId, history } }) => ({
      ...state,
      groupConsumptionHistory: {
        ...state.groupConsumptionHistory,
        [customerId]: asyncResourceSuccess(history),
      },
    })
  )
  .handleAction(
    getGroupConsumptionHistoryAsync.failure,
    (state, { payload: { customerId, error } }) => ({
      ...state,
      groupConsumptionHistory: {
        ...state.groupConsumptionHistory,
        [customerId]: asyncResourceFailure(error),
      },
    })
  )
  .handleAction(
    discardGroupConsumptionHistory,
    (state, { payload: customerId }) => {
      const filteredHistory = Object.fromEntries(
        Object.entries(state.groupConsumptionHistory).filter(
          ([key, value]) => key !== customerId
        )
      );

      return {
        ...state,
        groupConsumptionHistory: filteredHistory,
      };
    }
  );

export default groupsReducer;
export type GroupsState = ReturnType<typeof groupsReducer>;
