import { RootState } from 'typesafe-actions';
import * as overlaySelectors from '../selectors';
import { connect } from 'react-redux';
import { ErrorOverlayMessage } from './ErrorOverlayMessage';

const mapStateToProps = (state: RootState) => ({
  getOverlayState: () => overlaySelectors.overlayState(state.overlay),
});

export type ErrorsProps = ReturnType<typeof mapStateToProps>;

function ErrorsComponent({ getOverlayState }: ErrorsProps) {
  const state = getOverlayState();

  return (
    <>
      <div>
        {Object.values(state.messages).map(message => (
          <ErrorOverlayMessage key={message.id} message={message} />
        ))}
      </div>
    </>
  );
}

export const Errors = connect(mapStateToProps, {})(ErrorsComponent);
