import classNames from 'classnames';
import React, { AriaRole, useEffect, useRef } from 'react';
import styles from './Card.module.scss';

interface Props {
  focused?: boolean;
  id?: string;
  children: React.ReactNode;
  maxWidth?: number;
  minWidth?: number;
  role?: AriaRole;
}

export const Card: React.FC<Props> = ({
  focused,
  id,
  children,
  maxWidth,
  minWidth,
  role,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (focused && containerRef.current) {
      containerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  });

  return (
    <div
      className={classNames(styles['gaas-card'], {
        [styles['gaas-card--focused']]: focused,
      })}
      id={id}
      ref={containerRef}
      style={{ maxWidth: `${maxWidth}px`, minWidth: `${minWidth}px` }}
      role={role}
    >
      {children}
    </div>
  );
};
