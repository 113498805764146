import React from 'react';
import styles from './BarcodeScannerModal.module.scss';
import { Modal } from './Modal';

interface Props {
  onClose: () => void;
  children: React.ReactNode;
}

export const BarcodeScannerModal: React.FC<Props> = ({ onClose, children }) => {
  return (
    <Modal
      title="Barcode scannen"
      open={true}
      onClose={onClose}
      maxWidth={640}
      fullWidthOnMobile
      fullWidthOnTablet
    >
      <div className={styles['gaas-barcode-scanner-modal--content']}>
        {children}
      </div>
    </Modal>
  );
};
