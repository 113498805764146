import React from 'react';
import styles from './GroupCardDummy.module.scss';
import { TextButton } from './TextButton';
import { Add, BottleOpenGrey, BottleUseGrey } from './icons';
import { PermissionQueryResponse } from '../features/common/models/permission-query-response';

interface Props {
  onClick: () => void;
  permission: PermissionQueryResponse;
}

export const GroupCardDummy: React.FC<Props> = ({ onClick, permission }) => {
  return (
    <div className={styles['gaas-group-card-dummy']}>
      <h3 className={styles['gaas-group-card-dummy--title']}>
        Verbrauchsgruppe
      </h3>

      <div className={styles['gaas-group-card-dummy--summary']}>
        <div className={styles['gaas-group-card-dummy--summary--box']}>
          <BottleOpenGrey width={40} height={40} />
          <div
            className={styles['gaas-group-card-dummy--summary--box--value']}
          />
        </div>
        <div className={styles['gaas-group-card-dummy--summary--box']}>
          <BottleUseGrey width={40} height={40} />
          <div
            className={styles['gaas-group-card-dummy--summary--box--value']}
          />
        </div>
      </div>

      <div className={styles['gaas-group-card-dummy--list']}>
        <div className={styles['gaas-group-card-dummy--list--item']} />
        <div className={styles['gaas-group-card-dummy--list--item']} />
        <div className={styles['gaas-group-card-dummy--list--item']} />
      </div>

      {
        <div className={styles['gaas-group-card-dummy--cta']}>
          <p>
            Hier können Sie Ihren Gasverbrauch in Gruppen einteilen und
            kontrollieren.
          </p>
          <TextButton
            onClick={onClick}
            label="Eine Gruppe erstellen"
            LeadingIcon={Add}
            permission={permission}
          />
        </div>
      }
    </div>
  );
};
