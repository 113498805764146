const LOCAL_STORE_KEY = 'TD_SHOP_AUTH';

class ShopAuthClass {
  private _shopToken: string | null = null;
  setToken(shopJwtToken: string) {
    localStorage.setItem(LOCAL_STORE_KEY, shopJwtToken);

    this._shopToken = shopJwtToken;
  }

  clearToken() {
    localStorage.removeItem(LOCAL_STORE_KEY);
    this._shopToken = null;
  }

  getToken(): string | null {
    return this._shopToken;
  }

  getStoredToken(): string | null {
    return localStorage.getItem(LOCAL_STORE_KEY) ?? null;
  }
}

export const ShopAuth = new ShopAuthClass();
