import React, { useCallback, useState } from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import moment from 'moment';
import { BarcodeScannerV2 } from '../../../components/barcode-scanner-v2/BarcodeScannerV2';

const BarcodeTestPage: React.FC = () => {
  const [scanResults, setScanResults] = useState<
    { result: string; timestamp: Date }[]
  >([]);

  const barcodeCallback = useCallback(
    (barcode: string) => {
      setScanResults(previousScanResults => {
        if (
          previousScanResults.length > 0 &&
          previousScanResults[0].result === barcode
        ) {
          previousScanResults[0].timestamp = new Date();
          return previousScanResults;
        }

        return [
          { result: barcode, timestamp: new Date() },
          ...previousScanResults.slice(0, 9),
        ];
      });
    },
    [setScanResults]
  );

  return (
    <>
      <BarcodeScannerV2 onBarcodeFound={barcodeCallback} debugDrawIn={true} />
      Gefundene Barcodes:
      <List>
        {scanResults.map((result, index) => {
          return (
            <ListItem alignItems="flex-start" key={`result-${index}`}>
              <ListItemText
                primary={result.result}
                secondary={moment(result.timestamp).fromNow()}
              />
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default BarcodeTestPage;
