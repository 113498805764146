/**
 * This is a custom polyfill for the requestVideoFrameCallback API, which is not supported in firefox yet.
 * It can be removed as soon as firefox has implemented the api ( the bugzilla ticket https://bugzilla.mozilla.org/show_bug.cgi?id=1800882
 * and standards position here https://mozilla.github.io/standards-positions/#requestVideoFrameCallback for info
 * on when this will be the case)
 *
 * This implementation is loosely based on https://github.com/ThaUnknown/rvfc-polyfill but has been simplified
 * as all the firefox specific features that are used in the original polyfill (like mozPresentedFrames, mozPaintedFrames, mozFrameDelay)
 * are not available when the video element is hidden.
 *
 * I have thus fixed the fps to 25
 */
if (
  typeof HTMLVideoElement !== 'undefined' &&
  !('requestVideoFrameCallback' in HTMLVideoElement.prototype) &&
  'getVideoPlaybackQuality' in HTMLVideoElement.prototype
) {
  HTMLVideoElement.prototype.__tapdo_framecallback_polyfill = {};
  HTMLVideoElement.prototype.requestVideoFrameCallback = function (callback) {
    const startTimestamp = performance.now();

    const fps = 25;
    const nextFrame = (lastTs, nextTs) => {
      if (nextTs - lastTs > 1000 / fps) {
        callback(nextTs, {
          presentationTime: -1,
          expectedDisplayTime: -1,
          width: this.videoWidth,
          height: this.videoHeight,
          mediaTime: -1,
          presentedFrames: -1,
          processingDuration: -1,
        });
        delete this.__tapdo_framecallback_polyfill[lastTs];
      } else {
        this.__tapdo_framecallback_polyfill[startTimestamp] =
          requestAnimationFrame(nextTs => nextFrame(lastTs, nextTs));
      }
    };
    this.__tapdo_framecallback_polyfill[startTimestamp] = requestAnimationFrame(
      nextTs => nextFrame(startTimestamp, nextTs)
    );
    return startTimestamp;
  };

  HTMLVideoElement.prototype.cancelVideoFrameCallback = function (handle) {
    cancelAnimationFrame(this.__tapdo_framecallback_polyfill[handle]);
    delete this.__tapdo_framecallback_polyfill[handle];
  };
}
