import React, { useState } from 'react';
import { Modal } from '../../../stories/Modal';
import { Select } from '../../../stories/Select';
import { RootState } from 'typesafe-actions';
import { connect } from 'react-redux';
import { LabelAndText } from '../../../stories/LabelAndText';
import { Skeleton } from '../../../stories/Skeleton';
import { ApiAddress } from '../../../models/openapi/openapiTypes';
import { ShippingAddress } from '../../../stories/ShippingAddress';

const mapStateToProps = (state: RootState) => ({});

const dispatchProps = {};

interface ComponentProps {
  open: boolean;
  onClose: () => void;
  shippingAddressId?: string;
  setShippingAddressId: (addressId?: string) => void;
  addresses: ApiAddress[];
  shopWelcome?: boolean;
  disabledAddressIds?: string[];
}

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const ChangeAddressModal: React.FC<Props> = ({
  open,
  onClose,
  addresses,
  shippingAddressId,
  setShippingAddressId,
  shopWelcome,
  disabledAddressIds,
}) => {
  const [selectedShippingAddressId, setSelectedShippingAddressId] = useState<
    string | undefined
  >(shippingAddressId);

  let address = (
    <div>
      <LabelAndText label="Lieferadresse" />
      <Skeleton height={40} mt={4} mb={24} />
      <Skeleton height={20} width={150} mb={4} />
      <Skeleton height={20} width={150} mb={4} />
      <Skeleton height={20} width={150} mb={4} />
      <Skeleton height={20} width={120} />
    </div>
  );

  if (shippingAddressId !== undefined && shippingAddressId !== 'none') {
    const addressIdContent = addresses.find(
      address => address.addressId === selectedShippingAddressId
    );

    address = (
      <>
        <Select
          label={shopWelcome ? 'Kundenkonto' : 'Lieferadresse'}
          id="address"
          value={shopWelcome ? addresses[0].addressId : shippingAddressId}
          options={addresses.map(address => ({
            label: shopWelcome
              ? `${address.customerId} – ${address.name}`
              : address.name,
            value: address.addressId,
            additionalContent: `${address.street}, ${address.zipCode} ${address.city}`,
            disabled:
              (disabledAddressIds ?? []).find(
                id => id === address.addressId
              ) !== undefined,
          }))}
          onChange={selectedValue =>
            setSelectedShippingAddressId(selectedValue)
          }
          info="Weitere Adressen können über Ihren Westfalen-Shop-Account hinzugefügt werden."
        />

        <ShippingAddress
          name={addressIdContent?.name}
          street={addressIdContent?.street}
          zipCode={addressIdContent?.zipCode}
          city={addressIdContent?.city}
        />
      </>
    );
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      noClose
      title={shopWelcome ? 'Kundenkonto wählen' : 'Lieferadresse wählen'}
      maxWidth={480}
      onSave={{
        disabled: false,
        loading: false,
        action: () => {
          setShippingAddressId(selectedShippingAddressId);
          onClose();
        },
      }}
    >
      {shopWelcome && (
        <span>
          Willkommen im <i>Gas as a Service®</i> – Shop! Bitte wählen Sie ein
          Kundenkonto um fortzufahren.
        </span>
      )}
      {address}
    </Modal>
  );
};

export default connect(mapStateToProps, dispatchProps)(ChangeAddressModal);
