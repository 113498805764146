import styles from './ShopWizard.module.scss';
import { TextButton } from '../../../stories/TextButton';
import { Info } from '../../../stories/icons';
import ShopItemSelect from './ShopItemSelect';
import { Devider } from '../../../stories/Devider';
import { Button } from '../../../stories/Button';
import ShopConfiguratorDeviceInfo from './ShopConfiguratorDeviceInfo';
import React, { useState } from 'react';
import {
  ApiGetShopAddressesResponse,
  ApiGetShopItemsResponse,
} from '../../../models/openapi/openapiTypes';
import * as shopSelectors from '../selectors';
import { RootState } from 'typesafe-actions';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import * as shopActions from '../actions';

import { CartItem } from '../models/Cart';

interface ComponentProps {
  shopItemEntries: ApiGetShopItemsResponse;
  addressData: ApiGetShopAddressesResponse;
}

const mapStateToProps = (state: RootState) => ({
  cart: shopSelectors.currentCart(state.shop),
});

const dispatchProps = {
  changeItemQuantity: shopActions.currentCartChangeItemQuantity,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const ShopConfiguratorStepGateways: React.FC<Props> = ({
  shopItemEntries,
  cart,
  addressData,
  changeItemQuantity,
}) => {
  const [infoVisible, setInfoVisible] = useState<boolean>(false);
  const [nextItemId, setNextItemId] = useState<string>(uuidv4);
  const [nextCreationTs, setNextCreationTs] = useState<number>(Date.now());

  const availableGateways = shopItemEntries.filter(
    it => it.category === 'gateway'
  );

  const selectedGateways: CartItem[] = cart.items
    .filter(item => item.quantity > 0)
    .filter(
      item =>
        shopItemEntries.find(it => it.itemId === item.itemId)?.category ===
        'gateway'
    );

  const onAddNew = () => {
    setNextItemId(uuidv4());
    setNextCreationTs(Date.now());

    const nextGateway = availableGateways
      .map(meter => meter.itemId)
      .map(itemId =>
        addressData.shippingAddresses.map(address => [
          itemId,
          address.addressId,
        ])
      )
      .flat()
      .filter(
        ([itemId, addressId]) =>
          !selectedGateways.some(
            item =>
              item.itemId === itemId && item.shippingAddressId === addressId
          )
      )[0];

    changeItemQuantity({
      id: nextItemId,
      addedTs: nextCreationTs,
      itemId: nextGateway[0],
      quantity: 1,
      shippingAddressId: nextGateway[1],
    });
  };

  return (
    <>
      <div className={styles['gaas-shopPage--content--selection--info']}>
        <span>
          Bitte wählen Sie aus, wie viele LTE- oder WLAN-kompatible Geräte Sie
          benötigen.
        </span>
        <TextButton
          LeadingIcon={Info}
          onClick={() => setInfoVisible(!infoVisible)}
          inline
        />
      </div>
      {infoVisible && <ShopConfiguratorDeviceInfo />}

      {selectedGateways.map((item, index) => (
        <div key={`${item.id}wrapper`}>
          <ShopItemSelect
            item={item}
            price={
              availableGateways.find(gate => gate.itemId === item.itemId)!.price
            }
            name="gateways"
            options={availableGateways.map(option => ({
              itemLabel: option.name,
              itemId: option.itemId,
            }))}
            disabledOptions={selectedGateways.map(item => ({
              shippingAddressId: item.shippingAddressId,
              itemId: item.itemId,
            }))}
            onItemQuantityAndAddressChanged={changeItemQuantity}
            addresses={addressData}
          />
          <Devider noMargin />
        </div>
      ))}

      <div>
        <Button
          label={
            selectedGateways.length === 0 ? 'Hinzufügen' : 'Weitere hinzufügen'
          }
          variant="outlined"
          disabled={
            selectedGateways.length ===
            availableGateways.length * addressData.shippingAddresses.length
          }
          onClick={onAddNew}
        />
      </div>
    </>
  );
};

export default connect(
  mapStateToProps,
  dispatchProps
)(ShopConfiguratorStepGateways);
