import { asyncResourceSelector } from '../../store/async-resource';
import { GroupsState } from './reducer';

export const groupIsLoading = (state: GroupsState, groupId: string) =>
  asyncResourceSelector(state.groupLoading[groupId]);

export const groupIsDeleting = (state: GroupsState, groupId: string) =>
  asyncResourceSelector(state.groupDeleting[groupId]);

export const customerGroups = (state: GroupsState, customerId: string) =>
  asyncResourceSelector(state.groups[customerId]);

export const customerGroupConsumptionHistory = (
  state: GroupsState,
  customerId: string
) => asyncResourceSelector(state.groupConsumptionHistory[customerId]);
