import React from 'react';
import styles from './Bottles.module.scss';
import { BottleEmpty, BottleFull, BottleOpen } from './icons';

interface Props {
  full: number;
  open: number;
  empty: number;
  ordered: number;
}

export const Bottles: React.FC<Props> = ({ full, open, empty, ordered }) => {
  const all = full + open + empty;

  return (
    <div className={styles['gaas-bottles']} role={'list'}>
      <div className={styles['gaas-bottles--inventory']}>
        <div
          className={styles['gaas-bottles--column']}
          role={'listitem'}
          aria-label={'total bottles'}
        >
          <label>Gesamt</label>
          <div>{all}</div>
        </div>
        <div className={styles['gaas-bottles--icons']}>
          <div
            className={styles['gaas-bottles--icons--icon']}
            role={'listitem'}
            aria-label={'full bottles'}
          >
            <BottleFull
              width={40}
              height={40}
              data-testid={`icon:bottle-full`}
            />
            {full}
          </div>
          <div
            className={styles['gaas-bottles--icons--icon']}
            role={'listitem'}
            aria-label={'open bottles'}
          >
            <BottleOpen
              width={40}
              height={40}
              data-testid={`icon:bottle-open`}
            />
            {open}
          </div>
          <div
            className={styles['gaas-bottles--icons--icon']}
            role={'listitem'}
            aria-label={'empty bottles'}
          >
            <BottleEmpty
              width={40}
              height={40}
              data-testid={`icon:bottle-empty`}
            />
            {empty}
          </div>
        </div>
      </div>
      <div
        className={styles['gaas-bottles--column']}
        role={'listitem'}
        aria-label={'ordered bottles'}
      >
        <label>Bestellt</label>
        <div>{ordered}</div>
      </div>
    </div>
  );
};
