import { Epic } from 'redux-observable';
import { EMPTY, Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { RootAction, RootState, Services, isActionOf } from 'typesafe-actions';
import {
  WsSetCustomerMessageType,
  WsStatusSchema,
} from '../../models/zod/WsSchema';
import { selectedCustomerChanged, signedInAsync } from '../common/actions';
import {
  connectWebSocket,
  setWebSocketCustomer,
  webSocketCheckToken,
  webSocketConnected,
} from './actions';

export const handleWebSocketCheckToken: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = action$ =>
  action$.pipe(
    filter(isActionOf(webSocketCheckToken)),
    map(payload => payload.payload.token),
    distinctUntilChanged(),
    switchMap(token => of(connectWebSocket({ token })))
  );

export const handleWebSocketConnect: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state) =>
  action$.pipe(
    filter(isActionOf(connectWebSocket)),
    withLatestFrom(state),
    switchMap(([_, state]) => {
      const websocket = state.websocket.websocket!;
      if (websocket!.readyState === 1) {
        return of(webSocketConnected());
      }
      return new Observable<RootAction>(observer => {
        const openedHandler = () => {
          observer.next(webSocketConnected());
          websocket.removeEventListener('open', openedHandler);
        };
        websocket.addEventListener('open', openedHandler);
      });
    })
  );

export const handleWebSocketDisconnect: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state) =>
  action$.pipe(
    filter(isActionOf(connectWebSocket)),
    withLatestFrom(state),
    switchMap(
      ([{ payload }, state]) =>
        new Observable<RootAction>(observer => {
          const { websocket } = state.websocket;
          if (!websocket) {
            observer.next(connectWebSocket({ token: payload.token }));
            return;
          }
          const closeOrErrorHandler: EventListener = (errOrEv: Event) => {
            websocket.removeEventListener('error', closeOrErrorHandler);
            websocket.removeEventListener('close', closeOrErrorHandler);
            observer.next(connectWebSocket({ token: payload.token }));
          };
          websocket.addEventListener('error', closeOrErrorHandler);
          websocket.addEventListener('close', closeOrErrorHandler);
        })
    ),
    debounceTime(5000)
  );

export const handleSignIn: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state
) =>
  action$.pipe(
    filter(isActionOf(signedInAsync.success)),
    map(({ payload }) => connectWebSocket({ token: payload.token }))
  );

export const handleSelectCustomer: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state) =>
  action$.pipe(
    filter(
      action =>
        isActionOf(selectedCustomerChanged, action) ||
        isActionOf(webSocketConnected, action)
    ),
    withLatestFrom(state),
    map(([_, state]) => state.common.selectedCustomer?.customerId),
    filter(
      (customerId): customerId is string => typeof customerId === 'string'
    ),
    // distinctUntilChanged(),
    map(customerId => setWebSocketCustomer.request(customerId))
  );

export const handleSetWebSocketCustomer: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state, { websocket }) =>
  action$.pipe(
    filter(isActionOf(setWebSocketCustomer.request)),
    map(
      ({ payload }): WsSetCustomerMessageType => ({
        action: 'set-customer',
        messageId: uuidv4(),
        message: {
          customerId: payload,
        },
      })
    ),
    withLatestFrom(state),
    switchMap(([payload, state]) => {
      const { websocket: ws } = state.websocket;
      if (!ws) {
        console.log('no websocket');
        return EMPTY;
      }
      return websocket.sendWebSocketMessageAndAwaitResponse<
        typeof WsStatusSchema,
        WsSetCustomerMessageType
      >(ws, payload, WsStatusSchema);
    }),
    map(payload =>
      setWebSocketCustomer.success(payload!.request.message.customerId)
    ),
    catchError(error => of(setWebSocketCustomer.failure(error)))
  );
