import React from 'react';
import styles from './ListItem.module.scss';
import Check from '@mui/icons-material/CheckRounded';
import HorizontalRule from '@mui/icons-material/HorizontalRuleRounded';

interface Props {
  values: any[];
  grid: string;
}

const ListItem: React.FC<Props> = ({ grid, values }) => {
  return (
    <div className={styles.container} style={{ gridTemplateColumns: grid }}>
      {values.map((value, i) => (
        <div className={styles.entries} key={i}>
          {value !== undefined ? (
            typeof value === 'boolean' ? (
              value ? (
                <Check />
              ) : (
                <HorizontalRule />
              )
            ) : (
              value
            )
          ) : (
            '-'
          )}
          <div className={styles.overlay} />
        </div>
      ))}
    </div>
  );
};

export default ListItem;
