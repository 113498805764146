import React from 'react';
import { Container } from '@mui/material';
import { RootState } from 'typesafe-actions';
import List from '../../../components/list/List';
import { format } from 'date-fns';
import { listGatewaysAsync } from '../actions';
import * as administrationSelectors from '../selectors';
import { connect } from 'react-redux';
import { SortingParameters } from '../../common/models/listing-parameters';
import { GatewayWithCurrentAssignment } from '../models/gateway-with-assignment';
import styles from './AdministrationPage.module.scss';

const mapStateToProps = (state: RootState) => ({
  gatewayList: (params: SortingParameters) =>
    administrationSelectors.adminGatewayList(state.administration, params),
});

const dispatchProps = {
  getGatewayList: listGatewaysAsync.request,
};

interface ComponentProps {}

type Props = ComponentProps &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

const GatewayAdministrationPage: React.FC<Props> = ({
  gatewayList,
  getGatewayList,
}) => {
  return (
    <Container className={styles['gaas-administration-page']}>
      <h1>Gateway Administration</h1>
      <List
        hasPagination={true}
        hasSearchBar={true}
        hasSorting={false}
        editEntityLink={`/admin/edit-gateway`}
        columnLabels={[
          { prop: 'id', label: 'Gateway-ID' },
          { prop: 'assignment.customerId', label: 'WEF-Nr' },
          { prop: 'assignment?.startTs', label: 'Zugewiesen seit' },
          { prop: 'assignment?.gatewayName', label: 'Name' },
          // { prop: 'xxx', label: 'Letztes Paket' },
          // { prop: 'yyy', label: 'Alarmzustand' },
          { prop: 'note', label: 'Notiz' },
        ]}
        columnLabelGrid={'1.75fr 1.75fr 1.75fr 1.75fr 1.75fr 1.75fr'}
        rowValueTransform={(obj: GatewayWithCurrentAssignment) => [
          obj.id.slice(19),
          obj.assignment?.customerId ?? '-',
          obj.assignment?.startTs != null
            ? format(obj.assignment.startTs, 'dd.MM.yy')
            : '-',
          obj.assignment?.gatewayName ?? '-',
          obj.note?.slice(0, 96) ?? 'Keine',
        ]}
        searchProps={['id']}
        entityKey={'gatewayswithassignments'}
        listRequest={sortingParams => getGatewayList(sortingParams)}
        listSelector={gatewayList}
      />
    </Container>
  );
};

export default connect(
  mapStateToProps,
  dispatchProps
)(GatewayAdministrationPage);
