import {
  AsyncResource,
  asyncResourceFailure,
  asyncResourceRequest,
  asyncResourceSuccess,
} from '../../store/async-resource';
import {
  ApiCalculateCartResponse,
  ApiGetShopAccessibleCustomers,
  ApiGetShopAddressesResponse,
  ApiGetShopItemsResponse,
  ApiRenderContractResponse,
} from '../../models/openapi/openapiTypes';
import { createReducer } from 'typesafe-actions';
import {
  calculateCartAsync,
  clearCachedRenderedContract,
  clearCart,
  createFreeSubscriptionAsync,
  getShopAccessibleCustomersAsync,
  getShopAddressesAsync,
  getShopItemsAsync,
  renderContractAsync,
  signInShopUserRejected,
  signInShopUserSuccess,
  storeCartLocal,
  submitCartAsync,
} from './actions';
import { SavedCart } from './models/SavedCart';
import { Cart } from './models/Cart';

interface State {
  currentCart: Cart;
  currentCalculatedCart?: AsyncResource<ApiCalculateCartResponse>;
  currentContractPreview?: AsyncResource<ApiRenderContractResponse>;
  addresses: Record<string, AsyncResource<ApiGetShopAddressesResponse>>;
  accessibleCustomers?: AsyncResource<ApiGetShopAccessibleCustomers>;
  items?: AsyncResource<ApiGetShopItemsResponse>;
  shopUser?: { jwtToken: string };
  shopUserError?: { reason: 'expired' | 'invalid' };
  freeSubscriptionCreationRequest?: AsyncResource<{ sapCustomerNo: string }>;
  submitCartRequest?: AsyncResource<{ cartId: string }>;
}

const EMPTY_CART: Cart = {
  billingInterval: 'monthly',
  cartId: 'cartId',
  items: [],
  sapCustomerNo: '',
};

const initialState: State = {
  addresses: {},
  currentCart: EMPTY_CART,
};

export const shopReducer = createReducer(initialState)
  .handleAction(calculateCartAsync.request, state => ({
    ...state,
    currentCalculatedCart: asyncResourceRequest(),
  }))
  .handleAction(calculateCartAsync.success, (state, { payload }) => ({
    ...state,
    currentCalculatedCart: asyncResourceSuccess(payload),
  }))
  .handleAction(calculateCartAsync.failure, (state, { payload }) => ({
    ...state,
    currentCalculatedCart: asyncResourceFailure(payload.error),
  }))
  .handleAction(
    renderContractAsync.request,
    (state, { payload: { cartId } }) => ({
      ...state,
      currentContractPreview: asyncResourceRequest(),
    })
  )
  .handleAction(renderContractAsync.success, (state, { payload }) => ({
    ...state,
    currentContractPreview: asyncResourceSuccess(payload.contract),
  }))
  .handleAction(renderContractAsync.failure, (state, { payload }) => ({
    ...state,
    currentContractPreview: asyncResourceFailure(payload.error),
  }))
  .handleAction(clearCachedRenderedContract, state => ({
    ...state,
    currentContractPreview: undefined,
  }))
  .handleAction(
    getShopAddressesAsync.request,
    (state, { payload: { sapCustomerNo } }) => ({
      ...state,
      addresses: {
        ...state.addresses,
        [sapCustomerNo]: asyncResourceRequest(),
      },
    })
  )
  .handleAction(
    getShopAddressesAsync.success,
    (state, { payload: { addresses, sapCustomerNo } }) => ({
      ...state,
      addresses: {
        ...state.addresses,
        [sapCustomerNo]: asyncResourceSuccess(addresses),
      },
    })
  )
  .handleAction(
    getShopAddressesAsync.failure,
    (state, { payload: { sapCustomerNo, error } }) => ({
      ...state,
      addresses: {
        ...state.addresses,
        [sapCustomerNo]: asyncResourceFailure(error),
      },
    })
  )
  .handleAction(getShopItemsAsync.request, state => ({
    ...state,
    items: asyncResourceRequest(),
  }))
  .handleAction(getShopItemsAsync.success, (state, { payload }) => ({
    ...state,
    items: asyncResourceSuccess(payload),
  }))
  .handleAction(getShopItemsAsync.failure, (state, { payload }) => ({
    ...state,
    items: asyncResourceFailure(payload),
  }))
  .handleAction(getShopAccessibleCustomersAsync.request, state => ({
    ...state,
    accessibleCustomers: asyncResourceRequest(),
  }))
  .handleAction(
    getShopAccessibleCustomersAsync.success,
    (state, { payload }) => ({
      ...state,
      accessibleCustomers: asyncResourceSuccess(payload),
    })
  )
  .handleAction(
    getShopAccessibleCustomersAsync.failure,
    (state, { payload }) => ({
      ...state,
      accessibleCustomers: asyncResourceFailure(payload),
    })
  )
  .handleAction(createFreeSubscriptionAsync.request, state => ({
    ...state,
    freeSubscriptionCreationRequest: asyncResourceRequest(),
  }))
  .handleAction(createFreeSubscriptionAsync.success, (state, { payload }) => ({
    ...state,
    freeSubscriptionCreationRequest: asyncResourceSuccess(payload),
  }))
  .handleAction(
    createFreeSubscriptionAsync.failure,
    (state, { payload: { error } }) => ({
      ...state,
      freeSubscriptionCreationRequest: asyncResourceFailure(error),
    })
  )
  .handleAction(submitCartAsync.request, state => ({
    ...state,
    submitCartRequest: asyncResourceRequest(),
  }))
  .handleAction(submitCartAsync.success, (state, { payload: { cartId } }) => ({
    ...state,
    submitCartRequest: asyncResourceSuccess({ cartId }),
  }))
  .handleAction(
    submitCartAsync.failure,
    (state, { payload: { error, cartId } }) => ({
      ...state,
      submitCartRequest: asyncResourceFailure(error),
    })
  )
  .handleAction(clearCart, (state, { payload: { sapCustomerNo } }) => {
    localStorage.removeItem(`GAAS-CART-${sapCustomerNo}`);
    return {
      ...state,
      submitCartRequest: undefined,
      currentCart: EMPTY_CART,
    };
  })
  .handleAction(signInShopUserSuccess, (state, { payload }) => ({
    ...state,
    shopUser: payload,
  }))
  .handleAction(signInShopUserRejected, (state, { payload }) => ({
    ...state,
    shopUserError: payload,
  }))
  .handleAction(storeCartLocal, (state, { payload }) => {
    const cartToSave: SavedCart = {
      cart: payload,
      version: 'v1',
      lastModifiedAt: new Date().toISOString(),
    };
    localStorage.setItem(
      `GAAS-CART-${payload.sapCustomerNo}`,
      JSON.stringify(cartToSave)
    );

    return {
      ...state,
      currentCart: payload,
    };
  });

export default shopReducer;
export type ShopState = ReturnType<typeof shopReducer>;
