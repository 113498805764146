import {
  ApiCalculateCartRequest,
  ApiCartItem,
} from '../../../models/openapi/openapiTypes';

export type CartItem = ApiCartItem & {
  /// unique identifier for this line item which is only
  /// valid within this cart
  id: string;
  /// the timestamp when this item was added to the Cart
  /// this is only needed to get stable sorting on items
  /// in a dynamic ShopItemSelect
  addedTs: number;
};
export type Cart = Omit<ApiCalculateCartRequest, 'items'> & {
  items: CartItem[];
};

export function cartToApiCart(
  calculateCartRequest: Cart
): ApiCalculateCartRequest {
  return {
    ...calculateCartRequest,
    items: calculateCartRequest.items.map(item => ({
      itemId: item.itemId,
      quantity: item.quantity,
      shippingAddressId: item.shippingAddressId,
    })),
  };
}
