import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Card } from './Card';
import styles from './GroupCard.module.scss';
import { TextButton } from './TextButton';
import { BottleOpen, BottleUse, Edit } from './icons';
import { Skeleton } from '@mui/material';
import { PermissionQueryResponse } from '../features/common/models/permission-query-response';

interface Props {
  focused?: boolean;
  // containerRef?: React.ForwardedRef<HTMLDivElement>;
  name: string;
  inventory: number;
  consumption: number;
  consumptionLoading: boolean;
  materials: React.ReactNode;
  materialsCount: number;
  setEditModalOpen: () => void;
  setHistoryModalOpen?: () => void;
  readGroupConsumptionPermission: PermissionQueryResponse;
}

export const GroupCard: React.FC<Props> = ({
  focused,
  // containerRef,
  name,
  inventory,
  consumption,
  consumptionLoading,
  materials,
  materialsCount,
  setEditModalOpen,
  setHistoryModalOpen,
  readGroupConsumptionPermission,
}) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [fade, setFade] = useState<boolean>(false);

  useEffect(() => {
    const element = scrollRef.current;
    if (!element) {
      return;
    }

    element.addEventListener(
      'scroll',
      e => {
        setScrollPosition(element.scrollTop);
      },
      { passive: true }
    );
  }, [scrollRef]);

  useEffect(() => {
    const element = scrollRef.current;
    if (!element) {
      return;
    }

    if (
      Math.abs(
        element.scrollHeight - element.scrollTop - element.clientHeight
      ) < 1
    ) {
      setFade(false);
    } else {
      setFade(true);
    }
  }, [scrollPosition, scrollRef, setFade]);

  return (
    <Card
      focused={focused}
      id={name}
      // containerRef={containerRef}
      maxWidth={416}
      minWidth={0}
    >
      <div className={styles['gaas-group-card']}>
        <div className={styles['gaas-group-card--header']}>
          <h3 className={styles['gaas-group-card--header--title']}>{name}</h3>
          <TextButton size="l" LeadingIcon={Edit} onClick={setEditModalOpen} />
          {/* <TextButton
            size="l"
            LeadingIcon={Chart}
            onClick={setHistoryModalOpen}
            disabled={!setHistoryModalOpen}
          /> */}
        </div>
        <div className={styles['gaas-group-card--summary']}>
          <div className={styles['gaas-group-card--summary--box']}>
            <BottleOpen width={40} height={40} />
            <div className={styles['gaas-group-card--summary--box--value']}>
              <label>Bestand</label>
              <div data-testid={`group:${name}:inventory`}>{inventory}</div>
            </div>
          </div>
          <div className={styles['gaas-group-card--summary--box']}>
            <BottleUse width={40} height={40} />
            <div className={styles['gaas-group-card--summary--box--value']}>
              <label>Verbrauch</label>
              <div>
                {consumptionLoading ? (
                  <Skeleton width={20} height={43} />
                ) : (
                  // todo Nina, bitte hier readGroupConsumptionPermission prüfen und das irgendwie sinnvoll darstellen
                  consumption
                )}
              </div>
              <label
                className={styles['gaas-group-card--summary--box--timeframe']}
              >
                letzte 30 Tage
              </label>
            </div>
          </div>
        </div>
        {materialsCount > 0 && (
          <>
            <div
              ref={scrollRef}
              className={classNames(styles['gaas-group-card--list'], {
                [styles['gaas-group-card--list--withFade']]: fade,
                [styles['gaas-group-card--list--withScroll']]:
                  materialsCount >= 6,
              })}
            >
              <div className={styles['gaas-group-card--list--items']}>
                {materials}
              </div>
              {materialsCount >= 6 && (
                <div className={styles['gaas-group-card--list--fade']} />
              )}
            </div>
          </>
        )}
      </div>
    </Card>
  );
};
