import { Loading } from '../../stories/Loading';
import React from 'react';
import styles from './BarcodeScannerV2Loading.module.scss';

export const BarcodeScannerV2Loading: React.FC = () => {
  return (
    <div className={styles['gaas-barcode-scanner-loading']}>
      <Loading />
    </div>
  );
};
