import { makeAsyncAction } from '../../util/makeAsyncAction';
import {
  ApiCalculateCartResponse,
  ApiGetShopAccessibleCustomers,
  ApiGetShopAddressesResponse,
  ApiGetShopItemsResponse,
  ApiRenderContractResponse,
} from '../../models/openapi/openapiTypes';
import { createAction } from 'typesafe-actions';
import { Cart, CartItem } from './models/Cart';
import { SubmitCartRequest } from './models/SubmitCartRequest';

export const calculateCartAsync = makeAsyncAction('CALCULATE_CART')<
  void,
  ApiCalculateCartResponse,
  { cartId: string; error: Error }
>();

export const renderContractAsync = makeAsyncAction('RENDER_CONTRACT')<
  Cart,
  { cartId: string; contract: ApiRenderContractResponse },
  { cartId: string; error: Error }
>();

export const clearCachedRenderedContract = createAction(
  'CLEAR_CACHED_RENDERED_CONTRACT'
)();

export const getShopAddressesAsync = makeAsyncAction('GET_SHOP_ADDRESSES')<
  { sapCustomerNo: string },
  { sapCustomerNo: string; addresses: ApiGetShopAddressesResponse },
  { sapCustomerNo: string; error: Error }
>();

export const getShopAccessibleCustomersAsync = makeAsyncAction(
  'GET_SHOP_ACCESSIBLE_CUSTOMERS'
)<void, ApiGetShopAccessibleCustomers, Error>();

export const submitCartAsync = makeAsyncAction('SUBMIT_CART')<
  SubmitCartRequest,
  { cartId: string },
  { cartId: string; error: Error }
>();

export const clearCart = createAction('CLEAR_CART')<{
  sapCustomerNo: string;
}>();

export const getShopItemsAsync = makeAsyncAction('GET_SHOP_ITEMS')<
  void,
  ApiGetShopItemsResponse,
  Error
>();

export const loadOrCreateCartForCustomer = createAction('LOAD_OR_CREATE_CART')<{
  sapCustomerNo: string;
  defaultItems: CartItem[];
}>();

export const currentCartChangeBillingInterval = createAction(
  'CHANGE_BILLING_INTERVAL'
)<'monthly' | 'yearly'>();

export const currentCartChangeItemQuantity = createAction(
  'CHANGE_ITEM_QUANTITY'
)<CartItem>();

export const currentCartChangeReference = createAction(
  'CHANGE_CART_REFERENCE'
)<{ reference: string | undefined }>();

export const storeCartLocal = createAction('SAVE_CART')<Cart>();

export const signInShopUser = createAction('SIGN_IN_SHOP_USER')<{
  jwtToken: string;
}>();

export const signInShopUserSuccess = createAction('SIGN_IN_SHOP_USER_SUCCESS')<{
  jwtToken: string;
}>();

export const signInShopUserRejected = createAction(
  'SIGN_IN_SHOP_USER_REJECTED'
)<{
  reason: 'invalid' | 'expired';
}>();

export const createFreeSubscriptionAsync = makeAsyncAction(
  'CREATE_FREE_SUBSCRIPTION'
)<
  { sapCustomerNo: string },
  { sapCustomerNo: string },
  { sapCustomerNo: string; error: Error }
>();
