import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { RootAction, RootState, Services, isActionOf } from 'typesafe-actions';
import { getInventoryAsync } from '../inventory/actions';
import {
  createGroupAsync,
  deleteGroupAsync,
  getCustomerGroupsAsync,
  getGroupConsumptionHistoryAsync,
} from './actions';

export const createGroup: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  _,
  { groups }
) =>
  action$.pipe(
    filter(isActionOf(createGroupAsync.request)),
    switchMap(({ payload: { request, customerId } }) =>
      groups.createGroup(customerId, request).pipe(
        map(group => createGroupAsync.success({ customerId, group })),
        catchError(error => of(createGroupAsync.failure({ request, error })))
      )
    )
  );

export const deleteGroup: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  _,
  { groups }
) =>
  action$.pipe(
    filter(isActionOf(deleteGroupAsync.request)),
    switchMap(({ payload: { groupId, customerId, request } }) =>
      groups.deleteGroup(customerId, groupId, request).pipe(
        map(() => ({
          customerId,
          action: deleteGroupAsync.success({
            groupId,
          }),
        })),
        catchError(error =>
          of({
            customerId,
            action: deleteGroupAsync.failure({ groupId, error }),
          })
        )
      )
    ),
    switchMap(({ action, customerId }) =>
      from([
        action,
        getCustomerGroupsAsync.request({ customerId }),
        getInventoryAsync.request(customerId),
      ])
    )
  );

export const getCustomerGroups: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { groups }) =>
  action$.pipe(
    filter(isActionOf(getCustomerGroupsAsync.request)),
    switchMap(({ payload: { customerId } }) =>
      groups.getGroups(customerId).pipe(
        map(groups =>
          getCustomerGroupsAsync.success({
            customerId,
            groups,
          })
        ),
        catchError(error =>
          of(getCustomerGroupsAsync.failure({ customerId, error }))
        )
      )
    )
  );

export const getGroupConsumptionHistory: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { groups }) =>
  action$.pipe(
    filter(isActionOf(getGroupConsumptionHistoryAsync.request)),
    switchMap(({ payload: { customerId } }) =>
      groups.getGroupConsumptionHistory(customerId).pipe(
        map(history =>
          getGroupConsumptionHistoryAsync.success({
            customerId,
            history,
          })
        ),
        catchError(error =>
          of(getGroupConsumptionHistoryAsync.failure({ customerId, error }))
        )
      )
    )
  );
