import { Observable } from 'rxjs';
import { PressureThresholdView } from '../features/digidrumi/models/pressure-threshold-view';
import { apiDelete, apiGet, apiPost } from './api';

export function getThresholdsForCustomer(
  customerId: string
): Observable<PressureThresholdView[]> {
  return apiGet<PressureThresholdView[]>(
    'GaasBackend',
    `/threshold/by-customer/${customerId}/list`,
    {}
  );
}

export function createThreshold(
  threshold: PressureThresholdView
): Observable<{}> {
  return apiPost<{}>('GaasBackend', `/threshold/create`, threshold);
}

export function updateThreshold(
  threshold: PressureThresholdView
): Observable<{}> {
  return apiPost<{}>('GaasBackend', `/threshold/update`, threshold);
}

export function deleteThreshold(
  thresholdId: string,
  serialNumber: string
): Observable<{}> {
  return apiDelete<{}>(
    'GaasBackend',
    `/threshold/by-id/${thresholdId}/for-device/${serialNumber}`,
    {}
  );
}
