import { asyncResourceSelector } from '../../store/async-resource';
import { InventoryState } from './reducer';

export const inventory = (state: InventoryState) =>
  asyncResourceSelector(state.inventory);

export const containerStatesByBarcode = (
  state: InventoryState,
  barcode: string
) => asyncResourceSelector(state.container[barcode]);

export const stockContainer = (state: InventoryState) =>
  asyncResourceSelector(state.stockContainer);

// export const containerLoading = (state: InventoryState, containerId: string) =>
//   asyncResourceSelector(state.bottleLoading[containerId]);
export const bottleLoading = (state: InventoryState) => state.bottleLoading;

export const inventoryThresholds = (
  state: InventoryState,
  customerId: string
) => asyncResourceSelector(state.inventoryThresholds[customerId]);

export const inventoryThresholdPassedEvents = (
  state: InventoryState,
  customerId: string
) => asyncResourceSelector(state.inventoryThresholdPassedEvents[customerId]);

export const containerNote = (state: InventoryState, containerId: string) =>
  asyncResourceSelector(state.containerNotes[containerId]);
