import React from 'react';
import styles from './Badge.module.scss';

interface Props {
  count: number;
}

export const Badge: React.FC<Props> = ({ count }) => {
  return (
    <div className={styles['gaas-badge']}>
      <span className={styles['gaas-badge--count']}>
        {count > 9 ? '9+' : count}
      </span>
    </div>
  );
};
