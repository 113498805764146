import classNames from 'classnames';
import React, { useRef } from 'react';
import {
  PinColors,
  pinColorToColorValue,
} from '../features/common/models/pin-colors';
import styles from './ManometerPin.module.scss';
import { PermissionQueryResponse } from '../features/common/models/permission-query-response';

interface Props {
  value: string;
  color: PinColors;
  id: string;
  setCurrentPin: React.Dispatch<
    React.SetStateAction<
      | {
          id: string;
          ref: React.RefObject<SVGGElement>;
        }
      | undefined
    >
  >;
  permission: PermissionQueryResponse;
}

export const ManometerPin: React.FC<Props> = ({
  value,
  color,
  id,
  setCurrentPin,
  permission,
}) => {
  const ref = useRef<SVGGElement>(null);
  const [actionAllowed] = permission;
  return (
    <g
      className={classNames(styles['gaas-manometer-pin'], {
        [styles['gaas-manometer-pin--permission']]: !actionAllowed,
      })}
      data-testid={`pin-${id}`}
      transform={value}
      ref={ref}
      onClick={() => {
        if (!actionAllowed) return;
        setCurrentPin({
          id: id,
          ref: ref,
        });
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124 124">
        <path d="M0 0h124v124H0z" style={{ fill: 'none' }} />
        <path
          // data-testid={`pin-${id}`}
          className={classNames(
            styles[`gaas-manometer-pin--${pinColorToColorValue(color)}`],
            styles['gaas-manometer-pin--head']
          )}
          d="m66 16.12-3.99 3.39L58 16.12V3.51h8v12.61z"
        />
      </svg>

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124 124">
        <path d="M0 0h124v124H0z" style={{ fill: 'none' }} />
        <path
          className={
            styles[`gaas-manometer-pin--${pinColorToColorValue(color)}`]
          }
          d="M61.4 29.02h1.2V30h-1.2zM61.4 27.06h1.2v.98h-1.2zM61.4 25.11h1.2v.98h-1.2zM61.4 23.15h1.2v.98h-1.2zM61.4 21.19h1.2v.98h-1.2zM61.4 19.23h1.2v.98h-1.2zM61.4 17.28h1.2v.98h-1.2zM61.4 15.32h1.2v.98h-1.2zM61.4 13.36h1.2v.98h-1.2zM61.4 11.51h1.2v.87h-1.2z"
        />
      </svg>
    </g>
  );
};
