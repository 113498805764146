import classNames from 'classnames';
import React from 'react';
import {
  PinColors,
  pinColorToColorValue,
} from '../features/common/models/pin-colors';
import styles from './PinColorSelector.module.scss';
import { Check } from './icons';

interface Props {
  color: PinColors;
  selected?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export const PinColorSelector = ({
  color = PinColors.green,
  selected,
  ...props
}: Props) => {
  return (
    <button
      className={classNames(styles['gaas-pin-color-selector'], {
        [styles['gaas-pin-color-selector--selected']]: selected,
      })}
      {...props}
    >
      <div
        className={classNames(
          styles['gaas-pin-color-selector--circle'],
          styles[
            `gaas-pin-color-selector--circle--${pinColorToColorValue(color)}`
          ]
        )}
      >
        {selected && (
          <Check className={styles['gaas-pin-color-selector--circle--icon']} />
        )}
      </div>
    </button>
  );
};
