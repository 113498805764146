import { Observable } from 'rxjs';
import { apiGet } from './api';
import { MaintenanceNote } from '../features/common/models/maintenance-note';
import { map } from 'rxjs/operators';
import { ApiMaintenanceNote } from '../models/openapi/openapiTypes';

export function getMaintenanceNote(): Observable<MaintenanceNote | null> {
  return apiGet<ApiMaintenanceNote>('GaasBackend', `/maintenance-note`).pipe(
    map(note =>
      note === null
        ? note
        : {
            content: note.content,
            validUntil: new Date(Date.parse(note.validUntil)),
            createdAt: new Date(Date.parse(note.createdAt)),
          }
    )
  );
}
