import React from 'react';

export const InventoryLoading: React.FC = () => {
  return (
    <svg viewBox="1 0 59 38">
      <g
        transform="translate(0, 0) scale(0.9)"
        style={{ transformOrigin: 'center' }}
      >
        <path
          d="M17,12.61V38H0V12.61A8.48,8.48,0,0,1,7,4.25V0h3V4.25A8.48,8.48,0,0,1,17,12.61Z"
          fill="#fff"
        />
        <rect
          y="35"
          width="17"
          height="3"
          fill="#002f63"
          transform="scale(1,-1) translate(0,-73)"
        >
          <animate
            attributeName="height"
            from="3"
            to="20"
            values="3; 20; 3;"
            dur="1.2s"
            repeatCount="indefinite"
          />
        </rect>
      </g>

      <g transform="translate(22, 0)">
        <path
          d="M17,12.61V38H0V12.61A8.48,8.48,0,0,1,7,4.25V0h3V4.25A8.48,8.48,0,0,1,17,12.61Z"
          fill="#fff"
        />
        <rect
          y="38"
          width="17"
          height="24"
          fill="#002f63"
          transform="scale(1,-1) translate(0,-76)"
        >
          <animate
            attributeName="height"
            from="24"
            to="6"
            values="24; 6; 24;"
            dur="1.1s"
            repeatCount="indefinite"
          />
        </rect>
      </g>

      <g
        transform="translate(40, 0) scale(0.9)"
        style={{ transformOrigin: 'center' }}
      >
        <path
          d="M17,12.61V38H0V12.61A8.48,8.48,0,0,1,7,4.25V0h3V4.25A8.48,8.48,0,0,1,17,12.61Z"
          fill="#fff"
        />
        <rect
          y="30"
          width="17"
          height="8"
          fill="#002f63"
          transform="scale(1,-1) translate(0,-68)"
        >
          <animate
            attributeName="height"
            from="8"
            to="21"
            values="8; 21; 8;"
            dur="1.4s"
            repeatCount="indefinite"
          />
        </rect>
      </g>
    </svg>
  );
};
