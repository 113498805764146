import React from 'react';
import styles from './MaterialList.module.scss';

interface Props {
  grid: string;
  labels?: string[];
  items?: React.ReactNode;
  children?: React.ReactNode;
}

export const MaterialList: React.FC<Props> = ({ grid, labels, items }) => {
  return (
    <div className={styles['gaas-material-list']} role={'list'}>
      <div
        className={styles['gaas-material-list--labels']}
        style={{ gridTemplateColumns: grid }}
      >
        {labels &&
          labels.map(label => (
            <div
              className={styles['gaas-material-list--labels--label']}
              key={label}
            >
              {label}
            </div>
          ))}
      </div>
      {items && (
        <div className={styles['gaas-material-list--items']}>{items}</div>
      )}
    </div>
  );
};

export const MaterialListItem: React.FC<Props> = ({ grid, children }) => {
  return (
    <div
      className={styles['gaas-material-list-item']}
      style={{ gridTemplateColumns: grid }}
      role={'listitem'}
    >
      {children}
    </div>
  );
};
