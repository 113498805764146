import { Observable } from 'rxjs';
import { apiPost } from './api';

export function fireRay(rayId: string): Observable<{ rayid: string }> {
  return apiPost<{ rayid: string }>(
    'FirewallCheckBackend',
    `/v1/check/connectivity`,
    { rayid: rayId }
  );
}
