import { OutlinedInput } from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';
import { Modal } from '../../../stories/Modal';
import * as groupActions from '../actions';
import * as groupSelectors from '../selectors';

interface ComponentProps {
  isOpen: boolean;
  onClose: () => void;
  customerId: string;
}

const mapStateToProps = (state: RootState) => ({
  groupLoading: (groupId: string) =>
    groupSelectors.groupIsLoading(state.groups, groupId),
});
const dispatchProps = {
  createGroup: groupActions.createGroupAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const CreateGroupModal: React.FC<Props> = ({
  isOpen,
  onClose,
  customerId,
  createGroup,
  groupLoading,
}) => {
  const [groupName, setGroupName] = useState<string>('');
  const [newGroupId, setNewGroupId] = useState<string>('');

  useEffect(() => {
    setNewGroupId('');
  }, [isOpen, setNewGroupId]);

  const { data: newGroupCreated, loading } = groupLoading(newGroupId);

  useEffect(() => {
    if (newGroupId === '') {
      return;
    }
    if (newGroupCreated === true && !loading) {
      onClose();
    }
  }, [newGroupCreated, newGroupId, loading, onClose]);

  return (
    <Modal
      title={'Verbrauchsgruppe erstellen'}
      open={isOpen}
      onClose={onClose}
      onSave={{
        action: () => {
          const id = uuidv4();
          createGroup({
            customerId,
            request: {
              id,
              name: groupName!,
              parentId: undefined,
            },
          });
          setNewGroupId(id);
        },
        label: 'Gruppe anlegen',
        disabled: !groupName,
        loading: loading,
      }}
      maxWidth={500}
    >
      <OutlinedInput
        placeholder={'Name der Gruppe'}
        value={groupName}
        onChange={event => setGroupName(event.currentTarget.value)}
      />
    </Modal>
  );
};

export default connect(mapStateToProps, dispatchProps)(CreateGroupModal);
