import { Select } from '../../../stories/Select';
import React from 'react';
import { ApiCustomer } from '../../../models/openapi/openapiTypes';

interface Props {
  customers: ApiCustomer[];
  selectedCustomer?: ApiCustomer;
  onSelect: (customer: ApiCustomer) => void;
}

const CustomerNumberSelect: React.FC<Props> = ({
  customers,
  selectedCustomer,
  onSelect,
}) => {
  return (
    <Select
      label="Kundenkonto"
      id="customer"
      value={selectedCustomer?.customerId}
      size="l"
      onChange={value =>
        value && onSelect(customers.find(c => c.customerId === value)!)
      }
      options={customers.map(customer => ({
        label: `${customer.customerId} - ${customer.name}`,
        value: customer.customerId,
        additionalContent: 'Hier weitere Infos',
      }))}
    />
  );
};

export default CustomerNumberSelect;
