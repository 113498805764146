import { createAction } from 'typesafe-actions';
import { OverlayMessage, OverlayMessageType } from './models/OverlayMessage';

export const showMessageAction = createAction(
  'SHOW_OVERLAY_MESSAGE',
  (
    type: OverlayMessageType,
    title: string,
    message: string,
    sticky: boolean = false,
    details?: any
  ): OverlayMessage =>
    ({
      // timestamp than title to ensure uniqueness
      // and to make sure that the latest message is always on top
      id: `${Date.now()}-${title}`,
      message,
      title: title,
      type: type,
      createdAt: new Date(),
      sticky,
      details,
    } as const)
)();
