import { Autocomplete, Box, TextField } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ContainerValvePairing,
  PairingType,
} from '../features/digidrumi/models/container-valve-pairing';
import { isFull, isHalfFull } from '../features/inventory/helpers/fill-level';
import { ManualContainerFillLevel } from '../features/inventory/models/container-state';
import { Button } from './Button';
import { LabelAndText } from './LabelAndText';
import styles from './ManometerPairing.module.scss';
import { Modal } from './Modal';
import { Skeleton } from './Skeleton';
import { TextButton } from './TextButton';
import {
  BarcodeScanner,
  Bluetooth,
  BottleEmpty,
  BottleFull,
  BottleOpen,
  Delete,
  Keyboard,
} from './icons';
import { PermissionQueryResponse } from '../features/common/models/permission-query-response';

enum ComponentState {
  unpaired,
  pairingEdit,
  paired,
  deleteConfirmation,
  pairingInProgress,
}

type DropdownOption = {
  barcode: string;
  gasDescription: string;
  fillLevel: number | ManualContainerFillLevel;
};

interface Props {
  customerId: string;
  serialNumber: string;
  currentPairing?: ContainerValvePairing;
  onAutocompleteOpen: () => void;
  autocompleteLoading: boolean;
  dropdownOptions?: DropdownOption[];
  onSelect: React.Dispatch<React.SetStateAction<string | null>>;
  pairingDisabled: boolean;
  onPair: () => void;
  onUnpair: () => void;
  unpairingInProgress: boolean;
  permission: PermissionQueryResponse;
}

export const ManometerPairing: React.FC<Props> = ({
  customerId,
  serialNumber,
  currentPairing,
  onAutocompleteOpen,
  autocompleteLoading,
  dropdownOptions,
  onSelect,
  pairingDisabled,
  onPair,
  onUnpair,
  unpairingInProgress,
  permission,
}) => {
  const [componentState, setComponentState] = useState<ComponentState>(
    currentPairing != null ? ComponentState.paired : ComponentState.unpaired
  );
  const [confirmUnpair, setConfirmUnpair] = useState(false);

  const [actionAllowed] = permission;

  // This effect makes sure that the componentState is updated when
  // the assignment is changed by another client
  useEffect(() => {
    if (
      ![ComponentState.paired, ComponentState.unpaired].includes(componentState)
    ) {
      return;
    }

    if (currentPairing != null) {
      setComponentState(ComponentState.paired);
    } else {
      setComponentState(ComponentState.unpaired);
    }
  }, [setComponentState, componentState, currentPairing]);

  useEffect(() => {
    if (componentState !== ComponentState.pairingInProgress) {
      return;
    }
    if (currentPairing != null) {
      setComponentState(ComponentState.paired);
    }
  }, [setComponentState, componentState, currentPairing]);

  const pairingTypeIcon = useMemo(() => {
    switch (currentPairing?.paringType) {
      case PairingType.barcode:
        return <BarcodeScanner data-testid={'icon:barcode'} />;
      case PairingType.manual:
        return <Keyboard data-testid={'icon:keyboard'} />;
      case PairingType.bluetooth:
        return <Bluetooth data-testid={'icon:bluetooth'} />;
      default:
        return <></>;
    }
  }, [currentPairing?.paringType]);

  const fillLevelToIcon = (fillLevel: number | ManualContainerFillLevel) => {
    if (isFull(fillLevel)) {
      return <BottleFull width={10} />;
    } else if (isHalfFull(fillLevel)) {
      return <BottleOpen width={10} />;
    }
    return <BottleEmpty width={10} />;
  };

  if (componentState === ComponentState.pairingInProgress) {
    return (
      <LabelAndText
        label="Angeschlossene Flasche"
        text={<Skeleton height={27} width={150} />}
      />
    );
  } else if (componentState === ComponentState.paired) {
    return (
      <>
        <LabelAndText
          label="Angeschlossene Flasche"
          text={
            <div className={styles['gaas-manometer-pairing--paired']}>
              <span className={styles['gaas-manometer-pairing--paired--value']}>
                {pairingTypeIcon} &nbsp;
                <Link
                  to={`/inventory/inspect-bottle/${customerId}/${currentPairing?.container.barcode}`}
                  className={styles['gaas-manometer-pairing--paired--barcode']}
                >
                  {currentPairing?.container.barcode}
                </Link>
              </span>

              {actionAllowed && (
                <TextButton
                  LeadingIcon={Delete}
                  title={'unpair'}
                  onClick={() => {
                    setConfirmUnpair(true);
                  }}
                  disabled={unpairingInProgress}
                />
              )}
            </div>
          }
        />

        {confirmUnpair && (
          <Modal
            title="Entkoppeln bestätigen"
            open={confirmUnpair}
            onClose={() => setConfirmUnpair(false)}
            maxWidth={400}
          >
            <p>Flasche jetzt entkoppeln?</p>
            <div className={styles['gaas-manometer-pairing--buttons']}>
              <Button
                label="Abbrechen"
                onClick={() => {
                  setConfirmUnpair(false);
                }}
              />
              <Button
                label="Ok"
                variant="outlined"
                onClick={() => {
                  onUnpair();
                  setConfirmUnpair(false);
                }}
              />
            </div>
          </Modal>
        )}
      </>
    );
  } else if (componentState === ComponentState.pairingEdit) {
    return (
      <div>
        <LabelAndText label="Angeschlossene Flasche" />
        <Autocomplete
          // value={gasBottleBarcode != null ? ({barcode: gasBottleBarcode, gasType: gas}): undefined}
          onOpen={onAutocompleteOpen}
          isOptionEqualToValue={(option, value) =>
            option.barcode === value.barcode
          }
          loading={autocompleteLoading}
          getOptionLabel={(item: DropdownOption) => item.barcode}
          groupBy={(item: DropdownOption) =>
            item!.gasDescription!.length === 0
              ? 'Unbekannt'
              : item.gasDescription
          }
          renderGroup={option => (
            <Box
              component="div"
              sx={{ fontFamily: 'WestfalenNewsSans' }}
              key={option.key}
            >
              <h4 style={{ margin: ' 12px 12px 12px 1rem' }}>{option.group}</h4>
              {option.children}
            </Box>
          )}
          renderOption={(props, option: DropdownOption) => (
            // shamelessly stolen from https://mui.com/material-ui/react-autocomplete/#country-select
            <Box
              component="li"
              sx={{
                '& > img': { mr: 2, flexShrink: 0 },
                fontFamily: 'WestfalenNewsSans',
              }}
              {...props}
            >
              {fillLevelToIcon(option.fillLevel)} &nbsp; {option.barcode}
            </Box>
          )}
          onChange={(event, value, reason) => {
            if (reason === 'selectOption' && value != null) {
              onSelect(value.barcode);
            }
          }}
          renderInput={params => (
            <TextField
              {...params}
              placeholder={
                currentPairing?.container.barcode ?? 'Barcode eingeben'
              }
              fullWidth
              InputProps={{
                ...params.InputProps,
                // startAdornment: pairingTypeIcon,
                sx: {
                  borderRadius: 0,
                  fontFamily: 'WestfalenNewsSans',
                },
              }}
            />
          )}
          options={dropdownOptions ?? []}
        />
        <div className={styles['gaas-manometer-pairing--buttons']}>
          <Button
            label="Abbrechen"
            onClick={() => setComponentState(ComponentState.unpaired)}
          />
          <Button
            label="Speichern"
            fullWidth
            onClick={() => {
              onPair();
              setComponentState(ComponentState.pairingInProgress);
            }}
            disabled={pairingDisabled}
          />
        </div>
      </div>
    );
  } else
    return (
      <div>
        <LabelAndText label="Angeschlossene Flasche" />
        <div className={styles['gaas-manometer-pairing--buttons']}>
          <Button
            label="Eingeben"
            fullWidth
            onClick={() => {
              setComponentState(ComponentState.pairingEdit);
            }}
            LeadingIcon={Keyboard}
            permission={permission}
          />
          {permission[0] === 'GRANTED' ? (
            <Link
              to={`/digidrumi/pair-container/${customerId}/${serialNumber}`}
            >
              <Button label="Scannen" fullWidth LeadingIcon={BarcodeScanner} />
            </Link>
          ) : (
            <Button
              label="Scannen"
              fullWidth
              LeadingIcon={BarcodeScanner}
              permission={permission}
            />
          )}
        </div>
      </div>
    );
};
