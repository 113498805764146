import React from 'react';
import styles from './ShopOrderConfirmationPage.module.scss';

interface Props {}

export const ShopOrderConfirmationPage: React.FC<Props> = () => {
  return (
    <div className={styles['gaas-shopConfirm--background']}>
      <div className={styles['gaas-shopConfirm']}>
        <h1>Vielen Dank für Ihre Bestellung</h1>
        <p>
          Ihre Bestellung ist bei uns eingegangen und wird nun bearbeitet. Sie
          erhalten in Kürze eine E-Mail mit Informationen bezüglich der
          Einrichtung Ihres neuen GaaS-Accounts. Bitte überprüfen Sie auch Ihren
          Spam-Ordner.
        </p>
        <p>
          Sollten Sie in der Zwischenzeit Fragen zu Ihrer Bestellung haben,
          melden Sie sich gerne über{' '}
          <a href="mailto:support@mail.gaas.westfalen.com">
            support@mail.gaas.westfalen.com
          </a>
          .
        </p>
      </div>
    </div>
  );
};
