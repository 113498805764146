import classNames from 'classnames';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button } from './Button';
import styles from './Modal.module.scss';
import { Close } from './icons';

interface Props {
  title: string;
  children: ReactNode;
  open: boolean;
  onClose: () => void;
  noClose?: boolean;
  onSave?: {
    label?: string;
    disabled?: boolean;
    loading?: boolean;
    action: () => void;
  };
  onDelete?: {
    label?: string;
    disabled?: boolean;
    loading?: boolean;
    action: () => void;
  };
  maxWidth?: number;
  fullWidth?: boolean;
  fullWidthOnMobile?: boolean;
  fullWidthOnTablet?: boolean;
}

export const Modal = ({
  title,
  children,
  open,
  onClose,
  noClose,
  onSave,
  onDelete,
  maxWidth,
  fullWidth,
  fullWidthOnMobile = false,
  fullWidthOnTablet = false,
}: Props) => {
  const [visible, setVisible] = useState(open);
  const [transitioning, setTransitioning] = useState(false);

  const backgroundRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (visible) {
      document.body.style.overflowY = 'hidden';
    }
  }, [visible]);

  const closeModal = useCallback(() => {
    setTransitioning(true);

    setTimeout(() => {
      document.body.style.overflowY = '';
      onClose();
      setVisible(false);
      setTransitioning(false);
    }, 190);
  }, [onClose, setVisible]);

  useEffect(() => {
    if (visible && !open) {
      // if the modal is closed by setting "open" to false
      // we need to run the close hook
      closeModal();
    } else {
      setVisible(open);
    }
  }, [visible, open, closeModal, noClose]);

  useEffect(() => {
    // when navigating away from a modal
    // e.g. when calling router.push in a modal
    // we need to reset the body styles
    // because the visible state is not updated
    // and the useEffect hook is not called
    return () => {
      document.body.style.overflowY = '';
    };
  }, []);

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (noClose) return;
      if (e.target === backgroundRef.current) {
        closeModal();
      }
    },
    [closeModal, noClose]
  );

  useEffect(() => {
    document.addEventListener('pointerdown', handleClick);

    return () => {
      document.removeEventListener('pointerdown', handleClick);
    };
  }, [handleClick]);

  return (
    <div
      ref={backgroundRef}
      className={classNames(styles['gaas-modal'], {
        [styles['gaas-modal--visible']]: visible,
        [styles['gaas-modal--transitioning']]: transitioning,
      })}
    >
      <div
        className={classNames(styles['gaas-modal--container'], {
          [styles['gaas-modal--container--fullwidth']]: fullWidth,
          [styles['gaas-modal--container--mobile']]: fullWidthOnMobile,
          [styles['gaas-modal--container--tablet']]: fullWidthOnTablet,
        })}
        style={{ maxWidth: `${maxWidth}px` }}
        role={'dialog'}
      >
        <div className={styles['gaas-modal--header']}>
          <h2 className={styles['gaas-modal--header--title']}>{title}</h2>
          {!noClose && (
            <button
              className={styles['gaas-modal--header--close']}
              onClick={closeModal}
            >
              <Close />
            </button>
          )}
        </div>
        <div className={styles['gaas-modal--content']}>{children}</div>
        {(!!onSave || !!onDelete) && (
          <div className={styles['gaas-modal--buttons']}>
            <div className={styles['gaas-modal--buttons--grid']} dir="rtl">
              {!!onSave && (
                <Button
                  label={onSave.label ? onSave.label : 'Speichern'}
                  disabled={onSave.disabled}
                  onClick={onSave.action}
                  loading={onSave.loading}
                  variant="primary"
                />
              )}
              {!!onDelete ? (
                <Button
                  label={onDelete.label ? onDelete.label : 'Löschen'}
                  disabled={onDelete.disabled}
                  onClick={onDelete.action}
                  variant="outlined"
                  loading={onDelete.loading}
                />
              ) : (
                !noClose && (
                  <Button
                    label="Abbrechen"
                    onClick={closeModal}
                    variant="outlined"
                  />
                )
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
