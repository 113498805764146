import React from 'react';
import styles from './InfoBox.module.scss';
import { InfoFilled } from './icons';

interface Props {
  children: React.ReactNode;
}

export const InfoBox: React.FC<Props> = ({ children }) => {
  return (
    <div className={styles['gaas-infoBox']}>
      <div className={styles['gaas-infoBox--icon']}>
        <InfoFilled />
      </div>
      {children}
    </div>
  );
};
