import React from 'react';
import { useCameraStream } from './CameraStreamProvider';
import styles from './BarcodeScannerV2.module.scss';
import { CameraSwitch, FlashlightOff, FlashlightOn } from '../../stories/icons';

export const BarcodeScannerV2Controls: React.FC = () => {
  const { changeCamera, toggleTorch, torchOn } = useCameraStream();

  return (
    <div className={styles['gaas-barcode-scanner--buttons']}>
      {toggleTorch ? (
        <button
          className={styles['gaas-barcode-scanner--buttons--button']}
          onClick={toggleTorch ? () => toggleTorch() : undefined}
        >
          {torchOn ? <FlashlightOn /> : <FlashlightOff />}
        </button>
      ) : (
        <div></div>
      )}
      {changeCamera && (
        <button
          className={styles['gaas-barcode-scanner--buttons--button']}
          onClick={changeCamera}
        >
          <CameraSwitch />
        </button>
      )}
    </div>
  );
};
