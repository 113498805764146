import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import React, { ReactElement, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ShopAuth } from '../../../util/shopAuth';
import * as shopActions from '../actions';
import * as shopSelectors from '../selectors';
import ShopFullscreenLoading from '../components/ShopFullscreenLoading';
import ShopTokenExpiredModal from '../components/ShopTokenExpiredModal';

const mapStateToProps = (state: RootState) => ({
  shopUser: shopSelectors.shopUser(state.shop),
  shopUserError: shopSelectors.shopUserError(state.shop),
});

const dispatchProps = {
  signInShopUser: shopActions.signInShopUser,
};

interface ComponentProps {
  children: ReactElement | null;
}

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ShopUserGuard: React.FC<Props> = ({
  shopUser,
  shopUserError,
  signInShopUser,
  children,
}) => {
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const queryToken = query.get('jwt_token');

  useEffect(() => {
    if (queryToken) {
      signInShopUser({ jwtToken: queryToken });
      history.push({
        ...location,
        search: undefined,
      });
      return;
    }

    const storedToken = ShopAuth.getStoredToken();
    if (storedToken) {
      signInShopUser({ jwtToken: storedToken });
      return;
    }

    // we received no token with the request and there was no stored token,
    // so we redirect to the login page
    history.push('/');
  }, [history, location, queryToken, signInShopUser]);

  if (shopUserError) {
    return <ShopTokenExpiredModal />;
  }

  return shopUser !== undefined && shopUserError === undefined ? (
    children
  ) : (
    <ShopFullscreenLoading />
  );
};

export default connect(mapStateToProps, dispatchProps)(ShopUserGuard);
