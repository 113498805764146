import styles from './ShopWizard.module.scss';
import { InventoryLoading } from '../../../stories/InventoryLoading';

const ShopFullscreenLoading: React.FC = () => {
  return (
    <div className={styles['gaas-shopPage--loading']}>
      <h1>Gas as a Service® – Shop</h1>
      <div className={styles['gaas-shopPage--loading--icon']}>
        <InventoryLoading />
      </div>
    </div>
  );
};

export default ShopFullscreenLoading;
