import { Epic } from 'redux-observable';
import { EMPTY, from, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { RootAction, RootState, Services, isActionOf } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';
import {
  WsFetchValveUpdatesMessageSchema,
  WsStreamingValveUpdate,
} from '../../models/zod/WsSchema';
import { selectedCustomerChanged } from '../common/actions';
import { webSocketConnected } from '../websockets/actions';
import {
  createThresholdAsync,
  deleteThresholdAsync,
  getCustomerAssignmentForValveAsync,
  getThresholdAsync,
  getValveHistoryAsync,
  getValveHistoryExportAsync,
  getValvesAsync,
  pairContainerToValveAsync,
  unpairContainerFromValveAsync,
  updateThresholdAsync,
  updateValveLocationAsync,
} from './actions';

export const getValves: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state,
  { websocket }
) =>
  action$.pipe(
    filter(isActionOf(getValvesAsync.request)),
    map(
      ({ payload }): WsFetchValveUpdatesMessageSchema => ({
        action: 'fetch-valve-updates',
        messageId: uuidv4(),
        message: {
          customerId: payload,
        },
      })
    ),
    withLatestFrom(state),
    switchMap(([payload, state]) => {
      const { websocket: ws } = state.websocket;
      if (!ws) {
        return EMPTY;
      }
      const { customerId } = payload.message;
      return websocket
        .sendWebSocketMessageAndAwaitResponse<
          typeof WsStreamingValveUpdate,
          WsFetchValveUpdatesMessageSchema
        >(ws, payload, WsStreamingValveUpdate)
        .pipe(map(payload => ({ payload, customerId })));
    }),
    map(({ payload, customerId }) => {
      return getValvesAsync.success({
        customerId,
        valves: payload.response.updates,
      });
    }),
    catchError(error => of(getValvesAsync.failure(error)))
  );
export const startListeningForMeterUpdates: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state, { websocket }) =>
  action$.pipe(
    filter(
      action =>
        isActionOf(selectedCustomerChanged, action) ||
        isActionOf(webSocketConnected, action)
    ),
    mergeMap(action => {
      if (isActionOf(selectedCustomerChanged, action)) {
        return of(
          (action as ReturnType<typeof selectedCustomerChanged>).payload
            ?.customerId
        ).pipe(distinctUntilChanged());
      } else {
        return of(action as ReturnType<typeof webSocketConnected>).pipe(
          withLatestFrom(state),
          map(([_, state]) => state.common.selectedCustomer?.customerId)
        );
      }
    }),
    filter(
      (customerId): customerId is string => typeof customerId === 'string'
    ),
    withLatestFrom(state),
    switchMap(([customerId, state]) => {
      const { websocket: ws } = state.websocket;
      if (!ws) {
        return EMPTY;
      }
      return websocket
        .listenForWebSocketMessages<typeof WsStreamingValveUpdate>(
          ws,
          WsStreamingValveUpdate
        )
        .pipe(map(payload => ({ payload, customerId })));
    }),
    map(({ payload, customerId }) =>
      getValvesAsync.success({ customerId, valves: payload.updates })
    )
  );

export const getValveHistory: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { valve }) =>
  action$.pipe(
    filter(isActionOf(getValveHistoryAsync.request)),
    switchMap(({ payload: { serialNumber, bucket, deviceId } }) =>
      valve.getValveHistory(serialNumber, bucket).pipe(
        map(items =>
          getValveHistoryAsync.success({
            items,
            bucket,
            serialNumber,
            deviceId,
          })
        ),
        catchError(error =>
          of(
            getValveHistoryAsync.failure({
              error,
              bucket,
              serialNumber,
              deviceId,
            })
          )
        )
      )
    )
  );

export const getThresholds: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { threshold }) =>
  action$.pipe(
    filter(isActionOf(getThresholdAsync.request)),
    switchMap(({ payload: customerId }) =>
      threshold.getThresholdsForCustomer(customerId).pipe(
        map(thresholds => getThresholdAsync.success(thresholds)),
        catchError(error => of(getThresholdAsync.failure(error)))
      )
    )
  );

export const getValveHistoryExport: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { valve }) =>
  action$.pipe(
    filter(isActionOf(getValveHistoryExportAsync.request)),
    switchMap(({ payload: { serialNumber, bucket, deviceId } }) =>
      valve.getValveHistoryExport(serialNumber, bucket).pipe(
        map(items =>
          getValveHistoryExportAsync.success({
            data: items.csv,
            bucket,
            serialNumber,
            deviceId,
          })
        ),
        catchError(error =>
          of(
            getValveHistoryExportAsync.failure({
              error,
              bucket,
              serialNumber,
              deviceId,
            })
          )
        )
      )
    )
  );

export const updateValveLocation: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { valve }) =>
  action$.pipe(
    filter(isActionOf(updateValveLocationAsync.request)),
    switchMap(({ payload: { serialNumber, location, oldDeviceName } }) =>
      valve.updateLocation(serialNumber, location).pipe(
        map(() => updateValveLocationAsync.success({})),
        catchError(error =>
          of(
            updateValveLocationAsync.failure({
              error,
              serialNumber,
              oldDeviceName: oldDeviceName,
            })
          )
        )
      )
    )
  );

export const createThreshold: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { threshold }) =>
  action$.pipe(
    filter(isActionOf(createThresholdAsync.request)),
    switchMap(({ payload: { threshold: thresholdData, customerId } }) =>
      threshold.createThreshold(thresholdData).pipe(
        map(() => ({
          action: createThresholdAsync.success({}),
          customerId,
        })),
        catchError(error =>
          of({ action: createThresholdAsync.failure(error), customerId })
        )
      )
    ),
    switchMap(({ action, customerId }) =>
      from([action, getThresholdAsync.request(customerId)])
    )
  );

export const updateThreshold: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { threshold }) =>
  action$.pipe(
    filter(isActionOf(updateThresholdAsync.request)),
    switchMap(({ payload: { threshold: thresholdData, customerId } }) =>
      threshold.updateThreshold(thresholdData).pipe(
        map(() => ({
          action: updateThresholdAsync.success({}),
          customerId,
        })),
        catchError(error =>
          of({ action: updateThresholdAsync.failure(error), customerId })
        )
      )
    ),
    switchMap(({ action, customerId }) =>
      from([action, getThresholdAsync.request(customerId)])
    )
  );

export const deleteThreshold: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { threshold }) =>
  action$.pipe(
    filter(isActionOf(deleteThresholdAsync.request)),
    switchMap(({ payload: { serialNumber, thresholdId, customerId } }) =>
      threshold.deleteThreshold(thresholdId, serialNumber).pipe(
        map(() => ({
          action: deleteThresholdAsync.success({}),
          customerId,
        })),
        catchError(error =>
          of({ action: deleteThresholdAsync.failure(error), customerId })
        )
      )
    ),
    switchMap(({ action, customerId }) =>
      from([action, getThresholdAsync.request(customerId)])
    )
  );

export const pairContainerToValve: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state, { valve }) =>
  action$.pipe(
    filter(isActionOf(pairContainerToValveAsync.request)),
    switchMap(({ payload: { serialNumber, barcode, pairingType } }) =>
      valve.pairContainerToValve(serialNumber, barcode, pairingType).pipe(
        map(pairing =>
          pairContainerToValveAsync.success({ pairing, serialNumber })
        ),
        catchError(error =>
          of(pairContainerToValveAsync.failure({ error, serialNumber }))
        )
      )
    )
  );

export const unpairContainerFromValve: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state, { valve }) =>
  action$.pipe(
    filter(isActionOf(unpairContainerFromValveAsync.request)),
    switchMap(({ payload: { serialNumber } }) =>
      valve.unpairContainerFromValve(serialNumber).pipe(
        map(() => unpairContainerFromValveAsync.success({ serialNumber })),
        catchError(error =>
          of(unpairContainerFromValveAsync.failure({ error, serialNumber }))
        )
      )
    )
  );

export const getAssignmentForValve: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state, { valve }) =>
  action$.pipe(
    filter(isActionOf(getCustomerAssignmentForValveAsync.request)),
    switchMap(({ payload: { serialNumber } }) =>
      valve.getValveAssignmentResponse(serialNumber).pipe(
        map(response =>
          getCustomerAssignmentForValveAsync.success({
            serialNumber,
            customerId: response.customerId,
            deviceId: response.deviceId,
          })
        ),
        catchError(error =>
          of(
            getCustomerAssignmentForValveAsync.failure({
              error,
              serialNumber,
            })
          )
        )
      )
    )
  );
