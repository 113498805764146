import classNames from 'classnames';
import React, { AriaRole, useState } from 'react';
import { Badge } from './Badge';
import styles from './TextButton.module.scss';
import { PlansModal } from './PlansModal';
import { PlansChip } from './PlansChip';
import { PermissionQueryResponse } from '../features/common/models/permission-query-response';

interface Props {
  label?: string;
  size?: 's' | 'm' | 'l';
  disabled?: boolean;
  onClick?: () => void;
  active?: boolean;
  LeadingIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  TrailingIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  badgeCount?: number;
  buttonRef?: React.ForwardedRef<HTMLButtonElement>;
  permission?: PermissionQueryResponse;
  title?: string;
  role?: AriaRole;
  inline?: boolean;
}

export const TextButton = ({
  size = 'm',
  label,
  LeadingIcon,
  TrailingIcon,
  active,
  badgeCount,
  buttonRef,
  title,
  permission,
  role,
  inline,
  ...props
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);

  const Content = () => (
    <>
      {LeadingIcon &&
        (badgeCount && badgeCount > 0 ? (
          <div className={styles['gaas-text-button--badge']}>
            <LeadingIcon className={styles['gaas-text-button--icon']} />
            <Badge count={badgeCount} />
          </div>
        ) : (
          <LeadingIcon className={styles['gaas-text-button--icon']} />
        ))}
      {label && label}
      {TrailingIcon && (
        <TrailingIcon className={styles['gaas-text-button--icon']} />
      )}
    </>
  );

  if (permission && permission?.[0] !== 'GRANTED') {
    return (
      <>
        <button
          type="button"
          role={role}
          className={classNames(
            styles['gaas-text-button'],
            styles[`gaas-text-button--${size}`],
            styles['gaas-text-button--permission'],
            {
              [styles[`gaas-text-button--active`]]: active,
              [styles[`gaas-text-button--inline`]]: inline,
            }
          )}
          ref={buttonRef}
          title={title}
          onClick={() => setModalOpen(true)}
        >
          <PlansChip permission={permission} top={-16} right={-16} />
          <Content />
        </button>

        <PlansModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          permission={permission}
        />
      </>
    );
  } else
    return (
      <button
        type="button"
        role={role}
        className={classNames(
          styles['gaas-text-button'],
          styles[`gaas-text-button--${size}`],
          {
            [styles[`gaas-text-button--active`]]: active,
            [styles[`gaas-text-button--inline`]]: inline,
          }
        )}
        ref={buttonRef}
        title={title}
        {...props}
      >
        <Content />
      </button>
    );
};
