export enum PinColors {
  orange = 'COLOR1',
  green = 'COLOR2',
  red = 'COLOR3',
  yellow = 'COLOR4',
  grey = 'COLOR5',
}

export function pinColorToColorValue(color: PinColors) {
  switch (color) {
    case PinColors.green:
      return 'green';
    case PinColors.red:
      return 'red';
    case PinColors.yellow:
      return 'yellow';
    case PinColors.orange:
      return 'orange';
    case PinColors.grey:
      return 'grey';
  }
}
