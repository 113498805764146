import React, { AriaRole, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useTimer } from 'react-timer-hook';
import { RootState } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';
import { MaterialCard } from '../../../stories/MaterialCard';
import { shopUrl } from '../../../util/shopHelper';
import * as inventoryActions from '../actions';
import {
  isEmptyContainer,
  isFullContainer,
  isHalfFullContainer,
} from '../helpers/fill-level';
import { ContainerStateView } from '../models/inventory';
import { InventoryThresholdPassedEventView } from '../models/inventory-threshold-passed-event-view';
import { InventoryThresholdView } from '../models/inventory-threshold-view';
import { Order } from '../models/order';
import * as inventorySelectors from './../selectors';
import { ApiGaasAction } from '../../../models/openapi/openapiTypes';
import * as commonSelectors from '../../common/selectors';

interface ComponentProps {
  containerType: string;
  materialId: string;
  materialDescription: string;
  containers: ContainerStateView[];
  orders: Order[];
  customerId: string;
  role?: AriaRole;
}

const mapStateToProps = (state: RootState) => ({
  inventoryThresholds: (customerId: string) =>
    inventorySelectors.inventoryThresholds(state.inventory, customerId),

  inventoryThresholdPassedEvents: (customerId: string) =>
    inventorySelectors.inventoryThresholdPassedEvents(
      state.inventory,
      customerId
    ),
  hasPermission: (action: ApiGaasAction) =>
    commonSelectors.hasPermission(action, state.common),
});

const dispatchProps = {
  createInventoryThreshold:
    inventoryActions.createInventoryThresholdAsync.request,
  deleteInventoryThreshold:
    inventoryActions.deleteInventoryThresholdAsync.request,
  updateInventoryThreshold:
    inventoryActions.updateInventoryThresholdAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const Material: React.FC<Props> = ({
  containerType,
  materialId,
  materialDescription,
  containers,
  orders,
  customerId,
  createInventoryThreshold,
  inventoryThresholds,
  deleteInventoryThreshold,
  updateInventoryThreshold,
  inventoryThresholdPassedEvents,
  role,
  hasPermission,
}) => {
  const inventoryThresholdsForCustomer = inventoryThresholds(customerId);
  const inventoryThresholdPassedEventsForCustomer =
    inventoryThresholdPassedEvents(customerId);

  const thresholdsLoading = inventoryThresholdsForCustomer.loading;
  const thresholdPassedEventsLoading =
    inventoryThresholdPassedEventsForCustomer.loading;

  const thresholdsForMaterial =
    inventoryThresholdsForCustomer?.data?.filter(
      (it: InventoryThresholdView) => it.materialId === materialId
    ) ?? [];

  const passedThresholdsForMaterial = thresholdsForMaterial
    .map(threshold => ({
      threshold,
      alert: (inventoryThresholdPassedEventsForCustomer.data ?? []).find(
        event => threshold.id === event.thresholdId
      ),
    }))
    .filter(it => it.alert != null) as [
    {
      threshold: InventoryThresholdView;
      alert: InventoryThresholdPassedEventView;
    }
  ];
  passedThresholdsForMaterial.sort(
    (a, b) => b.alert.start.getTime() - a.alert.start.getTime()
  );

  //Glow on Route match
  interface RouteParams {
    customer: string;
    material: string;
  }
  let { material: routeMaterialId } = useParams<RouteParams>();
  // check if there is additional state
  // this timestamp allows us to trigger the fade effect again,
  // even if the route does not change
  const { state } = useLocation() as {
    state: { notificationAccessedAt: number };
  };
  const notificationAccessedAt = state?.notificationAccessedAt;
  const [focused, setFocused] = useState(false);
  const { restart: restartFocusTimer } = useTimer({
    autoStart: false,
    expiryTimestamp: new Date(),
    onExpire: () => {
      setFocused(false);
    },
  });

  useEffect(() => {
    if (materialId === routeMaterialId) {
      setFocused(true);
      restartFocusTimer(new Date(Date.now() + 2000), true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialId, routeMaterialId, notificationAccessedAt]);

  const materialShopLink = useMemo(() => shopUrl(materialId), [materialId]);

  return (
    <MaterialCard
      focused={focused}
      customerId={customerId}
      containerType={containerType}
      materialDescription={materialDescription}
      materialId={materialId}
      materialShopLink={materialShopLink}
      bottles={{
        full: containers.filter(b => isFullContainer(b)).length,
        open: containers.filter(b => isHalfFullContainer(b)).length,
        empty: containers.filter(b => isEmptyContainer(b)).length,
        ordered: orders.reduce((sum, o) => sum + o.quantity, 0),
      }}
      containers={containers}
      thresholds={thresholdsForMaterial}
      thresholdsLoading={thresholdsLoading}
      thresholdsPassed={passedThresholdsForMaterial}
      thresholdPassedEventsLoading={thresholdPassedEventsLoading}
      addPin={pin => {
        createInventoryThreshold({
          materialId,
          id: uuidv4(),
          minFullBottles: pin.pressureBar,
          color: pin.color,
          name: pin.name,
          customerId: customerId,
          emailRecipients: pin.emailRecipients,
          portalNotification: pin.portalNotification,
        });
      }}
      editPin={(pin, updatedPin) => {
        updateInventoryThreshold({
          customerId,
          threshold: {
            id: pin.id,
            customerId: pin.customerId,
            materialId: pin.materialId,
            emailRecipients: updatedPin.emailRecipients,
            minFullBottles: updatedPin.pressureBar,
            name: updatedPin.name,
            color: updatedPin.color,
            portalNotification: updatedPin.portalNotification,
          },
        });
      }}
      deletePin={pin => deleteInventoryThreshold(pin)}
      role={role}
      permissionReadStatistics={hasPermission(
        'inventory.material.history.read'
      )}
      permissionWriteAlarm={hasPermission('threshold.inventory.write')}
    />
  );
};

export default connect(mapStateToProps, dispatchProps)(Material);
