import { createAction, createAsyncAction } from 'typesafe-actions';
import { PairValveToCustomerRequest } from './models/pair-valve-to-customer-request';
import { PaginatedEntityList } from '../common/models/paginated-entity-list';
import {
  ValveCustomerAssignment,
  ValveWithCurrentAssignment,
} from './models/valves-with-assignment';
import {
  ListingParameters,
  SortingParameters,
} from '../common/models/listing-parameters';
import { UnpairValveFromCustomerRequest } from './models/unpair-valve-from-customer-request';
import { DynamoDbKey } from './models/dynamo-db-key';
import {
  AdminListValveEventsResponse,
  SuspiciousValueDiagnosticEvent,
  ThresholdPassedEventAdminView,
  ValveOfflineDiagnosticEvent,
} from './models/admin-list-valve-events-response';
import {
  GatewayAssignment,
  GatewayWithCurrentAssignment,
} from './models/gateway-with-assignment';
import { UnpairGatewayFromCustomerRequest } from './models/unpair-gateway-from-customer-request';
import { PairGatewayToCustomerRequest } from './models/pair-gateway-to-customer-request';
import { ApiMaintenanceNoteRequest } from '../../models/openapi/openapiTypes';

export const pairValveToCustomerAsync = createAsyncAction(
  'PAIR_VALVE_TO_CUSTOMER_REQUEST',
  'PAIR_VALVE_TO_CUSTOMER_SUCCESS',
  'PAIR_VALVE_TO_CUSTOMER_FAILURE'
)<PairValveToCustomerRequest, {}, Error>();

export const unpairValveFromCustomerAsync = createAsyncAction(
  'UNPAIR_VALVE_FROM_CUSTOMER_REQUEST',
  'UNPAIR_VALVE_FROM_CUSTOMER_SUCCESS',
  'UNPAIR_VALVE_FROM_CUSTOMER_FAILURE'
)<UnpairValveFromCustomerRequest, {}, Error>();

export const pairGatewayToCustomerAsync = createAsyncAction(
  'PAIR_GATEWAY_TO_CUSTOMER_REQUEST',
  'PAIR_GATEWAY_TO_CUSTOMER_SUCCESS',
  'PAIR_GATEWAY_TO_CUSTOMER_FAILURE'
)<PairGatewayToCustomerRequest, {}, Error>();

export const unpairGatewayFromCustomerAsync = createAsyncAction(
  'UNPAIR_GATEWAY_FROM_CUSTOMER_REQUEST',
  'UNPAIR_GATEWAY_FROM_CUSTOMER_SUCCESS',
  'UNPAIR_GATEWAY_FROM_CUSTOMER_FAILURE'
)<UnpairGatewayFromCustomerRequest, {}, Error>();

export const listGatewaysAsync = createAsyncAction(
  'LIST_GATEWAYS_REQUEST',
  'LIST_GATEWAYS_SUCCESS',
  'LIST_GATEWAYS_FAILURE'
)<
  SortingParameters,
  {
    result: PaginatedEntityList<GatewayWithCurrentAssignment>;
    params: SortingParameters;
  },
  { error: Error; params: SortingParameters }
>();

export const getGatewayAsync = createAsyncAction(
  'GET_GATEWAY_ADMIN_REQUEST',
  'GET_GATEWAY_ADMIN_SUCCESS',
  'GET_GATEWAY_ADMIN_FAILURE'
)<string, GatewayWithCurrentAssignment, { id: string; error: Error }>();

export const getGatewayAssignmentsAsync = createAsyncAction(
  'GET_GATEWAY_ASSIGNMENTS_ADMIN_REQUEST',
  'GET_GATEWAY_ASSIGNMENTS_ADMIN_SUCCESS',
  'GET_GATEWAY_ASSIGNMENTS_ADMIN_FAILURE'
)<
  { id: string; params: ListingParameters },
  {
    id: string;
    assignments: PaginatedEntityList<GatewayAssignment>;
    params: ListingParameters;
  },
  { id: string; error: Error; params: ListingParameters }
>();

export const updateGatewayNoteAsync = createAsyncAction(
  'UPDATE_GATEWAY_NOTE_REQUEST',
  'UPDATE_GATEWAY_NOTE_SUCCESS',
  'UPDATE_GATEWAY_NOTE_FAILURE'
)<
  { gatewayId: string; note: string | null },
  { gatewayId: string },
  { id: string; error: Error }
>();

export const listValvesAsync = createAsyncAction(
  'LIST_VALVES_REQUEST',
  'LIST_VALVES_SUCCESS',
  'LIST_VALVES_FAILURE'
)<
  SortingParameters,
  {
    result: PaginatedEntityList<ValveWithCurrentAssignment>;
    params: SortingParameters;
  },
  { error: Error; params: SortingParameters }
>();

export const getValveAsync = createAsyncAction(
  'GET_VALVE_ADMIN_REQUEST',
  'GET_VALVE_ADMIN_SUCCESS',
  'GET_VALVE_ADMIN_FAILURE'
)<string, ValveWithCurrentAssignment, { id: string; error: Error }>();

export const getValveAssignmentsAsync = createAsyncAction(
  'GET_VALVE_ASSIGNMENTS_ADMIN_REQUEST',
  'GET_VALVE_ASSIGNMENTS_ADMIN_SUCCESS',
  'GET_VALVE_ASSIGNMENTS_ADMIN_FAILURE'
)<
  { id: string; params: ListingParameters },
  {
    id: string;
    assignments: PaginatedEntityList<ValveCustomerAssignment>;
    params: ListingParameters;
  },
  { id: string; error: Error; params: ListingParameters }
>();

export const clearValveAssignmentCacheSync = createAction(
  'CLEAR_ASSIGNMENT_CACHE'
)<{}>();

export const clearGatewayAssignmentCacheSync = createAction(
  'CLEAR_GATEWAY_ASSIGNMENT_CACHE'
)<{}>();

export const updateValveNoteAsync = createAsyncAction(
  'UPDATE_VALVE_NOTE_REQUEST',
  'UPDATE_VALVE_NOTE_SUCCESS',
  'UPDATE_VALVE_NOTE_FAILURE'
)<
  { valveId: string; note: string | null },
  { valveId: string },
  { id: string; error: Error }
>();

export const editMaintenanceNoteAsync = createAsyncAction(
  'UPDATE_MAINTENANCE_NOTE_REQUEST',
  'UPDATE_MAINTENANCE_NOTE_SUCCESS',
  'UPDATE_MAINTENANCE_NOTE_FAILURE'
)<ApiMaintenanceNoteRequest, undefined, Error>();

export const fetchNextValveOfflineEventsAsync = createAsyncAction(
  'FETCH_NEXT_VALVE_OFFLINE_EVENTS_REQUEST',
  'FETCH_NEXT_VALVE_OFFLINE_EVENTS_SUCCESS',
  'FETCH_NEXT_VALVE_OFFLINE_EVENTS_FAILURE'
)<
  { valveId: string; lastKey: DynamoDbKey | null },
  {
    response: AdminListValveEventsResponse<ValveOfflineDiagnosticEvent>;
    valveId: string;
  },
  { id: string; error: Error }
>();

export const clearValveOfflineEvents = createAction(
  'CLEAR_VALVE_OFFLINE_EVENTS'
)<{ valveId: string }>();

export const fetchNextValveSuspiciousValueEventsAsync = createAsyncAction(
  'FETCH_NEXT_VALVE_SUS_VALUE_EVENTS_REQUEST',
  'FETCH_NEXT_VALVE_SUS_VALUE_EVENTS_SUCCESS',
  'FETCH_NEXT_VALVE_SUS_VALUE_EVENTS_FAILURE'
)<
  { valveId: string; lastKey: DynamoDbKey | null },
  {
    response: AdminListValveEventsResponse<SuspiciousValueDiagnosticEvent>;
    valveId: string;
  },
  { id: string; error: Error }
>();

export const clearValveSusValueEvents = createAction(
  'CLEAR_VALVE_SUS_VALUE_EVENTS'
)<{ valveId: string }>();

export const fetchNextThresholdPassedEventsAsync = createAsyncAction(
  'FETCH_NEXT_THRESHOLD_PASSED_EVENTS_REQUEST',
  'FETCH_NEXT_THRESHOLD_PASSED_EVENTS_SUCCESS',
  'FETCH_NEXT_THRESHOLD_PASSED_EVENTS_FAILURE'
)<
  { valveId: string; lastKey: DynamoDbKey | null },
  {
    response: AdminListValveEventsResponse<ThresholdPassedEventAdminView>;
    valveId: string;
  },
  { id: string; error: Error }
>();

export const clearValveThresholdPassedEvents = createAction(
  'CLEAR_VALVE_THRESHOLD_PASSED_EVENTS'
)<{ valveId: string }>();
