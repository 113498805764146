import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import * as digidrumiActions from '../actions';
import * as digidrumiSelectors from '../selectors';
import React, { useEffect } from 'react';
import { ManometerLoading } from '../../../stories/ManometerLoading';

interface RouteParams {
  serialNumber: string;
}

const mapStateToProps = (state: RootState) => ({
  customerAssignmentForValve: digidrumiSelectors.customerAssignmentForValve(
    state.digidrumi
  ),
});

const dispatchProps = {
  loadAssignment: digidrumiActions.getCustomerAssignmentForValveAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const DigiDrumiRedirectPage: React.FC<Props> = ({
  customerAssignmentForValve,
  loadAssignment,
}) => {
  const { serialNumber } = useParams<RouteParams>();
  const history = useHistory();

  useEffect(() => {
    loadAssignment({
      serialNumber,
    });
  }, [loadAssignment, serialNumber]);

  const {
    data: assignmentData,
    loading,
    error,
  } = customerAssignmentForValve ?? {};

  useEffect(() => {
    if (loading || !assignmentData) {
      return;
    }
    history.push(`/digidrumi/${assignmentData.customerId}/${serialNumber}`);
  }, [loading, assignmentData, history, serialNumber]);

  return (
    <div
      style={{
        maxWidth: '500px',
        margin: '0 auto',
        display: 'flex',
        flexFlow: 'column nowrap',
        alignContent: 'center',
      }}
    >
      {!error ? (
        <>
          <ManometerLoading />
          <p style={{ textAlign: 'center' }}>
            Lade Informationen zum Meter {serialNumber}
          </p>
        </>
      ) : (
        <p style={{ textAlign: 'center' }}>
          Das Meter "{serialNumber}" existiert nicht oder gehört keinem der
          Ihnen zugewiesenen Kunden.
        </p>
      )}
    </div>
  );
};

export default connect(mapStateToProps, dispatchProps)(DigiDrumiRedirectPage);
