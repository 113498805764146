import classNames from 'classnames';
import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Badge } from './Badge';
import styles from './HeaderLink.module.scss';

interface Props {
  type?: 'link' | 'button';
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  label?: string;
  url?: string;
  onClick?: () => void | ((params: any) => any);
  mobile?: boolean;
  badgeCount?: number;
  title?: string;
}

export const HeaderLink: React.FC<Props> = ({
  type = 'link',
  Icon,
  label,
  url,
  onClick,
  mobile,
  badgeCount,
  title,
}) => {
  let match = useRouteMatch(`/${url}/`);

  if (type === 'link') {
    return (
      <Link
        className={classNames(styles['gaas-header-link'], {
          [styles['gaas-header-link--active']]: match,
          [styles['gaas-header-link--mobile']]: mobile,
        })}
        to={`/${url}/`}
        onClick={onClick}
        title={title}
      >
        {Icon && <Icon className={styles['gaas-header-link--icon']} />}
        {label && (
          <span className={styles['gaas-header-link--label']}>{label}</span>
        )}
      </Link>
    );
  }

  return (
    <button
      className={classNames(styles['gaas-header-button'], {
        [styles['gaas-header-button--mobile']]: mobile,
      })}
      type="button"
      onClick={onClick}
      title={title}
    >
      {Icon &&
        (badgeCount && badgeCount > 0 ? (
          <div className={styles['gaas-header-button--badge']}>
            <Icon className={styles['gaas-header-button--icon']} />
            <Badge count={badgeCount} />
          </div>
        ) : (
          <Icon className={styles['gaas-header-button--icon']} />
        ))}
      {label && (
        <span className={styles['gaas-header-button--label']}>{label}</span>
      )}
    </button>
  );
};
