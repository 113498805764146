import { createAction, createAsyncAction } from 'typesafe-actions';
import {
  ApiCustomerManagedGroupCreateRequest,
  ApiCustomerManagedGroupDeleteRequest,
} from '../../models/openapi/openapiTypes';
import { CustomerManagedGroup } from './models/customer-managed-group';
import { makeAsyncAction } from '../../util/makeAsyncAction';
import { ConsumptionHistory } from './models/consumption-history';

export const createGroupAsync = createAsyncAction(
  'CREATE_GROUP_REQUEST',
  'CREATE_GROUP_SUCCESS',
  'CREATE_GROUP_FAILURE'
)<
  { customerId: string; request: ApiCustomerManagedGroupCreateRequest },
  { customerId: string; group: CustomerManagedGroup },
  { error: Error; request: ApiCustomerManagedGroupCreateRequest }
>();

export const deleteGroupAsync = createAsyncAction(
  'DELETE_GROUP_REQUEST',
  'DELETE_GROUP_SUCCESS',
  'DELETE_GROUP_FAILURE'
)<
  {
    groupId: string;
    customerId: string;
    request: ApiCustomerManagedGroupDeleteRequest;
  },
  { groupId: string },
  { groupId: string; error: Error }
>();

export const getCustomerGroupsAsync = createAsyncAction(
  'GET_GROUPS_REQUEST',
  'GET_GROUPS_SUCCESS',
  'GET_GROUPS_FAILURE'
)<
  { customerId: string },
  { customerId: string; groups: CustomerManagedGroup[] },
  { customerId: string; error: Error }
>();

export const getGroupConsumptionHistoryAsync = makeAsyncAction(
  'GET_GROUP_CONSUMPTION_HISTORY'
)<
  { customerId: string },
  { customerId: string; history: ConsumptionHistory },
  { customerId: string; error: Error }
>();

export const discardGroupConsumptionHistory = createAction(
  'DISCARD_GROUP_CONSUMPTION_HISTORY'
)<string>();
