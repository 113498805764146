import React, { useEffect, useState } from 'react';
import { Modal } from './Modal';
import { Lock, Subscription } from './icons';
import styles from './PlansModal.module.scss';
import { LabelAndText } from './LabelAndText';
import { Button } from './Button';
import classNames from 'classnames';
import { ReactComponent as Upgrade } from './assets/graphics/upgrade.svg';
import { PermissionQueryResponse } from '../features/common/models/permission-query-response';
import {
  ApiGaasSubscription,
  ApiGaasUserRole,
  GaasSubscription,
  GaasUserRole,
} from '../models/openapi/openapiTypes';

interface Props {
  open: boolean;
  onClose: () => void;
  permission: PermissionQueryResponse;
}

function subscriptionToLabel(subscription: ApiGaasSubscription): string {
  switch (subscription) {
    case GaasSubscription.Transparency:
      return 'Transparency';
    case GaasSubscription.Free:
      return 'Free';
    default:
      return 'Unbekannt';
  }
}

function roleToLabel(role: ApiGaasUserRole): string {
  switch (role) {
    case GaasUserRole.Basic:
      return 'Basic';
    case GaasUserRole.Premium:
      return 'Premium';
    case GaasUserRole.Admin:
      return 'Admin';
    case GaasUserRole.Free:
      return 'Free';
    default:
      return 'Unbekannt';
  }
}

export const PlansModal: React.FC<Props> = ({ open, onClose, permission }) => {
  const [requiredLabel, setRequiredLabel] = useState('');
  const [currentLabel, setCurrentLabel] = useState('Free');

  useEffect(() => {
    const [
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _,
      { requiredSubscription, requiredRole },
      { currentRole, currentSubscription },
    ] = permission;
    if (requiredSubscription) {
      setRequiredLabel(subscriptionToLabel(requiredSubscription));
      setCurrentLabel(subscriptionToLabel(currentSubscription));
    } else if (requiredRole) {
      setRequiredLabel(roleToLabel(requiredRole));
      setCurrentLabel(roleToLabel(currentRole));
    } else {
      setRequiredLabel('No Plan');
    }
  }, [permission]);

  return (
    <Modal title="" open={open} onClose={onClose} maxWidth={400}>
      <div className={styles['gaas-plansModal']}>
        <div className={styles['gaas-plansModal--icon']}>
          {permission[0] === 'DENIED_ROLE' ? <Lock /> : <Subscription />}
        </div>
        <p className={styles['gaas-plansModal--text']}>
          {permission[0] === 'DENIED_ROLE'
            ? `Sie sind nicht berechtigt, diese Aktion auszuführen.\n Sprechen Sie Ihre:n Administrator:in an!`
            : 'Ihr GaaS-Hub kann noch mehr! Um diese Aktion auszuführen, benötigen Sie einen höhren Nutzungs-Plan.'}
        </p>
        <div className={styles['gaas-plansModal--permission']}>
          <div className={styles['gaas-plansModal--permission--current']}>
            <LabelAndText
              label={
                permission[0] === 'DENIED_ROLE'
                  ? 'Aktuelle Rolle'
                  : 'Aktueller Plan'
              }
            />

            <div
              className={classNames(
                styles['gaas-plansModal--permission--chip'],
                styles['gaas-plansModal--permission--current--chip']
              )}
            >
              {currentLabel}
              <Upgrade />
            </div>
          </div>

          <div>
            <LabelAndText
              label={
                permission[0] === 'DENIED_ROLE'
                  ? 'Benötigte Rolle'
                  : 'Benötigter Plan'
              }
            />
            <div className={styles['gaas-plansModal--permission--chip']}>
              {requiredLabel}
            </div>
          </div>
        </div>
        {permission[1].requiredSubscription && (
          <Button
            onClick={() => {
              window.location.href = 'https://westfalen.com/gaas';
            }}
            label="Weitere Informationen"
          />
        )}
      </div>
    </Modal>
  );
};
