import React from 'react';
import styles from './Progress.module.scss';
import { Check } from './icons';
import classNames from 'classnames';

interface ItemProps {
  label: string;
  index: number;
  currentIndex: number;
}

const ProgressItem: React.FC<ItemProps> = ({ label, index, currentIndex }) => {
  return (
    <div
      className={classNames(styles['gaas-progress-item'], {
        [styles['gaas-progress-item--active']]: index === currentIndex,
        [styles['gaas-progress-item--done']]: index < currentIndex,
      })}
    >
      <div className={styles['gaas-progress-item--icon']}>
        {index < currentIndex ? (
          <Check />
        ) : (
          <span className={styles['gaas-progress-item--icon--number']}>
            {index + 1}
          </span>
        )}
      </div>
      <span className={styles['gaas-progress-item--label']}>{label}</span>
    </div>
  );
};

interface Props {
  labels: string[];
  currentIndex: number;
}

export const Progress: React.FC<Props> = ({ labels, currentIndex }) => {
  return (
    <div className={styles['gaas-progress']}>
      {labels.map((label, index) => {
        return (
          <ProgressItem
            label={label}
            index={index}
            currentIndex={currentIndex}
            key={index}
          />
        );
      })}
    </div>
  );
};
