import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { RootState } from 'typesafe-actions';
import * as inventoryActions from '../../inventory/actions';
import BottleDetails from '../../inventory/components/BottleDetails';
import { ContainerState } from '../../inventory/models/container-state';
import * as inventorySelectors from '../../inventory/selectors';
import styles from './../../inventory/screens/InventoryBottlePage.module.scss';
import { TextButton } from '../../../stories/TextButton';
import { ArrowBack } from '../../../stories/icons';
import { Header } from '../../../stories/Header';
import { InventoryLoading } from '../../../stories/InventoryLoading';

const mapStateToProps = (state: RootState) => ({
  containerStates: (barcode: string) =>
    inventorySelectors.containerStatesByBarcode(state.inventory, barcode),
});

const dispatchProps = {
  getContainerStateByBarcode:
    inventoryActions.getContainerStateByBarcodeAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const PublicBottlePage: React.FC<Props> = ({
  getContainerStateByBarcode,
  containerStates,
}) => {
  let history = useHistory();
  const { barcode } = useParams<{ barcode: string }>();
  // undefined container === container does not exist, null container === container not yet loaded
  const [container, setContainer] = useState<ContainerState | null | undefined>(
    null
  );
  const [containerError, setContainerError] = useState<null | string>(null);

  useEffect(() => {
    const foundContainer = containerStates(barcode);

    if (foundContainer.error) {
      setContainerError(foundContainer.error.message);
    } else if (foundContainer.data == null && !foundContainer.loading) {
      getContainerStateByBarcode(barcode);
    } else if (foundContainer.data) {
      setContainer(foundContainer.data);
    }
  }, [containerStates, getContainerStateByBarcode, barcode]);

  if (container === null && containerError == null) {
    return (
      <div className={styles['gaas-bottle-page']}>
        <Header publicPage />
        <div className={styles['gaas-bottle-page--background']}>
          <div className={styles['gaas-bottle-page--loading']}>
            <InventoryLoading />
          </div>
        </div>
      </div>
    );
  }

  let siteContent;

  if (container) {
    siteContent = (
      <BottleDetails container={container} actionsDisabled={true}>
        <p>Bitte logge dich ein, um alle Funktionen zu nutzen.</p>
      </BottleDetails>
    );
  } else {
    siteContent = <p>Flasche konnte nicht gefunden werden.</p>;
  }

  return (
    <div className={styles['gaas-bottle-page']}>
      <Header publicPage />
      <div className={styles['gaas-bottle-page--background']}>
        <div className={styles['gaas-bottle-page--content']}>
          <TextButton
            label="Zum GaaS Hub"
            LeadingIcon={ArrowBack}
            onClick={() => {
              history.push(`/`);
            }}
          />
          {siteContent}
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, dispatchProps)(PublicBottlePage);
