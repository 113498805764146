import { createAsyncAction } from 'typesafe-actions';
import {
  ApiChangeContainerGroupAssignmentRequest,
  ApiContainerGroupAssignment,
} from '../../models/openapi/openapiTypes';
import { StockContainer } from '../digidrumi/models/stock-container';
import { ContainerNoteView } from './models/container-note';
import { ContainerState } from './models/container-state';
import { Inventory } from './models/inventory';
import { InventoryThresholdPassedEventView } from './models/inventory-threshold-passed-event-view';
import { InventoryThresholdView } from './models/inventory-threshold-view';

export const getInventoryAsync = createAsyncAction(
  'GET_INVENTORY_REQUEST',
  'GET_INVENTORY_SUCCESS',
  'GET_INVENTORY_FAILURE'
)<string, Inventory, Error>();

export const getContainerStateByBarcodeAsync = createAsyncAction(
  'GET_CONTAINER_STATE_BY_BARCODE_REQUEST',
  'GET_CONTAINER_STATE_BY_BARCODE_SUCCESS',
  'GET_CONTAINER_STATE_BY_BARCODE_FAILURE'
)<
  string,
  { barcode: string; container: ContainerState },
  { barcode: string; error: Error }
>();

export const getStockContainerAsync = createAsyncAction(
  'GET_STOCK_CONTAINER_REQUEST',
  'GET_STOCK_CONTAINER_SUCCESS',
  'GET_STOCK_CONTAINER_FAILURE'
)<string, StockContainer[], Error>();

export const takeContainerAsync = createAsyncAction(
  'TAKE_CONTAINER_REQUEST',
  'TAKE_CONTAINER_SUCCESS',
  'TAKE_CONTAINER_FAILURE'
)<
  { bottleId: string; customerId: string; groupId: string | null },
  { container: ContainerState; groupId: string | null },
  { error: Error; bottleId: string }
>();

export const returnContainerAsync = createAsyncAction(
  'RETURN_CONTAINER_REQUEST',
  'RETURN_CONTAINER_SUCCESS',
  'RETURN_CONTAINER_FAILURE'
)<
  { bottleId: string; customerId: string; fillLevel: 'full' | 'empty' },
  ContainerState,
  { error: Error; bottleId: string }
>();

export const createInventoryThresholdAsync = createAsyncAction(
  'CREATE_INVENTORY_ALERT_REQUEST',
  'CREATE_INVENTORY_ALERT_SUCCESS',
  'CREATE_INVENTORY_ALERT_FAILURE'
)<
  InventoryThresholdView,
  {},
  { error: Error; inventoryThreshold: InventoryThresholdView }
>();

export const deleteInventoryThresholdAsync = createAsyncAction(
  'DELETE_INVENTORY_ALERT_REQUEST',
  'DELETE_INVENTORY_ALERT_SUCCESS',
  'DELETE_INVENTORY_ALERT_FAILURE'
)<
  InventoryThresholdView,
  {},
  { error: Error; inventoryThreshold: InventoryThresholdView }
>();

export const listInventoryThresholdsAsync = createAsyncAction(
  'LIST_INVENTORY_ALERTS_REQUEST',
  'LIST_INVENTORY_ALERTS_SUCCESS',
  'LIST_INVENTORY_ALERTS_FAILURE'
)<
  { customerId: string },
  { customerId: string; inventoryThresholds: InventoryThresholdView[] },
  { error: Error; customerId: string }
>();

export const listActiveInventoryThresholdEventsAsync = createAsyncAction(
  'LIST_INVENTORY_ALERT_EVENTS_REQUEST',
  'LIST_INVENTORY_ALERT_EVENTS_SUCCESS',
  'LIST_INVENTORY_ALERT_EVENTS_FAILURE'
)<
  { customerId: string },
  { customerId: string; events: InventoryThresholdPassedEventView[] },
  { error: Error; customerId: string }
>();

export const updateInventoryThresholdAsync = createAsyncAction(
  'UPDATE_INVENTORY_THRESHOLD_REQUEST',
  'UPDATE_INVENTORY_THRESHOLD_SUCCESS',
  'UPDATE_INVENTORY_THRESHOLD_FAILURE'
)<{ threshold: InventoryThresholdView; customerId: string }, {}, Error>();

export const getContainerNoteAsync = createAsyncAction(
  'GET_CONTAINER_NOTE_REQUEST',
  'GET_CONTAINER_NOTE_SUCCESS',
  'GET_CONTAINER_NOTE_FAILURE'
)<
  { containerId: string; customerId: string },
  { containerId: string; containerNote: ContainerNoteView | null },
  { containerId: string; error: Error }
>();

export const updateContainerNoteAsync = createAsyncAction(
  'UPDATE_CONTAINER_NOTE_REQUEST',
  'UPDATE_CONTAINER_NOTE_SUCCESS',
  'UPDATE_CONTAINER_NOTE_FAILURE'
)<
  { containerId: string; customerId: string; content: string },
  { containerId: string },
  { containerId: string; error: Error }
>();

export const changeContainerGroupAsync = createAsyncAction(
  'CHANGE_CONTAINER_GROUP_REQUEST',
  'CHANGE_CONTAINER_GROUP_SUCCESS',
  'CHANGE_CONTAINER_GROUP_FAILURE'
)<
  {
    containerId: string;
    customerId: string;
    request: ApiChangeContainerGroupAssignmentRequest;
  },
  {
    containerId: string;
    customerId: string;
    newAssignment: ApiContainerGroupAssignment;
  },
  { containerId: string; customerId: string; error: Error }
>();
