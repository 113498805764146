export enum PermissionState {
  /// This action is allowed
  GRANTED = 'GRANTED',
  /// This action has been denied because the user does not have the required role
  DENIED_ROLE = 'DENIED_ROLE',
  /// This action has been denied because the customer does not have the required subscription
  DENIED_SUBSCRIPTION = 'DENIED_SUBSCRIPTION',
  /// This action is denied (temporarily) because the permissions are still loading
  DENIED_LOADING = 'DENIED_LOADING',
  /// This action has been denied because the user is missing the assignment
  /// for the requested customer. This can be considered to be an internal error
  DENIED_ASSIGNMENT = 'DENIED_ASSIGNMENT',
}
