import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RootState } from 'typesafe-actions';

import { Button, Container, Tab, Tabs, TextField } from '@mui/material';
import { format } from 'date-fns';
import { connect } from 'react-redux';
import List from '../../../components/list/List';
import { Loading } from '../../../stories/Loading';
import { ListingParameters } from '../../common/models/listing-parameters';
import {
  getValveAssignmentsAsync,
  getValveAsync,
  pairValveToCustomerAsync,
  unpairValveFromCustomerAsync,
  updateValveNoteAsync,
} from '../actions';
import EventList from '../components/EventList';
import { ValveCustomerAssignment } from '../models/valves-with-assignment';
import * as administrationSelectors from '../selectors';
import styles from './AdministrationPage.module.scss';

const mapStateToProps = (state: RootState) => ({
  valveNoteUpdateResult: administrationSelectors.updateValveNoteResult(
    state.administration
  ),
  adminValveSelector: (id: string) =>
    administrationSelectors.adminValve(state.administration, id),
  adminValveAssignmentSelector: (id: string, params: ListingParameters) =>
    administrationSelectors.adminValveAssignments(
      state.administration,
      id,
      params
    ),
  pairValveToCustomerResult: administrationSelectors.pairValveToCustomerResult(
    state.administration
  ),
  unpairValveFromCustomerResult:
    administrationSelectors.unpairValveFromCustomerResult(state.administration),
});

const dispatchProps = {
  updateValveNote: updateValveNoteAsync.request,
  getValve: getValveAsync.request,
  getValveAssignments: getValveAssignmentsAsync.request,
  pairValveToCustomer: pairValveToCustomerAsync.request,
  unpairValveFromCustomer: unpairValveFromCustomerAsync.request,
};

interface ComponentProps {}

type Props = ComponentProps &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;
const ValveEdit: React.FC<Props> = ({
  valveNoteUpdateResult,
  updateValveNote,
  adminValveSelector,
  getValve,
  adminValveAssignmentSelector,
  getValveAssignments,
  pairValveToCustomer,
  pairValveToCustomerResult,
  unpairValveFromCustomer,
  unpairValveFromCustomerResult,
}) => {
  const { valveId } = useParams<{ valveId: string }>();
  const humanValveId = useMemo(() => valveId.slice(18, -4), [valveId]);

  const [note, setNote] = useState<string | null>('');
  const [nextCustomerId, setNextCustomerId] = useState<string>('');

  const [alertTab, setAlertTab] = useState<number>(0);
  const valveEntity = adminValveSelector(valveId);

  useEffect(() => {
    if (valveEntity?.data == null && !valveEntity?.loading) {
      getValve(valveId);
    }
  }, [valveId, valveEntity, getValve]);

  useEffect(() => {
    if (valveEntity?.data) {
      setNote(valveEntity.data.note ?? '');
    }
  }, [valveEntity?.data]);

  if (valveEntity == null || valveEntity?.loading) {
    return <Loading />;
  }

  const onSaveNote = () => {
    updateValveNote({ valveId, note });
  };

  const onPairValve = () => {
    pairValveToCustomer({
      customerId: nextCustomerId,
      digimanoId: valveId,
      upsert: false,
    });
  };

  const onUnpairValve = () => {
    unpairValveFromCustomer({
      deviceIdentifier: valveId,
    });
  };

  return (
    <Container className={styles['gaas-administration-page']}>
      <h2>Manometer {humanValveId}</h2>

      <TextField
        id="notes"
        label="Notizen zum Gerät"
        multiline
        rows={4}
        fullWidth
        variant="filled"
        value={note}
        onChange={event => setNote(event.target.value)}
      />

      <Button variant="contained" onClick={onSaveNote}>
        {valveNoteUpdateResult.loading ? <Loading /> : 'Notizen speichern'}
      </Button>

      <h3>Alarme</h3>
      <Tabs
        value={alertTab}
        onChange={(ev, tab) => {
          setAlertTab(tab);
        }}
      >
        <Tab label="Offline Alarme" />
        <Tab label="Wertbereich Alarme" />
        <Tab label="Druckmarken" />
      </Tabs>

      {alertTab === 0 ? (
        <EventList valveId={valveId} type={'Offline'} key={'list-offline'} />
      ) : alertTab === 1 ? (
        <EventList
          valveId={valveId}
          type={'SuspiciousValue'}
          key={'list-sus'}
        />
      ) : alertTab === 2 ? (
        <EventList
          valveId={valveId}
          type={'Threshold'}
          key={'list-threshold'}
        />
      ) : (
        <div>nothing</div>
      )}

      <h3>Kundenzuweisungen</h3>

      <TextField
        label={'Neue Kundennummer'}
        style={{ marginLeft: '14px', marginRight: '7px' }}
        value={nextCustomerId}
        onChange={event => setNextCustomerId(event.target.value)}
      />

      <Button
        variant="contained"
        disabled={nextCustomerId === ''}
        style={{ marginRight: '7px' }}
        onClick={onPairValve}
      >
        {pairValveToCustomerResult.loading ? <Loading /> : 'Manometer zuweisen'}
      </Button>

      <Button
        variant="contained"
        color="secondary"
        disabled={valveEntity?.data?.assignment === null}
        onClick={onUnpairValve}
      >
        {unpairValveFromCustomerResult.loading ? (
          <Loading />
        ) : (
          'Zuweisung entfernen'
        )}
      </Button>

      <List
        hasPagination={true}
        hasSearchBar={false}
        hasSorting={false}
        columnLabels={[
          { prop: 'id', label: 'ID' },
          { prop: 'customerId', label: 'WEF' },
          { prop: 'startTs', label: 'Start' },
          { prop: 'endTs', label: 'Ende' },
        ]}
        columnLabelGrid={'2.25fr 1.75fr 1.75fr 1.75fr '}
        rowValueTransform={(obj: ValveCustomerAssignment) => [
          obj.id,
          obj.customerId,
          // '-'
          format(obj.startTs, 'dd.MM.yy, HH:mm'),
          obj.endTs != null ? format(obj.endTs, 'dd.MM.yy, HH:mm') : 'Aktiv',
        ]}
        searchProps={['id']}
        entityKey={'valvesassignments'}
        listRequest={sortingParams =>
          getValveAssignments({
            id: valveId,
            params: { start: sortingParams.start, size: sortingParams.size },
          })
        }
        listSelector={params => adminValveAssignmentSelector(valveId, params)}
      />
    </Container>
  );
};

export default connect(mapStateToProps, dispatchProps)(ValveEdit);
