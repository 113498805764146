import React from 'react';
import styles from './GroupMaterial.module.scss';
import { TextButton } from './TextButton';
import { BottleOpen, BottleUse, Chart, List } from './icons';
import { Skeleton } from './Skeleton';
import { PermissionQueryResponse } from '../features/common/models/permission-query-response';

interface Props {
  material: string;
  inventory: number;
  consumption: number;
  consumptionLoading: boolean;
  setListModalOpen: () => void;
  setHistoryModalOpen?: () => void;
  readGroupConsumptionPermission: PermissionQueryResponse;
}

export const GroupMaterial: React.FC<Props> = ({
  material,
  inventory,
  consumption,
  consumptionLoading,
  readGroupConsumptionPermission,
  setListModalOpen,
  setHistoryModalOpen,
}) => {
  return (
    <div className={styles['gaas-group-material']}>
      <div className={styles['gaas-group-material--material']}>{material}</div>
      <div className={styles['gaas-group-material--values']}>
        <div className={styles['gaas-group-material--values--value']}>
          <BottleOpen /> <span>{inventory}</span>
        </div>
        <div className={styles['gaas-group-material--values--value']}>
          <BottleUse />
          <span>
            {consumptionLoading ? (
              <Skeleton width={16} height={20} />
            ) : (
              // todo Nina, bitte hier readGroupConsumptionPermission prüfen und das irgendwie sinnvoll darstellen
              consumption
            )}
          </span>
        </div>
      </div>
      <div className={styles['gaas-group-material--buttons']}>
        <TextButton LeadingIcon={List} onClick={setListModalOpen} />
        <TextButton
          disabled={!setHistoryModalOpen}
          LeadingIcon={Chart}
          onClick={setHistoryModalOpen}
        />
      </div>
    </div>
  );
};
