import { createAction } from 'typesafe-actions';
import { makeAsyncAction } from '../../util/makeAsyncAction';

export const webSocketCheckToken = createAction('WEBSOCKET_CHECK_TOKEN')<{
  token: string;
}>();

export const connectWebSocket = createAction('WEBSOCKET_CONNECT')<{
  token: string;
}>();

export const webSocketConnected = createAction('WEBSOCKET_CONNECTED')();

export const setWebSocketCustomer = makeAsyncAction('WEBSOCKET_SET_CUSTOMER')<
  string,
  string,
  void
>();
