import classNames from 'classnames';
import React from 'react';
import styles from './Devider.module.scss';

interface Props {
  noMargin?: boolean;
}

export const Devider: React.FC<Props> = ({ noMargin }) => (
  <hr
    className={classNames(styles['gaas-devider'], {
      [styles['gaas-devider--nomargin']]: noMargin,
    })}
  />
);
