import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Snackbar,
} from '@mui/material';
import { OverlayMessage } from '../models/OverlayMessage';
import styles from './Overlay.module.scss';
import { useState } from 'react';

export interface ErrorOverlayMessageProps {
  message: OverlayMessage;
}

export function ErrorOverlayMessage({ message }: ErrorOverlayMessageProps) {
  const copyDetailsToClipboard = () => {
    navigator.clipboard.writeText(
      JSON.stringify(message.details, undefined, 2)
    );
  };

  const [visible, setVisible] = useState<boolean>(true);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={visible}
      autoHideDuration={6000}
      message={message.title}
      // don't close the message after the timer if it's sticky
      onClose={() => setVisible(message.sticky)}
      role={'alert'}
    >
      <Alert
        severity={message.type}
        variant="filled"
        sx={{ width: '100%' }}
        onClose={() => setVisible(false)}
      >
        <h3 className={styles['gaas-om-header']}>{message.title}</h3>
        <p className={styles['gaas-om-message']}>{message.message}</p>
        <Accordion>
          <AccordionSummary aria-controls="panel1-content" id="panel1-header">
            Details
          </AccordionSummary>
          <AccordionDetails>
            <pre className={styles['gaas-om-details-container']}>
              {JSON.stringify(message.details ?? 'none', undefined, 2)
                .replace(/"Bearer .*"/gm, '"Bearer [token]"')
                .replace(/"Store .*"/gm, '"Store [token]"')}
            </pre>
            <Button onClick={copyDetailsToClipboard}>Copy</Button>
          </AccordionDetails>
        </Accordion>
      </Alert>
    </Snackbar>
  );
}
