import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import styles from './Notification.module.scss';
import { Tooltip } from './Tooltip';

interface Props {
  text: any;
  createdAt: Date;
  onClick: () => void;
  unread: boolean;
}

export const Notification: React.FC<Props> = ({
  text,
  createdAt,
  onClick,
  unread,
}) => {
  return (
    <div
      className={styles['gaas-notification']}
      onClick={onClick}
      role={'list'}
    >
      <div
        className={classNames(styles['gaas-notification--dot'], {
          [styles['gaas-notification--dot--unread']]: unread,
        })}
        aria-label={unread ? 'unread' : 'read'}
      />

      <div role={'listitem'}>
        <Tooltip text={moment(createdAt).format('LLL')}>
          <span className={styles['gaas-notification--date']}>
            {moment(createdAt).fromNow()}
          </span>
        </Tooltip>

        <div
          className={classNames(styles['gaas-notification--text'], {
            [styles['gaas-notification--text--unread']]: unread,
          })}
        >
          {text}
        </div>
      </div>
    </div>
  );
};
