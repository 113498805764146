import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'typesafe-actions';
import * as commonActions from '../features/common/actions';

import * as commonSelectors from '../features/common/selectors';
import { HeaderLink } from '../stories/HeaderLink';
import { Notification } from '../stories/Notification';
import { Notifications as NotificationsStory } from '../stories/Notifications';
import { Skeleton } from '../stories/Skeleton';
import { Notifications as NotificationsIcon } from '../stories/icons';
import {
  ApiAnyNotification,
  ApiUserPressureThresholdNotification,
  isApiUserBatteryCriticalNotification,
  isApiUserInventoryThresholdNotification,
  isApiUserPressureThresholdNotification,
  isApiUserSuspiciousValuesNotification,
  isApiUserValveOfflineNotification,
} from '../models/openapi/openapiTypes';

const isDeviceRelatedNotification = (
  notification: ApiAnyNotification
): notification is ApiUserPressureThresholdNotification => {
  return isApiUserPressureThresholdNotification(notification);
};

const mapStateToProps = (state: RootState) => ({
  notifications: commonSelectors.notifications(state.common),
  notificationMarkingPending: commonSelectors.notificationsMarkingPending(
    state.common
  ),
});

const dispatchProps = {
  getNotifications: commonActions.getNotificationsAsync.request,
  markAllNotificationsRead: commonActions.readAllNotificationsAsync.request,
  markNotificationRead: commonActions.readNotificationAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const Notifications: React.FC<Props> = ({
  notifications,
  getNotifications,
  markAllNotificationsRead,
  notificationMarkingPending,
  markNotificationRead,
}) => {
  let history = useHistory();
  const [openNotifications, setOpenNotifications] = useState(false);

  useEffect(() => {
    if (
      notifications.data === undefined &&
      notifications.error === undefined &&
      !notifications.loading
    ) {
      getNotifications();
    }
  }, [
    notifications,
    getNotifications,
    notifications.data,
    notifications.error,
    notifications.loading,
  ]);

  const notificationsEntities = notifications?.data ?? [];
  const numUnreadNotifications = notificationsEntities.filter(
    it => !it.readAt
  ).length;

  const textFromNotification = (
    notification: ApiAnyNotification
  ): JSX.Element => {
    let serialNumber = null;
    if (isDeviceRelatedNotification(notification)) {
      serialNumber = notification.serialNumber;
    }
    if (isApiUserPressureThresholdNotification(notification)) {
      return (
        <span>
          Der Alarm "{notification.threshold.name}" des Meters {serialNumber}{' '}
          hat den Grenzwert von {notification.threshold.pressure}
          &#8239;bar mit {notification.pressure}&#8239;bar unterschritten.
        </span>
      );
    }
    if (isApiUserSuspiciousValuesNotification(notification)) {
      return (
        <span>
          Das Meter {serialNumber} sendet ungewöhnliche Messwerte (
          {notification.pressure}&#8239;bar und{' '}
          {notification.temperatureManometer}&#8239;°C).
        </span>
      );
    }

    if (isApiUserValveOfflineNotification(notification)) {
      return <span>Das Meter {serialNumber} sendet keine Daten mehr.</span>;
    }

    if (isApiUserBatteryCriticalNotification(notification)) {
      return (
        <span>
          Das Meter {notification.serialNumber} hat einen kritischen
          Batteriestand erreicht. Bitte laden Sie das Gerät schnellstmöglich
          auf.
        </span>
      );
    }

    if (isApiUserInventoryThresholdNotification(notification)) {
      return (
        <span>
          Der Grenzwert "{notification.threshold.name}" für das Material{' '}
          {notification.materialId} beim Kunden {notification.customerId} wurde
          mit {notification.bottles} von {notification.threshold.minFullBottles}{' '}
          vollen Flaschen erreicht!
        </span>
      );
    }

    return <span>Unbekannte Benachrichtigung</span>;
  };
  return (
    <>
      <HeaderLink
        type="button"
        Icon={NotificationsIcon}
        title={'Notifications'}
        onClick={() => {
          setOpenNotifications(!openNotifications);
        }}
        badgeCount={numUnreadNotifications}
      />

      <NotificationsStory
        open={openNotifications}
        onClose={() => setOpenNotifications(false)}
        onReadAllClick={markAllNotificationsRead}
        readAllDisabled={
          notifications.loading || notificationMarkingPending.loading
        }
      >
        <>
          {notificationsEntities ? (
            notificationsEntities.length > 0 ? (
              notificationsEntities.map((notification, key) => {
                return (
                  <Notification
                    text={textFromNotification(notification)}
                    createdAt={new Date(Date.parse(notification.createdAt))}
                    onClick={() =>
                      // We push an additional timestamp onto the history
                      // to be able to trigger notification effects again
                      // even if the router does not change
                      {
                        if (
                          isApiUserPressureThresholdNotification(notification)
                        ) {
                          history.push(
                            `/digidrumi/${notification.customerId}/${notification.serialNumber}`,
                            { notificationAccessedAt: Date.now() }
                          );
                        } else if (
                          isApiUserInventoryThresholdNotification(notification)
                        ) {
                          history.push(
                            `/inventory/${notification.customerId}/${notification.materialId}`,
                            { notificationAccessedAt: Date.now() }
                          );
                        }
                        if (notification.readAt == null) {
                          markNotificationRead(notification.id);
                        }
                        setOpenNotifications(false);
                      }
                    }
                    unread={!notification.readAt}
                    key={key}
                  />
                );
              })
            ) : (
              <span>Keine Benachrichtigungen vorhanden.</span>
            )
          ) : (
            <>
              <Skeleton height={50} />
              <Skeleton height={50} />
              <Skeleton height={50} />
            </>
          )}
        </>
      </NotificationsStory>
    </>
  );
};

export default connect(mapStateToProps, dispatchProps)(Notifications);
