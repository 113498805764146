import React from 'react';
import { LabelAndText } from './LabelAndText';
import styles from './SidebarCustomerInfo.module.scss';

interface Props {
  customerId: string | number;
  customerAddress: string;
}

export const SidebarCustomerInfo: React.FC<Props> = ({
  customerId,
  customerAddress,
}) => {
  return (
    <div className={styles['gaas-sidebar--customer-info']}>
      <LabelAndText label="Kundennummer" text={customerId} />
      <LabelAndText label="Adresse" text={customerAddress} />
    </div>
  );
};
