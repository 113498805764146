import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import React from 'react';
import ShopPage from './ShopPage';
import { Header } from '../../../stories/Header';
import styles from './PublicShopPage.module.scss';
import ShopUserGuard from '../components/ShopUserGuard';

const mapStateToProps = (state: RootState) => ({});

const dispatchProps = {};

interface ComponentProps {
  standalone: boolean;
}

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const PublicShopPage: React.FC<Props> = ({ standalone }) => {
  return (
    <div className={styles['gaas-publicShopPage']}>
      {standalone && <Header publicPage />}
      <ShopUserGuard>
        <ShopPage />
      </ShopUserGuard>
    </div>
  );
};

export default connect(mapStateToProps, dispatchProps)(PublicShopPage);
