import React from 'react';
import { Devider } from './Devider';
import { LabelAndText } from './LabelAndText';
import styles from './Sidebar.module.scss';
import { Skeleton } from './Skeleton';

interface Props {
  title: string;
  loading?: boolean;
}

export const Sidebar: React.FC<Props> = ({ title, loading, children }) => {
  return (
    <div className={styles['gaas-sidebar']}>
      <h1 className={styles['gaas-sidebar--title']}>{title}</h1>

      {loading ? (
        <div>
          <Skeleton height={56} />
          <Devider />
          <LabelAndText label="Kundennummer" text={<Skeleton height={27} />} />
          <LabelAndText label="Adresse" text={<Skeleton height={27} />} />
        </div>
      ) : (
        children
      )}
    </div>
  );
};
