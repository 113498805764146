export interface ListingParameters {
  start: number;
  size: number;
}

export interface FilterList {
  [key: string]: string | string[];
}

export enum SortDirection {
  asc,
  desc,
}

export interface SortingParameters extends ListingParameters {
  sort?: {
    prop: string;
    dir: SortDirection;
  };
  filter?: FilterList;
  search?: FilterList;
}
