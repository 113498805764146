import React from 'react';

export const GroupsLoading: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        d="M17,9v14H7v-14c0-2.42,1.72-4.44,4-4.9V1h2v3.1c.98.2,1.86.68,2.54,1.36.9.91,1.46,2.16,1.46,3.54Z"
        fill="#fff"
      />
      <rect
        x="7"
        y="21"
        width="10"
        height="14"
        fill="#002f63"
        transform="scale(1,-1) translate(0,-44)"
      >
        <animate
          attributeName="height"
          from="1"
          to="14"
          values="1; 1; 14; 1"
          dur="3s"
          repeatCount="indefinite"
        />
      </rect>

      <g style={{ transformOrigin: '17px 13px' }}>
        <path
          d="m12.84,13c0-1.37.67-2.59,1.69-3.36v1.39h.84v-2.81h-2.81v.84h1.33c-1.15.93-1.9,2.34-1.9,3.94,0,2.6,1.97,4.75,4.5,5.03v-.85c-2.06-.28-3.66-2.04-3.66-4.18h0Z"
          fill="#969696"
        />
        <path
          d="m22.12,13c0-2.6-1.97-4.75-4.5-5.03v.85c2.06.28,3.66,2.04,3.66,4.18,0,1.37-.67,2.59-1.69,3.36v-1.39h-.84v2.81h2.81v-.84h-1.33c1.15-.93,1.9-2.34,1.9-3.94h0Z"
          fill="#969696"
        />
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          from="0"
          to="360"
          values="0; 360; 360;"
          dur="3s"
          repeatCount="indefinite"
        />
      </g>
    </svg>
  );
};
