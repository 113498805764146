import {
  ScanDetection,
  ScanDetectionCodeSet,
} from '../../features/scanning/models/scan-result';

export enum BarcodeVerificationResult {
  VALID_16 = 'valid_16',
  VALID_14 = 'valid_14',
  INVALID = 'invalid',
}

/**
 *
 * @param result
 * @see the specification for the gas bottle barcodes https://drive.google.com/file/d/1Dv0IF8qZwq7Kx_mAtnc8BaqTXddQE31y/view?usp=drive_link
 */
export function verifyWestfalenBarcode(
  result: ScanDetection
): BarcodeVerificationResult {
  const { code: barcode, error, codeSet } = result;

  if (error > 0.25) {
    return BarcodeVerificationResult.INVALID;
  }

  // A valid WAG Barcode must use the Codeset C (which is used to encode numbers)
  // sadly, the native barcode scanner ist not able to detect the codeset
  if (codeSet !== undefined && codeSet !== ScanDetectionCodeSet.VARIANT_C) {
    return BarcodeVerificationResult.INVALID;
  } else {
    // We can assume that the barcode is in Codeset C if it only contains numbers
    if (!/^\d+$/.test(barcode)) {
      return BarcodeVerificationResult.INVALID;
    }
  }

  // According to the WAG Barcode specification (see link above)
  // the payload of barcode must be 16 characters long.
  // 14 of these are the payload and 2 are an additional checksum that
  // mirrors the code128 algorithm. This checksum exists parallel to the
  // code128 checksum (so there are actually two checksums in the barcode)
  //
  // If a barcode is 14 chars longs, it is a legacy barcode that does not contain
  // the additional checksum.
  if (barcode.length !== 14 && barcode.length !== 16) {
    return BarcodeVerificationResult.INVALID;
  }

  // a valid barcode starts wird 276 to signify a bottle from germany,
  // as required in ISO 3166
  if (!barcode.startsWith('276')) {
    return BarcodeVerificationResult.INVALID;
  }

  const owner = barcode.slice(3, 6);
  const ownerNo = parseInt(owner, 10);
  // Bottles owned by wag use an owner number between 001 and 050.
  if (isNaN(ownerNo) || ownerNo < 1 || ownerNo > 50) {
    return BarcodeVerificationResult.INVALID;
  }

  // If the barcode is 16 chars long, perform the checksum validation
  if (barcode.length === 16) {
    // split the barcode into groups of two characters
    const barcodeParts = new Array(8)
      .fill(0)
      .map((_, i) => i * 2)
      .map(i => barcode.substring(i, i + 2));
    const checksum = parseInt(barcodeParts[barcodeParts.length - 1], 10);

    // calculate the checksum as one would do for a code128 barcode
    // see https://en.wikipedia.org/wiki/Code_128#Check_digit_calculation
    const calculatedChecksum =
      (barcodeParts
        .slice(0, -1)
        .reduce(
          (acc, part, index) => acc + parseInt(part, 10) * (index + 1),
          0
        ) +
        // because of reasons, WAG includes the start code in the checksum, which is why
        // we have to add the value of the Code128 Variant C startcode
        // (aka 105) to the checksum
        105) %
      103;

    // additional wag convention: barcodes with a checksum > 100 are invalid
    return checksum < 100 && checksum === calculatedChecksum
      ? BarcodeVerificationResult.VALID_16
      : BarcodeVerificationResult.INVALID;
  }

  return BarcodeVerificationResult.VALID_14;
}
