import React from 'react';
import styles from './GatewayStatus.module.scss';
import { LabelAndText } from './LabelAndText';
import { Skeleton } from './Skeleton';
import { Router } from './icons';

interface Props {
  sum?: number;
  online?: number;
  loading?: boolean;
}

export const GatewayStatus: React.FC<Props> = ({
  sum,
  online = 0,
  loading,
}) => {
  return (
    <div className={styles['gaas-gateway-status']}>
      <div className={styles['gaas-gateway-status--icon']}>
        <Router />
      </div>
      {loading ? (
        <LabelAndText
          label="Gateways"
          text={<Skeleton height={27} width={120} />}
        />
      ) : sum && sum > 0 ? (
        <LabelAndText
          label="Gateways"
          text={`${online} / ${sum} online`}
          state={
            online === sum
              ? 'success'
              : online < sum
              ? 'warning'
              : online === 0
              ? 'error'
              : 'default'
          }
        />
      ) : (
        <LabelAndText label="Gateways" text="Keine Gateways" />
      )}
    </div>
  );
};
