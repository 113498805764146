import { createReducer } from 'typesafe-actions';
import { ScanResult } from './models/scan-result';
import { newScannerResult } from './actions';

interface State {
  barcodeResult: ScanResult;
}

const initialState: State = {
  barcodeResult: {
    timestamp: new Date(0),
  },
} as State;

export const scanningReducer = createReducer(initialState).handleAction(
  newScannerResult,
  (state, { payload }) => {
    if (
      payload.timestamp.getTime() < state.barcodeResult.timestamp.getTime() &&
      (payload.detected !== undefined || payload.candidates !== undefined)
    ) {
      return state;
    }

    return {
      ...state,
      barcodeResult: payload,
    };
  }
);

export default scanningReducer;
export type ScanningState = ReturnType<typeof scanningReducer>;
