import React, { useState } from 'react';
import { Button, Container, TextField } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { editMaintenanceNoteAsync } from '../actions';
import styles from './AdministrationPage.module.scss';

const mapStateToProps = (state: RootState) => ({});

const dispatchProps = {
  editMaintenanceNote: editMaintenanceNoteAsync.request,
};

interface ComponentProps {}

type Props = ComponentProps &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

const MaintenancePage: React.FC<Props> = ({ editMaintenanceNote }) => {
  const [note, setNote] = useState<string>('');
  const [validUntil, setValidUntil] = useState<Date>(new Date());
  const maxNoteLength = 3800;
  const noteTooLong = note.length > maxNoteLength;
  const noteTooShort = note.length === 0;

  return (
    <Container className={styles['gaas-administration-page']}>
      <h2>Maintenance & Incidents</h2>
      <span>
        Der Inhalt des Maintenance Banners wird allen Nutzern im Portal
        angezeigt, bis dieser ersetzt wird oder abläuft. Verlinkungen sind nicht
        möglich; es wird nur Text unterstützt. Es kann bis zu 5 Minuten dauern,
        bis die Nachricht für alle Nutzer (auch für den Ersteller der Nachricht)
        sichtbar wird.
      </span>
      <br />
      <br />
      <span>
        Um eine Nachricht vorzeitig auszublenden, kann eine neue Nachricht mit
        einem Ablaufdatum in der Vergangenheit gespeichert werden. Bereits
        abgesendete Nachrichten können nicht bearbeitet werden.
      </span>
      <TextField
        id="note"
        label="Inhalt des Maintenance Banners"
        multiline
        rows={2}
        fullWidth
        variant="filled"
        value={note}
        onChange={event => setNote(event.target.value)}
        style={{ paddingBottom: '16px' }}
        error={noteTooLong}
      />

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          renderInput={props => <TextField {...props} />}
          label="Ablauf Datum"
          value={validUntil}
          onChange={newValue => {
            setValidUntil(new Date(newValue?.getTime() ?? Date.now()));
          }}
        />
      </LocalizationProvider>

      <div style={{ paddingTop: '16px' }}>
        <Button
          variant="contained"
          onClick={() => {
            editMaintenanceNote({
              content: note,
              validUntil: validUntil.getTime(),
            });
          }}
          disabled={noteTooLong || noteTooShort}
        >
          {noteTooLong
            ? `Nachricht zu lang (max ${maxNoteLength} Zeichen)`
            : noteTooShort
            ? 'Nachricht darf nicht leer sein'
            : 'Maintenance Nachricht speichern'}
        </Button>
      </div>
    </Container>
  );
};

export default connect(mapStateToProps, dispatchProps)(MaintenancePage);
