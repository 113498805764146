import { Modal } from '../../../stories/Modal';
import React from 'react';

const ShopTokenExpiredModal: React.FC = () => {
  return (
    <Modal
      open={true}
      onClose={() => {}}
      title="Sitzung abgelaufen"
      maxWidth={480}
    >
      <span>
        Ihre Sitzung im{' '}
        <a href={'https://shop.westfalen.com'}>Westfalen Online Shop</a> ist
        abgelaufen. Bitte starten Sie den Konfigurator über den Menüpunkt
        "Produktsortiment" {'->'} "GaaS - Flaschengase-Management" im Westfalen
        Online Shop erneut. Etwaige bisherige Einstellungen wurden gespeichert.
      </span>
    </Modal>
  );
};

export default ShopTokenExpiredModal;
