import * as customer from './customer-service';
import * as user from './user-service';
import * as valve from './valve-service';
import * as administration from './admin-service';
import * as threshold from './threshold-service';
import * as notifications from './notification-service';
import * as firewall from './firewall-service';
import * as common from './common-service';
import * as groups from './group-service';
import * as websocket from './websocket-service';
import * as shop from './shop-service';

const services = {
  customer,
  user,
  valve,
  administration,
  notifications,
  threshold,
  firewall,
  common,
  groups,
  websocket,
  shop,
};

export default services;
