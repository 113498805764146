import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createEpicMiddleware } from 'redux-observable';
import { RootAction, RootState, Services } from 'typesafe-actions';
import services from '../services';
import { errorReporterMiddleware } from './error-reporter-middleware';
import rootEpic from './root-epic';
import createRootReducer from './root-reducer';

export const history = createBrowserHistory({ basename: '/' });

const epicMiddleware = createEpicMiddleware<
  RootAction,
  RootAction,
  RootState,
  Services
>({ dependencies: services });

const middlewares = [
  routerMiddleware(history),
  errorReporterMiddleware,
  epicMiddleware,
];
const composeEnhancers = composeWithDevTools({});
const enhancer = composeEnhancers(applyMiddleware(...middlewares));

const initialState = {};

const store = createStore(createRootReducer(history), initialState, enhancer);

epicMiddleware.run(rootEpic);

export default store;
