import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { Button } from '../../../stories/Button';
import { Devider } from '../../../stories/Devider';
import { LabelAndText } from '../../../stories/LabelAndText';
import { TextButton } from '../../../stories/TextButton';
import {
  Delete,
  PropaneTank,
  PropaneTankFilled,
  ShoppingCart,
  BottleEmpty,
  BottleFull,
  BottleOpen,
} from '../../../stories/icons';
import { shopUrl } from '../../../util/shopHelper';
import * as groupActions from '../../groups/actions';
import * as groupSelectors from '../../groups/selectors';
import * as commonSelectors from '../../common/selectors';
import * as inventoryActions from '../actions';
import {
  isEmptyContainer,
  isFullContainer,
  isHalfFullContainer,
} from '../helpers/fill-level';
import {
  ContainerState,
  isAutomaticContainerState,
  isContainerStateView,
} from '../models/container-state';
import styles from './BottleDetails.module.scss';
import * as inventorySelectors from '../selectors';
import ContainerNote from './ContainerNote';
import GroupSelectModal from './GroupSelectModal';
import { ContainerStateView } from '../models/inventory';
import { ApiGaasAction } from '../../../models/openapi/openapiTypes';
import { Skeleton } from '@mui/material';

interface ComponentProps {
  container: ContainerState | ContainerStateView;
  actionsDisabled: boolean;
  children?: React.ReactNode;
  actionsLoading?: boolean;
}

const mapStateToProps = (state: RootState) => ({
  containerLoading: inventorySelectors.bottleLoading(state.inventory),
  customerGroups: (customerId: string) =>
    groupSelectors.customerGroups(state.groups, customerId),
  hasPermission: (action: ApiGaasAction) =>
    commonSelectors.hasPermission(action, state.common),
});

const dispatchProps = {
  takeBottle: inventoryActions.takeContainerAsync.request,
  returnBottle: inventoryActions.returnContainerAsync.request,
  getCustomerGroups: groupActions.getCustomerGroupsAsync.request,
  changeContainerGroup: inventoryActions.changeContainerGroupAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const BottleDetails: React.FC<Props> = ({
  container,
  children,
  takeBottle,
  returnBottle,
  containerLoading,
  actionsDisabled,
  actionsLoading,
  customerGroups,
  getCustomerGroups,
  changeContainerGroup,
  hasPermission,
}) => {
  const [deleteAllowed] = hasPermission('group.membership.write');
  const containerIsFull = useMemo(
    () => (container ? isFullContainer(container) : false),
    [container]
  );
  const containerIsHalfFull = useMemo(
    () => (container ? isHalfFullContainer(container) : false),
    [container]
  );
  const containerIsEmpty = useMemo(
    () => (container ? isEmptyContainer(container) : true),
    [container]
  );

  const [groupSelectModalIsOpen, setGroupSelectModalIsOpen] =
    useState<boolean>(false);

  const [groupEditModalIsOpen, setGroupEditModalIsOpen] =
    useState<boolean>(false);

  const [groupAssignModalOpen, setGroupAssignModalOpen] =
    useState<boolean>(false);

  const { customerId } = container;
  const {
    loading: groupsLoading,
    data: groups,
    error: groupError,
  } = customerGroups(customerId);
  useEffect(() => {
    if (
      !groupsLoading &&
      groups == null &&
      groupError == null &&
      !actionsDisabled
    ) {
      getCustomerGroups({ customerId });
    }
  }, [
    customerId,
    groups,
    groupsLoading,
    getCustomerGroups,
    groupError,
    actionsDisabled,
  ]);

  const groupName = useMemo(() => {
    if (groupError) {
      return 'Gruppe konnte nicht geladen werden ...';
    }
    const groupId = isContainerStateView(container)
      ? container.groupId
      : undefined;
    if (!groups || !groupId) {
      return 'Lade...';
    }
    return groups.find(group => group.id === groupId)?.name ?? 'Unbekannt';
  }, [container, groups, groupError]);

  const materialShopLink = useMemo(
    () => shopUrl(container.materialId),
    [container.materialId]
  );

  const containerIsPerformingAction =
    containerLoading?.[container.id]?.loading === true;
  return (
    <div className={styles['gaas-bottle-details']}>
      <h1 className={styles['gaas-bottle-details--title']}>Flaschendetails</h1>

      <div
        className={styles['gaas-bottle-details--values']}
        role={'group'}
        title={'bottle information'}
      >
        <LabelAndText label="Material" text={container.materialDescription} />
        <LabelAndText label="Barcode" text={container.barcode} />
        <LabelAndText
          label="Füllstand"
          text={
            containerIsFull ? (
              <div className={styles['gaas-bottle-details--level']}>
                <BottleFull
                  className={styles['gaas-bottle-details--level--bottle']}
                  data-testid={`icon:bottle-full`}
                />
                Voll
              </div>
            ) : containerIsEmpty ? (
              <div className={styles['gaas-bottle-details--level']}>
                <BottleEmpty
                  className={styles['gaas-bottle-details--level--bottle']}
                  data-testid={`icon:bottle-empty`}
                />
                Leer
              </div>
            ) : (
              <div className={styles['gaas-bottle-details--level']}>
                <BottleOpen
                  className={styles['gaas-bottle-details--level--bottle']}
                  data-testid={`icon:bottle-open`}
                />
                Angebrochen
              </div>
            )
          }
        />
        <LabelAndText
          label="Fülldruck"
          text={`${container.fillPressure} bar`}
        />
        {isAutomaticContainerState(container) && (
          <LabelAndText
            label="Letzter Gemessener Druck"
            text={`${container.pressureBar} bar`}
          />
        )}
        {container.fillLevel === 'HALF' &&
        isContainerStateView(container) &&
        container.groupId ? (
          <LabelAndText label="Verbrauchsgruppe" text={groupName}>
            {deleteAllowed && (
              <TextButton
                LeadingIcon={Delete}
                onClick={() => setGroupEditModalIsOpen(true)}
              />
            )}
          </LabelAndText>
        ) : container.fillLevel === 'HALF' ? (
          <div>
            <LabelAndText label="Verbrauchsgruppe" />
            <Button
              label="Gruppe auswählen"
              fullWidth
              onClick={() => setGroupAssignModalOpen(true)}
              permission={hasPermission('group.membership.write')}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <Devider />

      {containerIsPerformingAction || actionsLoading || groupsLoading ? (
        <div>
          <Skeleton height={40} />
        </div>
      ) : (
        <div className={styles['gaas-bottle-details--actions']}>
          <div className={styles['gaas-bottle-details--actions--store']}>
            {containerIsFull && (
              <Button
                label="Flasche entnehmen"
                fullWidth
                disabled={actionsDisabled}
                onClick={() =>
                  (groups ?? []).length === 0
                    ? takeBottle({
                        bottleId: container.id,
                        customerId: container.customerId,
                        groupId: null,
                      })
                    : setGroupSelectModalIsOpen(true)
                }
                permission={hasPermission('inventory.fill-level.write')}
              />
            )}
            {containerIsHalfFull && (
              <Button
                label="Leer zurückstellen"
                LeadingIcon={PropaneTank}
                fullWidth
                disabled={actionsDisabled}
                onClick={() =>
                  returnBottle({
                    bottleId: container.id,
                    customerId: container.customerId,
                    fillLevel: 'empty',
                  })
                }
                permission={hasPermission('inventory.fill-level.write')}
              />
            )}
            {!containerIsFull && (
              <Button
                label="Voll zurückstellen"
                LeadingIcon={PropaneTankFilled}
                fullWidth
                disabled={actionsDisabled}
                onClick={() =>
                  returnBottle({
                    bottleId: container.id,
                    customerId: container.customerId,
                    fillLevel: 'full',
                  })
                }
                permission={hasPermission('inventory.fill-level.write')}
              />
            )}
          </div>
          {children}
          <a href={materialShopLink} target="_blank" rel="noreferrer">
            <Button
              label={
                actionsDisabled ? 'Produkt bestellen' : 'Produkt nachbestellen'
              }
              LeadingIcon={ShoppingCart}
              fullWidth
            />
          </a>

          {!actionsDisabled ? (
            <div>
              <Devider />
              <ContainerNote
                containerId={container.id}
                customerId={container.customerId}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      )}

      <GroupSelectModal
        isOpen={groupSelectModalIsOpen}
        groups={groups ?? []}
        onAbort={() => setGroupSelectModalIsOpen(false)}
        onConfirm={selected => {
          takeBottle({
            groupId: selected,
            bottleId: container.id,
            customerId,
          });
          setGroupSelectModalIsOpen(false);
        }}
        titleText={'Flasche entnehmen'}
        descriptionText={
          'Möchten Sie diese Flasche einer Verbrauchsgruppe zuordnen?'
        }
        confirmWithoutGroupText={'Entnehmen'}
        confirmWithGroupText={'Entnehmen und zuweisen'}
        allowConfirmWithoutGroup={true}
      />

      <GroupSelectModal
        isOpen={groupEditModalIsOpen}
        groups={groups ?? []}
        onAbort={() => setGroupEditModalIsOpen(false)}
        onConfirm={selected => {
          changeContainerGroup({
            customerId,
            containerId: container.id,
            request: {
              groupId: selected,
            },
          });
          setGroupEditModalIsOpen(false);
        }}
        titleText={'Zuweisung ändern'}
        descriptionText={
          'Möchten Sie diese Flasche in eine andere Gruppe übertragen oder die Zuweisung entfernen?'
        }
        confirmWithoutGroupText={'Entfernen'}
        confirmWithGroupText={'Übertragen'}
        allowConfirmWithoutGroup={true}
      />

      <GroupSelectModal
        isOpen={groupAssignModalOpen}
        groups={groups ?? []}
        onAbort={() => setGroupAssignModalOpen(false)}
        onConfirm={selected => {
          changeContainerGroup({
            customerId,
            containerId: container.id,
            request: {
              groupId: selected,
            },
          });
          setGroupAssignModalOpen(false);
        }}
        titleText={'Verbrauchsgruppe auswählen'}
        descriptionText={
          'Möchten Sie dieser bereits entnommenen Flasche eine Verbrauchsgruppe zuweisen?'
        }
        confirmWithoutGroupText={'Übertragen'}
        confirmWithGroupText={'Übertragen'}
        allowConfirmWithoutGroup={false}
      />
    </div>
  );
};

export default connect(mapStateToProps, dispatchProps)(BottleDetails);
