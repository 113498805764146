import { Observable } from 'rxjs';
import { apiGet, apiPost } from './api';
import { ApiAnyNotification } from '../models/openapi/openapiTypes';

export function getNotificationsForUser(): Observable<ApiAnyNotification[]> {
  return apiGet<ApiAnyNotification[]>(
    'GaasBackend',
    `/notifications/by-user`,
    {}
  );
}

export function markAllNotificationsRead(): Observable<{}> {
  return apiPost<{}>('GaasBackend', `/notifications/by-user/read`, {});
}

export function markNotificationRead(notificationId: string): Observable<{}> {
  return apiPost<{}>(
    'GaasBackend',
    `/notifications/by-id/${notificationId}/read`,
    {}
  );
}
