import React from 'react';
import ShopCustomerNumberProvider from '../components/ShopCustomerNumberProvider';
import FreeSubscriptionWizard from '../components/FreeSubscriptionWizard';

const ShopPage: React.FC = () => {
  return (
    <ShopCustomerNumberProvider>
      <FreeSubscriptionWizard />
    </ShopCustomerNumberProvider>
  );
};

export default ShopPage;
