import { OutlinedInput } from '@mui/material';
import React, {
  AriaRole,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ManometerPin } from '../features/common/models/manometer-pin';
import {
  PressureThresholdView,
  PressureThresholdViewData,
} from '../features/digidrumi/models/pressure-threshold-view';
import { Button } from './Button';
import { Card } from './Card';
import { Devider } from './Devider';
import { Manometer } from './Manometer';
import styles from './ManometerCard.module.scss';
import moment from 'moment';
import useWindowSize from '../hooks/useWindowSize';
import { LabelAndText } from './LabelAndText';
import { PinModal } from './PinModal';
import { TextButton } from './TextButton';
import { Tooltip } from './Tooltip';
import {
  Battery0,
  Battery100,
  Battery25,
  Battery50,
  Battery75,
  BatteryCharging,
  Chart,
  Check,
  Close,
  Edit,
  Notifications,
  Snowflake,
} from './icons';
import { PermissionQueryResponse } from '../features/common/models/permission-query-response';

interface Props {
  focused: boolean;
  deviceName: string;
  sanitizedDeviceName: string;
  serialNumber: string;
  customName?: string;
  onNameChange: (name: string | null) => void;
  batteryFillLevel?: number;
  batteryCharging?: boolean;
  pressureBar?: number;
  fillPressureBar?: number;
  temperatureCelsius?: number;
  updateTimestamp: number;
  thresholds: PressureThresholdView[];
  addPin: (threshold: PressureThresholdView) => void;
  editPin: (
    threshold: PressureThresholdView,
    updatedThreshold: PressureThresholdViewData
  ) => void;
  deletePin: (threshold: PressureThresholdView) => void;
  ManometerPairingElement: React.ReactNode;
  setShowHistory: React.Dispatch<React.SetStateAction<boolean>>;
  HistoryModal: React.ReactNode;
  permissionReadHistory: PermissionQueryResponse;
  permissionWriteThershold: PermissionQueryResponse;
  role?: AriaRole;
}

export const ManometerCard: React.FC<Props> = ({
  focused,
  deviceName,
  sanitizedDeviceName,
  serialNumber,
  customName,
  onNameChange,
  batteryFillLevel,
  batteryCharging,
  pressureBar,
  fillPressureBar,
  temperatureCelsius,
  updateTimestamp,
  thresholds,
  addPin,
  editPin,
  deletePin,
  ManometerPairingElement,
  setShowHistory,
  HistoryModal,
  permissionReadHistory,
  permissionWriteThershold,
  role,
}) => {
  const [editName, setEditName] = useState(false);
  const [name, setName] = useState(customName);
  const [addPinModalOpen, setAddPinModalOpen] = useState(false);
  const [editPinModalOpen, setEditPinModalOpen] = useState(false);
  const [currentPin, setCurrentPin] = useState<
    | {
        id: string;
        ref: React.RefObject<SVGGElement>;
      }
    | undefined
  >();
  const windowWidth = useWindowSize().width;

  useMemo(() => {
    if (currentPin) {
      setEditPinModalOpen(true);
    }
  }, [currentPin]);

  useCallback(() => {
    const handleClick = (e: MouseEvent) => {
      if (currentPin && currentPin.ref) {
        if (e.target !== currentPin.ref.current) {
        } else {
          setCurrentPin(undefined);
          return () => {
            document.removeEventListener('pointerdown', handleClick);
          };
        }
      }
    };

    if (currentPin && !editPinModalOpen) {
      document.addEventListener('pointerdown', handleClick);
    }
    return () => {
      document.removeEventListener('pointerdown', handleClick);
    };
  }, [editPinModalOpen, currentPin]);

  const handleHeaderSubmit = () => {
    const trimmedName = name?.trim() || '';
    setName(trimmedName);
    onNameChange(trimmedName.length ? trimmedName : null);
    setEditName(false);
  };

  // updates Name on cancel
  useEffect(() => {
    setName(customName);
  }, [customName]);

  const Header = () => {
    if (editName) {
      return (
        <div className={styles['gaas-manometer-card--header']}>
          <OutlinedInput
            fullWidth
            placeholder="Namen zuweisen…"
            value={name}
            onChange={e => setName(e.target.value)}
            sx={{ borderRadius: 0, fontFamily: 'WestfalenNewsSans' }}
          />
          <TextButton
            size="l"
            LeadingIcon={Check}
            onClick={handleHeaderSubmit}
          />
          <TextButton
            size="l"
            LeadingIcon={Close}
            onClick={() => {
              setEditName(false);
              setName(customName);
            }}
          />
        </div>
      );
    } else {
      return (
        <div className={styles['gaas-manometer-card--header']}>
          <h3 className={styles['gaas-manometer-card--header--title']}>
            {name ? name : 'Manometer'}
          </h3>

          <TextButton
            size="l"
            LeadingIcon={Edit}
            onClick={() => setEditName(true)}
            title={'Namen bearbeiten'}
          />
          <TextButton
            size="l"
            LeadingIcon={Chart}
            onClick={() => setShowHistory(true)}
            title={'Historie öffnen'}
            permission={permissionReadHistory}
          />
        </div>
      );
    }
  };

  const batteryIconClassName =
    styles['gaas-manometer-card--manometer--values--withIcon--icon'];
  let batteryIcon = <></>;
  let batteryText = '';
  if (batteryCharging === true) {
    batteryIcon = <BatteryCharging className={batteryIconClassName} />;
    batteryText = 'Lädt...';
  } else if (batteryFillLevel !== undefined) {
    batteryText = `${batteryFillLevel.toFixed(0)} %`;
    if (batteryFillLevel <= 10) {
      batteryIcon = <Battery0 className={batteryIconClassName} />;
    } else if (batteryFillLevel > 10 && batteryFillLevel <= 30) {
      batteryIcon = <Battery25 className={batteryIconClassName} />;
    } else if (batteryFillLevel > 30 && batteryFillLevel <= 60) {
      batteryIcon = <Battery50 className={batteryIconClassName} />;
    } else if (batteryFillLevel > 60 && batteryFillLevel <= 90) {
      batteryIcon = <Battery75 className={batteryIconClassName} />;
    } else {
      batteryIcon = <Battery100 className={batteryIconClassName} />;
    }
  }

  return (
    <>
      <Card
        focused={focused}
        id={deviceName}
        maxWidth={440}
        minWidth={windowWidth >= 1300 ? 440 : undefined}
        role={role}
      >
        {Header()}

        <div className={styles['gaas-manometer-card--manometer']}>
          <div className={styles['gaas-manometer-card--manometer--values']}>
            <div
              className={
                styles['gaas-manometer-card--manometer--values--withIcon']
              }
            >
              {batteryIcon}
              <span>{batteryText}</span>
            </div>
            <span style={{ textAlign: 'center' }}>{`${
              pressureBar ? pressureBar.toFixed(0) : '0'
            } bar`}</span>
            <div
              className={
                styles['gaas-manometer-card--manometer--values--withIcon']
              }
              style={{ justifySelf: 'end' }}
            >
              {temperatureCelsius && temperatureCelsius < 8 && (
                <Tooltip text="Temperaturen für Aktualisierung des Displays zu kalt. Informationen können hier abgerufen werden. Das Gerät funktioniert weiter.">
                  <Snowflake />
                </Tooltip>
              )}
              <span>{`${
                temperatureCelsius ? temperatureCelsius.toFixed(1) : '-'
              } °C`}</span>
            </div>
          </div>

          <Manometer
            skala={
              fillPressureBar != null
                ? fillPressureBar > 200
                  ? 300
                  : 200
                : fillPressureBar
            }
            pressureBar={pressureBar}
            pins={thresholds.map(({ pressureBar, color, id, ...rest }) => {
              return { value: pressureBar, color, id } as ManometerPin;
            })}
            setCurrentPin={pin => {
              if (permissionWriteThershold[0] === 'GRANTED') {
                setCurrentPin(pin);
              }
            }}
            permission={permissionWriteThershold}
          />

          <Button
            label="Marke hinzufügen"
            variant="outlined"
            size="l"
            fullWidth
            LeadingIcon={Notifications}
            onClick={() => setAddPinModalOpen(true)}
            permission={permissionWriteThershold}
          />
        </div>

        <Devider />

        <div className={styles['gaas-manometer-card--details']}>
          {ManometerPairingElement}

          <LabelAndText label="Seriennummer" text={serialNumber} />

          {Date.now() - updateTimestamp > 1000 * 60 * 5 + 1000 * 10 ? (
            <LabelAndText
              label="Letztes Update"
              text={
                <Tooltip text={moment(updateTimestamp).format('LLL')}>
                  {moment(updateTimestamp).fromNow()}
                </Tooltip>
              }
              state="warning"
            />
          ) : (
            <LabelAndText
              label="Letztes Update"
              text={
                <Tooltip text={moment(updateTimestamp).format('LLL')}>
                  Gerade eben
                </Tooltip>
              }
              state="success"
            />
          )}
        </div>
      </Card>

      {HistoryModal}

      <PinModal
        action="add"
        variant="manometer"
        open={addPinModalOpen}
        onClose={() => setAddPinModalOpen(false)}
        onSave={newPin => addPin(newPin)}
      />

      {currentPin &&
        thresholds
          .filter(pin => pin.id === currentPin.id)
          .map((pin, i) => (
            <PinModal
              action="edit"
              variant="manometer"
              open={editPinModalOpen}
              onClose={() => setEditPinModalOpen(false)}
              onSave={updatedPin => editPin(pin, updatedPin)}
              currentColor={pin.color}
              currentName={pin.name}
              currentValue={pin.pressureBar}
              emailNotifications={pin.emailRecipients}
              portalNotification={pin.portalNotification}
              onDelete={() => deletePin(pin)}
              key={pin.id}
            />
          ))}
    </>
  );
};
