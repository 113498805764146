import { FC, useCallback, useState } from 'react';
import { RootState } from 'typesafe-actions';
import { connect } from 'react-redux';
import { Input } from '../../../stories/Input';
import { Select } from '../../../stories/Select';
import styles from './ShopItemSelect.module.scss';
import { TextButton } from '../../../stories/TextButton';
import { Package } from '../../../stories/icons';
import classNames from 'classnames';
import ChangeAddressModal from './ChangeAddressModal';
import { Skeleton } from '../../../stories/Skeleton';
import { ApiGetShopAddressesResponse } from '../../../models/openapi/openapiTypes';
import useWindowSize from '../../../hooks/useWindowSize';

import { CartItem } from '../models/Cart';

const mapStateToProps = (state: RootState) => ({});

const dispatchProps = {};

interface ComponentProps {
  item: CartItem;
  price: string;
  name: string;
  options: { itemLabel: string; itemId: string }[];
  disabledOptions: { itemId: string; shippingAddressId: string }[];
  onItemQuantityAndAddressChanged: (item: CartItem) => void;
  onDelete?: () => void;
  addresses: ApiGetShopAddressesResponse;
}

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const ShopItemSelect: FC<Props> = ({
  item,
  name,
  price,
  options,
  onItemQuantityAndAddressChanged,
  onDelete,
  addresses,
  disabledOptions,
}) => {
  const internalId = item.id;
  const internalAddDate = item.addedTs;
  const itemId = item.itemId;
  const numItems = item.quantity;
  const shippingAddressId = item.shippingAddressId;

  const [modalOpen, setModalOpen] = useState(false);

  const { width } = useWindowSize();

  const onItemsChanged = useCallback(
    (num: number, address: string, id: string) => {
      onItemQuantityAndAddressChanged({
        id: internalId,
        addedTs: internalAddDate,
        itemId: id,
        quantity: num,
        shippingAddressId: address,
      });
    },
    [onItemQuantityAndAddressChanged, internalId, internalAddDate]
  );

  let addressContent = <Skeleton height={45} />;

  if (shippingAddressId !== undefined) {
    const shippingAddressIdContent = addresses.shippingAddresses.find(
      address => address.addressId === shippingAddressId
    );
    addressContent = (
      <>
        <div className={styles['gaas-shopItemSelect--address--left--address']}>
          <span>{shippingAddressIdContent?.name}</span>
          <span>
            {shippingAddressIdContent?.street},{' '}
            {shippingAddressIdContent?.zipCode} {shippingAddressIdContent?.city}
          </span>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles['gaas-shopItemSelect']}>
        <div className={styles['gaas-shopItemSelect--row']}>
          <div className={styles['gaas-shopItemSelect--input']}>
            <div className={styles['gaas-shopItemSelect--input--withPrice']}>
              <Input
                label="Menge"
                placeholder="Anzahl"
                type="number"
                value={numItems}
                name={`${itemId}|${shippingAddressId}-input`}
                onChange={event =>
                  onItemsChanged(
                    Number(event.target.value),
                    shippingAddressId!,
                    itemId
                  )
                }
                min={1}
                key={`${itemId}|${shippingAddressId}-input`}
              />
              {width < 768 && price && (
                <span className={styles['gaas-shopItemSelect--input--label']}>
                  {price} EUR / Mon.
                </span>
              )}
            </div>

            <Select
              label="Gewindetyp"
              id={`${itemId}|${shippingAddressId}-select`}
              onChange={value =>
                onItemsChanged(numItems, shippingAddressId!, value!)
              }
              options={options.map(option => ({
                label: option.itemLabel,
                value: option.itemId,
                disabled:
                  disabledOptions.find(
                    disabledOption =>
                      disabledOption.itemId === option.itemId &&
                      disabledOption.shippingAddressId === shippingAddressId
                  ) !== undefined,
              }))}
              value={itemId}
            />
            {width > 768 && price && (
              <span className={styles['gaas-shopItemSelect--input--label']}>
                {price} EUR / Mon.
              </span>
            )}
          </div>

          {width > 768 && (
            <div className={styles['gaas-shopItemSelect--input--button']}>
              <TextButton
                label="Löschen"
                onClick={
                  !itemId
                    ? onDelete
                    : () => onItemsChanged(0, shippingAddressId!, itemId)
                }
              />
            </div>
          )}
        </div>
        {addresses.shippingAddresses.length > 0 && (
          <>
            <div
              className={classNames(
                styles['gaas-shopItemSelect--row'],
                styles['gaas-shopItemSelect--address']
              )}
            >
              <div className={styles['gaas-shopItemSelect--address--left']}>
                <Package />
                {addressContent}
              </div>
              <div className={styles['gaas-shopItemSelect--address--right']}>
                <TextButton
                  label="Lieferadresse ändern"
                  onClick={() => setModalOpen(true)}
                />
              </div>
            </div>

            {width < 768 && (
              <div className={styles['gaas-shopItemSelect--input--button']}>
                <TextButton
                  label="Löschen"
                  onClick={
                    !itemId
                      ? onDelete
                      : () => onItemsChanged(0, shippingAddressId!, itemId)
                  }
                />
              </div>
            )}

            {modalOpen && (
              <ChangeAddressModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                shippingAddressId={shippingAddressId}
                setShippingAddressId={addressId =>
                  addressId !== shippingAddressId &&
                  onItemsChanged(numItems, addressId!, itemId)
                }
                addresses={addresses.shippingAddresses}
                disabledAddressIds={disabledOptions
                  .filter(
                    disabledOption =>
                      disabledOption.itemId === itemId &&
                      disabledOption.itemId !== shippingAddressId
                  )
                  .map(disabledOption => disabledOption.shippingAddressId)}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default connect(mapStateToProps, dispatchProps)(ShopItemSelect);
