import { createAction, createAsyncAction } from 'typesafe-actions';
import {
  ApiAnyNotification,
  ApiCustomer,
  ApiPermissionInformation,
} from '../../models/openapi/openapiTypes';
import { WsStreamingNotificationsType } from '../../models/zod/WsSchema';
import { FakeAuthCredentials } from '../../util/fakeAuth';
import { makeAsyncAction } from '../../util/makeAsyncAction';
import { GatewayStateView } from '../inventory/models/gateway-state-view';
import { MaintenanceNote } from './models/maintenance-note';
import { UserRole } from './models/user-role';

export const awaitTokenRefresh = createAction('AWAIT_TOKEN_REFRESH')<number>();

export const refreshUserTokenAsync = createAction('REFRESH_TOKEN')();

export const signedInAsync = createAsyncAction(
  'SIGNED_IN_REQUEST',
  'SIGNED_IN_SUCCESS',
  'SIGNED_IN_FAILURE'
)<undefined, { user: string; role: UserRole; token: string }, Error>();

export const signOutAsync = createAsyncAction(
  'SIGN_OUT_REQUEST',
  'SIGN_OUT_SUCCESS',
  'SIGN_OUT_FAILURE'
)<undefined, undefined, Error>();

export const signedInLocalAsync = createAsyncAction(
  'SIGNED_IN_LOCAL_REQUEST',
  'SIGNED_IN_LOCAL_SUCCESS',
  'SIGNED_IN_LOCAL_FAILURE'
)<
  FakeAuthCredentials,
  { user: string; role: UserRole; token: string },
  Error
>();

export const signOutLocalAsync = createAsyncAction(
  'SIGN_OUT_LOCAL_REQUEST',
  'SIGN_OUT_LOCAL_SUCCESS',
  'SIGN_OUT_LOCAL_FAILURE'
)<undefined, undefined, Error>();

export const onboardCustomersAsync = makeAsyncAction('ONBOARD_CUSTOMERS')<
  { user: string; role: UserRole; token: string },
  { permissions: ApiPermissionInformation },
  Error
>();

export const getCustomersAsync = createAsyncAction(
  'GET_CUSTOMERS_REQUEST',
  'GET_CUSTOMERS_SUCCESS',
  'GET_CUSTOMERS_FAILURE'
)<undefined, ApiCustomer[], Error>();

export const getNotificationsAsync = createAsyncAction(
  'GET_NOTIFICATIONS_REQUEST',
  'GET_NOTIFICATIONS_SUCCESS',
  'GET_NOTIFICATIONS_FAILURE'
)<undefined, ApiAnyNotification[], Error>();

export const notificationPush =
  createAction('NOTIFICATION_PUSH')<WsStreamingNotificationsType>();

export const readAllNotificationsAsync = createAsyncAction(
  'READ_ALL_NOTIFICATIONS_REQUEST',
  'READ_ALL_NOTIFICATIONS_SUCCESS',
  'READ_ALL_NOTIFICATIONS_FAILURE'
)<undefined, undefined, Error>();

export const readNotificationAsync = createAsyncAction(
  'READ_NOTIFICATION_REQUEST',
  'READ_NOTIFICATION_SUCCESS',
  'READ_NOTIFICATION_FAILURE'
)<string, undefined, { error: Error; id: string }>();

export const getGatewayStatesAsync = createAsyncAction(
  'GET_GATEWAY_STATE_REQUEST',
  'GET_GATEWAY_STATE_SUCCESS',
  'GET_GATEWAY_STATE_FAILURE'
)<
  string,
  { states: GatewayStateView[]; customerId: string },
  { error: Error; customerId: string }
>();

export const fireRayAsync = createAsyncAction(
  'FIRE_RAY_REQUEST',
  'FIRE_RAY_SUCCESS',
  'FIRE_RAY_FAILURE'
)<string, string, Error>();

export const getMaintenanceNoteAsync = createAsyncAction(
  'GET_MAINTENANCE_NOTE_REQUEST',
  'GET_MAINTENANCE_NOTE_SUCCESS',
  'GET_MAINTENANCE_NOTE_FAILURE'
)<undefined, MaintenanceNote | null, Error>();

export const selectCustomer = createAction('SELECT_CUSTOMER')<
  ApiCustomer | undefined
>();

export const selectedCustomerChanged = createAction(
  'SELECTED_CUSTOMER_CHANGED'
)<ApiCustomer | undefined>();
