import { ApiSubmitCartRequest } from '../../../models/openapi/openapiTypes';
import { Cart, cartToApiCart } from './Cart';

export type SubmitCartRequest = Omit<ApiSubmitCartRequest, 'cart'> & {
  cart: Cart;
};

export function submitCartRequestToApiSubmitCartRequest(
  submitCartRequest: SubmitCartRequest
): ApiSubmitCartRequest {
  return {
    ...submitCartRequest,
    cart: cartToApiCart(submitCartRequest.cart),
  };
}
