import React, { AriaRole, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useTimer } from 'react-timer-hook';
import { RootState } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';
import { ManometerCard } from '../../../stories/ManometerCard';
import { selectedCustomer } from '../../common/selectors';
import * as digidrumiActions from '../actions';
import { ContainerValvePairing } from '../models/container-valve-pairing';
import { PressureThresholdView } from '../models/pressure-threshold-view';
import DigiDrumiHistoryModal from './DigiDrumiHistoryModal';
import DigiDrumiPairing from './DigiDrumiPairing';
import * as commonSelectors from '../../common/selectors';
import { ApiGaasAction } from '../../../models/openapi/openapiTypes';

const mapStateToProps = (state: RootState) => ({
  currentCustomer: selectedCustomer(state.common),
  hasPermission: (action: ApiGaasAction) =>
    commonSelectors.hasPermission(action, state.common),
});

const dispatchProps = {
  createThreshold: digidrumiActions.createThresholdAsync.request,
  deleteThreshold: digidrumiActions.deleteThresholdAsync.request,
  updateThreshold: digidrumiActions.updateThresholdAsync.request,
};

type ComponentProps = {
  deviceName: string;
  serialNumber: string;
  updateTimestamp: number;
  location?: string;
  currentPairing?: ContainerValvePairing;
  pressureBar?: number;
  fillPressureBar?: number;
  remainingTimeMinutes?: number;
  temperatureCelsius?: number;
  onLocationChange: (location: string | null) => void;
  open: boolean;
  thresholds: PressureThresholdView[];
  batteryFillLevel?: number;
  batteryCharging?: boolean;
  role?: AriaRole;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const DigiDrumiCard: React.FC<Props> = ({
  deviceName,
  serialNumber,
  updateTimestamp,
  location,
  currentPairing,
  pressureBar,
  fillPressureBar,
  remainingTimeMinutes,
  temperatureCelsius,
  onLocationChange,
  open,
  thresholds,
  deleteThreshold,
  createThreshold,
  updateThreshold,
  currentCustomer,
  batteryFillLevel,
  batteryCharging,
  hasPermission,
  role,
}) => {
  const sanitizedDeviceName = deviceName.split('_')[2].substring(0, 12);
  //Glow on Route match
  interface RouteParams {
    serialNumber: string;
  }
  let { serialNumber: routeSerialNumber } = useParams<RouteParams>();
  // check if there is additional state
  // this timestamp allows us to trigger the fade effect again,
  // even if the route does not change
  const { state } = useLocation() as {
    state: { notificationAccessedAt: number };
  };
  const notificationAccessedAt = state?.notificationAccessedAt;
  const [focused, setFocused] = useState(false);
  const { restart: restartFocusTimer } = useTimer({
    autoStart: false,
    expiryTimestamp: new Date(),
    onExpire: () => {
      setFocused(false);
    },
  });

  useEffect(() => {
    if (serialNumber === routeSerialNumber) {
      setFocused(true);
      restartFocusTimer(new Date(Date.now() + 2000), true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serialNumber, routeSerialNumber, notificationAccessedAt]);

  const [showHistory, setShowHistory] = useState(false);

  return (
    <ManometerCard
      role={role}
      focused={focused}
      deviceName={deviceName}
      batteryCharging={batteryCharging}
      batteryFillLevel={batteryFillLevel}
      sanitizedDeviceName={sanitizedDeviceName}
      serialNumber={serialNumber}
      customName={location}
      onNameChange={onLocationChange}
      pressureBar={pressureBar}
      fillPressureBar={fillPressureBar}
      temperatureCelsius={temperatureCelsius}
      updateTimestamp={updateTimestamp}
      thresholds={thresholds}
      addPin={threshold => {
        createThreshold({
          threshold: {
            ...threshold,
            deviceId: deviceName,
            id: uuidv4(),
          },
          customerId: currentCustomer!.customerId,
        });
      }}
      editPin={(threshold, updatedThreshold) =>
        updateThreshold({
          threshold: {
            ...updatedThreshold,
            id: threshold.id,
            deviceId: threshold.deviceId,
          },
          customerId: currentCustomer!.customerId,
        })
      }
      deletePin={threshold =>
        deleteThreshold({
          thresholdId: threshold.id,
          serialNumber,
          customerId: currentCustomer!.customerId,
        })
      }
      ManometerPairingElement={
        <DigiDrumiPairing
          customerId={currentCustomer!.customerId}
          serialNumber={serialNumber}
          currentPairing={currentPairing}
        />
      }
      setShowHistory={setShowHistory}
      HistoryModal={
        <DigiDrumiHistoryModal
          open={showHistory}
          onClose={() => setShowHistory(false)}
          deviceId={deviceName}
          serialNumber={serialNumber}
        />
      }
      permissionReadHistory={hasPermission('meter.history.read')}
      permissionWriteThershold={hasPermission('threshold.pressure.write')}
    />
  );
};

export default connect(mapStateToProps, dispatchProps)(DigiDrumiCard);
