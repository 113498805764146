import { Epic } from 'redux-observable';
import {
  catchError,
  debounceTime,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf, RootAction, RootState, Services } from 'typesafe-actions';
import { of, from, iif } from 'rxjs';
import {
  calculateCartAsync,
  clearCachedRenderedContract,
  createFreeSubscriptionAsync,
  currentCartChangeBillingInterval,
  currentCartChangeItemQuantity,
  currentCartChangeReference,
  getShopAccessibleCustomersAsync,
  getShopAddressesAsync,
  getShopItemsAsync,
  loadOrCreateCartForCustomer,
  renderContractAsync,
  signInShopUser,
  signInShopUserRejected,
  signInShopUserSuccess,
  storeCartLocal,
  submitCartAsync,
} from './actions';
import { SavedCart } from './models/SavedCart';
import { v4 as uuidv4 } from 'uuid';
import { ShopAuth } from '../../util/shopAuth';
import { Cart } from './models/Cart';

export const calculateCart: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state, { shop }) =>
  action$.pipe(
    filter(isActionOf(calculateCartAsync.request)),
    debounceTime(500),
    withLatestFrom(state),
    switchMap(([_, state]) =>
      shop.calculateCart(state.shop.currentCart).pipe(
        map(cart => calculateCartAsync.success(cart)),
        catchError(error =>
          of(
            calculateCartAsync.failure({
              cartId: state.shop.currentCart.cartId,
              error,
            })
          )
        )
      )
    ),
    switchMap(action => from([clearCachedRenderedContract(), action]))
  );

export const submitCart: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  _,
  { shop }
) =>
  action$.pipe(
    filter(isActionOf(submitCartAsync.request)),
    switchMap(({ payload }) =>
      shop.submitCart(payload).pipe(
        map(() => submitCartAsync.success({ cartId: payload.cart.cartId })),
        catchError(error =>
          of(
            submitCartAsync.failure({
              cartId: payload.cart.cartId,
              error,
            })
          )
        )
      )
    )
  );

export const onCreateFreeSubscription: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { shop }) =>
  action$.pipe(
    filter(isActionOf(createFreeSubscriptionAsync.request)),
    switchMap(({ payload: { sapCustomerNo } }) =>
      shop.createFreeSubscription(sapCustomerNo).pipe(
        map(() => createFreeSubscriptionAsync.success({ sapCustomerNo })),
        catchError(error =>
          of(
            createFreeSubscriptionAsync.failure({
              sapCustomerNo,
              error,
            })
          )
        )
      )
    )
  );

export const renderContract: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { shop }) =>
  action$.pipe(
    filter(isActionOf(renderContractAsync.request)),
    switchMap(({ payload }) =>
      shop.renderContract(payload).pipe(
        map(doc =>
          renderContractAsync.success({ cartId: payload.cartId, contract: doc })
        ),
        catchError(error =>
          of(renderContractAsync.failure({ cartId: payload.cartId, error }))
        )
      )
    )
  );

export const getShopAddresses: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { shop }) =>
  action$.pipe(
    filter(isActionOf(getShopAddressesAsync.request)),
    switchMap(({ payload: { sapCustomerNo } }) =>
      shop.getAddresses(sapCustomerNo).pipe(
        map(addresses =>
          getShopAddressesAsync.success({ sapCustomerNo, addresses })
        ),
        catchError(error =>
          of(getShopAddressesAsync.failure({ sapCustomerNo, error }))
        )
      )
    )
  );

export const getShopItems: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  _,
  { shop }
) =>
  action$.pipe(
    filter(isActionOf(getShopItemsAsync.request)),
    switchMap(() =>
      shop.getShopItems().pipe(
        map(items => getShopItemsAsync.success(items)),
        catchError(error => of(getShopItemsAsync.failure(error)))
      )
    )
  );

export const getShopAccessibleCustomers: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { shop }) =>
  action$.pipe(
    filter(isActionOf(getShopAccessibleCustomersAsync.request)),
    switchMap(() =>
      shop.getAccessibleCustomers().pipe(
        map(items => getShopAccessibleCustomersAsync.success(items)),
        catchError(error => of(getShopAccessibleCustomersAsync.failure(error)))
      )
    )
  );

export const onLoadOrCreateCartForCustomer: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { shop }) =>
  action$.pipe(
    filter(isActionOf(loadOrCreateCartForCustomer)),
    map(({ payload: { sapCustomerNo, defaultItems } }): Cart => {
      const cartJson = localStorage.getItem(`GAAS-CART-${sapCustomerNo}`);
      if (cartJson) {
        const parsedCartCandidate = JSON.parse(cartJson) as SavedCart;
        if (parsedCartCandidate.version === 'v1') {
          return parsedCartCandidate.cart;
        }
      }

      return {
        billingInterval: 'monthly',
        cartId: uuidv4(),
        items: defaultItems,
        sapCustomerNo,
      };
    }),
    switchMap(cart =>
      from([storeCartLocal(cart), calculateCartAsync.request()])
    )
  );

export const onChangeBillingInterval: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state, { shop }) =>
  action$.pipe(
    filter(isActionOf(currentCartChangeBillingInterval)),
    withLatestFrom(state),
    switchMap(([{ payload: billingInterval }, state]) =>
      of({
        ...state.shop.currentCart,
        billingInterval,
      })
    ),
    switchMap(cart =>
      from([storeCartLocal(cart), calculateCartAsync.request()])
    )
  );

export const onChangeItemQuantity: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state, { shop }) =>
  action$.pipe(
    filter(isActionOf(currentCartChangeItemQuantity)),
    withLatestFrom(state),
    switchMap(([{ payload: cartItem }, state]) => {
      const currentCart = state.shop.currentCart;
      let currentCartItems = currentCart.items;

      // remove the existing item from the cart
      currentCartItems = currentCartItems.filter(
        item => item.id !== cartItem.id
      );

      // push the item again if it has a quantity > 0
      if (cartItem.quantity > 0) {
        currentCartItems.push(cartItem);
      }

      return of({
        ...currentCart,
        items: currentCartItems,
      });
    }),
    switchMap(cart =>
      from([storeCartLocal(cart), calculateCartAsync.request()])
    )
  );

export const onCurrentCartChangeReference: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state, { shop }) =>
  action$.pipe(
    filter(isActionOf(currentCartChangeReference)),
    withLatestFrom(state),
    switchMap(
      ([
        {
          payload: { reference },
        },
        state,
      ]) => {
        const currentCart = state.shop.currentCart;
        return of({
          ...currentCart,
          reference,
        });
      }
    ),
    switchMap(cart =>
      from([storeCartLocal(cart), calculateCartAsync.request()])
    )
  );

export const onShopUserSignIn: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _) =>
  action$.pipe(
    filter(isActionOf(signInShopUser)),
    map(({ payload: { jwtToken } }) => {
      // The following code unpacks the JWT token and checks if it is expired.
      // It is important to note that the integrity of the token as well as the
      // signature is not checked. These checks are performed by the backend for each
      // request. The expiry check is just a friendly reminder to the user that
      // the following requests will fail. If the user forces a backend call somehow
      // he will receive a 403
      const jwtPayloadBase64 = jwtToken
        .split('.')[1]
        .replace(/-/g, '+')
        .replace(/_/g, '/');
      const decodedJwt = JSON.parse(window.atob(jwtPayloadBase64)) as {
        iat: number | undefined;
      };
      const iat = decodedJwt?.iat ?? 0;
      return { jwtToken, iat };
    }),
    switchMap(({ jwtToken, iat }) =>
      iif(
        // We assume that the token expires after 29 days
        () => Date.now() / 1000 - iat <= 60 * 60 * 24 * 29,
        of(signInShopUserSuccess({ jwtToken })),
        of(signInShopUserRejected({ reason: 'expired' }))
      )
    ),
    catchError(() => of(signInShopUserRejected({ reason: 'invalid' })))
  );

export const onShopUserSignInSuccess: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _) =>
  action$.pipe(
    filter(isActionOf(signInShopUserSuccess)),
    tap(({ payload: { jwtToken } }) => {
      ShopAuth.setToken(jwtToken);
    }),
    switchMap(() => of(getShopAccessibleCustomersAsync.request()))
  );
