import React, { useCallback, useEffect, useState } from 'react';
import { CameraConsentGuard } from './CameraConsentGuard';
import { CameraStreamProvider } from './CameraStreamProvider';
import { CameraVideoFrameCallbackProvider } from './CameraVideoFrameCallbackProvider';
import QuaggaBarcodeScannerV2 from './QuaggaBarcodeScannerV2';
import CameraCanvasRenderer from './CameraCanvasRenderer';
import NativeBarcodeScanner from './NativeBarcodeScanner';
import styles from './BarcodeScannerV2.module.scss';
import { BarcodeScannerV2Controls } from './BarcodeScannerV2Controls';
import {
  BarcodeVerificationResult,
  verifyWestfalenBarcode,
} from './BarcodeUtils';
import { writeLastSuccessfulCameraIdToLocalStore } from './CameraUtil';
import { ScanDetection } from '../../features/scanning/models/scan-result';

interface Props {
  onBarcodeFound: (barcode: string) => void;
  debugDrawIn?: boolean;
}

export const BarcodeScannerV2: React.FC<Props> = ({
  onBarcodeFound,
  debugDrawIn,
}) => {
  // @ts-expect-error The barcode detector api is only available in Chrome on Android right now, see https://caniuse.com/mdn-api_barcodedetector
  const hasNativeBarcodeScanner = typeof BarcodeDetector !== 'undefined';
  const [barcodeHistory] = useState<string[]>([]);
  const [debounceState] = useState<{ barcode: string; ts: number }[]>([]);

  useEffect(() => {
    return () => {
      debounceState.slice(0);
    };
  }, [debounceState]);

  useEffect(() => {
    const intervalHandel = setInterval(() => {
      const now = Date.now();
      const filtered = debounceState.filter(it => now - it.ts < 5000);
      debounceState.splice(0).concat(...filtered);
    }, 1000);
    return () => {
      clearInterval(intervalHandel);
    };
  }, [debounceState]);

  const onBarcodeFoundWrapper = useCallback(
    (barcode: ScanDetection, cameraId) => {
      const verification = verifyWestfalenBarcode(barcode);
      if (verification === BarcodeVerificationResult.INVALID) {
        return;
      }

      barcodeHistory.push(barcode.code);
      const minScans = 8;
      const minBufferLen = 24;
      while (barcodeHistory.length > minBufferLen) {
        barcodeHistory.shift();
      }

      // if the barcode is a valid 14 char barcode (without the additional checksum), \
      // we try to reduce scan errors by requiring a minimum number of scans before we accept it
      if (
        verification === BarcodeVerificationResult.VALID_14 &&
        (barcodeHistory.length < minBufferLen ||
          barcodeHistory.reduce(
            (acc, code): number => (code === barcode.code ? acc + 1 : acc),
            0
          ) < minScans)
      ) {
        return;
      }

      if (debounceState.find(it => it.barcode === barcode.code)) {
        return;
      }
      debounceState.push({ barcode: barcode.code, ts: Date.now() });

      barcodeHistory.splice(0);
      // Writing the camera id that was able to scan a barcode here is a bit of a layer violation,
      // but this is the easiest way to pass information back the camera management components, which,
      // in theory, don't know about the whole barcode scanning shenanigans.
      writeLastSuccessfulCameraIdToLocalStore(cameraId);

      // in case the code was a 16 char code, we only take the first 14 chars
      const barcodePayload = barcode.code.substring(0, 14);
      onBarcodeFound(barcodePayload);
    },
    [onBarcodeFound, barcodeHistory, debounceState]
  );

  return (
    <div className={styles['gaas-barcode-scanner']}>
      <CameraConsentGuard>
        <CameraStreamProvider hidden={true}>
          <CameraVideoFrameCallbackProvider>
            <div className={styles['gaas-barcode-scanner--container']}>
              {hasNativeBarcodeScanner && (
                <NativeBarcodeScanner onBarcodeFound={onBarcodeFoundWrapper} />
              )}
              {!hasNativeBarcodeScanner && (
                <QuaggaBarcodeScannerV2
                  onBarcodeFound={onBarcodeFoundWrapper}
                />
              )}
              <CameraCanvasRenderer debugDrawIn={debugDrawIn} />

              <BarcodeScannerV2Controls />
            </div>
          </CameraVideoFrameCallbackProvider>
        </CameraStreamProvider>
      </CameraConsentGuard>
    </div>
  );
};
