import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Quagga, {
  ImageWrapper,
  QuaggaJSConfigObject,
  QuaggaJSResultObject,
  QuaggaJSResultObject_CodeResult,
} from '@ericblade/quagga2';
import { useCameraStream } from './CameraStreamProvider';
import { useCameraVideoFeedCallbacks } from './CameraVideoFrameCallbackProvider';
import * as scannerActions from '../../features/scanning/actions';
import { RootState } from 'typesafe-actions';
import { connect } from 'react-redux';
import {
  ScanCandidate,
  ScanDetection,
  ScanDetectionCodeSet,
  ScanResult,
} from '../../features/scanning/models/scan-result';
import { BarcodeScannerResultCallback } from './CameraUtil';

interface ComponentProps {
  onBarcodeFound?: BarcodeScannerResultCallback;
}

const mapStateToProps = (_: RootState) => ({});

const dispatchProps = {
  newScannerResult: scannerActions.newScannerResult,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const quaggaBaseConfig: QuaggaJSConfigObject = {
  src: undefined,
  inputStream: {
    // we don't need an input stream
  },
  // locator,
  locator: {
    // The patchSize is proportional to the size of the scanned barcodes.
    // If you have really large barcodes which can be read close-up, then the use of large or x-large is recommended
    patchSize: 'medium',
    // halfSample does not work with our setup, as
    // this param is handled by the frame grabber, which we skip
    // halfSample: true,
    willReadFrequently: true,
  },
  decoder: {
    readers: ['code_128_reader'],
    multiple: false,
  },
  locate: true,
};

function getMedian(arr: number[]): number | undefined {
  if (arr.length === 0) {
    return undefined;
  }

  const newArr = [...arr]; // copy the array before sorting, otherwise it mutates the array passed in, which is generally undesireable
  newArr.sort((a, b) => a - b);
  const half = Math.floor(newArr.length / 2);
  if (newArr.length % 2 === 1) {
    return newArr[half];
  }
  return (newArr[half - 1] + newArr[half]) / 2;
}

function getMedianOfCodeErrors(
  decodedCodes: QuaggaJSResultObject_CodeResult['decodedCodes']
) {
  const errors = decodedCodes
    .flatMap(x => x.error)
    .filter((x): x is number => x !== undefined);
  return getMedian(errors);
}

const QuaggaBarcodeScannerV2: React.FC<Props> = ({
  onBarcodeFound,
  newScannerResult,
}) => {
  const [framebufferSize, setFramebufferSize] = React.useState<{
    width: number;
    height: number;
  }>({ width: 100, height: 100 });
  const [framebufferRef, setFramebufferRef] =
    useState<HTMLCanvasElement | null>(null);
  const framebufferRefCb = useCallback(node => {
    if (node !== null) {
      setFramebufferRef(node);
    }
  }, []);

  const [processingState] = useState<{
    isProcessing: boolean;
  }>({ isProcessing: false });

  const { subscribeToFrames, unsubscribeFromFrames } =
    useCameraVideoFeedCallbacks();

  const { videoRef, cameraId } = useCameraStream();

  const scaleFactor = useMemo<number>(() => {
    if (!videoRef) {
      return 0.5;
    }
    const { videoWidth, videoHeight } = videoRef;
    const scaleThreshold = 1024;
    if (videoWidth > scaleThreshold || videoHeight > scaleThreshold) {
      return 0.5;
    }

    return 1;
  }, [videoRef]);

  useEffect(() => {
    if (!videoRef) {
      return;
    }
    setFramebufferSize({
      width: videoRef.videoWidth * scaleFactor,
      height: videoRef.videoHeight * scaleFactor,
    });
  }, [videoRef, videoRef?.videoHeight, videoRef?.videoWidth, scaleFactor]);

  const [quaggaReady, setQuaggaReady] = useState<boolean>(false);
  const [quaggaImageBuf, setQuaggaImageBuf] = useState<
    ImageWrapper | undefined
  >(undefined);

  const onNewScannerResult = useCallback(
    (result: ScanResult) => {
      newScannerResult(result);
      const { detected } = result;
      if (onBarcodeFound && detected) {
        onBarcodeFound(detected, cameraId);
      }
    },
    [newScannerResult, onBarcodeFound, cameraId]
  );

  useEffect(() => {
    const imageWrapper = new ImageWrapper({
      x: framebufferSize.width,
      y: framebufferSize.height,
      type: 'XYSize',
    });

    setQuaggaImageBuf(imageWrapper);
  }, [setQuaggaImageBuf, framebufferSize.width, framebufferSize.height]);

  useEffect(() => {
    if (!quaggaImageBuf) {
      return;
    }
    let ignoreStart = false;
    const init = async () => {
      // wait for one tick to see if we get unmounted before we can possibly even begin cleanup
      await new Promise(resolve => setTimeout(resolve, 100));
      if (ignoreStart) {
        return;
      }

      await Quagga.init(
        {
          ...quaggaBaseConfig,
          inputStream: {
            ...quaggaBaseConfig.inputStream,
            // @ts-ignore
            target: undefined,
          },
          locator: {
            ...quaggaBaseConfig.locator,
          },
        },
        // @ts-expect-error we want to use the promise variant of the init function, but need to specify an image buffer
        undefined,
        quaggaImageBuf
      );

      Quagga.onProcessed(result => {
        processingState.isProcessing = false;

        let resultOrResultArray = result as unknown as
          | QuaggaJSResultObject[]
          | QuaggaJSResultObject
          | null
          | undefined;
        if (!resultOrResultArray) {
          return;
        }

        if (!(resultOrResultArray instanceof Array)) {
          resultOrResultArray = [resultOrResultArray];
        }
        const candidatesWithoutCode: ScanCandidate[] = [];
        const candidatesWithCode: ScanDetection[] = [];
        for (const candidate of resultOrResultArray) {
          const { codeResult } = candidate;
          const code = codeResult?.code;
          const errorMedian = getMedianOfCodeErrors(
            codeResult?.decodedCodes ?? []
          );
          const codeSetValue = codeResult?.codeset;
          let codeSet: ScanDetectionCodeSet | undefined = undefined;
          // see https://github.com/ericblade/quagga2/blob/master/src/reader/code_128_reader.ts#L416
          // for this mapping
          switch (codeSetValue) {
            case 99:
              codeSet = ScanDetectionCodeSet.VARIANT_C;
              break;
            case 100:
              codeSet = ScanDetectionCodeSet.VARIANT_B;
              break;
            case 101:
              codeSet = ScanDetectionCodeSet.VARIANT_A;
              break;
          }

          if (
            codeResult &&
            code !== null &&
            errorMedian !== undefined &&
            codeSet !== undefined
          ) {
            candidatesWithCode.push({
              code,
              roi: candidate.box.map(arr =>
                arr.map(u => u * (1 / scaleFactor))
              ),
              error: errorMedian,
              codeSet: codeSet,
            });
          } else {
            candidatesWithoutCode.push({
              roi: (candidate.box ? candidate.box : candidate.boxes[0]).map(
                arr => arr.map(u => u * (1 / scaleFactor))
              ),
            });
          }
        }

        candidatesWithCode.sort((a, b) => a.error - b.error);

        onNewScannerResult({
          timestamp: new Date(),
          detected: candidatesWithCode[0],
          candidates: [
            ...candidatesWithoutCode,
            ...candidatesWithCode.slice(1),
          ],
        });
      });
      setQuaggaReady(true);
    };
    init();
    return () => {
      ignoreStart = true;
    };
  }, [
    quaggaImageBuf,
    onNewScannerResult,
    setQuaggaReady,
    processingState,
    scaleFactor,
  ]);

  const onNewFrame = useMemo(() => {
    return () => {
      if (!framebufferRef || !videoRef || !quaggaReady || !quaggaImageBuf) {
        return;
      }
      if (processingState.isProcessing) {
        return;
      }
      processingState.isProcessing = true;

      const ctx = framebufferRef.getContext('2d');
      if (!ctx) {
        processingState.isProcessing = false;
        return;
      }

      // as of today, 2024-04-12, safari does not support the filter property on canvas elements
      // see https://caniuse.com/mdn-api_canvasrenderingcontext2d_filter
      const canvasFilterSupported = false;
      // const canvasFilterSupported = 'filter' in ctx;
      if (canvasFilterSupported) {
        ctx.filter = 'grayscale(100%)';
      }

      ctx.drawImage(
        videoRef,
        0,
        0,
        videoRef.videoWidth,
        videoRef.videoHeight,
        0,
        0,
        framebufferSize.width,
        framebufferSize.height
      );
      const imgData = ctx.getImageData(
        0,
        0,
        framebufferSize.width,
        framebufferSize.height
      );

      if (!canvasFilterSupported) {
        // if our browser does not support canvas filters, we need to manually convert the image to grayscale
        for (let i = 0; i < quaggaImageBuf.data.length; i += 1) {
          //see https://github.com/ericblade/quagga2/blob/96d9891a4f0e9c9559d2bccc69b6f7d10bd112e0/src/common/cv_utils.js#L553
          quaggaImageBuf.data[i] =
            0.299 * imgData.data[i * 4 + 0] +
            0.587 * imgData.data[i * 4 + 1] +
            0.114 * imgData.data[i * 4 + 2];
        }
      } else {
        // if the browser supports canvas filters, we can just copy the data
        for (let i = 0; i < quaggaImageBuf.data.length; i += 1) {
          quaggaImageBuf.data[i] = imgData.data[i * 4];
        }
      }
      Quagga.locateAndDecodeOnce();
    };
  }, [
    framebufferRef,
    videoRef,
    processingState,
    quaggaImageBuf,
    quaggaReady,
    framebufferSize.height,
    framebufferSize.width,
  ]);

  useEffect(() => {
    const handle = subscribeToFrames(onNewFrame);
    return () => {
      unsubscribeFromFrames(handle);
    };
  }, [videoRef, onNewFrame, subscribeToFrames, unsubscribeFromFrames]);

  return (
    <canvas
      style={{ display: 'none' }}
      ref={framebufferRefCb}
      width={framebufferSize.width}
      height={framebufferSize.height}
      id={'BarcodeReaderFramebuffer'}
    />
  );
};

export default connect(mapStateToProps, dispatchProps)(QuaggaBarcodeScannerV2);
