import {
  ApiAutomaticContainerState,
  ApiAutomaticContainerStateView,
  ApiContainerManagementType,
  ApiManualContainerState,
  ApiManualContainerStateView,
} from '../../../models/openapi/openapiTypes';
import { ContainerStateView } from './inventory';

export type ContainerManagementType = ApiContainerManagementType;
export type ManualContainerFillLevel = 'FULL' | 'HALF' | 'EMPTY'; // = ApiManualContainerFillLevel;

export type ManualContainerState = ApiManualContainerState;
export type AutomaticContainerState = ApiAutomaticContainerState;

export type ContainerState = ManualContainerState | AutomaticContainerState;

export type ManualContainerStateView = ApiManualContainerStateView;
export type AutomaticContainerStateView = ApiAutomaticContainerStateView;

export function isManualContainerState(
  state: ContainerState
): state is ManualContainerState {
  return state.management === 'MANUAL';
}

export function isAutomaticContainerState(
  state: ContainerState | ContainerStateView
): state is AutomaticContainerState {
  return state.management === 'AUTOMATIC';
}

export const isContainerStateView = (
  container: ContainerState | ContainerStateView
): container is ContainerStateView => {
  return 'groupId' in container;
};
