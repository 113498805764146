import { Container } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { Devider } from '../../../stories/Devider';
import styles from './AdministrationPage.module.scss';

const AdministrationPage: React.FC = () => {
  return (
    <Container className={styles['gaas-administration-page']}>
      <h1>Administration</h1>
      <Devider />
      <Link to={'/admin/valves'}>Meter</Link>
      <Devider />
      <Link to={'/admin/gateways'}>Gateways</Link>
      <Devider />
      <Link to={'/admin/maintenance'}>Maintenance & Incidents</Link>
      <Devider />
      <Link to={'/admin/barcode-scanner'}>Barcodescanner Test</Link>
      <Devider />
      <Link to={'/admin/barcode-scanner-quagga-base'}>
        Quagga-Wie-Im-Shop-Barcodescanner Test
      </Link>
      <Devider />
    </Container>
  );
};

export default AdministrationPage;
