import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BarcodeScannerV2 } from '../../../components/barcode-scanner-v2/BarcodeScannerV2';
import { BarcodeScannerModal } from '../../../stories/BarcodeScannerModal';

const InventoryBarcodeScannerPage: React.FC = () => {
  const history = useHistory();
  const { customer } = useParams<{ customer: string }>();

  const onBarcodeFound = useCallback(
    (barcode: string) => {
      history.push(`/inventory/inspect-bottle/${customer}/${barcode}`);
    },
    [customer, history]
  );

  return (
    <BarcodeScannerModal onClose={() => history.push(`/inventory`)}>
      <BarcodeScannerV2 onBarcodeFound={onBarcodeFound} />
    </BarcodeScannerModal>
  );
};

export default InventoryBarcodeScannerPage;
