import { TypeConstant, createAsyncAction } from 'typesafe-actions';

export const makeAsyncAction = <T extends TypeConstant>(name: T) => {
  return <TReq, TSuccess, TFailure>() => {
    return createAsyncAction<`${T}_REQUEST`, `${T}_SUCCESS`, `${T}_FAILURE`>(
      `${name}_REQUEST`,
      `${name}_SUCCESS`,
      `${name}_FAILURE`
    )<TReq, TSuccess, TFailure>();
  };
};
